import { ReactNode, useMemo, useState } from 'react'
import { groupBy } from 'lodash'
import {
  MoveHandler,
  NodeApi,
  NodeRendererProps,
  SimpleTree,
  Tree,
} from 'react-arborist'
import {
  RiDeleteBinLine,
  RiEditBoxLine,
  RiFileCopyLine,
  RiFolderLine,
  RiFolderOpenLine,
  RiQuestionFill,
} from 'react-icons/ri'
import { Tooltip, TooltipProvider, TooltipWrapper } from 'react-tooltip'

import useLocalStorage from '@/frontend/hooks/useLocalStorage'
import { OrderParams, updateOrder } from '../api'
import { DuplicateModal } from './DuplicateModal'
import { EditModal } from './EditModal'
import { CustomReport, CustomReportFolder, Data, Item, SubProps } from './type'

type Props = {
  orgId: number
  updateOrderUrl: string
  items: Item[]
  subProps: SubProps
  isSortable: boolean
  reportsEditableById: { [id: number]: boolean }
  reportsDestroyableById: { [id: number]: boolean }
  reportsDuplicatableById: { [id: number]: boolean }
  foldersEditableById: { [id: number]: boolean }
  foldersDestroyableById: { [id: number]: boolean }
}

type initialOpenStateType = { [id: string]: boolean }

const ROW_HEIGHT = 60

export function IndexList({
  orgId,
  updateOrderUrl,
  items,
  subProps,
  isSortable,
  reportsEditableById,
  reportsDestroyableById,
  reportsDuplicatableById,
  foldersEditableById,
  foldersDestroyableById,
}: Props) {
  const INITIAL_OPEN_STATE_STORAGE_KEY =
    `CustomReportFolderInitialOpenState_${orgId}` as const
  const initialData = itemsToData(items, subProps)
  const [data, setData] = useState(initialData)
  const tree = useMemo(() => new SimpleTree(data), [data])
  const onMove: MoveHandler<Data> = (args: {
    dragIds: string[]
    parentId: null | string
    index: number
    parentNode: NodeApi<Data> | null
  }) => {
    for (const id of args.dragIds) {
      tree.move({ id, parentId: args.parentId, index: args.index })
    }
    setData(tree.data)

    if (
      args.parentNode &&
      args.parentNode.data.item.type === 'CustomReportFolder'
    ) {
      updateInitialOpenState(args.parentNode.id, true)
    }

    // APIしばき
    const updateParams = dataToApiParams(tree.data)
    updateOrder(updateOrderUrl, {
      orderParams: updateParams,
      template: false,
    })
  }

  const NodeWithSubProps = (props: NodeRendererProps<Data>) => (
    <Node
      {...props}
      updateInitialOpenState={updateInitialOpenState}
      reportsEditableById={reportsEditableById}
      reportsDestroyableById={reportsDestroyableById}
      reportsDuplicatableById={reportsDuplicatableById}
      foldersEditableById={foldersEditableById}
      foldersDestroyableById={foldersDestroyableById}
    />
  )

  const [initialOpenState, setInitialOpenState] =
    useLocalStorage<initialOpenStateType>(INITIAL_OPEN_STATE_STORAGE_KEY)

  const updateInitialOpenState = (id: string, isOpen: boolean) => {
    setInitialOpenState((prevInitialOpenState) => {
      return prevInitialOpenState
        ? { ...prevInitialOpenState, [id]: isOpen }
        : { [id]: isOpen }
    })
  }

  return (
    <TooltipProvider>
      <Container>
        <Header />
        <Tree
          data={data}
          onMove={onMove}
          width={'100%'}
          height={ROW_HEIGHT * items.length}
          rowHeight={ROW_HEIGHT}
          disableDrag={!isSortable}
          initialOpenState={initialOpenState}
        >
          {NodeWithSubProps}
        </Tree>
      </Container>
      <Tooltip />
    </TooltipProvider>
  )
}

function Container({ children }: { children: ReactNode }) {
  return (
    <div className="align-middle inline-block min-w-full overflow-hidden">
      {children}
    </div>
  )
}

function Header() {
  return (
    <div className="flex px-6 py-3 border-b border-gray-200 bg-gray-50 leading-4 tracking-wider font-medium text-left text-xs text-gray-500">
      <div className="flex-none w-96">名称</div>
      <div className="flex-none w-32">形式</div>
      <div className="flex-none w-32">分析項目（縦軸）</div>
      <div className="flex-none w-20">横軸</div>
      <div className="flex-none w-20">タグ数の上限</div>
    </div>
  )
}

function Node(
  props: NodeRendererProps<Data> & {
    updateInitialOpenState: (id: string, isOpen: boolean) => void
    reportsEditableById: { [id: number]: boolean }
    reportsDestroyableById: { [id: number]: boolean }
    reportsDuplicatableById: { [id: number]: boolean }
    foldersEditableById: { [id: number]: boolean }
    foldersDestroyableById: { [id: number]: boolean }
  },
) {
  const {
    style,
    node,
    dragHandle,
    updateInitialOpenState,
    reportsEditableById,
    reportsDestroyableById,
    reportsDuplicatableById,
    foldersEditableById,
    foldersDestroyableById,
  } = props
  const { paddingLeft, ...rowStyle } = style
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [duplidateModalOpen, setDuplicateModalOpen] = useState(false)
  return (
    <>
      <div style={rowStyle}>
        <div
          style={{ height: ROW_HEIGHT }}
          className={
            'flex px-6 items-center whitespace-nowrap border-b border-gray-200 text-sm leading-5 bg-white text-gray-900 ' +
            (node.willReceiveDrop ? 'bg-gray-100' : '')
          }
          ref={dragHandle}
        >
          <div className="w-full">
            <Row
              node={node}
              onEditModalOpen={() => setEditModalOpen(true)}
              onDuplicateModalOpen={() => setDuplicateModalOpen(true)}
              style={{ paddingLeft }}
              updateInitialOpenState={updateInitialOpenState}
              reportsEditableById={reportsEditableById}
              reportsDestroyableById={reportsDestroyableById}
              reportsDuplicatableById={reportsDuplicatableById}
              foldersEditableById={foldersEditableById}
              foldersDestroyableById={foldersDestroyableById}
            />
          </div>
        </div>
      </div>
      {node.data.item.type === 'CustomReport' && (
        <DuplicateModal
          node={node}
          duplidateModalOpen={duplidateModalOpen}
          onDuplicateModalClose={() => setDuplicateModalOpen(false)}
        />
      )}
      <EditModal
        node={node}
        editModalOpen={editModalOpen}
        onEditModalClose={() => setEditModalOpen(false)}
      />
    </>
  )
}

function Row({
  node,
  onEditModalOpen,
  onDuplicateModalOpen,
  style,
  updateInitialOpenState,
  reportsEditableById,
  reportsDestroyableById,
  reportsDuplicatableById,
  foldersEditableById,
  foldersDestroyableById,
}: {
  node: NodeApi<Data>
  onEditModalOpen: () => void
  onDuplicateModalOpen: () => void
  style: React.CSSProperties
  updateInitialOpenState: (id: string, isOpen: boolean) => void
  reportsEditableById: { [id: number]: boolean }
  reportsDestroyableById: { [id: number]: boolean }
  reportsDuplicatableById: { [id: number]: boolean }
  foldersEditableById: { [id: number]: boolean }
  foldersDestroyableById: { [id: number]: boolean }
}) {
  const item = node.data.item
  const hasChildItem = node.data.children && node.data.children?.length > 0
  const reportsEditable = reportsEditableById[item.id]
  const reportsDestroyable = reportsDestroyableById[item.id]
  const reportsDuplicatable = reportsDuplicatableById[item.id]
  const foldersEditable = foldersEditableById[item.id]
  const foldersDestroyable = foldersDestroyableById[item.id]
  switch (item.type) {
    case 'CustomReport':
      return (
        <div className="flex text-gray-700">
          <div style={style} className="flex-none w-96 leading-7 truncate">
            <a
              className="font-medium text-blue-600 transition ease-in-out duration-150 hover:text-blue-500 focus:outline-none focus:underline"
              href={item.showUrl}
              data-turbolinks="false"
            >
              {item.name}
            </a>
          </div>
          <div className="flex-none w-32 leading-7">{item.reportTypeName}</div>
          <div className="flex-none w-32 leading-7">
            {item.rowSubjectTypeName}
          </div>
          <div className="flex-none w-20 leading-7 truncate">
            {item.columnSubjectTypeName}
          </div>
          <div className="flex-none w-20 leading-7">
            <DisplayColumnLimit item={item} />
          </div>
          <div className="flex-auto">
            <div className="flex justify-end space-x-4">
              {reportsEditable && (
                <div className="btn-wrapper">
                  <button
                    className="btn btn-xs btn-white"
                    onClick={onEditModalOpen}
                  >
                    <RiEditBoxLine className="mr-2" />
                    編集
                  </button>
                </div>
              )}
              {reportsDuplicatable && (
                <div className="btn-wrapper">
                  <button
                    className="btn btn-xs btn-white"
                    onClick={onDuplicateModalOpen}
                  >
                    <RiFileCopyLine className="mr-2" />
                    複製
                  </button>
                </div>
              )}
              {reportsDestroyable && (
                <a
                  className="btn btn-xs btn-text"
                  data-confirm="本当に削除しますか？"
                  rel="nofollow"
                  data-method="delete"
                  href={item.showUrl}
                >
                  <RiDeleteBinLine className="mr-2" />
                  削除
                </a>
              )}
            </div>
          </div>
        </div>
      )
    case 'CustomReportFolder':
      return (
        <div className="flex text-gray-700">
          <button
            type="button"
            style={style}
            onClick={() => {
              node.toggle()
              updateInitialOpenState(node.id, node.isOpen)
            }}
            className="inline-flex items-center flex-none w-96 leading-7 truncate"
          >
            {node.state.isOpen ? (
              <RiFolderOpenLine className="mr-2 text-xl text-gray-500" />
            ) : (
              <RiFolderLine className="mr-2 text-xl text-gray-500" />
            )}
            {item.name}
          </button>
          <div className="flex-none w-32 leading-7"></div>
          <div className="flex-none w-32 leading-7"></div>
          <div className="flex-none w-20 leading-7"></div>
          <div className="flex-none w-20 leading-7"></div>
          <div className="flex-auto">
            <div className="flex justify-end space-x-4">
              {foldersEditable && (
                <div className="btn-wrapper">
                  <button
                    className="btn btn-xs btn-white"
                    onClick={onEditModalOpen}
                  >
                    <RiEditBoxLine className="mr-2" />
                    編集
                  </button>
                </div>
              )}
              {foldersDestroyable && (
                <TooltipWrapper
                  content={
                    hasChildItem
                      ? 'フォルダまたはファイルが含まれるため削除できません。'
                      : ''
                  }
                >
                  <a
                    className={`btn btn-xs btn-text ${
                      hasChildItem ? 'disabled' : ''
                    }`}
                    data-confirm="本当に削除しますか？"
                    rel="nofollow"
                    data-method="delete"
                    href={item.showUrl}
                  >
                    <RiDeleteBinLine className="mr-2" />
                    削除
                  </a>
                </TooltipWrapper>
              )}
            </div>
          </div>
        </div>
      )
  }
}

function DisplayColumnLimit({ item }: { item: CustomReport }) {
  if (item.columnSubjectType !== 'tags') return null

  if (item.displayColumnLimit == null) {
    return (
      <div className="inline-flex items-center">
        なし
        <TooltipWrapper content="横軸に表示できるタグ数の上限です。表示に時間がかかる場合は、編集から上限数を減らしてください。">
          <RiQuestionFill className="ml-1" />
        </TooltipWrapper>
      </div>
    )
  } else {
    return <>{item.displayColumnLimit}</>
  }
}

function itemsToData(items: Item[], subProps: SubProps) {
  const groupedItem = groupBy(items, (item) => {
    switch (item.type) {
      case 'CustomReport':
        return item.customReportFolderId
      case 'CustomReportFolder':
        return item.parentId
    }
  })

  const findChildItems = (item: Item): Item[] => {
    return groupedItem[item.id] || []
  }

  const rootItems = items.filter((item) => {
    switch (item.type) {
      case 'CustomReport':
        return item.customReportFolderId === null
      case 'CustomReportFolder':
        return item.parentId === null
    }
  })

  return rootItems.map((item) => itemToData(item, findChildItems, subProps))
}

function itemToData(
  item: Item,
  findChildItems: (item: Item) => Item[],
  subProps: SubProps,
): Data {
  return {
    id: [item.type, item.id].join('-'),
    name: item.name,
    children: childItemsToData(item, findChildItems, subProps),
    subProps: subProps,
    item,
  }
}

function childItemsToData(
  item: Item,
  findChildItems: (item: Item) => Item[],
  subProps: SubProps,
): Data[] | undefined {
  switch (item.type) {
    case 'CustomReport':
      return undefined
    case 'CustomReportFolder':
      return findChildItems(item).map((childItem) =>
        itemToData(childItem, findChildItems, subProps),
      )
  }
}

function dataToApiParams(
  data: readonly Data[],
  parent?: CustomReportFolder,
): OrderParams {
  const convert = (item: Item, position: number) => {
    const getType = (item: Item): 'custom_report' | 'custom_report_folder' => {
      switch (item.type) {
        case 'CustomReport':
          return 'custom_report'
        case 'CustomReportFolder':
          return 'custom_report_folder'
      }
    }
    return {
      id: item.id,
      type: getType(item),
      parentFolderId: parent ? parent.id : null,
      position,
    }
  }

  const params: OrderParams = []
  data.forEach((d, index) => {
    params.push(convert(d.item, index))
    if (d.children && d.item.type === 'CustomReportFolder') {
      dataToApiParams(d.children, d.item).forEach((p) => {
        params.push(p)
      })
    }
  })

  return params
}
