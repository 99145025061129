import React, { forwardRef, useState } from 'react'
import { Disclosure } from '@headlessui/react'
import { RiArrowUpSLine } from 'react-icons/ri'

import { Org } from '@/frontend/api'
import { SectionTitle } from '@/frontend/features/importers/components/SectionTitle'
import { ImportSetting } from '@/frontend/features/importers/types'
import useLocalStorage from '@/frontend/hooks/useLocalStorage'
import { useImportSettingContext } from '../hooks/useImportSettingContext'

type Props = {
  org: Org
  children: React.ReactNode
}

const STORED_VALUES_STORAGE_KEY = 'GeneralCsvMasterImportSettings' as const

type storeValues = { accordionOpen: boolean }
type ImportFormat = ImportSetting['importFormat']

type storedValueStorageType = {
  // NOTE: [#orgId]: { [#importFormat]: { accordionOpen: boolean } }
  [id: number]: Partial<Record<ImportFormat, storeValues>>
}

const MasterImportAccordion = forwardRef(function MasterImportAccordion(
  { org, children }: Props,
  ref: React.Ref<HTMLDivElement>,
) {
  const {
    importSetting: { importFormat },
  } = useImportSettingContext()

  const [storedValues, setStoreValues] =
    useLocalStorage<storedValueStorageType>(STORED_VALUES_STORAGE_KEY)

  // NOTE: headless ui から open/close が取れなかったので自前で管理しているよ
  const [accordionOpen, setAccordionOpen] = useState<boolean>(
    storedValues?.[org.id]?.[importFormat]?.accordionOpen ?? true,
  )

  const handleAccordionClick = () => {
    setStoreValues((prev) => {
      return {
        ...prev,
        [org.id]: {
          ...(prev?.[org.id] || {}),
          [importFormat]: {
            accordionOpen: !accordionOpen,
          },
        },
      }
    })
    setAccordionOpen(!accordionOpen)
  }

  return (
    <Disclosure defaultOpen={accordionOpen}>
      {({ open }) => (
        <>
          <div ref={ref} className="space-y-4">
            <Disclosure.Button as="div" onClick={handleAccordionClick}>
              <div className="flex items-center justify-between bg-gray-100">
                <SectionTitle>マスタインポート</SectionTitle>
                <RiArrowUpSLine
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } mr-4 h-4 w-4`}
                />
              </div>
            </Disclosure.Button>
            <Disclosure.Panel>{children}</Disclosure.Panel>
          </div>
        </>
      )}
    </Disclosure>
  )
})

export default MasterImportAccordion
