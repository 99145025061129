type Props = {
  children: React.ReactNode
  noBorderDecoration?: boolean
}

export function SectionTitle({ children, noBorderDecoration }: Props) {
  const className = () =>
    noBorderDecoration
      ? 'text-lg text-gray-600 font-bold'
      : 'border-l-4 border-blue-800 pl-2 text-lg text-gray-600 font-bold'

  return <div className={className()}>{children}</div>
}
