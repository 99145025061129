import { IconType } from 'react-icons'

export function ButtonContent({
  icon,
  children,
}: {
  icon?: IconType
  children: React.ReactNode
}) {
  const Icon = icon
  return Icon ? <Icon className="h-5 w-5" /> : children
}
