import { ReactNode, useMemo, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { groupBy } from 'lodash'
import {
  MoveHandler,
  NodeApi,
  NodeRendererProps,
  SimpleTree,
  Tree,
} from 'react-arborist'
import {
  RiArrowDownSLine,
  RiDeleteBinLine,
  RiEditBoxLine,
  RiEyeLine,
  RiEyeOffLine,
  RiFolderLine,
  RiFolderOpenLine,
} from 'react-icons/ri'
import { Tooltip, TooltipProvider, TooltipWrapper } from 'react-tooltip'

import useLocalStorage from '@/frontend/hooks/useLocalStorage'
import { OrderParams, updateOrder } from '../api'
import { EditModal } from './EditModal'
import { Budget, BudgetFolder, Data, Item, SubProps } from './type'

type Props = {
  orgId: number
  updateOrderUrl: string
  items: Item[]
  subProps: SubProps
  isSortable: boolean
  isBudgetsShowableById: { [id: number]: boolean }
}

type initialOpenStateType = { [id: string]: boolean }

const ROW_HEIGHT = 60
const DROPDOWN_HEIGHT_OFFSET = 150

export function IndexList({
  orgId,
  updateOrderUrl,
  items,
  subProps,
  isSortable,
  isBudgetsShowableById,
}: Props) {
  const INITIAL_OPEN_STATE_STORAGE_KEY =
    `budgetFolderInitialOpenState_${orgId}` as const
  const initialData = itemsToData(items, subProps)
  const [data, setData] = useState(initialData)
  const tree = useMemo(() => new SimpleTree(data), [data])
  const onMove: MoveHandler<Data> = (args: {
    dragIds: string[]
    parentId: null | string
    index: number
    parentNode: NodeApi<Data> | null
  }) => {
    for (const id of args.dragIds) {
      tree.move({ id, parentId: args.parentId, index: args.index })
    }
    setData(tree.data)
    if (args.parentNode && args.parentNode.data.item.type === 'BudgetFolder') {
      updateInitialOpenState(args.parentNode.id, true)
    }

    // APIしばき
    const updateParams = dataToApiParams(tree.data)
    updateOrder(updateOrderUrl, {
      orderParams: updateParams,
    })
  }

  const NodeWithSubProps = (props: NodeRendererProps<Data>) => (
    <Node
      {...props}
      subProps={subProps}
      updateInitialOpenState={updateInitialOpenState}
      isBudgetsShowableById={isBudgetsShowableById}
    />
  )

  const [initialOpenState, setInitialOpenState] =
    useLocalStorage<initialOpenStateType>(INITIAL_OPEN_STATE_STORAGE_KEY)

  const updateInitialOpenState = (id: string, isOpen: boolean) => {
    setInitialOpenState((prevInitialOpenState) => {
      return prevInitialOpenState
        ? { ...prevInitialOpenState, [id]: isOpen }
        : { [id]: isOpen }
    })
  }

  return (
    <TooltipProvider>
      <Container>
        <Header />
        <Tree
          data={data}
          onMove={onMove}
          width={'100%'}
          rowHeight={ROW_HEIGHT}
          height={ROW_HEIGHT * items.length + DROPDOWN_HEIGHT_OFFSET}
          disableDrag={!isSortable}
          initialOpenState={initialOpenState}
        >
          {NodeWithSubProps}
        </Tree>
      </Container>
      <Tooltip />
    </TooltipProvider>
  )
}

function Container({ children }: { children: ReactNode }) {
  return (
    <div className="align-middle inline-block min-w-full overflow-hidden">
      {children}
    </div>
  )
}

function Header() {
  return (
    <div className="flex px-6 py-3 border-b border-gray-200 bg-gray-50 leading-4 tracking-wider font-medium text-left text-sm text-gray-500">
      <div className="flex-none w-5/12">名称</div>
      <div className="flex-none w-1/12">種別</div>
      <div className="flex-none w-1/12">作成日</div>
      <div className="flex-none w-2/12">コピー元</div>
      <div className="flex-none w-2/12">メモ</div>
    </div>
  )
}

function Node({
  style,
  node,
  dragHandle,
  subProps,
  updateInitialOpenState,
  isBudgetsShowableById,
}: NodeRendererProps<Data> & {
  subProps: SubProps
  updateInitialOpenState: (id: string, isOpen: boolean) => void
  isBudgetsShowableById: { [id: number]: boolean }
}) {
  const { paddingLeft, ...rowStyle } = style
  const [editModalOpen, setEditModalOpen] = useState(false)
  return (
    <>
      <div style={rowStyle}>
        <div
          style={{ height: ROW_HEIGHT }}
          className={
            'flex px-6 items-center whitespace-nowrap border-b border-gray-200 text-sm leading-5 bg-white text-gray-900 ' +
            (node.willReceiveDrop ? 'bg-gray-100' : '')
          }
          ref={dragHandle}
        >
          <div className="w-full">
            <Row
              node={node}
              onEditModalOpen={() => setEditModalOpen(true)}
              style={{ paddingLeft }}
              subProps={subProps}
              updateInitialOpenState={updateInitialOpenState}
              isBudgetsShowableById={isBudgetsShowableById}
            />
          </div>
        </div>
      </div>
      <EditModal
        node={node}
        editModalOpen={editModalOpen}
        onEditModalClose={() => setEditModalOpen(false)}
      />
    </>
  )
}

function Row({
  node,
  onEditModalOpen,
  style,
  subProps,
  updateInitialOpenState,
  isBudgetsShowableById,
}: {
  node: NodeApi<Data>
  onEditModalOpen: () => void
  style: React.CSSProperties
  subProps: SubProps
  updateInitialOpenState: (id: string, isOpen: boolean) => void
  isBudgetsShowableById: { [id: number]: boolean }
}) {
  const item = node.data.item
  const hasChildItem = !!(node.data.children && node.data.children?.length > 0)
  const isShowable = (item: Budget | BudgetFolder) => {
    return item.type == 'Budget' ? isBudgetsShowableById[item.id] : true
  }

  const base_budget_cell_style = (item: Budget) => {
    return (
      'flex-none leading-9' +
      (item.archived ? ' text-gray-400 ' : ' text-gray-700 ')
    )
  }

  const isAnyControllable = (item: Budget | BudgetFolder) => {
    return item.isUpdatable || item.isDestroyable
  }

  switch (item.type) {
    case 'Budget':
      return (
        <div className="flex">
          <div
            style={style}
            className={base_budget_cell_style(item) + 'w-5/12 flex pr-3'}
          >
            <div className="truncate">
              {item.archived || !isShowable(item) ? (
                <span className="text-gray-400">{item.name}</span>
              ) : (
                <a
                  className="font-medium text-blue-600 transition ease-in-out duration-150 hover:text-blue-500 focus:outline-none focus:underline"
                  href={item.showUrl}
                  data-turbolinks="false"
                >
                  {item.name}
                </a>
              )}
            </div>
            {item.archived && (
              <div
                className="ml-2 inline-flex items-center"
                data-tooltip="計画バージョンは非表示です"
              >
                <RiEyeOffLine className="ml-2 text-lg" />
              </div>
            )}
            {item.current && <CurrentLabel />}
          </div>
          <div className={base_budget_cell_style(item) + 'w-1/12'}>
            {item.budgetType}
          </div>
          <div className={base_budget_cell_style(item) + 'w-1/12'}>
            {item.createdAt}
          </div>
          <div className={base_budget_cell_style(item) + 'w-2/12 truncate'}>
            {item.originalBudgetName}
          </div>
          <div className={base_budget_cell_style(item) + 'w-2/12 truncate'}>
            {item.memo}
          </div>
          <div className="flex-auto">
            <div className="flex justify-end space-x-4">
              {isAnyControllable(item) && (
                <Popover className="relative">
                  <div className="btn-wrapper">
                    <Popover.Button className="btn btn-sm btn-white">
                      操作
                      <RiArrowDownSLine className="ml-2" />
                    </Popover.Button>
                  </div>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                    className="absolute z-50 mt-1 right-0"
                  >
                    <Popover.Panel>
                      <div className="simple-dropdown">
                        <div className="simple-dropdown-content">
                          <div className="py-1">
                            {item.isUpdatable && (
                              <button
                                className="simple-dropdown-link-item"
                                disabled={!item.isUpdatable}
                                onClick={onEditModalOpen}
                              >
                                <span className="inline-flex items-center text-sm">
                                  <RiEditBoxLine className="mr-2 text-gray-500" />
                                  編集
                                </span>
                              </button>
                            )}
                            {item.isUpdatable && (
                              <form method="post" action={item.updateUrl}>
                                <input
                                  type="hidden"
                                  name="_method"
                                  value="put"
                                  autoComplete="off"
                                />
                                <input
                                  type="hidden"
                                  name="authenticity_token"
                                  value={subProps.formAuthenticityToken}
                                  autoComplete="off"
                                />
                                <input
                                  type="hidden"
                                  name="budget[archived]"
                                  value={item.archived ? 'false' : 'true'}
                                  autoComplete="off"
                                />
                                {!item.current && (
                                  <button
                                    className="simple-dropdown-link-item"
                                    type="submit"
                                    disabled={!item.isUpdatable}
                                  >
                                    <span className="text-sm inline-flex items-center">
                                      {item.archived ? (
                                        <RiEyeLine className="mr-2 text-gray-500" />
                                      ) : (
                                        <RiEyeOffLine className="mr-2 text-gray-500" />
                                      )}
                                      {item.archived ? '表示' : '非表示'}
                                    </span>
                                  </button>
                                )}
                              </form>
                            )}
                            {item.isDestroyable && (
                              <DropdownItemDeleteButton
                                tooltip={
                                  item.isLocked
                                    ? 'この予算はロックされています'
                                    : ''
                                }
                                disabled={item.isLocked}
                                deleteUrl={item.showUrl}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              )}
            </div>
          </div>
        </div>
      )
    case 'BudgetFolder':
      return (
        <div className="flex text-gray-700">
          <div
            style={style}
            onClick={() => {
              node.toggle()
              updateInitialOpenState(node.id, node.isOpen)
            }}
            className="inline-flex items-center flex-none w-5/12 leading-9 truncate"
          >
            {node.state.isOpen ? (
              <RiFolderOpenLine className="mr-2 text-xl text-gray-500" />
            ) : (
              <RiFolderLine className="mr-2 text-xl text-gray-500" />
            )}
            {item.name}
          </div>
          <div className="flex-none w-1/12 leading-9"></div>
          <div className="flex-none w-1/12 leading-9"></div>
          <div className="flex-none w-2/12 leading-9"></div>
          <div className="flex-none w-2/12 leading-9"></div>
          <div className="flex-auto">
            <div className="flex justify-end space-x-4">
              {isAnyControllable(item) && (
                <Popover className="relative">
                  <div className="btn-wrapper">
                    <Popover.Button className="btn btn-sm btn-white">
                      操作
                      <RiArrowDownSLine className="ml-2" />
                    </Popover.Button>
                  </div>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                    className="absolute z-50 mt-1 right-0"
                  >
                    <Popover.Panel>
                      <div className="simple-dropdown">
                        <div className="simple-dropdown-content">
                          <div className="py-1">
                            {item.isUpdatable && (
                              <button
                                className="simple-dropdown-link-item"
                                onClick={onEditModalOpen}
                                disabled={!item.isUpdatable}
                              >
                                <span className="inline-flex items-center text-sm">
                                  <RiEditBoxLine className="mr-2 text-gray-500" />
                                  編集
                                </span>
                              </button>
                            )}
                            {item.isDestroyable && (
                              <DropdownItemDeleteButton
                                tooltip={
                                  hasChildItem
                                    ? 'フォルダまたはファイルが含まれるため削除できません。'
                                    : ''
                                }
                                disabled={hasChildItem}
                                deleteUrl={item.showUrl}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              )}
            </div>
          </div>
        </div>
      )
  }
}

function CurrentLabel() {
  return (
    <div>
      <span className="ml-2 items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
        デフォルト
      </span>
    </div>
  )
}

function DropdownItemDeleteButton({
  tooltip = '',
  disabled = false,
  deleteUrl = '',
}: {
  tooltip: string | undefined
  disabled: boolean
  deleteUrl: string | undefined
}) {
  const handleClick = (e: React.MouseEvent) => {
    // Popoverが閉じてしまうと要素が消滅するのでHoverイベントが発生せず
    // Tooltipが出っぱなしになってしまうので伝播を回避
    // 有効時は伝播させないとaタグのdata-methodが発火しない
    if (disabled) e.stopPropagation()
  }

  const classes = () => {
    return ['simple-dropdown-link-item', disabled ? 'cursor-not-allowed' : '']
  }

  return (
    <>
      {disabled ? (
        <TooltipWrapper content={tooltip}>
          <div className={classes().join(' ')} onClick={handleClick}>
            <span className="text-gray-400 text-sm inline-flex items-center">
              <RiDeleteBinLine className="mr-2" />
              削除
            </span>
          </div>
        </TooltipWrapper>
      ) : (
        <TooltipWrapper content={tooltip}>
          <a
            className={classes().join(' ')}
            onClick={handleClick}
            data-confirm="本当に削除しますか？"
            rel="nofollow"
            data-method="delete"
            href={deleteUrl}
          >
            <span className="text-sm inline-flex items-center">
              <RiDeleteBinLine className="mr-2 text-gray-500" />
              削除
            </span>
          </a>
        </TooltipWrapper>
      )}
    </>
  )
}

function itemsToData(items: Item[], subProps: SubProps) {
  const groupedItem = groupBy(items, (item) => {
    switch (item.type) {
      case 'Budget':
        return item.budgetFolderId
      case 'BudgetFolder':
        return item.parentId
    }
  })

  const findChildItems = (item: Item): Item[] => {
    return groupedItem[item.id] || []
  }

  const rootItems = items.filter((item) => {
    switch (item.type) {
      case 'Budget':
        return item.budgetFolderId === null
      case 'BudgetFolder':
        return item.parentId === null
    }
  })

  return rootItems.map((item) => itemToData(item, findChildItems, subProps))
}

function itemToData(
  item: Item,
  findChildItems: (item: Item) => Item[],
  subProps: SubProps,
): Data {
  return {
    id: [item.type, item.id].join('-'),
    name: item.name,
    children: childItemsToData(item, findChildItems, subProps),
    subProps: subProps,
    item,
  }
}

function childItemsToData(
  item: Item,
  findChildItems: (item: Item) => Item[],
  subProps: SubProps,
): Data[] | undefined {
  switch (item.type) {
    case 'Budget':
      return undefined
    case 'BudgetFolder':
      return findChildItems(item).map((childItem) =>
        itemToData(childItem, findChildItems, subProps),
      )
  }
}

function dataToApiParams(
  data: readonly Data[],
  parent?: BudgetFolder,
): OrderParams {
  const convert = (item: Item, position: number) => {
    const getType = (item: Item): 'budget' | 'budget_folder' => {
      switch (item.type) {
        case 'Budget':
          return 'budget'
        case 'BudgetFolder':
          return 'budget_folder'
      }
    }
    return {
      id: item.id,
      type: getType(item),
      parentFolderId: parent ? parent.id : null,
      position,
    }
  }

  const params: OrderParams = []
  data.forEach((d, index) => {
    params.push(convert(d.item, index))
    if (d.children && d.item.type === 'BudgetFolder') {
      dataToApiParams(d.children, d.item).forEach((p) => {
        params.push(p)
      })
    }
  })

  return params
}
