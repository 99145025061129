import { forwardRef } from 'react'
import classNames from 'classnames'

type Props = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'type' | 'className'
> & {
  invalid?: boolean
}

export const MonthInput = forwardRef<HTMLInputElement, Props>(function Input(
  { invalid = false, ...props },
  ref,
) {
  const baseClassName =
    'border-gray-300 rounded-md block w-full py-1 text-sm transition duration-150 ease-in-out focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
  const validClassName =
    'border-gray-300 focus:ring-blue-200 focus:border-blue-300'
  const invalidClassName =
    'border-red-300 focus:ring-red-200 focus:border-red-300'
  const className = classNames({
    [baseClassName]: true,
    [validClassName]: !invalid,
    [invalidClassName]: invalid,
  })

  return (
    <input
      className={className}
      ref={ref}
      type="month"
      max="9999-12"
      {...props}
    />
  )
})
