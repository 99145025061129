import { Org } from '@/frontend/api'
import ImportSettingFormContainer from '@/frontend/features/importers/transition_table_import/ImportSettingFormContainer'
import SectionLayout from '@/frontend/features/importers/transition_table_import/SectionLayout'
import { RawCsvTable } from '@/frontend/features/importers/transition_table_import/types'

type Props = {
  org: Org
  formAuthenticityToken: string
  transitionTableImportId: number
  budgetId: number
  rawCsvTable: RawCsvTable
}

export default function ImportSettingsPage({
  org,
  formAuthenticityToken,
  transitionTableImportId,
  budgetId,
  rawCsvTable,
}: Props) {
  return (
    <SectionLayout
      sectionWidthClass="max-w-3xl"
      title={
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          推移表インポート
        </h3>
      }
    >
      <ImportSettingFormContainer
        org={org}
        formAuthenticityToken={formAuthenticityToken}
        transitionTableImportId={transitionTableImportId}
        budgetId={budgetId}
        rawCsvTable={rawCsvTable}
      />
    </SectionLayout>
  )
}
