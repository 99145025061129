import React, { useCallback } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { FileRejection, FileWithPath } from 'react-dropzone'
import { useForm } from 'react-hook-form'

import {
  ApiError,
  BulkImportCsvBudgetTagsPayload,
  bulkImportCsvBudgetTagsSchema,
  Org,
} from '@/frontend/api'
import { ApiAlert, ButtonLink } from '@/frontend/components'
import { Alert } from '@/frontend/components/Alert/Alert'
import { Button } from '@/frontend/components/buttons/Button'
import Dropzone from '../components/Dropzone'
import { LastImportStatus } from '../components/LastImportStatus'
import { ImportFileStatus } from '../types'

type Props = {
  org: Org
  importFileStatus: ImportFileStatus | null
  onFileChange: (file: FileWithPath) => void
  onSubmit: (data: BulkImportCsvBudgetTagsPayload) => void
  isLoading: boolean
  isSucceeded: boolean
  apiError?: ApiError
}

export default function TransitionTableMasterImportForm({
  org: org,
  importFileStatus,
  onSubmit,
  onFileChange,
  isLoading,
  isSucceeded,
  apiError,
}: Props) {
  const [fileRejections, setFileRejections] = React.useState<FileRejection[]>(
    [],
  )

  const { setValue, watch, handleSubmit } =
    useForm<BulkImportCsvBudgetTagsPayload>({
      resolver: zodResolver(bulkImportCsvBudgetTagsSchema),
      defaultValues: {
        csvFile: null,
      },
    })

  const csvFile = watch('csvFile')

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
      if (acceptedFiles.length <= 0) return
      const acceptedFile = acceptedFiles[0]
      onFileChange(acceptedFile as FileWithPath)
      setValue('csvFile', acceptedFile as FileWithPath)
      setFileRejections(fileRejections)
    },
    [setValue],
  )

  const _onSubmit = handleSubmit((data) => {
    onSubmit(data)
    setValue('csvFile', null)
  })

  const errorMessage =
    fileRejections.length > 0 ? 'CSVファイルを選択してください' : ''

  return (
    <form onSubmit={_onSubmit}>
      <div className="space-y-4">
        <div className="w-1/5">
          <div>
            <ButtonLink href={getCsvTemplateBudgetTagsUrl(org.id)} download>
              <div>
                テンプレート <i className="ri-download-line"></i>
              </div>
            </ButtonLink>
          </div>
        </div>
        {apiError && (
          <div className="py-4">
            <ApiAlert apiError={apiError} />
          </div>
        )}
        {errorMessage && <Alert title={errorMessage} />}
        <Dropzone onDrop={onDrop} disabled={isLoading} />
        {importFileStatus && (
          <LastImportStatus
            importFileStatus={importFileStatus}
            loading={isLoading}
          />
        )}
        {isSucceeded && (
          <Alert variant="success" title="データのインポートが完了しました。" />
        )}
        <div className="flex justify-end items-center">
          <div className="mt-auto">
            <Button
              type="submit"
              variant="primary"
              loading={isLoading}
              disabled={csvFile == null}
            >
              インポート
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

function getCsvTemplateBudgetTagsUrl(orgId: number) {
  return `/orgs/${orgId}/budget_tags/importer/csv_template`
}
