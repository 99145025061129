import { Dialog } from '@headlessui/react'
import { RiCloseLine } from 'react-icons/ri'

type Props = {
  title: string
  description: string
  onClose: () => void
  actions: React.ReactNode
  children: React.ReactNode
}

export function Panel({
  title,
  description,
  onClose,
  actions,
  children,
}: Props) {
  return (
    <div className="flex h-full flex-col">
      <div className="shrink-0 bg-blue-700 py-6 px-6">
        <div className="flex items-start justify-between space-x-3">
          <div className="space-y-1">
            <Dialog.Title className="text-base leading-6 text-white">
              {title}
            </Dialog.Title>
            <p className="text-sm text-blue-300">{description}</p>
          </div>
          <div className="flex h-7 items-center">
            <button
              type="button"
              className="text-blue-200 hover:text-white focus:outline-none"
              onClick={onClose}
            >
              <span className="sr-only">Close panel</span>
              <RiCloseLine className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>
      <div className="relative flex-1 overflow-y-scroll">{children}</div>
      <div className="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
        <div className="flex justify-end space-x-3">{actions}</div>
      </div>
    </div>
  )
}
