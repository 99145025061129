import { RiErrorWarningLine } from 'react-icons/ri'

export function ErrorBoundaryFallback() {
  return (
    <div className="inline-flex items-center justify-center text-red-400">
      <RiErrorWarningLine className="mr-1" />
      データの取得に失敗しました
    </div>
  )
}
