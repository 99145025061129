import React from 'react'

type Props = {
  title: React.ReactNode
  sectionWidthClass?: string
  children: React.ReactNode
}
export default function SectionLayout({
  title,
  sectionWidthClass = 'max-w-3xl',
  children,
}: Props) {
  return (
    <section className="bg-white pt-3">
      <div className={`${sectionWidthClass} mx-auto p-8`}>
        <div className="bg-white shadow overflow-hidden rounded border border-gray-200">
          <div className="border-b px-8 py-4">{title}</div>
          <div>{children}</div>
        </div>
      </div>
    </section>
  )
}
