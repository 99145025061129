import React, { useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { RxCross1, RxPlus } from 'react-icons/rx'

import { Tooltip } from '@/frontend/components'
import { SingleSelect } from '@/frontend/features/importers/components/SingleSelect'
import { CsvHeader, MappingFormProps, MonthlyMapping } from './types'

type TableRowProps = {
  index: number
  item: MonthlyMapping | null
  options: CsvHeader[]
}

const MAX_ADD_COLUMN_COUNT = 2

function TableRow({ index, item, options }: TableRowProps) {
  const { setValue, control } = useFormContext<MappingFormProps>()
  const {
    fields: addColumnsFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `monthlyMappings.${index}.addColumns`,
  })
  const [isHovered, setIsHovered] = useState(false)

  const handleSelectChange = (key: string | null | undefined) => {
    if (key === null) {
      // NOTE: ブランクが選ばれた場合(選択肢クリア)の処理だよ
      setValue(`monthlyMappings.${index}.csvHeader`, null)
      return
    }

    const selectedOption = options.find((o) => o.key === key)
    if (selectedOption) {
      setValue(`monthlyMappings.${index}.csvHeader`, selectedOption)
    }
  }

  const handleAddColumn = () => {
    if (addColumnsFields.length > MAX_ADD_COLUMN_COUNT) return
    append({
      index: addColumnsFields.length,
      name: '',
      position: 0,
      key: '',
    })
  }

  return (
    <>
      <tr
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <td className="pl-4 py-2 w-1/2 text-sm">
          <div className="flex items-center space-x-2">
            {item?.month}
            <div className="ml-auto relative group">
              <Tooltip content="列を追加する">
                {(ref) => (
                  <button
                    ref={ref}
                    type="button"
                    className="items-center p-0.5 border border-gray-400 rounded text-black-500 bg-white hover:text-gray-500"
                    style={{ visibility: isHovered ? 'visible' : 'hidden' }}
                    onClick={handleAddColumn}
                  >
                    <RxPlus className="w-3 h-3" />
                  </button>
                )}
              </Tooltip>
            </div>
          </div>
        </td>
        <td className="pl-2 pr-4 py-2 w-1/2">
          <div className="flex items-center space-x-2 pr-6">
            <div className="w-full text-sm">
              <Controller
                control={control}
                name={`monthlyMappings.${index}.csvHeader.key`}
                render={({ field: { onChange, value } }) => (
                  <SingleSelect
                    onChange={(value) => {
                      handleSelectChange(value)
                      onChange(value)
                    }}
                    value={value}
                    items={options.map((o) => ({
                      id: o.key,
                      name: o.duplicated
                        ? `${o.name} (${o.position + 1})`
                        : o.name,
                    }))}
                    blankOption={{ id: null, name: '' }}
                    truncate
                  />
                )}
              />
            </div>
          </div>
        </td>
      </tr>
      {addColumnsFields.map((field, colIndex) => (
        <AdditionalTableRow
          index={index}
          colIndex={colIndex}
          options={options}
          key={field.id}
          remove={remove}
        />
      ))}
    </>
  )
}

type AdditionalTableRowProps = {
  index: number
  colIndex: number
  options: CsvHeader[]
  remove: (index: number) => void
}

function AdditionalTableRow({
  index,
  colIndex,
  options,
  remove,
}: AdditionalTableRowProps) {
  const { control, setValue } = useFormContext<MappingFormProps>()

  const handleSelectChange = (key: string | undefined) => {
    const selectedOption = options.find((o) => o.key === key)
    if (selectedOption) {
      setValue(`monthlyMappings.${index}.addColumns.${colIndex}`, {
        index: colIndex,
        name: selectedOption.name,
        position: selectedOption.position,
        key: selectedOption.key,
      })
    }
  }

  const handleDeleteAddColumn = () => {
    remove(colIndex)
  }

  return (
    <tr>
      <td className="pl-4 py-2 w-1/2">
        <div className="flex items-center space-x-2 pr-6"></div>
      </td>
      <td className="pl-2 pr-4 py-2 w-1/2">
        <div className="flex items-center space-x-2 text-sm">
          <div className="flex-1">
            <Controller
              control={control}
              name={`monthlyMappings.${index}.addColumns.${colIndex}.key`}
              render={({ field: { onChange, value } }) => (
                <SingleSelect
                  onChange={(value) => {
                    handleSelectChange(value)
                    onChange(value)
                  }}
                  value={value}
                  items={options.map((o) => ({
                    id: o.key,
                    name: o.name,
                  }))}
                  truncate
                />
              )}
            />
          </div>
          <button
            type="button"
            className="items-center p-0.5 border border-gray-400 rounded text-black-500 bg-white hover:text-gray-500"
            onClick={handleDeleteAddColumn}
          >
            <RxCross1 className="w-3 h-3" />
          </button>
        </div>
      </td>
    </tr>
  )
}

type ColumnMappingFormProps = {
  csvHeaders: CsvHeader[]
}

export default function ColumnMappingForm({
  csvHeaders,
}: ColumnMappingFormProps) {
  const { watch } = useFormContext<MappingFormProps>()
  const monthlyMappings = watch('monthlyMappings') || []

  return (
    <div>
      <div>
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="px-4 pb-4 font-bold text-left text-xs w-1/2">
                Manageboard上の年月
              </th>
              <th className="px-4 pb-4 font-bold text-left text-xs w-1/2">
                CSVから取得した年月
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="max-h-[700px] overflow-y-auto">
        <table className="min-w-full table-fixed">
          <tbody>
            {monthlyMappings.map((item, index) => (
              <React.Fragment key={index}>
                <TableRow index={index} item={item} options={csvHeaders} />
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
