import { RiFileTextFill } from 'react-icons/ri'

import { Alert } from '@/frontend/components'

type Props = {
  unmappedFileNames: string[]
}

export default function TransitionTableTagUnmappedTable({
  unmappedFileNames,
}: Props) {
  return (
    <div className="border border-gray-300 rounded-lg py-2">
      <div className="px-4 py-4 text-left font-bold">
        <p>自動割当なし</p>
      </div>
      <div className="max-h-[800px] overflow-y-auto">
        <div className="sticky top-0 px-4 z-10">
          <Alert
            variant="error"
            title="該当するタグが見つかりませんでした。ファイル名を変更し再度一括アップロードするか、個別タグのエリアにファイルを直接アップロードしてください。このエリアにあるファイルはアップロードされません。"
          ></Alert>
        </div>
        <table className="min-w-full">
          <tbody>
            {unmappedFileNames.map((fileName, index) => (
              <tr key={index}>
                <td className="px-4 py-4 flex items-center">
                  <RiFileTextFill className="mr-2 text-xl flex-shrink-0" />
                  {fileName}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
