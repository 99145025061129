import { tv } from 'tailwind-variants'

import { AlertIcon } from './AlertIcon'

type Props = {
  title: React.ReactNode
  variant?: 'info' | 'error' | 'success' | 'warning'
  children?: React.ReactNode
}

const alertClassName = tv({
  slots: {
    base: 'rounded-md p-4',
    icon: 'shrink-0',
    text: 'ml-3',
  },
  variants: {
    variant: {
      info: {
        base: 'bg-blue-50',
        icon: 'text-blue-500',
        text: 'text-blue-500',
      },
      error: {
        base: 'bg-red-50',
        icon: 'text-red-500',
        text: 'text-red-500',
      },
      success: {
        base: 'bg-green-50',
        icon: 'text-green-500',
        text: 'text-green-500',
      },
      warning: {
        base: 'bg-orange-50',
        icon: 'text-orange-600',
        text: 'text-orange-800',
      },
    },
  },
})

export function Alert({ title, variant = 'error', children }: Props) {
  const { base, icon, text } = alertClassName({ variant })
  return (
    <div className={base()}>
      <div className="flex">
        <div className={icon()}>
          <AlertIcon variant={variant} />
        </div>
        <div className={text()}>
          <h3 className="text-sm">{title}</h3>
          {children && <div className="mt-2 text-sm">{children}</div>}
        </div>
      </div>
    </div>
  )
}
