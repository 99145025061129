import { useEffect } from 'react'

import { JobLog, Org } from '@/frontend/api'
import { usePollingJobLog } from '../hooks/usePollingJobLog'

export function JobLogPollingContainer({
  org,
  pollingJobLog,
  initJobLogId,
  onStopPolling,
  children,
}: {
  org: Org
  pollingJobLog: JobLog | null
  initJobLogId: number | null
  onStopPolling: (jobLog: JobLog) => void
  children: (props: { isPolling: boolean }) => React.ReactNode
}) {
  const { isPolling, setPollingJobLogId } = usePollingJobLog(
    org,
    initJobLogId,
    { onStopPolling },
  )

  useEffect(() => {
    if (pollingJobLog?.id) {
      setPollingJobLogId(pollingJobLog.id)
    }
  }, [pollingJobLog, setPollingJobLogId])

  return children({ isPolling })
}
