import classNames from 'classnames'

import { AccountItem, Item, ReservedItem } from './types'

type Props = {
  items: Item[]
  getAmount: (item: Item) => number
  onChange: (accountItemId: number, amount: number) => void
}

export function InputTable({ items, getAmount, onChange }: Props) {
  return (
    <table className="text-gray-900 text-xs w-full">
      <thead className="bg-gray-100 border">
        <tr>
          <th className="py-1 px-2 text-left">勘定科目</th>
          <th className="py-1 px-2 text-center">金額</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <Row
            key={index}
            item={item}
            amount={getAmount(item)}
            onChange={onChange}
          />
        ))}
      </tbody>
    </table>
  )
}

type RowProps = {
  item: Item
  amount: number
  onChange: (accountItemId: number, amount: number) => void
}
function Row({ item, amount, onChange }: RowProps) {
  switch (item.type) {
    case 'ReservedItem': {
      return <ReservedItemRow item={item} amount={amount} />
    }
    case 'AccountItem': {
      return <AccountItemRow item={item} amount={amount} onChange={onChange} />
    }
  }
}

type ReservedItemRowProps = {
  item: ReservedItem
  amount: number
}
function ReservedItemRow({ item, amount }: ReservedItemRowProps) {
  return (
    <tr className="border-b border-gray-200">
      <td className={classNames('py-2 px-2', { 'font-bold': item.joint })}>
        <ItemName item={item} />
      </td>
      <td className="py-2 px-2 text-right font-bold">
        {numberWithDelimiter(amount)}
      </td>
    </tr>
  )
}

type AccountItemRowProps = {
  item: AccountItem
  amount: number
  onChange: (accountItemId: number, amount: number) => void
}
function AccountItemRow({ item, amount, onChange }: AccountItemRowProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(item.id, delimitedToNumber(e.target.value))
  }

  // フォーカス外れた時にカンマ区切り
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const n = delimitedToNumber(e.target.value)
    e.target.value = numberWithDelimiter(n)
  }

  return (
    <tr className="border-b border-gray-200">
      <td className="py-2 px-2" style={{ maxWidth: 0 }}>
        <ItemName item={item} />
      </td>
      <td className="py-1 px-0 w-4/12">
        <input
          type="text"
          defaultValue={numberWithDelimiter(amount)}
          onChange={handleChange}
          onBlur={handleBlur}
          className="form-input py-1 px-2 block w-full transition duration-150 ease-in-out text-right text-xs border-gray-200"
        />
      </td>
    </tr>
  )
}

function ItemName({ item }: { item: Item }) {
  return (
    <div
      className="truncate"
      style={{ paddingLeft: `${item.indent * 0.75}rem` }}
    >
      {item.name}
    </div>
  )
}

function numberWithDelimiter(n: number): string {
  return n.toLocaleString('ja')
}

function delimitedToNumber(s: string): number {
  const ns = s.replace(/,/g, '')
  const n = parseInt(ns)
  return isNaN(n) ? 0 : n
}
