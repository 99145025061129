export function TableHead() {
  return (
    <thead>
      <tr className="bg-gray-50 font-bold">
        <th scope="col" className="py-4 pl-6 pr-3 w-64">
          名称
        </th>
        <th scope="col" className="py-4 px-3 w-28">
          数値タイプ
        </th>
        <th scope="col" className="py-4 px-3 w-40">
          端数処理
        </th>
        <th scope="col" className="py-4 px-3 w-40">
          タグ別の値の合算
        </th>
        <th scope="col" className="py-4 px-3 w-40">
          期間の合算
        </th>
        <th scope="col" className="py-4 px-3 max-w-0">
          メモ
        </th>
        <th scope="col" className="py-4 pl-3 pr-6 w-px"></th>
      </tr>
    </thead>
  )
}
