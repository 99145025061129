import ImportPageBreadcrumbs from '../components/ImportPageBreadcrumbs'

type Props = {
  children: React.ReactNode
}
export default function ImportPageLayout({ children }: Props) {
  return (
    <>
      <ImportPageBreadcrumbs />
      {children}
    </>
  )
}
