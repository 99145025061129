import { Fragment, useMemo } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { RiCloseLine } from 'react-icons/ri'

import { AccountItem, PlItemDefinition, ReservedItem } from '../types'
import { AccountItemRows } from './AccountItemRows'
import {
  DefinitionInput,
  DefinitionsForm,
  DefinitionsFormSchema,
} from './formSchema'
import {
  useGetAccountItem,
  useGetAccountItemsByReservedItem,
  useGetPlItemDefinition,
  useGetReservedItem,
} from './hooks'

type Props = {
  formAuthenticityToken: string
  formAction: string
  formMethod: string
  onClose: () => void
  accountingFirmPlan: boolean
  reservedItems: ReservedItem[]
  accountItems: AccountItem[]
  plItemDefinitions: PlItemDefinition[]
}

// TODO: 非財務項目のReact化終わってからコンポーネント分割
export function Form({
  formAuthenticityToken,
  formAction,
  formMethod,
  onClose,
  accountingFirmPlan,
  reservedItems,
  accountItems,
  plItemDefinitions,
}: Props) {
  const { getReservedItemByKey } = useGetReservedItem(reservedItems)
  const getAccountItem = useGetAccountItem(accountItems)
  const getAccountItems = useGetAccountItemsByReservedItem(accountItems)
  const getPlItemDefinition = useGetPlItemDefinition(plItemDefinitions)

  const plJointReservedItems = useMemo(() => {
    return reservedItems.filter((reservedItem) => {
      return (
        reservedItem.joint &&
        reservedItem.fiscalStateType === 'pl_state' &&
        !reservedItem.addedByUser
      )
    })
  }, [reservedItems])

  const plAccountItems = useMemo(() => {
    return plJointReservedItems.reduce<AccountItem[]>((acc, ri) => {
      return [...acc, ...getAccountItems(ri)]
    }, [])
  }, [plJointReservedItems, getAccountItems])

  const isAccountItemIdCash = (accountItemId: string | null) => {
    return accountItemId
      ? getAccountItem(accountItemId)?.reservedItemKey == 'cash_and_deposits'
      : false
  }

  const formMethods = useForm<DefinitionsForm>({
    mode: 'onChange',
    resolver: zodResolver(DefinitionsFormSchema),
    defaultValues: {
      definitions: plAccountItems.reduce<{ [id: string]: DefinitionInput }>(
        (acc, accountItem) => {
          const definition = getPlItemDefinition(accountItem)
          return {
            ...acc,
            [accountItem.id.toString()]: {
              plan: accountingFirmPlan ? 'A' : 'M',
              id: definition.id?.toString(),
              accountItemId: definition.accountItemId.toString(),
              nonfund: definition.nonfund,
              ratios: definition.plItemDefinitionRatios.map((ratio) => ({
                ...ratio,
                id: ratio.id?.toString(),
                accountItemId: accountingFirmPlan
                  ? null
                  : ratio.accountItemId.toString(),
                memo: ratio.memo || '',
                isCash: accountingFirmPlan
                  ? false
                  : isAccountItemIdCash(ratio.accountItemId.toString()),
              })),
            },
          }
        },
        {},
      ),
    },
  })

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    const form = e.target as HTMLFormElement
    form.tabIndex = -1
    form.focus()
    formMethods.handleSubmit(() => {
      form.submit()
    })(e)
  }

  return (
    <FormProvider {...formMethods}>
      <form
        action={formAction}
        method="post"
        className="slide-over-content flex flex-col"
        onSubmit={onSubmit}
      >
        <input
          type="hidden"
          readOnly
          name="authenticity_token"
          value={formAuthenticityToken}
        />
        <input type="hidden" readOnly name="_method" value={formMethod} />
        <div className="shrink-0">
          <header className="py-2 px-4 z-50 bg-white w-full">
            <div className="flex items-start justify-between space-x-3">
              <h6 className="text-gray-900">
                {accountingFirmPlan ? 'CF項目設定' : 'BS・CF項目設定'}
              </h6>
              <button
                className="slide-over-close focus:outline-none"
                onClick={onClose}
                type="button"
              >
                <RiCloseLine className="text-2xl" />
              </button>
            </div>
          </header>
        </div>
        <div className="flex-1 relative min-h-0 overflow-y-scroll px-4">
          <table className="w-full text-xs text-left">
            <thead className="font-bold z-30">
              <tr>
                <th className="sticky top-0 bg-blue-50 p-2 w-2/12">勘定科目</th>
                <th
                  className="sticky top-0 bg-blue-50 p-2 text-center whitespace-nowrap"
                  style={{ width: '1%' }}
                >
                  非資金項目
                </th>
                <th
                  className="sticky top-0 bg-blue-50"
                  style={{ width: '1%' }}
                ></th>
                {!accountingFirmPlan && (
                  <th className="sticky top-0 bg-blue-50 p-2 w-2/12">
                    相手勘定科目
                  </th>
                )}
                <th className="sticky top-0 bg-blue-50 p-2 w-2/12">
                  現金化タイミング
                </th>
                <th
                  className="sticky top-0 bg-blue-50 p-2 whitespace-nowrap"
                  style={{ width: '1%' }}
                >
                  割合
                </th>
                <th className="sticky top-0 bg-blue-50 p-2">メモ</th>
                <th className="sticky top-0 w-10"></th>
              </tr>
            </thead>
            {plJointReservedItems.map((reservedItem) => {
              return (
                <Fragment key={reservedItem.key}>
                  <tbody>
                    <tr>
                      <td
                        colSpan={accountingFirmPlan ? 6 : 7}
                        className="border-b border-gray-400 font-bold p-2"
                      >
                        {reservedItem.name}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                  {getAccountItems(reservedItem).map((accountItem) => {
                    return (
                      <AccountItemRows
                        key={accountItem.id}
                        accountingFirmPlan={accountingFirmPlan}
                        accountItem={accountItem}
                        getReservedItemByKey={getReservedItemByKey}
                        getAccountItem={getAccountItem}
                        getAccountItems={getAccountItems}
                        isAccountItemIdCash={isAccountItemIdCash}
                      />
                    )
                  })}
                </Fragment>
              )
            })}
            <tbody>
              <tr></tr>
            </tbody>
          </table>
        </div>
        <div className="shrink-0 px-4 py-4 space-x-4 flex justify-end border-t border-gray-200">
          <div className="btn-wrapper">
            {/* TODO: Buttonコンポーネントに置き換え */}
            <button className="btn btn-white" type="button" onClick={onClose}>
              キャンセル
            </button>
          </div>
          <div className="btn-wrapper">
            {/* TODO: Buttonコンポーネントに置き換え */}
            <button
              type="submit"
              className="btn btn-primary"
              disabled={
                formMethods.formState.isSubmitting ||
                formMethods.formState.isSubmitSuccessful
              }
            >
              保存
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
