import { isAxiosError } from 'axios'

import { ApiError } from './types'

export function handleAxiosError(error: unknown): ApiError {
  if (isAxiosError(error)) {
    if (error.response?.status == 401) {
      return {
        type: 'Unauthorized',
      }
    }

    if (error.response?.status == 403) {
      return {
        type: 'Forbidden',
      }
    }

    if (error.response?.status == 422) {
      return {
        type: 'ValidationFailed',
        messages: error.response.data.errors,
      }
    }

    return {
      type: 'Unexpected',
    }
  }

  throw error
}
