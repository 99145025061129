import { useCallback, useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { FileRejection, FileWithPath } from 'react-dropzone'
import { useForm } from 'react-hook-form'

import { ApiError, Org } from '@/frontend/api'
import {
  GeneralCsvJournalsPayload,
  generalCsvJournalsSchema,
} from '@/frontend/api/importer'
import { ApiAlert, InputGroup, InputText } from '@/frontend/components'
import { Alert } from '@/frontend/components/Alert/Alert'
import { Button } from '@/frontend/components/buttons/Button'
import Dropzone from '../components/Dropzone'
import { ImportStatus } from '../components/ImportStatus'
import JobLogLink from '../components/JobLogLink'
import LastImportedAt from '../components/LastImportedAt'
import { LastImportStatus } from '../components/LastImportStatus'
import { AlertStatus } from '../hooks/useAlertStatus'
import { useImportSettingContext } from '../hooks/useImportSettingContext'
import { ImportFileStatus, JobLogWithAttachedFile } from '../types'

type Props = {
  initialValues?: Pick<GeneralCsvJournalsPayload, 'headerRowNumber'>
  importFileStatus: ImportFileStatus | null
  onFileChange: (file: FileWithPath) => void
  onSubmit: (data: GeneralCsvJournalsPayload) => void
  isLoading: boolean
  isJournalImportable: boolean
  apiError?: ApiError
  alertStatus: AlertStatus | null
  jobLogWithAttachedFile: JobLogWithAttachedFile | null
  org: Org
}

export default function JournalImportForm({
  initialValues,
  importFileStatus,
  onFileChange,
  onSubmit,
  isLoading,
  isJournalImportable,
  apiError,
  alertStatus,
  jobLogWithAttachedFile,
  org,
}: Props) {
  const { importSetting } = useImportSettingContext()
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([])
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<GeneralCsvJournalsPayload>({
    resolver: zodResolver(generalCsvJournalsSchema),
    defaultValues: {
      budgetId: Number(importSetting.budgetId),
      headerRowNumber: initialValues?.headerRowNumber ?? 1,
      csvFile: null,
    },
  })
  const csvFile = watch('csvFile')
  const _onSubmit = handleSubmit((data) => {
    onSubmit(data)
    setValue('csvFile', null)
  })

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
      if (acceptedFiles.length <= 0) return
      setValue('csvFile', acceptedFiles[0] as FileWithPath)
      onFileChange(acceptedFiles[0] as FileWithPath)
      setFileRejections(fileRejections)
    },
    [],
  )

  const errorMessage =
    fileRejections.length > 0 ? 'CSVファイルを選択してください' : ''

  useEffect(() => {
    setValue('budgetId', Number(importSetting.budgetId))
  }, [importSetting, setValue])

  return (
    <form onSubmit={_onSubmit}>
      <div className="space-y-4">
        <div className="w-1/5">
          <InputGroup
            label="ヘッダー開始行"
            errorMsg={errors.headerRowNumber?.message}
          >
            {(props) => (
              <InputText
                {...props}
                invalid={!!errors.headerRowNumber}
                {...register('headerRowNumber')}
              />
            )}
          </InputGroup>
        </div>
        {errorMessage && <Alert title={errorMessage} />}
        {apiError && <ApiAlert apiError={apiError} />}
        <Dropzone onDrop={onDrop} disabled={isLoading} />
        {importFileStatus && (
          <LastImportStatus
            importFileStatus={importFileStatus}
            loading={isLoading}
          />
        )}
        {alertStatus && (
          <ImportStatus
            status={alertStatus.status}
            errorDetail={alertStatus.errorDetail}
          />
        )}
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <LastImportedAt
              lastImportedAt={jobLogWithAttachedFile?.createdAt}
            />
            <JobLogLink org={org} />
          </div>
          <div className="mt-auto">
            <Button
              type="submit"
              variant="primary"
              loading={isLoading}
              disabled={csvFile == null || !isJournalImportable}
            >
              アップロード
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}
