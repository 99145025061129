import { Org } from '@/frontend/api'
import { Card as Section } from '@/frontend/components'
import { AccountingSoftware } from '../../types'
import JournalUpdateContainer from './JournalUpdateContainer'
import MasterMappingContainer, {
  Props as MasterMappingContainerProps,
} from './MasterMappingContainer'

export type Props = {
  org: Org
  accountingSoftware: AccountingSoftware
} & MasterMappingContainerProps

export default function DataSyncSection({
  org,
  accountingSoftware,
  pendingChecker,
}: Props) {
  return (
    <Section onGray>
      <Section.Title>データ反映</Section.Title>
      <div className="p-6 border space-y-12 text-sm rounded-md">
        {/*マスタの割当*/}
        <MasterMappingContainer
          org={org}
          accountingSoftware={accountingSoftware}
          pendingChecker={pendingChecker}
        />

        {/*実績の反映*/}
        <JournalUpdateContainer
          org={org}
          accountingSoftwareKey={accountingSoftware.key}
          pendingChecker={pendingChecker}
        />
      </div>
    </Section>
  )
}
