import { RiErrorWarningFill } from 'react-icons/ri'
import { tv } from 'tailwind-variants'

type Props = {
  children: React.ReactNode
}

const icon = tv({
  base: 'h-4 w-4',
})

export function ErrorMessage({ children }: Props) {
  return (
    <div className="text-red-400 h-3 px-1">
      <div className="flex items-center">
        <div className="shrink-0">
          <RiErrorWarningFill className={icon()} />
        </div>
        <div className="ml-1">
          <h3 className="text-sm">{children}</h3>
        </div>
      </div>
    </div>
  )
}
