const A_ASCII_CODE = 65
const ALPHABET_COUNT = 26

export const indexToAlpha = (index: number): string | undefined => {
  if (isNaN(index)) {
    throw new Error('Invalid input: must be a number')
  }

  if (index < 0) {
    return undefined
  }

  let result = ''

  while (index >= 0) {
    result =
      String.fromCharCode(A_ASCII_CODE + (index % ALPHABET_COUNT)) + result
    index = Math.floor(index / ALPHABET_COUNT) - 1
  }

  return result
}
