import { ComponentProps, useState } from 'react'
import dayjs from 'dayjs'
import { RiDeleteBinLine } from 'react-icons/ri'

import { Client, MemberWithAssign, Org } from '@/frontend/api/types'
import { Tooltip } from '@/frontend/components'
import { AssignMembersForm } from '@/frontend/features/clients/AssignMembersForm'

export function ClientRow({
  org,
  member,
  client,
  assignable,
  destroyable,
  onDelete,
  style,
}: {
  org: Org
  member: MemberWithAssign
  client: Client
  assignable: boolean
  destroyable: boolean
  onDelete: (client: Client) => void
  style: React.CSSProperties
}) {
  const assignedMembers = client.members.filter((m) => m.assigned)
  const [isAccessible, setIsAccessible] = useState(
    assignedMembers.some((m) => m.id === member.id),
  )

  const getClientPageUrl = (clientId: number) => {
    return `/orgs/${clientId}`
  }

  const deleteTooltipContent = destroyable
    ? client.destroyable
      ? null
      : 'クライアント契約中のため削除できません。'
    : 'クライアントを削除するためにはマネージャー以上の権限が必要です。'

  return (
    <div className="flex text-gray-700">
      <div style={style} className="flex-none w-72 leading-10 truncate">
        <Tooltip content={`${client.name}`} mouseDownClosed={true}>
          {(ref) => (
            <span ref={ref} className="text-gray-900">
              {client.name}
            </span>
          )}
        </Tooltip>
      </div>
      <div className="flex-none w-96 text-ellipsis">
        <MembersForm
          org={org}
          client={client}
          assignable={assignable}
          onAssignMembers={(members) =>
            setIsAccessible(members.some((m) => m.id === member.id))
          }
          withTooltip={!assignable}
        />
      </div>
      <div className="flex-none w-48 text-right leading-10 pr-8">
        {client.commonMemberCount}
      </div>
      <div className="flex-none w-40 leading-10">
        {client.resultUpdatedAt
          ? dayjs(client.resultUpdatedAt).format('YYYY/MM/DD')
          : '-'}
      </div>
      <div className="flex-none w-40 leading-10">
        {dayjs(client.createdAt).format('YYYY/MM/DD')}
      </div>
      <div className="flex-auto">
        <div className="flex h-full items-center justify-end">
          <div className="btn-wrapper mr-5">
            <a
              href={getClientPageUrl(client.id)}
              className={`button btn btn-xs btn-secondary ${
                isAccessible ? '' : 'disabled'
              }`}
            >
              ログイン
            </a>
          </div>
          <Tooltip content={deleteTooltipContent}>
            {(ref) => (
              <div className="btn-wrapper" ref={ref}>
                <button
                  className="btn btn-xs btn-white"
                  disabled={!destroyable || !client.destroyable}
                  onClick={() => {
                    if (!client.destroyable) return
                    onDelete(client)
                  }}
                >
                  <i className="ri-fw mr-2">
                    <RiDeleteBinLine />
                  </i>
                  削除
                </button>
              </div>
            )}
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

type MembersFormProps = ComponentProps<typeof AssignMembersForm> & {
  withTooltip?: boolean
}

function MembersForm(props: MembersFormProps) {
  return props.withTooltip ? (
    <Tooltip content="担当者を設定するためにはマネージャー以上の権限が必要です。">
      {(ref) => (
        <div ref={ref}>
          <AssignMembersForm {...props} />
        </div>
      )}
    </Tooltip>
  ) : (
    <AssignMembersForm {...props} />
  )
}
