import { Org, PendingChecker } from '@/frontend/api/types'
import { Alert, ButtonLink } from '@/frontend/components'
import { useImportSettingContext } from '@/frontend/features/importers/hooks/useImportSettingContext'
import { getSourceAccountItemUrl } from '../../getSourceAccountItemUrl'
import { AccountingSoftware } from '../../types'

export type Props = {
  org: Org
  accountingSoftware: AccountingSoftware
  pendingChecker: PendingChecker
}

export default function MasterMappingContainer({
  org,
  accountingSoftware,
  pendingChecker,
}: Props) {
  const { importSetting } = useImportSettingContext()

  const sourceAccountItemUrl = getSourceAccountItemUrl(
    org.id,
    accountingSoftware.key,
    importSetting.dataFormat,
  )

  const isShownAlert =
    (importSetting.dataFormat === 'api' && pendingChecker.isApiMasterPending) ||
    (importSetting.dataFormat === 'csv' && pendingChecker.isCsvMasterPending)

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <div className="font-bold">マスタの割当</div>
        <div className="flex justify-between items-center">
          <div>
            <p>
              Manageboardにおけるデータの集計単位をカスタマイズすることができます。
            </p>
          </div>
          <ButtonLink
            variant={`${isShownAlert ? 'primary' : 'secondary'}`}
            href={sourceAccountItemUrl}
          >
            マスタの割当
          </ButtonLink>
        </div>
      </div>
      {isShownAlert ? (
        <Alert
          title="マスタの割当が完了していない項目があります。マスタの割当ボタンから設定を行ってください。"
          variant="warning"
        />
      ) : null}
    </div>
  )
}
