import { tv } from 'tailwind-variants'

const cardRoot = tv({
  base: 'ring-1 ring-gray-200 rounded bg-white p-4',
  variants: {
    onGray: {
      true: 'ring-0',
    },
  },
})

type RootProps = {
  onGray?: boolean
  children: React.ReactNode
}
export function CardRoot({ onGray = false, children }: RootProps) {
  return <div className={cardRoot({ onGray })}>{children}</div>
}

type TitleProps = {
  children: React.ReactNode
}
export function CardTitle({ children }: TitleProps) {
  return (
    <div className="text-lg font-bold leading-none border-l-[3px] border-l-blue-800 pl-2 text-gray-800 mb-4">
      {children}
    </div>
  )
}

export const Card = Object.assign(CardRoot, {
  Title: CardTitle,
})
