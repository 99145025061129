type Props = {
  horizontal?: boolean
  children: React.ReactNode
}

// childrenに
export function SelectionGroup({ horizontal = false, children }: Props) {
  if (horizontal) {
    // 横並べ
    return <div className="flex items-center gap-y-2 gap-x-5">{children}</div>
  } else {
    // 縦並べ
    return <div className="flex flex-col gap-y-2">{children}</div>
  }
}
