import { tv } from 'tailwind-variants'

type Variant = 'green' | 'red' | 'blue' | 'gray'

type Props = {
  variant: Variant
  children: React.ReactNode
}

export const messageLabel = tv({
  base: [
    'inline-flex items-center justify-center',
    'py-1 px-2',
    'rounded',
    'text-xs leading-4',
  ].join(' '),
  variants: {
    variant: {
      green: 'text-green-600 bg-green-50',
      red: 'text-red-500 bg-red-50',
      blue: 'text-blue-600 bg-blue-50',
      gray: 'text-white bg-gray-400',
    },
  },
})

export function MessageLabel({ variant, children }: Props) {
  return <span className={messageLabel({ variant })}>{children}</span>
}
