import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Tooltip } from '@/frontend/components'
import { AlertIcon } from '@/frontend/components/Alert/AlertIcon'
import { SingleSelect } from '@/frontend/features/importers/components/SingleSelect'
import { AccountItem, CsvAccountItem, MappingFormProps } from './types'

type TableRowProps = {
  index: number
  item: AccountItem
  options: CsvAccountItem[]
}

function TableRow({ index, item, options }: TableRowProps) {
  const { watch, setValue, control } = useFormContext<MappingFormProps>()
  const [isDuplicated, setIsDuplicated] = useState(false)

  useEffect(() => {
    const initialSelectedOptionKey = watch(
      `accountItemMappings.${index}.csvAccountItem.key`,
    )

    if (initialSelectedOptionKey) {
      const initialOption = options.find(
        (o) => o.key === initialSelectedOptionKey,
      )
      if (initialOption) {
        setIsDuplicated(initialOption.duplicated)
      }
    }
  }, [])

  const handleSelectChange = (key: string | null | undefined) => {
    if (key === null) {
      // NOTE: ブランクが選ばれた場合(選択肢クリア)の処理だよ
      setValue(`accountItemMappings.${index}.csvAccountItem`, null)
      setIsDuplicated(false)
      return
    }

    const selectedOption = options.find((o) => {
      return o.key === key
    })

    if (selectedOption) {
      setValue(`accountItemMappings.${index}.csvAccountItem`, selectedOption)
      setIsDuplicated(selectedOption.duplicated)
    }
  }

  return (
    <tr>
      <td className="px-4 py-2 w-1/3 text-sm">{item.reservedItem.name}</td>
      <td className="px-4 py-2 w-1/3 text-sm">{item.name}</td>
      <td className="px-4 py-2 w-1/3 text-sm">
        <div className="flex items-center space-x-2">
          <Controller
            control={control}
            name={`accountItemMappings.${index}.csvAccountItem.key`}
            render={({ field: { onChange, value } }) => (
              <div className="w-52">
                <SingleSelect
                  onChange={(value) => {
                    handleSelectChange(value)
                    onChange(value)
                  }}
                  value={value}
                  items={options.map((o) => ({
                    id: o.key,
                    name: o.duplicated
                      ? `${o.name} (${o.position + 1})`
                      : o.name,
                  }))}
                  blankOption={{ id: null, name: '' }}
                  truncate={true}
                />
              </div>
            )}
          />

          {isDuplicated && (
            <Tooltip
              content="CSVファイルに同じ名称の勘定科目が存在します。複数のファイル間でフォーマットの構成が異なる場合、正しく数字を取り込めない場合があります。インポート後に数値の整合性を確認してください。"
              variant="long"
            >
              {(ref) => (
                <div ref={ref} className="text-orange-600 relative group">
                  <AlertIcon variant="warning" />
                </div>
              )}
            </Tooltip>
          )}
        </div>
      </td>
    </tr>
  )
}

type RowMappingFormProps = {
  accountItems: AccountItem[]
  csvAccountItems: CsvAccountItem[]
}

export default function RowMappingForm({
  accountItems,
  csvAccountItems,
}: RowMappingFormProps) {
  return (
    <div>
      <div>
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="px-4 pb-4 font-bold text-left text-xs w-1/3">
                属性
              </th>
              <th className="px-4 pb-4 font-bold text-left text-xs w-1/3">
                Manageboard上の勘定科目
              </th>
              <th className="px-4 pb-4 font-bold text-left text-xs w-1/3">
                CSVから取得した勘定科目
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="max-h-[700px] overflow-y-auto">
        <table className="min-w-full table-fixed">
          <tbody>
            {accountItems.map((item, index) => (
              <TableRow
                index={index}
                item={item}
                options={csvAccountItems}
                key={index}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
