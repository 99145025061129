import { IconType } from 'react-icons'
import { RiRefreshLine } from 'react-icons/ri'
import { tv } from 'tailwind-variants'

type EmptyStateProps = {
  icon: IconType
  children: React.ReactNode
  loading?: boolean
  onClick?: () => void
}

const emptyState = tv({
  base: [
    'relative block w-full rounded-lg border-2 border-dashed border-gray-400 p-10 text-center text-gray-400 overflow-hidden',
    'transition duration-150 ease-in-out',
    'hover:border-gray-400 hover:text-gray-800',
    'focus:outline-offset-0 focus:outline-[3px] focus:outline focus:outline-blue-100',
    'active:bg-gray-50',
  ].join(' '),
  variants: {
    loading: {
      true: 'bg-gray-100 pointer-events-none',
    },
  },
})

export function EmptyState({
  icon,
  children,
  loading,
  onClick,
}: EmptyStateProps) {
  const Icon = icon

  return (
    <button
      type="button"
      className={emptyState({ loading })}
      onClick={onClick}
      disabled={loading}
    >
      <Icon className="mx-auto h-10 w-10" />
      <span className="mt-2 block text-sm font-bold">{children}</span>
      {loading && (
        <div className="absolute inset-0 bg-gray-100 flex items-center justify-center">
          <RiRefreshLine className="mx-auto h-10 w-10 animate-spin" />
        </div>
      )}
    </button>
  )
}
