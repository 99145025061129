import { getSourceAccountItemUrl } from '@/frontend/features/importers/getSourceAccountItemUrl'
import type {
  AccountingSoftwareKey,
  DataFormat,
} from '@/frontend/features/importers/types'
import Header from '@/frontend/pages/common/NestedTabHeader'

type Props = {
  importUrl: string
  orgId: number
  accountingSoftwareKey: AccountingSoftwareKey
  isMasterPendingExists: boolean
  dataFormat: DataFormat
}

export default function IndexTabs({
  importUrl,
  orgId,
  accountingSoftwareKey,
  isMasterPendingExists,
  dataFormat,
}: Props) {
  // NOTE: MFC会計の場合のみマスタ割当がapi/csvがある。
  //       他の会計ソフトの場合は、マスタ割当の一意のためURLを受け取ることができる
  const sourceAccountItemUrl = getSourceAccountItemUrl(
    orgId,
    accountingSoftwareKey,
    dataFormat,
  )

  const tabItems = [
    { label: 'インポート', href: importUrl, active: false },
    {
      label: 'マスタ割当',
      href: sourceAccountItemUrl,
      active: false,
      attention: isMasterPendingExists,
    },
    {
      label: '開始残高',
      href: '#',
      active: true,
    },
  ]

  return (
    <Header
      breadcrumbItems={[{ label: '計画/実績' }, { label: '開始残高' }]}
      tabItems={tabItems}
    />
  )
}
