type Props = {
  numerator: number
  denominator: number
  isShownDenominator: boolean
}

export function CountLabel({
  numerator,
  denominator,
  isShownDenominator,
}: Props) {
  return (
    <div className="flex items-center">
      <p className="text-sm leading-9 text-gray-700 ml-4">
        クライアント数
        <span className="mx-2">|</span>
        <span className="font-bold">{numerator}</span>社
        {isShownDenominator && (
          <>
            <span className="mx-1">/</span>
            <span className="font-bold">{denominator}</span>社
          </>
        )}
      </p>
    </div>
  )
}
