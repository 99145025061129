import { Breadcrumbs } from '@/frontend/components'

export default function ImportPageBreadcrumbs() {
  const breadcrumbItems = [{ label: '計画/実績' }, { label: 'インポート' }]
  return (
    <div className="px-8 py-2">
      <Breadcrumbs items={breadcrumbItems} />
    </div>
  )
}
