import { cloneElement, useCallback, useState } from 'react'

export function useRemount() {
  const [counter, setCounter] = useState(0)
  const Remount = useCallback(
    ({ children }: { children: React.ReactElement }) => (
      <>{[cloneElement(children, { key: `remount-${counter}` })]}</>
    ),
    [counter],
  )

  return {
    remount: () => setCounter((v) => v + 1),
    Remount,
  }
}
