import { forwardRef } from 'react'

import { ButtonContent } from './ButtonContent'
import { ButtonLoadingOverlay } from './ButtonLoadingOverlay'
import { ButtonProps } from './types'
import { buttonClassName } from './utils'

type Props = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'> &
  ButtonProps

export const Button = forwardRef<HTMLButtonElement, Props>(function Button(
  {
    variant,
    type = 'button',
    disabled = false,
    loading = false,
    icon,
    children,
    ...props
  },
  ref,
) {
  const iconOnly = !!icon

  return (
    <button
      className={buttonClassName({ variant, disabled, loading, iconOnly })}
      disabled={disabled}
      type={type}
      ref={ref}
      {...props}
    >
      <ButtonLoadingOverlay loading={loading}>
        <ButtonContent icon={icon}>{children}</ButtonContent>
      </ButtonLoadingOverlay>
    </button>
  )
})
