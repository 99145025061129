import { RiDeleteBinLine } from 'react-icons/ri'

export function ActionCell({
  budgetDetailItemUrl,
}: {
  budgetDetailItemUrl: string
}) {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <a
        className="text-gray-400 hover:text-gray-600"
        data-confirm="すべての会計期間の計画の数値が削除されますが、よろしいですか？"
        rel="nofollow"
        data-method="delete"
        href={budgetDetailItemUrl}
      >
        <RiDeleteBinLine />
      </a>
    </div>
  )
}
