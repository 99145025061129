import { useState } from 'react'
import { Disclosure } from '@headlessui/react'
import { RiArrowUpSLine } from 'react-icons/ri'

import { JobLog, Org, PendingChecker } from '@/frontend/api'
import { SectionTitle } from '@/frontend/features/importers/components/SectionTitle'
import useLocalStorage from '@/frontend/hooks/useLocalStorage'
import { JobLogPollingContainer } from '../components/JobLogPollingContainer'
import { getInitialImportFileStatus } from '../getInitialImportFileStatus'
import useAlertStatus from '../hooks/useAlertStatus'
import { useApiPendingChecker } from '../hooks/useApiPendingChecker'
import useImportFileStatus from '../hooks/useImportFileStatus'
import useReturnedPendingCheckerEffect from '../hooks/useReturnedPendingCheckerEffect'
import { JobLogWithAttachedFile } from '../types'
import MasterImportForm from './MasterImportForm'
import { useImportBugyoICsvSourceAccountItems } from './useImportBugyoICsvSourceAccountItems'

type Props = {
  org: Org
  jobLogWithAttachedFile: JobLogWithAttachedFile | null
  initialInProgressJobLogId: number | null
  setIsJournalImportable: (isJournalImportable: boolean) => void
  setPendingChecker: (pendingChecker: PendingChecker) => void
}

const STORED_VALUES_STORAGE_KEY = 'BugyoICsvMasterImportSettings' as const

type StoredValueStorageType = {
  [id: number]: { accordionOpen: boolean }
}

export default function MasterImportFormContainer({
  org,
  jobLogWithAttachedFile,
  initialInProgressJobLogId,
  setIsJournalImportable,
  setPendingChecker,
}: Props) {
  const {
    importFileStatus,
    updateOnlyStatusForImportFile,
    updateImportFileToQueued,
  } = useImportFileStatus(getInitialImportFileStatus(jobLogWithAttachedFile))

  const {
    submit: pendingCheckerSubmit,
    apiError: pendingCheckerApiError,
    returnedPendingChecker,
  } = useApiPendingChecker(org)

  const [storedValues, setStoreValues] =
    useLocalStorage<StoredValueStorageType>(STORED_VALUES_STORAGE_KEY)

  const { alertStatus, updateAlertStatusFromJobLog, resetAlertStatus } =
    useAlertStatus(jobLogWithAttachedFile)

  const { submit, jobLog, isSubmitting, apiError } =
    useImportBugyoICsvSourceAccountItems(org)

  useReturnedPendingCheckerEffect(returnedPendingChecker, setPendingChecker)

  // NOTE: headless ui から open/close が取れなかったので自前で管理しているよ
  const [accordionOpen, setAccordionOpen] = useState<boolean>(
    storedValues?.[org.id]?.accordionOpen ?? true,
  )
  const handleAccordionClick = () => {
    setStoreValues((prev) => {
      return {
        ...prev,
        [org.id]: {
          accordionOpen: !accordionOpen,
        },
      }
    })
    setAccordionOpen(!accordionOpen)
  }

  return (
    <JobLogPollingContainer
      org={org}
      pollingJobLog={jobLog}
      initJobLogId={initialInProgressJobLogId}
      onStopPolling={(jobLog: JobLog) => {
        if (jobLog?.status === 'succeeded') {
          setIsJournalImportable(true)
        }
        updateOnlyStatusForImportFile(jobLog.status)
        updateAlertStatusFromJobLog(jobLog)
        pendingCheckerSubmit()
      }}
    >
      {({ isPolling }) => (
        <Disclosure defaultOpen={accordionOpen}>
          {({ open }) => (
            <>
              <div className="space-y-4">
                <Disclosure.Button as="div" onClick={handleAccordionClick}>
                  <div className="flex items-center justify-between bg-gray-100">
                    <SectionTitle>マスタインポート</SectionTitle>
                    <RiArrowUpSLine
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } mr-4 h-4 w-4`}
                    />
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel>
                  <div className="space-y-4">
                    <div>
                      会計ソフトから出力した勘定科目データを取り込みます。
                    </div>
                    <div className="font-bold">勘定科目</div>
                    <MasterImportForm
                      importFileStatus={importFileStatus}
                      onFileChange={(file: File) => {
                        updateImportFileToQueued([file])
                        resetAlertStatus()
                      }}
                      onSubmit={submit}
                      isLoading={isSubmitting || isPolling}
                      apiError={apiError || pendingCheckerApiError}
                      alertStatus={alertStatus}
                      jobLogWithAttachedFile={jobLogWithAttachedFile}
                    />
                  </div>
                </Disclosure.Panel>
              </div>
            </>
          )}
        </Disclosure>
      )}
    </JobLogPollingContainer>
  )
}
