import { RiExternalLinkLine } from 'react-icons/ri'

import { Org } from '@/frontend/api/types'

export default function JobLogLink({ org }: { org: Org }) {
  return (
    <a
      href={`/orgs/${org.id}/job_logs`}
      target="_blank"
      rel="noreferrer"
      className="text-blue-600 flex flex-row items-center"
    >
      実行履歴
      <RiExternalLinkLine />
    </a>
  )
}
