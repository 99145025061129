import { RiCheckLine } from 'react-icons/ri'

export function StatusLabelContent({
  withIcon,
  children,
}: {
  withIcon: boolean
  children: React.ReactNode
}) {
  if (!withIcon) {
    return <>{children}</>
  }

  return (
    <>
      <RiCheckLine className="mr-1" />
      <span>{children}</span>
    </>
  )
}
