import { RiFileTextFill } from 'react-icons/ri'

import { Status, StatusLabel } from '@/frontend/components'
import { Card } from '@/frontend/components/Card'
import { getJobStatusMap } from '../getJobStatusMap'
import { ImportFileStatus } from '../types'

type Props = {
  importFileStatus: ImportFileStatus
  loading?: boolean
}

export const LastImportStatus = ({ importFileStatus, loading }: Props) => {
  const { attachedFiles: files, status } = importFileStatus
  const { variant, labelText } = getJobStatusMap(status)
  return (
    <Card>
      <div className="flex items-center">
        <div className="flex flex-col">
          {files.map(({ name }, idx) => (
            <div key={idx} className="flex items-center">
              <RiFileTextFill className="mr-1" />
              <span>{name}</span>
            </div>
          ))}
        </div>
        <div className="ml-10">
          <StatusLabel variant={variant as Status} loading={loading}>
            {labelText}
          </StatusLabel>
        </div>
      </div>
    </Card>
  )
}
