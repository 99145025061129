import {
  Control,
  FieldErrors,
  FieldValues,
  Path,
  useController,
} from 'react-hook-form'

import { InputDate, InputGroup } from '@/frontend/components'

type Props<T extends FieldValues> = {
  control: Control<T>
  disabled?: boolean
}

// NOTE: インポート画面かつRHFを使うことを前提として、RHFに依存しています。
export default function PeriodInputs<T extends FieldValues>({
  control,
  disabled,
}: Props<T>) {
  const {
    field: firstDateField,
    fieldState: { error: firstDateError },
  } = useController({ name: 'firstDate' as Path<T>, control })

  const {
    field: lastDateField,
    fieldState: { error: lastDateError },
  } = useController({ name: 'lastDate' as Path<T>, control })

  const errors = {
    firstDate: firstDateError,
    lastDate: lastDateError,
    period: control._formState.errors['period'],
  }

  const getDateErrorMsg = (errors: FieldErrors) => {
    if (errors.firstDate || errors.lastDate) {
      return (errors.firstDate?.message || errors.lastDate?.message) as string
    }
    if (errors['period']) {
      return errors['period'].message as string
    }
    return
  }

  return (
    <InputGroup
      disabled={disabled}
      label={'期間'}
      name="dateRange"
      errorMsg={getDateErrorMsg(errors)}
    >
      {({ disabled }) => (
        <div className="flex items-center">
          <InputDate {...firstDateField} disabled={disabled} />
          <span className="px-2">〜</span>
          <InputDate {...lastDateField} disabled={disabled} />
        </div>
      )}
    </InputGroup>
  )
}
