import { RiAddLine } from 'react-icons/ri'

import { Org } from '@/frontend/api/types'
import { ButtonLink, Tooltip } from '@/frontend/components'

type Props = {
  org: Org
  creatable: boolean
}

export function InvitationButton({ org, creatable }: Props) {
  const getNewClientsUrl = (orgId: number) => {
    return `/orgs/${orgId}/clients/new`
  }

  if (creatable) {
    return (
      <div>
        <ButtonLink variant="primary" href={getNewClientsUrl(org.id)}>
          <div className="mr-2">
            <RiAddLine />
          </div>
          クライアントの新規登録
        </ButtonLink>
      </div>
    )
  } else {
    return (
      <Tooltip content="クライアントを新規登録するためにはマネージャー以上の権限が必要です。">
        {(ref) => (
          <div className="flex justify-end" ref={ref}>
            <ButtonLink
              variant="primary"
              href={getNewClientsUrl(org.id)}
              disabled={!creatable}
            >
              <div className="mr-2">
                <RiAddLine />
              </div>
              クライアントの新規登録
            </ButtonLink>
          </div>
        )}
      </Tooltip>
    )
  }
}
