import { useEffect, useRef } from 'react'

import { BudgetTagCategory, Org, PendingChecker } from '@/frontend/api'
import { useImportSettingContext } from '../hooks/useImportSettingContext'
import { JobLogWithAttachedFile } from '../types'
import AccountItemImportFormContainer from './AccountItemImportFormContainer'
import BudgetTagImportFormContainer from './BudgetTagImportFormContainer'
import MasterImportAccordion from './MasterImportAccordion'

type Props = {
  org: Org
  sourceAccountItemUrl: string
  primaryBudgetTagCategory: BudgetTagCategory | null
  jobLogWithAttachedFile: JobLogWithAttachedFile | null
  initialInProgressJobLogId: number | null
  setIsJournalImportable: (isJournalImportable: boolean) => void
  pendingChecker: PendingChecker
  setPendingChecker: (pendingChecker: PendingChecker) => void
}

export default function MasterImportFormContainer({
  org,
  sourceAccountItemUrl,
  primaryBudgetTagCategory,
  jobLogWithAttachedFile,
  initialInProgressJobLogId,
  setIsJournalImportable,
  pendingChecker,
  setPendingChecker,
}: Props) {
  const {
    importSetting: { importFormat },
  } = useImportSettingContext()

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const hashSave = window.location.hash
    if (hashSave == '#master-import-form' && ref.current) {
      ref.current.scrollIntoView()
    }
  }, [])

  return (
    <MasterImportAccordion ref={ref} org={org}>
      {importFormat === 'general' && (
        <div className="space-y-6">
          {/* その他形式のマスタインポート */}
          <AccountItemImportFormContainer
            org={org}
            sourceAccountItemUrl={sourceAccountItemUrl}
            jobLogWithAttachedFile={jobLogWithAttachedFile}
            initialInProgressJobLogId={initialInProgressJobLogId}
            setIsJournalImportable={setIsJournalImportable}
            pendingChecker={pendingChecker}
            setPendingChecker={setPendingChecker}
          />
        </div>
      )}

      {importFormat === 'transitionTable' && (
        <div className="space-y-6">
          {/* 推移表形式のマスタインポート */}
          <AccountItemImportFormContainer
            org={org}
            sourceAccountItemUrl={sourceAccountItemUrl}
            jobLogWithAttachedFile={jobLogWithAttachedFile}
            initialInProgressJobLogId={initialInProgressJobLogId}
            setIsJournalImportable={setIsJournalImportable}
            pendingChecker={pendingChecker}
            setPendingChecker={setPendingChecker}
          />

          <BudgetTagImportFormContainer
            org={org}
            primaryBudgetTagCategory={
              primaryBudgetTagCategory as BudgetTagCategory
            }
          />
        </div>
      )}
    </MasterImportAccordion>
  )
}
