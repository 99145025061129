import { useEffect, useState } from 'react'

import {
  AccountItem,
  BudgetTag,
  FormValue,
  IncomeTaxesSimpleCalculationSlideOver,
  PlItemDefinition,
  PlItemDefinitionsSlideOver,
  ReservedItem,
  Slider,
} from './components'
import { Dropdown } from './components/Dropdown'

type Props = {
  formAuthenticityToken: string
  formAction: string
  formMethod: string
  accountItems: AccountItem[]
  budgetTags: BudgetTag[]
  incomeBeforeTaxesTotalBalance: number
  defaultFormValue: FormValue
  accountItemsPath: string
  instantSimulatorSlideOverId: string
  settingSlideOverId: string
  costTypesSlideOverId: string
  borrowingsPath: string
  plState: boolean
  accountingFirmPlan: boolean
  slider: Slider
  fiscalPeriodId: number
  isWholeCompany: boolean
  reservedItems: ReservedItem[]
  plItemDefinitionsSlideOverAction: string
  plItemDefinitionsSlideOverMethod: string
  plItemDefinitions: PlItemDefinition[]
}

export default function Show({
  formAuthenticityToken,
  formAction,
  formMethod,
  accountItems,
  budgetTags,
  incomeBeforeTaxesTotalBalance,
  defaultFormValue,
  accountItemsPath,
  instantSimulatorSlideOverId,
  settingSlideOverId,
  costTypesSlideOverId,
  borrowingsPath,
  plState,
  accountingFirmPlan,
  slider,
  fiscalPeriodId,
  isWholeCompany,
  reservedItems,
  plItemDefinitionsSlideOverAction,
  plItemDefinitionsSlideOverMethod,
  plItemDefinitions,
}: Props) {
  const [open, setOpen] = useState(false)
  const [plItemDefinitionsSlideOverOpen, setPlItemDefinitionsSlideOverOpen] =
    useState(false)
  const [
    updatedIncomeBeforeTaxesTotalBalance,
    setUpdatedIncomeBeforeTaxesTotalBalance,
  ] = useState(incomeBeforeTaxesTotalBalance)

  // 最新の税引前当期純利益を取得するために
  useEffect(() => {
    if (!open) return

    const el = document.getElementById('totalIncomeBeforeTaxes')
    if (!el?.textContent) return

    const txt = el.textContent.replace(/,/g, '')
    const n = parseInt(txt)
    setUpdatedIncomeBeforeTaxesTotalBalance(isNaN(n) ? 0 : n)
  }, [open])

  const incomeTaxesCurrentAccountItems = accountItems.filter((accountItem) => {
    return accountItem.reservedItemKey == 'income_taxes_current'
  })

  return (
    <>
      {!slider.incomeTaxesSimpleCalculationHidden && (
        <div className="flex-end">
          <div
            className="btn-wrapper mr-3"
            data-tooltip={
              slider.incomeTaxesSimpleCalculationDisabled
                ? plState
                  ? '「すべて」のタグが選択されている場合に操作できます。'
                  : '計画PLの画面から操作できます。'
                : undefined
            }
          >
            <button
              className="btn btn-sm btn-white"
              type="button"
              disabled={slider.incomeTaxesSimpleCalculationDisabled}
              onClick={() => setOpen(true)}
            >
              法人税等の計算
            </button>
          </div>
        </div>
      )}
      <div className="flex-none">
        <Dropdown
          instantSimulatorSlideOverId={instantSimulatorSlideOverId}
          settingSlideOverId={settingSlideOverId}
          costTypesSlideOverId={costTypesSlideOverId}
          borrowingsPath={borrowingsPath}
          plState={plState}
          slider={slider}
          openPlItemDefinitionsSlideOver={() => {
            setPlItemDefinitionsSlideOverOpen(true)
          }}
          accountingFirmPlan={accountingFirmPlan}
        />
      </div>
      {plState && (
        <IncomeTaxesSimpleCalculationSlideOver
          formAuthenticityToken={formAuthenticityToken}
          formAction={formAction}
          formMethod={formMethod}
          accountItems={incomeTaxesCurrentAccountItems}
          budgetTags={budgetTags}
          incomeBeforeTaxesTotalBalance={updatedIncomeBeforeTaxesTotalBalance}
          defaultFormValue={defaultFormValue}
          accountItemsPath={accountItemsPath}
          open={open}
          setOpen={setOpen}
          isWholeCompany={isWholeCompany}
          fiscalPeriodId={fiscalPeriodId}
        />
      )}
      <PlItemDefinitionsSlideOver
        formAuthenticityToken={formAuthenticityToken}
        formAction={plItemDefinitionsSlideOverAction}
        formMethod={plItemDefinitionsSlideOverMethod}
        open={plItemDefinitionsSlideOverOpen}
        onClose={() => setPlItemDefinitionsSlideOverOpen(false)}
        accountingFirmPlan={accountingFirmPlan}
        accountItems={accountItems}
        reservedItems={reservedItems}
        plItemDefinitions={plItemDefinitions}
      />
    </>
  )
}
