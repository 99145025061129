import { useState } from 'react'

import { AuthScopes, Org, PendingChecker } from '@/frontend/api'
import ImportPageContent from '@/frontend/features/importers/components/ImportPageContent'
import ImportPageLayout from '@/frontend/features/importers/layout/ImportPageLayout'
import { AccountingSoftware, Budget } from '@/frontend/features/importers/types'
import DataSourceSection, {
  DataSourceSectionProps,
} from '@/frontend/features/importers/yayoi/DataSourceSection'

type Props = {
  org: Org
  accountingSoftware: AccountingSoftware
  budgets: Budget[]
  openingBalanceUrl: string
  pendingChecker: PendingChecker
  authScopes: AuthScopes
} & Omit<DataSourceSectionProps, 'setPendingChecker'>

export default function ShowPage({
  org,
  accountingSoftware,
  budgets,
  openingBalanceUrl,
  pendingChecker: initialPendingChecker,
  authScopes,
  ...dataSourceSection
}: Props) {
  const [pendingChecker, setPendingChecker] = useState<PendingChecker>(
    initialPendingChecker,
  )

  return (
    <ImportPageLayout>
      <ImportPageContent
        org={org}
        budgets={budgets}
        accountingSoftware={accountingSoftware}
        openingBalanceUrl={openingBalanceUrl}
        pendingChecker={pendingChecker}
        authScopes={authScopes}
      >
        <DataSourceSection
          org={org}
          setPendingChecker={setPendingChecker}
          {...dataSourceSection}
        />
      </ImportPageContent>
    </ImportPageLayout>
  )
}
