import { nanoid } from 'nanoid'
import { RiErrorWarningFill } from 'react-icons/ri'

import { Label } from '../Label'

type Props = {
  label: string | React.ReactNode
  name?: string
  disabled?: boolean
  optional?: boolean
  helpText?: string | React.ReactNode
  errorMsg?: string
  children: (props: {
    id: string
    name?: string
    disabled?: boolean
    invalid: boolean
  }) => React.ReactNode
}
export function InputGroup({
  label,
  name,
  disabled,
  optional = false,
  helpText,
  errorMsg,
  children,
}: Props) {
  const id = nanoid()
  const invalid = !!errorMsg

  return (
    <div className="space-y-1">
      <Label htmlFor={id} optional={optional} disabled={disabled}>
        {label}
      </Label>
      {children({ id, name, disabled, invalid })}
      {helpText && !disabled && (
        <div className="text-xs text-gray-800">{helpText}</div>
      )}
      {errorMsg && !disabled && (
        <div className="flex items-center text-red-400 text-xs space-x-1">
          <RiErrorWarningFill className="text-sm" />
          <div>{errorMsg}</div>
        </div>
      )}
    </div>
  )
}
