import { useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useFieldArray, useForm } from 'react-hook-form'
import { RiAddLine } from 'react-icons/ri'

import { ApiError, MemberInvitation, Org } from '@/frontend/api'
import {
  MemberInvitationsPayload,
  memberInvitationsSchema,
} from '@/frontend/api/memberInvitations/bulkImport'
import {
  ApiAlert,
  Button,
  ButtonLink,
  ErrorMessage,
  InputText,
  Select,
  SimpleTable,
  Tooltip,
} from '@/frontend/components'
import { defaultValue, roleMap, roleNames, roleOptions } from './formOptions'
import { useCreateMemberInvitations } from './hooks'

const MEMBER_INVITATION_ROW_LIMIT = 100 as const
const OVER_LIMIT_ERROR_MESSAGE =
  '一括で招待できるメンバー数の上限は100名です。101名以降は招待の操作を分割してください。' as const

type Props = {
  org: Org
  membersUrl: string
  memberInvitationsUrl: string
}

export default function Form({ org, membersUrl, memberInvitationsUrl }: Props) {
  const { createMemberInvitations } = useCreateMemberInvitations(org)
  const [apiError, setApiError] = useState<ApiError | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<MemberInvitationsPayload>({
    mode: 'onChange',
    resolver: zodResolver(memberInvitationsSchema),
    defaultValues: {
      memberInvitations: [defaultValue],
    },
  })

  const { fields, append, remove, update } = useFieldArray({
    name: 'memberInvitations',
    control,
  })

  const onSubmit = async (data: MemberInvitationsPayload) => {
    setApiError(null)
    setIsLoading(true)

    const result = await createMemberInvitations(data)

    if (result.ok) {
      location.href = memberInvitationsUrl
    } else {
      setApiError(result.err)
      setIsLoading(false)
    }
  }

  const onClipboardPaste = (
    e: React.ClipboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    const clipboardData = e.clipboardData
      .getData('text/plain')
      .replace(/\r\n/g, '\n')
    const rows = clipboardData.split('\n').map((line) => {
      const [email, role] = line.split('\t')
      return {
        email,
        role: roleMap[role as roleNames] || defaultValue.role,
      }
    })
    if (rows && rows[rows.length - 1]?.email === '') {
      rows.pop()
    }
    const updateRowCount = fields.length - index
    const updatingRows = rows.slice(0, updateRowCount)
    const appendingRows = rows.slice(updateRowCount, rows.length)

    updatingRows.forEach((row, i) => {
      update(index + i, row as MemberInvitation)
    })
    appendRows(appendingRows as MemberInvitation[])
  }

  const appendRows = (rows: MemberInvitation[]) => {
    const remainingAdditionalCount = MEMBER_INVITATION_ROW_LIMIT - fields.length
    const isOverLimit = remainingAdditionalCount < rows.length
    const additionalRows = isOverLimit
      ? rows.slice(0, remainingAdditionalCount)
      : rows.slice(0, MEMBER_INVITATION_ROW_LIMIT)

    additionalRows.forEach((row) => {
      append(row as MemberInvitation)
    })

    if (isOverLimit) {
      confirm(OVER_LIMIT_ERROR_MESSAGE)
    }
  }

  const isValidationError = (index: number) => {
    return !!errors?.memberInvitations?.[index]
  }

  return (
    <>
      {apiError && (
        <div className="pb-4">
          <ApiAlert apiError={apiError} autoScroll />
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleTable>
          <TableHead />
          <SimpleTable.TBody>
            {fields.map((field, index) => {
              return (
                <tr key={field.id} className="bg-white">
                  {/* メールアドレス */}
                  <td className="p-3">
                    <InputText
                      invalid={!!errors?.memberInvitations?.[index]?.email}
                      {...register(`memberInvitations.${index}.email`, {
                        required: true,
                      })}
                      onPaste={(e) => {
                        e.preventDefault()
                        onClipboardPaste(e, index)
                      }}
                    />
                    {isValidationError(index) && (
                      <div className="pt-1">
                        <ErrorMessage>
                          {errors?.memberInvitations?.[index]?.email?.message}
                        </ErrorMessage>
                      </div>
                    )}
                  </td>

                  {/* 権限 */}
                  <td className="p-3">
                    <Select
                      {...register(`memberInvitations.${index}.role`, {
                        required: true,
                      })}
                    >
                      {roleOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                    {isValidationError(index) && <ErrorMessageSpacer />}
                  </td>

                  {/* 削除 */}
                  <td className="p-3 text-right">
                    <Button
                      variant="dangerSecondary"
                      onClick={() => remove(index)}
                      disabled={fields.length === 1}
                    >
                      削除
                    </Button>
                    {isValidationError(index) && <ErrorMessageSpacer />}
                  </td>
                </tr>
              )
            })}
          </SimpleTable.TBody>
        </SimpleTable>

        <div className="flex py-4">
          <div className="flex space-x-4 items-center">
            <Tooltip
              content={OVER_LIMIT_ERROR_MESSAGE}
              disabled={fields.length < MEMBER_INVITATION_ROW_LIMIT}
            >
              {(ref) => (
                <div ref={ref}>
                  <Button
                    variant="outlined"
                    icon={RiAddLine}
                    onClick={() => {
                      append(defaultValue)
                    }}
                    disabled={fields.length >= MEMBER_INVITATION_ROW_LIMIT}
                  />
                </div>
              )}
            </Tooltip>
            <div className="text-sm items-center font-medium leading-6 text-gray-900">
              メンバーを追加する
            </div>
          </div>
          <div className="ml-auto">
            <div className="flex space-x-4 items-center">
              <ButtonLink variant="outlined" href={membersUrl}>
                キャンセル
              </ButtonLink>
              <Button variant="primary" type="submit" loading={isLoading}>
                招待する
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

function TableHead() {
  return (
    <thead>
      <tr className="bg-gray-50 font-bold">
        <th scope="col" className="py-4 pl-6 pr-3 w-40">
          メールアドレス
        </th>
        <th scope="col" className="py-4 px-3 w-20">
          権限
        </th>
        <th scope="col" className="py-4 px-3 w-28">
          {/* 操作 */}
        </th>
      </tr>
    </thead>
  )
}

function ErrorMessageSpacer() {
  return (
    <div className="pt-1">
      <div className="h-3"></div>
    </div>
  )
}
