import { useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { RiErrorWarningLine } from 'react-icons/ri'

import { AccountItem, ReservedItem } from '../types'
import { AccountItemRow } from './AccountItemRow'
import { DefinitionsForm } from './formSchema'

export function AccountItemRows({
  accountingFirmPlan,
  accountItem,
  getReservedItemByKey,
  getAccountItem,
  getAccountItems,
  isAccountItemIdCash,
}: {
  accountingFirmPlan: boolean
  accountItem: AccountItem
  getReservedItemByKey: (x: string) => ReservedItem
  getAccountItem: (x: string) => AccountItem | undefined
  getAccountItems: (x: ReservedItem) => AccountItem[]
  isAccountItemIdCash: (x: string | null) => boolean
}) {
  const [showAddBtn, setShowAddBtn] = useState(false)

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<DefinitionsForm>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: `definitions.${accountItem.id}.ratios`,
  })

  const isAddable = fields.length < 5
  const lastIndex = fields.length - 1
  const addRatio = () => {
    append({
      id: null,
      accountItemId: getValues(
        `definitions.${accountItem.id}.ratios.${lastIndex}.accountItemId`,
      ),
      cashingMonthOffset: getValues(
        `definitions.${accountItem.id}.ratios.${lastIndex}.cashingMonthOffset`,
      ),
      percentage: 0,
      memo: '',
      isCash: false,
    })
  }

  const percentagesError = errors.definitions?.[accountItem.id]?.ratios?.message

  const onNonfundChange = () => {
    const nonfund = getValues(`definitions.${accountItem.id}.nonfund`)
    if (nonfund) {
      fields.forEach((_field, index) => {
        const ratioAccountItemId = getValues(
          `definitions.${accountItem.id}.ratios.${index}.accountItemId`,
        )
        if (ratioAccountItemId == null) return

        const ratioAccountItem = getAccountItem(ratioAccountItemId)
        if (!ratioAccountItem) return null

        // 非資金項目の時に現預金が選ばれてたらリセットする
        if (ratioAccountItem.reservedItemKey === 'cash_and_deposits') {
          setValue(
            `definitions.${accountItem.id}.ratios.${index}.accountItemId`,
            '',
          )
        }
      })
    }
  }

  return (
    <tbody
      onMouseEnter={() => setShowAddBtn(true)}
      onMouseLeave={() => setShowAddBtn(false)}
    >
      {fields.map((field, index) => {
        return (
          <AccountItemRow
            key={field.id}
            index={index}
            accountingFirmPlan={accountingFirmPlan}
            accountItem={accountItem}
            getReservedItemByKey={getReservedItemByKey}
            getAccountItems={getAccountItems}
            isAccountItemIdCash={isAccountItemIdCash}
            isAddable={showAddBtn && isAddable}
            onAdd={addRatio}
            isRemovable={fields.length > 1}
            onRemove={remove}
            onNonfundChange={onNonfundChange}
          />
        )
      })}
      <tr>
        <td colSpan={accountingFirmPlan ? 4 : 5}></td>
        <td colSpan={3} className="align-top">
          <ErrorMsg msg={percentagesError} />
        </td>
      </tr>
    </tbody>
  )
}

// TODO: 非財務項目React化の方でコンポーネント化されるので後で置き換える
function ErrorMsg({ msg }: { msg: string | undefined }) {
  if (!msg) return null

  return (
    <div className="flex items-center mt-1 text-red-500 text-xs pb-2">
      <RiErrorWarningLine className="mr-1" size="1.25em" />
      <div>{msg}</div>
    </div>
  )
}
