// いいかんじにOptionを遅延読み込みして負荷対策するSelect
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

export type Option = { label: string; value: string | number; hidden: boolean }
export type Optgroup = { label: string; options: Option[] }
export type Options = (Optgroup | Option)[]

type Props = {
  options: Options
  placeholder?: string
}

type ComponentProps = ComponentPropsWithoutRef<'select'> & Props

export const LazyLoadSelect = forwardRef<HTMLSelectElement, ComponentProps>(
  function AccountItemSelect({ options, placeholder, onFocus, ...props }, ref) {
    const selectRef = useRef<HTMLSelectElement>(null)
    useImperativeHandle(ref, () => selectRef.current!)

    const memorizedOptions = useMemo(
      () =>
        ([] as Options)
          .concat(
            placeholder !== undefined
              ? [
                  {
                    label: placeholder,
                    value: '',
                    hidden: true,
                  },
                ]
              : [],
          )
          .concat(options),
      [options, placeholder],
    )

    const [loadOption, setLoadOption] = useState(false)

    const handleOnFocus = (e: React.FocusEvent<HTMLSelectElement>) => {
      setLoadOption(true)
      if (onFocus) {
        onFocus(e)
      }
    }

    const initialOption = searchOption(memorizedOptions, props.value)

    return (
      <select ref={selectRef} onFocus={handleOnFocus} {...props}>
        {loadOption ? (
          <Options options={memorizedOptions} />
        ) : (
          <Options options={initialOption ? [initialOption] : []} />
        )}
      </select>
    )
  },
)

// valueからoptgroup & optionを探す
function searchOption(
  options: Options,
  value?: string | number | readonly string[],
) {
  const option = options.find((option) => {
    if ('options' in option) {
      // Optgroupの場合
      return option.options.some(
        (option) =>
          value != null && option.value.toString() === value.toString(),
      )
    } else {
      // Optionの場合
      return value != null && option.value.toString() === value.toString()
    }
  })
  return option
}

// optgroup & optionをレンダーする
function Options({ options }: { options: Options }) {
  return options.map((option) => {
    if ('options' in option) {
      // Optgroupの場合
      return (
        <optgroup label={option.label} key={option.label}>
          {option.options.map((option) => (
            <option
              value={option.value}
              key={option.value}
              hidden={option.hidden}
            >
              {option.label}
            </option>
          ))}
        </optgroup>
      )
    } else {
      // Optionの場合
      return (
        <option value={option.value} key={option.value} hidden={option.hidden}>
          {option.label}
        </option>
      )
    }
  })
}
