import { useCallback, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { FileRejection, FileWithPath } from 'react-dropzone'
import { useForm } from 'react-hook-form'

import { ApiError, ApiSuccessMessages } from '@/frontend/api'
import {
  YayoiCsvSourceAccountItemPayload,
  yayoiCsvSourceAccountItemSchema,
} from '@/frontend/api/importer'
import { ApiAlert } from '@/frontend/components'
import { Alert } from '@/frontend/components/Alert/Alert'
import { Button } from '@/frontend/components/buttons/Button'
import Dropzone from '../components/Dropzone'
import { ImportStatus } from '../components/ImportStatus'
import LastImportedAt from '../components/LastImportedAt'
import { LastImportStatus } from '../components/LastImportStatus'
import { AlertStatus } from '../hooks/useAlertStatus'
import { ImportFileStatus, JobLogWithAttachedFile } from '../types'

type Props = {
  importFileStatus: ImportFileStatus | null
  onFileChange: (file: FileWithPath) => void
  onSubmit: (data: YayoiCsvSourceAccountItemPayload) => void
  isLoading: boolean
  apiError?: ApiError
  apiSuccessMessages?: ApiSuccessMessages
  alertStatus: AlertStatus | null
  jobLogWithAttachedFile: JobLogWithAttachedFile | null
}

export default function MasterImportForm({
  importFileStatus,
  onFileChange,
  onSubmit,
  isLoading,
  apiError,
  apiSuccessMessages,
  alertStatus,
  jobLogWithAttachedFile,
}: Props) {
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([])
  const { setValue, watch, handleSubmit } =
    useForm<YayoiCsvSourceAccountItemPayload>({
      resolver: zodResolver(yayoiCsvSourceAccountItemSchema),
      defaultValues: {
        csvFile: null,
      },
    })

  const file = watch('csvFile')

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
      if (acceptedFiles.length <= 0) return
      const acceptedFile = acceptedFiles[0]
      onFileChange(acceptedFile as FileWithPath)
      setValue('csvFile', acceptedFile as FileWithPath)
      setFileRejections(fileRejections)
    },
    [setValue],
  )

  const _onSubmit = handleSubmit((data) => {
    onSubmit(data)
    setValue('csvFile', null)
  })

  const errorMessage =
    fileRejections.length > 0 ? 'CSVファイルを選択してください' : ''

  return (
    <form onSubmit={_onSubmit}>
      <div className="space-y-4">
        {errorMessage && <Alert title={errorMessage} />}
        {apiSuccessMessages && (
          <ApiSuccessAlert apiSuccessMessages={apiSuccessMessages} />
        )}
        {apiError && (
          <div className="py-4">
            <ApiAlert apiError={apiError} />
          </div>
        )}
        <Dropzone
          onDrop={onDrop}
          disabled={isLoading}
          acceptFileTypes={['csv', 'txt']}
        />
        {importFileStatus && (
          <LastImportStatus
            importFileStatus={importFileStatus}
            loading={isLoading}
          />
        )}
        {alertStatus && (
          <ImportStatus
            status={alertStatus.status}
            errorDetail={alertStatus.errorDetail}
          />
        )}
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <LastImportedAt
              lastImportedAt={jobLogWithAttachedFile?.createdAt}
            />
          </div>
          <div className="mt-auto">
            <Button
              type="submit"
              variant="primary"
              loading={isLoading}
              disabled={file == null}
            >
              インポート
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

// NOTE: ここでしか使わないので、ここに書いてるよ
function ApiSuccessAlert({
  apiSuccessMessages,
}: {
  apiSuccessMessages: ApiSuccessMessages
}) {
  const messages = apiSuccessMessages.messages

  return (
    messages.length > 0 && (
      <Alert title={`${messages.length}件の情報があります`} variant="warning">
        <ul role="list" className="list-disc space-y-1 pl-5">
          {messages.map((message, i) => (
            <li key={i} dangerouslySetInnerHTML={{ __html: message }} />
          ))}
        </ul>
      </Alert>
    )
  )
}
