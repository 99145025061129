import { useEffect, useState } from 'react'

import { JobLogStatus } from '@/frontend/api'
import { TableRowInfo } from '../components/TransitionTable/TransitionTableTagMappingImportTable'

// NOTE: 全体ステータスを判定するためのフック。
// 各itemを走査して、全体ステータスを決定しているよ
export function useTransitionTableItemOverallStatus(rows: TableRowInfo[]) {
  const [overallStatus, setOverallStatus] = useState<JobLogStatus>('pending')

  useEffect(() => {
    // NOTE: statusがnullのもの(ファイルのないもの)は除外する
    const filteredRows = rows.filter((row) => row.status !== null)
    if (filteredRows.length === 0) {
      setOverallStatus('pending')
      return
    }

    const succeeded = filteredRows.every((row) => row.status === 'succeeded')
    const hasInProgress = filteredRows.some(
      (row) => row.status === 'in_progress',
    )
    const hasPending = filteredRows.some((row) => row.status === 'pending')
    const hasFailed = filteredRows.some((row) => row.status === 'failed')

    if (succeeded) {
      setOverallStatus('succeeded')
      return
    }

    if (hasInProgress) {
      setOverallStatus('in_progress')
      return
    }

    if (hasPending) {
      setOverallStatus('pending')
      return
    }

    if (hasFailed) {
      setOverallStatus('failed')
      return
    }
  }, [rows])

  return overallStatus
}
