import { createErr, createOk, Result } from 'option-t/PlainResult'

import { MappingFormProps } from '@/frontend/features/importers/transition_table_import/types'
import { axios } from '@/frontend/utils'
import { journalImportTransitionTableItemsUrl } from '../routes'
import { ApiError } from '../types'
import { handleAxiosError } from '../utils'

export async function apiTransitionTableItemsJournalImport(
  orgId: number,
  transitionTableImportId: number,
  budgetId: number,
  payload: MappingFormProps,
): Promise<Result<void, ApiError>> {
  const url = journalImportTransitionTableItemsUrl(
    orgId,
    transitionTableImportId,
    budgetId,
  )
  try {
    await axios.post(url, payload)
    return createOk(undefined)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
