import { useMemo, useState } from 'react'

import { MemberWithAssign } from '@/frontend/api/types'
import { Dropdown } from './Dropdown'
import { DropdownIndicatorContainer } from './DropdownIndicatorContainer'
import { MultiValueBadge } from './MultiValueBadge'
import { OptionSelect } from './OptionSelect'
import { TriggerContainer } from './TriggerContainer'

export function AssignMembersSelect({
  value,
  name,
  members,
  onChange,
  onMenuOpen,
  onMenuClose,
  disabled,
}: {
  name?: string
  value: Array<number> | undefined
  members: MemberWithAssign[]
  onChange: (value: number[] | undefined) => void
  onMenuClose?: () => void
  onMenuOpen?: () => void
  disabled?: boolean
}) {
  // NOTE: ドロップダウンを開いたときに選択前の値を保持するよ
  //       2回目以降のときに選択されたものを並べかえるためだよ
  const [beforeValue, setBeforeValue] = useState(value)
  const [isOpen, setIsOpen] = useState(false)

  const handleOnOpen = () => {
    setIsOpen(true)
    setBeforeValue(value)
    onMenuOpen?.()
  }

  const handleOnClose = () => {
    setIsOpen(false)
    onMenuClose?.()
  }

  const options = useMemo(
    () =>
      members
        .map((member) => ({
          label: member.user.name,
          value: member.user.id,
        }))
        .sort((a, b) => {
          if (!beforeValue) {
            return 0
          }
          // NOTE: 選択されたもの順番を上に持ってくるよ
          return (
            Number(beforeValue.includes(b.value)) -
            Number(beforeValue.includes(a.value))
          )
        }),
    [members, beforeValue],
  )

  return (
    <div>
      <HiddenInputs value={value} name={name} />
      <Dropdown
        isOpen={isOpen}
        onOpen={handleOnOpen}
        onClose={handleOnClose}
        disabled={disabled}
        trigger={
          <TriggerContainer>
            <DropdownIndicatorContainer>
              <MultiValueBadge
                placeholder="担当者を選択"
                values={members
                  .filter((member) => value?.includes(member.user.id))
                  .map((member) => member.user.name)}
                limit={3}
              />
            </DropdownIndicatorContainer>
          </TriggerContainer>
        }
        menuZIndex={20}
      >
        <OptionSelect
          name={name}
          options={options}
          value={value}
          placeholder="検索"
          onChange={onChange}
          noOptionsMessage={NoOptionsMessage}
        />
      </Dropdown>
    </div>
  )
}

function NoOptionsMessage() {
  return <span className="text-sm">一致するメンバーはいません</span>
}

function HiddenInputs({
  value,
  name,
}: {
  value: number[] | undefined
  name?: string
}) {
  return (
    <>
      {value?.map((v) => (
        <input key={v} type="hidden" name={name} value={v} />
      ))}
    </>
  )
}
