import { cloneElement, useCallback, useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useController, useForm } from 'react-hook-form'
import { RiErrorWarningLine } from 'react-icons/ri'

import {
  ApiError,
  CreateCommentPayload,
  createCommentSchema,
} from '@/frontend/api'
import { ApiAlert, Button } from '@/frontend/components'
import { CommentEditor } from './CommentEditor'
import { MentionItem } from './lexicalPlugins/MentionsPlugin'

type Props = {
  initialValue?: string
  apiError: ApiError | null
  onSubmit: (data: CreateCommentPayload) => Promise<boolean>
  onCancel?: () => void
  suggestionContainerRef: React.RefObject<HTMLElement>
  commentMentionMembers: MentionItem[]
  memberId: number
}

export function CommentForm({
  initialValue,
  apiError,
  onSubmit,
  onCancel,
  suggestionContainerRef,
  commentMentionMembers,
  memberId,
}: Props) {
  const { remount, Remount } = useRemount()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, errors },
    reset,
  } = useForm<CreateCommentPayload>({
    resolver: zodResolver(createCommentSchema),
    defaultValues: { content: initialValue || '' },
  })

  useEffect(() => {
    reset()
  }, [reset, isSubmitSuccessful])

  const submitHandler = handleSubmit(async (data: CreateCommentPayload) => {
    const isSucceeded = await onSubmit(data)
    if (isSucceeded) {
      // コンポーネントを再マウントすることでLexicalの値をリセットしている
      // Lexicalの値をリセットする方法が外部から使いにくかったため...
      remount()
    }
  })

  const { field: fieldContent } = useController({
    control,
    name: 'content',
  })
  const { field: fieldMentions } = useController({
    control,
    name: 'mentions',
  })
  const { field: fieldTextContent } = useController({
    control,
    name: 'textContent',
  })

  return (
    <form onSubmit={submitHandler} className="space-y-2">
      {apiError && <ApiAlert apiError={apiError} />}
      <Remount>
        <CommentEditor
          initialValue={initialValue}
          onChangeContent={fieldContent.onChange}
          onChangeMentions={fieldMentions.onChange}
          onChangeTextContent={fieldTextContent.onChange}
          onSubmit={submitHandler}
          suggestionContainerRef={suggestionContainerRef}
          commentMentionMembers={commentMentionMembers}
          memberId={memberId}
        />
      </Remount>
      {errors.content?.message && (
        <div className="flex items-center text-red-500 text-xs">
          <RiErrorWarningLine className="mr-1" />
          <div>{errors.content.message}</div>
        </div>
      )}
      <div className="flex items-center justify-end space-x-2">
        {onCancel && (
          <Button
            type="button"
            disabled={isSubmitting}
            onClick={onCancel}
            variant="outlined"
          >
            キャンセル
          </Button>
        )}
        <Button type="submit" disabled={isSubmitting} variant="primary">
          送信
        </Button>
      </div>
    </form>
  )
}

function useRemount() {
  const [counter, setCounter] = useState(0)
  const Remount = useCallback(
    ({ children }: { children: React.ReactElement }) => (
      <>{[cloneElement(children, { key: `remount-${counter}` })]}</>
    ),
    [counter],
  )

  return {
    remount: () => setCounter((v) => v + 1),
    Remount,
  }
}
