import { useRef, useState } from 'react'

import { ApiError, Client } from '@/frontend/api'
import {
  ApiAlert,
  Button,
  InputText,
  ModalWithIcon,
} from '@/frontend/components'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: (x: Client) => void
  client?: Client
  isSubmitting: boolean
  apiError?: ApiError
}

export function DeleteDialog({
  open,
  onClose,
  onSubmit,
  client,
  isSubmitting,
  apiError,
}: Props) {
  const cancelButtonRef = useRef(null)
  const [inputText, setInputText] = useState('')

  return (
    <ModalWithIcon
      variant="danger"
      open={open}
      onClose={() => onClose()}
      initialFocus={cancelButtonRef}
      title="組織の削除"
      actions={
        client && (
          <>
            <Button variant="outlined" onClick={onClose} ref={cancelButtonRef}>
              キャンセル
            </Button>
            <Button
              type="submit"
              variant="dangerPrimary"
              disabled={inputText !== client.name}
              loading={isSubmitting}
              onClick={() => onSubmit(client)}
            >
              削除
            </Button>
          </>
        )
      }
      errorAlert={apiError && <ApiAlert apiError={apiError} autoScroll />}
    >
      {client && (
        <>
          本当に組織「{client.name}」を削除しますか？
          <br />
          この操作は取り消すことはできません。
          <br />
          削除する場合は以下のフィールドに「{client.name}
          」と入力してから、削除ボタンを押してください。
        </>
      )}
      <div className="mt-5">
        <InputText
          placeholder={'組織名を入力'}
          onChange={(e) => setInputText(e.target.value)}
        />
      </div>
    </ModalWithIcon>
  )
}
