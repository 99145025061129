import { AccountingSoftware } from '../types'

export default function AuthedAccountingSoftwareLabel({
  accountingSoftware,
}: {
  accountingSoftware: AccountingSoftware
}) {
  return (
    accountingSoftware.isApiAuthed && (
      <span className="text-sm">
        {`連携中の会計ソフト：${accountingSoftware.name}`}
      </span>
    )
  )
}
