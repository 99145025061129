type Props = {
  placeholder: React.ReactNode
  value: string | undefined
  truncate?: boolean
}

export function SingleValueBadge({ value, placeholder, truncate }: Props) {
  const valuesPresent = value

  return (
    <div className="grow-1 flex items-center text-gray-700">
      {valuesPresent ? (
        <div key={value} className={`${truncate ? 'truncate' : ''} py-0.5`}>
          {value}
        </div>
      ) : (
        <div className="py-0.5">{placeholder}</div>
      )}
    </div>
  )
}
