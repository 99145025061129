import { NodeApi } from 'react-arborist'

import { Button, ModalWithIcon } from '@/frontend/components'
import { AllocationDriver, Data } from './types'

type Props = {
  node: NodeApi<Data>
  deleteModalOpen: boolean
  onDeleteModalClose: () => void
}

export function DeleteModal({
  node,
  deleteModalOpen,
  onDeleteModalClose,
}: Props) {
  const item = node.data.item
  const data = node.data
  return (
    <AllocationDriverDeleteModal
      item={item}
      data={data}
      deleteModalOpen={deleteModalOpen}
      onDeleteModalClose={onDeleteModalClose}
    />
  )
}

function AllocationDriverDeleteModal({
  item,
  data,
  deleteModalOpen,
  onDeleteModalClose,
}: {
  item: AllocationDriver
  data: Data
  deleteModalOpen: boolean
  onDeleteModalClose: () => void
}) {
  const subProps = data.subProps

  return (
    <ModalWithIcon
      variant="danger"
      open={deleteModalOpen}
      onClose={onDeleteModalClose}
      title="削除の確認"
      actions={
        <>
          <Button variant="outlined" onClick={onDeleteModalClose}>
            キャンセル
          </Button>
          <form method="post" action={item.showUrl}>
            <input type="hidden" name="_method" value="delete" />
            <input
              type="hidden"
              name="authenticity_token"
              value={subProps.formAuthenticityToken}
            />
            <Button type="submit" variant="dangerPrimary" value="削除">
              削除
            </Button>
          </form>
        </>
      }
    >
      配賦ドライバ「{item.name}」を削除しますか？
      <br />
      {item.enableNonFinancialTable ? (
        <>
          この操作は巻き戻しができず、
          <span className="font-bold">
            テーブルに保存した値も削除されます。
          </span>
        </>
      ) : (
        <>この操作は巻き戻しできません。</>
      )}
    </ModalWithIcon>
  )
}
