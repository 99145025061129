import { useState } from 'react'

import { BudgetTagCategory, Org } from '@/frontend/api'
import { Button } from '@/frontend/components'
import { NonFinancialItemSlideOver } from './NonFinancialItemSlideOver'
import { useCreateNonFinancialItem } from './useCreateNonFinancialItem'

export function NewNonFinancialItemButton({
  org,
  primaryBudgetTagCategory,
}: {
  org: Org
  primaryBudgetTagCategory: BudgetTagCategory | null
}) {
  const [newSlideOverOpen, setNewSlideOverOpen] = useState(false)
  const createNonFinancialItem = useCreateNonFinancialItem(org)

  return (
    <>
      <Button variant="secondary" onClick={() => setNewSlideOverOpen(true)}>
        非財務項目の新規登録
      </Button>
      <NonFinancialItemSlideOver
        open={newSlideOverOpen}
        org={org}
        primaryBudgetTagCategory={primaryBudgetTagCategory}
        onClose={() => setNewSlideOverOpen(false)}
        onSubmit={createNonFinancialItem}
      />
    </>
  )
}
