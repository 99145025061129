import { KeyboardEvent, useCallback, useEffect, useState } from 'react'
import {
  CellChange,
  CellTemplates,
  Column,
  DefaultCellTypes,
  HeaderCell,
  Id,
  NumberCell,
  ReactGrid,
  Row,
} from '@silevis/reactgrid'

import { NonFinancialItem } from '@/frontend/api'

import '@silevis/reactgrid/styles.css'

import dayjs from 'dayjs'

import { orderBudgetDetailItems } from '@/frontend/api'
import { Button } from '@/frontend/components'
import {
  Budget,
  BudgetTagCategory,
} from '@/frontend/features/allocationDrivers'
import { ActionCell } from './ActionCell'
import { RenderCell, RenderCellTemplate } from './RenderCellTemplate'
import {
  saveNonFinancialValues,
  SaveNonFinancialValuesParams,
} from './saveNonFinancialValues'
import { NonFinancialItemTableRow } from './types'

type CellType = DefaultCellTypes | RenderCell

const cellTemplates: CellTemplates = {
  render: new RenderCellTemplate(),
}

const getNumberFormat = (
  nonFinancialItem: NonFinancialItem,
): Intl.NumberFormat => {
  switch (nonFinancialItem.numberType) {
    case 'integer':
      return Intl.NumberFormat('ja', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    case 'decimal':
      return Intl.NumberFormat('ja', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 2,
      })
    case 'percentage':
      return Intl.NumberFormat('ja', {
        style: 'unit',
        unit: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 2,
      })
  }
}

const getColumns = (months: dayjs.Dayjs[]): Column[] => [
  { columnId: 'name', width: 180, reorderable: true },
  { columnId: 'tag', width: 124, reorderable: true },
  ...months.map((_, i) => ({
    columnId: `${i}`,
    width: 100,
    reorderable: true,
  })),
  { columnId: 'total', width: 100, reorderable: true },
  { columnId: 'action', width: 24 },
]

const getHeaderRow = (
  months: dayjs.Dayjs[],
  primaryBudgetTagCategory: BudgetTagCategory,
): Row => ({
  rowId: 'header',
  cells: [
    { type: 'header', text: '' },
    { type: 'header', text: primaryBudgetTagCategory.name },
    ...months.map(
      (m): HeaderCell => ({
        type: 'header',
        text: m.format('YYYY/MM月'),
        className: 'justify-center',
      }),
    ),
    { type: 'header', text: '合計', className: 'justify-center' },
    { type: 'header', text: '' },
  ],
})

const getTotalRow = (
  nonFinancialItem: NonFinancialItem,
  months: dayjs.Dayjs[],
  tableRows: NonFinancialItemTableRow[],
): Row => {
  const columnTotalMap = indexedColumnTotal(months, tableRows)
  const format = getNumberFormat(nonFinancialItem).format
  return {
    rowId: 'total',
    cells: [
      { type: 'header', text: '合計' },
      { type: 'header', text: '' },
      ...months.map(
        (_m, i): HeaderCell => ({
          type: 'header',
          text: format(columnTotalMap[i] as number),
          className: '!bg-gray-50 justify-end',
        }),
      ),
      {
        type: 'header',
        text: format(Object.values(columnTotalMap).reduce((sum, v) => sum + v)),
        className: '!bg-gray-50 justify-end',
      },
      { type: 'header', text: '', className: '!bg-gray-50' },
    ],
  }
}

const indexedColumnTotal = (
  months: dayjs.Dayjs[],
  tableRows: NonFinancialItemTableRow[],
) => {
  const hash = {} as { [id: number]: number }
  months.forEach((_m, i) => {
    const sum = tableRows.reduce((sum, row) => {
      return sum + (row.values[i] || 0)
    }, 0)
    hash[i] = sum
  })
  return hash
}

const getRows = (
  months: dayjs.Dayjs[],
  nonFinancialItem: NonFinancialItem,
  tableRows: NonFinancialItemTableRow[],
  result: boolean,
  budget: Budget,
  BudgetTagCategory: BudgetTagCategory,
): Row<CellType>[] => [
  getHeaderRow(months, BudgetTagCategory),
  ...tableRows.map<Row<CellType>>((row, i) => ({
    rowId: i,
    reorderable: !result,
    cells: [
      { type: 'header', text: nonFinancialItem.name },
      { type: 'header', text: row.budgetTag.name },
      ...row.values.map<CellType>((value) => ({
        type: 'number',
        value,
        format: getNumberFormat(nonFinancialItem),
        nonEditable: budget.locked,
      })),
      {
        type: 'header',
        text: rowTotal(nonFinancialItem, row),
        className: '!bg-gray-50 justify-end',
      },
      {
        type: 'render',
        className: '!bg-gray-50',
        render:
          row.url && !budget.locked ? (
            <ActionCell budgetDetailItemUrl={row.url} />
          ) : null,
      },
    ],
  })),
  getTotalRow(nonFinancialItem, months, tableRows),
]

const rowTotal = (
  nonFinancialItem: NonFinancialItem,
  row: NonFinancialItemTableRow,
) => {
  const numberFormat = getNumberFormat(nonFinancialItem)
  const num = row.values.reduce((sum, v) => sum + v, 0)
  return numberFormat.format(num)
}

const applyNewValue = (
  nonFinancialItem: NonFinancialItem,
  changes: CellChange<DefaultCellTypes>[],
  prevTableRows: NonFinancialItemTableRow[],
  usePrevValue = false,
): NonFinancialItemTableRow[] => {
  changes.forEach((change) => {
    const { rowId, columnId } = change
    const rowIndex = parseInt(rowId.toString())
    const monthIndex = parseInt(columnId.toString())
    const tableRow = prevTableRows[rowIndex]
    if (!tableRow) throw new Error('Unexpected')
    if (change.newCell.type !== 'number') throw new Error('Unexpected')

    const cell = (
      usePrevValue ? change.previousCell : change.newCell
    ) as NumberCell
    tableRow.values[monthIndex] = normalizedCellValue(
      nonFinancialItem,
      cell.value,
    )
  })
  return [...prevTableRows]
}

const updateChangeValues = (
  nonFinancialItem: NonFinancialItem,
  changes: CellChange<DefaultCellTypes>[],
  prevChangeValues: ChangeValues,
  tableRows: NonFinancialItemTableRow[],
) => {
  changes.forEach((change) => {
    const { rowId, columnId } = change
    const rowIndex = parseInt(rowId.toString())
    const monthIndex = parseInt(columnId.toString())
    const row = tableRows[rowIndex]
    if (!row) throw new Error('Unexpected')
    if (change.newCell.type !== 'number') throw new Error('Unexpected')

    const map = (prevChangeValues[monthIndex] ||= {})
    map[row.budgetDetailItemId] = normalizedCellValue(
      nonFinancialItem,
      change.newCell.value,
    )
  })
  return { ...prevChangeValues }
}

const normalizedCellValue = (
  nonFinancialItem: NonFinancialItem,
  value: number,
): number => {
  const v = isNaN(value) ? 0 : value

  switch (nonFinancialItem.numberType) {
    case 'integer':
      return Number(v.toFixed(0))
    case 'decimal':
      return Number(v.toFixed(2))
    case 'percentage':
      return Number(v.toFixed(2))
  }
}

const monthToFirstDate = (month: dayjs.Dayjs): string => {
  return month.startOf('month').format('YYYY-MM-DD')
}
const monthToLastDate = (month: dayjs.Dayjs): string => {
  return month.endOf('month').format('YYYY-MM-DD')
}

type ChangeValues = Record<number, Record<number, number>>

export function NonFinancialItemTable({
  url,
  itemsOrderUrl,
  months,
  result,
  budget,
  nonFinancialItem,
  primaryBudgetTagCategory,
  tableRows: _tableRows,
  fiscalPeriodId,
}: {
  url: string
  itemsOrderUrl: string
  months: dayjs.Dayjs[]
  result: boolean
  budget: Budget
  nonFinancialItem: NonFinancialItem
  tableRows: NonFinancialItemTableRow[]
  primaryBudgetTagCategory: BudgetTagCategory
  fiscalPeriodId: number
}) {
  const [tableRows, setTableRows] =
    useState<NonFinancialItemTableRow[]>(_tableRows)
  const [cellChangesIndex, setCellChangesIndex] = useState(() => -1)
  const [cellChanges, setCellChanges] = useState<
    CellChange<DefaultCellTypes>[][]
  >(() => [])

  const [changeValues, setChangeValues] = useState<ChangeValues>({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const columns = getColumns(months)
  const rows = getRows(
    months,
    nonFinancialItem,
    tableRows,
    result,
    budget,
    primaryBudgetTagCategory,
  )

  const isChanged = Object.keys(changeValues).length > 0

  const handleChanges = (changes: CellChange<DefaultCellTypes>[]) => {
    setTableRows((prevTableRows) =>
      applyChangesToTableRows(changes, prevTableRows),
    )
    setChangeValues((prevChangeValues) =>
      updateChangeValues(
        nonFinancialItem,
        changes,
        prevChangeValues,
        tableRows,
      ),
    )
  }

  const submit = async () => {
    setIsSubmitting(true)
    const params: SaveNonFinancialValuesParams = []
    months.forEach((month, i) => {
      const firstDate = monthToFirstDate(month)
      const lastDate = monthToLastDate(month)

      tableRows.forEach((tableRow) => {
        const amount = changeValues[i]?.[tableRow.budgetDetailItemId]
        if (amount == undefined) return

        params.push({
          budgetDetailItemId: tableRow.budgetDetailItemId,
          firstDate,
          lastDate,
          amount,
        })
      })
    })
    try {
      await saveNonFinancialValues(url, { fiscalPeriodId, params })
      setChangeValues({})
    } catch {
      alert('値の保存に失敗しました。')
    } finally {
      setIsSubmitting(false)
    }
  }

  /* eslint-disable */
  // 以下のドキュメント通りにコピペ https://reactgrid.com/docs/4.0/2-implementing-core-features/3-column-and-row-reordering/
  const reorderArray = <T extends {}>(arr: T[], idxs: number[], to: number) => {
    const movedElements = arr.filter((_, idx) => idxs.includes(idx))
    const targetIdx =
      Math.min(...idxs) < to
        ? (to += 1)
        : (to -= idxs.filter((idx) => idx < to).length)
    const leftSide = arr.filter(
      (_, idx) => idx < targetIdx && !idxs.includes(idx),
    )
    const rightSide = arr.filter(
      (_, idx) => idx >= targetIdx && !idxs.includes(idx),
    )
    return [...leftSide, ...movedElements, ...rightSide]
  }
  /* eslint-enable */

  const handleRowsReorder = (targetRowId: Id, rowIds: Id[]) => {
    setTableRows((prevTableRows) => {
      const numberRowIds = rowIds.map(Number)
      const reorderedArray = reorderArray(
        prevTableRows,
        numberRowIds,
        Number(targetRowId),
      )
      const orderedIds = reorderedArray.map((e) => e.budgetDetailItemId)
      orderBudgetDetailItems(itemsOrderUrl, orderedIds).then((result) => {
        if (!result.ok) {
          alert('不正な値が送信されたため並び順の保存に失敗しました。')
        }
      })
      return reorderedArray
    })
  }

  // 画面遷移時の離脱アラート
  const handleBeforeUnloadEvent = useCallback(
    (event: BeforeUnloadEvent): void => {
      if (isChanged) {
        event.preventDefault()
        event.returnValue = ''
      }
    },
    [isChanged],
  )

  // 画面リロード時の離脱アラート
  const handleBeforeVisitEvent = useCallback(
    (event: Event): void => {
      if (isChanged) {
        const msg =
          'このページを離れますか？行った変更が保存されない可能性があります。'
        const result = confirm(msg)
        if (!result) {
          event.preventDefault()
        }
      }
    },
    [isChanged],
  )

  useEffect(() => {
    document.addEventListener('turbolinks:before-visit', handleBeforeVisitEvent)
    document.addEventListener('turbolinks:visit', () => {
      document.removeEventListener(
        'turbolinks:before-visit',
        handleBeforeVisitEvent,
      )
      window.removeEventListener('beforeunload', handleBeforeUnloadEvent)
    })
    window.addEventListener('beforeunload', handleBeforeUnloadEvent)
    return () => {
      document.removeEventListener(
        'turbolinks:before-visit',
        handleBeforeVisitEvent,
      )
      window.removeEventListener('beforeunload', handleBeforeUnloadEvent)
    }
  }, [handleBeforeUnloadEvent, handleBeforeVisitEvent])

  const handleUndoChanges = () => {
    if (cellChangesIndex >= 0) {
      const changes = cellChanges[cellChangesIndex]
      if (!changes) {
        throw new Error('Unexpected')
      }
      setTableRows((prevTableRows) =>
        undoChanges(nonFinancialItem, changes, prevTableRows),
      )
    }
  }

  const handleRedoChanges = () => {
    if (cellChangesIndex + 1 <= cellChanges.length - 1) {
      const changes = cellChanges[cellChangesIndex + 1]
      if (!changes) {
        throw new Error('Unexpected')
      }
      setTableRows((prevTableRows) =>
        redoChanges(nonFinancialItem, changes, prevTableRows),
      )
    }
  }
  const undoChanges = (
    nonFinancialItem: NonFinancialItem,
    changes: CellChange<DefaultCellTypes>[],
    prevTableRows: NonFinancialItemTableRow[],
  ): NonFinancialItemTableRow[] => {
    const updated = applyNewValue(
      nonFinancialItem,
      changes,
      prevTableRows,
      true,
    )
    setCellChangesIndex(cellChangesIndex - 1)
    return updated
  }

  const redoChanges = (
    nonFinancialItem: NonFinancialItem,
    changes: CellChange<DefaultCellTypes>[],
    prevTableRows: NonFinancialItemTableRow[],
  ): NonFinancialItemTableRow[] => {
    const updated = applyNewValue(nonFinancialItem, changes, prevTableRows)
    setCellChangesIndex(cellChangesIndex + 1)
    return updated
  }

  const applyChangesToTableRows = (
    changes: CellChange<DefaultCellTypes>[],
    prevTableRows: NonFinancialItemTableRow[],
    usePrevValue = false,
  ) => {
    const updated = applyNewValue(
      nonFinancialItem,
      changes,
      prevTableRows,
      usePrevValue,
    )
    setCellChanges([...cellChanges.slice(0, cellChangesIndex + 1), changes])
    setCellChangesIndex(cellChangesIndex + 1)
    return [...updated]
  }

  // see: https://stackoverflow.com/questions/10527983/best-way-to-detect-mac-os-x-or-windows-computers-with-javascript-or-jquery
  const isMacOs = navigator.platform.toUpperCase().indexOf('MAC') >= 0

  const handleOnKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if ((!isMacOs && e.ctrlKey) || e.metaKey) {
      switch (e.key) {
        case 'z':
          handleUndoChanges()
          e.preventDefault()
          return
        case 'y':
          handleRedoChanges()
          e.preventDefault()
          return
      }
    }
  }

  return (
    <>
      <div
        className="w-full h-full overflow-auto text-xs"
        onKeyDown={handleOnKeyDown}
      >
        <ReactGrid
          rows={rows}
          columns={columns}
          stickyTopRows={1}
          stickyLeftColumns={2}
          onCellsChanged={handleChanges}
          customCellTemplates={cellTemplates}
          onRowsReordered={handleRowsReorder}
          enableRowSelection
          enableRangeSelection
          enableFillHandle
        />
      </div>
      <div className="fixed bottom-0 inset-x-0 flex bg-white w-full py-2 px-16 border-t z-50 shadow justify-end">
        <div className="flex-none">
          {isChanged && (
            <div className="text-red-600 text-sm py-2 px-3">
              ボタンを押して変更を保存してください
            </div>
          )}
        </div>
        <div className="flex-none">
          <Button
            variant="primary"
            onClick={submit}
            disabled={!isChanged || isSubmitting}
          >
            {isSubmitting ? '送信中' : '変更を保存'}
          </Button>
        </div>
      </div>
    </>
  )
}
