import { JobLog, Org, PendingChecker } from '@/frontend/api'
import { ApiImportFormHeader } from '../components/ApiImportFormHeader'
import { JobLogPollingContainer } from '../components/JobLogPollingContainer'
import useAlertStatus from '../hooks/useAlertStatus'
import { useApiPendingChecker } from '../hooks/useApiPendingChecker'
import useReturnedPendingCheckerEffect from '../hooks/useReturnedPendingCheckerEffect'
import useStoredFormValues, {
  BaseApiFormValuesType,
} from '../hooks/useStoredFormValues'
import ApiImportForm from './ApiImportForm'
import { useImportMfcPlusApi } from './useImportMfcPlusApi'

type Props = {
  org: Org
  lastApiImportJobLog: JobLog | null
  lastInProgressApiImportJobId: number | null
  setPendingChecker: (pendingChecker: PendingChecker) => void
}

export default function ApiImportFormContainer({
  org,
  lastApiImportJobLog,
  lastInProgressApiImportJobId,
  setPendingChecker,
}: Props) {
  const { alertStatus, updateAlertStatusFromJobLog, resetAlertStatus } =
    useAlertStatus(lastApiImportJobLog)

  const { storedFormValues, storeImportFormValues } = useStoredFormValues<
    BaseApiFormValuesType & { unapprovedImport: boolean }
  >(org, 'MfcPlusApiI')

  const {
    submit,
    jobLog: returnedJobLog,
    isSubmitting,
    apiError,
  } = useImportMfcPlusApi(org)

  const {
    submit: pendingCheckerSubmit,
    apiError: pendingCheckerApiError,
    returnedPendingChecker,
  } = useApiPendingChecker(org)

  useReturnedPendingCheckerEffect(returnedPendingChecker, setPendingChecker)

  return (
    <JobLogPollingContainer
      org={org}
      pollingJobLog={returnedJobLog}
      initJobLogId={lastInProgressApiImportJobId}
      onStopPolling={(jobLog: JobLog) => {
        updateAlertStatusFromJobLog(jobLog)
        pendingCheckerSubmit()
      }}
    >
      {({ isPolling }) => (
        <div className="space-y-2">
          <ApiImportFormHeader />
          <ApiImportForm
            org={org}
            alertStatus={alertStatus}
            onSubmit={(data) => {
              const { budgetId: _, ...values } = data
              storeImportFormValues(values)
              resetAlertStatus()
              submit(data)
            }}
            apiError={apiError || pendingCheckerApiError}
            isLoading={isPolling || isSubmitting}
            initialValues={storedFormValues}
            lastApiImportJobLog={lastApiImportJobLog}
          />
        </div>
      )}
    </JobLogPollingContainer>
  )
}
