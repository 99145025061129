import { AccountingSoftwareKey } from './types'

export type ImportFormatMap = {
  [key in 'budget' | 'result']: {
    api: { value: ApiImportFormat; label: string }[]
    csv: { value: CsvImportFormat; label: string }[]
  }
}

type ApiImportFormat = AccountingSoftwareKey
type CsvImportFormat = 'transitionTable' | AccountingSoftwareKey

export type ImportFormat = ApiImportFormat | CsvImportFormat

// NOTE: 会計ソフト毎に選択できる項目
// https://knowledgelabo.atlassian.net/browse/APP-3460
export function getImportFormatMap(
  accountingSoftwareKey: AccountingSoftwareKey,
): ImportFormatMap {
  switch (accountingSoftwareKey) {
    case 'mfc':
      return mfcImportFormatMap
    case 'mfc_plus':
      return mfcPlusImportFormatMap
    case 'freee':
      return freeePlusImportFormatMap
    case 'yayoi':
      return yayoiImportFormatMap
    case 'bugyo_i':
      return bugyoIImportFormatMap
    case 'bugyo_cloud':
      return bugyoCloudImportFormatMap
    case 'general':
      return generalImportFormatMap
    default:
      throw new Error(
        `Unknown accounting software key: ${accountingSoftwareKey}`,
      )
  }
}

export const csvTransitionTableSelectValue = {
  value: 'transitionTable',
  label: '推移表（汎用形式）',
}
const mfcSelectValue = { value: 'mfc', label: 'マネーフォワードクラウド会計' }
const mfcPlusSelectValue = {
  value: 'mfc_plus',
  label: 'マネーフォワードクラウド会計Plus',
}
const freeeSelectValue = { value: 'freee', label: 'freee会計' }
const yayoiSelectValue = { value: 'yayoi', label: '弥生会計' }
const bugyoISelectValue = { value: 'bugyo_i', label: '奉行i11シリーズ' }
const bugyoCloudSelectValue = { value: 'bugyo_cloud', label: '奉行クラウド' }
const generalSelectValue = { value: 'general', label: '仕訳帳（汎用形式）' }

const mfcImportFormatMap = {
  budget: {
    csv: [csvTransitionTableSelectValue],
  },
  result: {
    api: [mfcSelectValue],
    csv: [mfcSelectValue],
  },
} as ImportFormatMap

const mfcPlusImportFormatMap = {
  budget: {
    csv: [csvTransitionTableSelectValue],
  },
  result: {
    api: [mfcPlusSelectValue],
  },
} as ImportFormatMap

const freeePlusImportFormatMap = {
  budget: {
    csv: [csvTransitionTableSelectValue],
  },
  result: {
    api: [freeeSelectValue],
  },
} as ImportFormatMap

const yayoiImportFormatMap = {
  budget: {
    csv: [csvTransitionTableSelectValue],
  },
  result: {
    csv: [yayoiSelectValue],
  },
} as ImportFormatMap

const bugyoIImportFormatMap = {
  budget: {
    csv: [csvTransitionTableSelectValue],
  },
  result: {
    csv: [bugyoISelectValue],
  },
} as ImportFormatMap

const bugyoCloudImportFormatMap = {
  budget: {
    csv: [csvTransitionTableSelectValue],
  },
  result: {
    api: [bugyoCloudSelectValue],
  },
} as ImportFormatMap

const generalImportFormatMap = {
  budget: {
    csv: [csvTransitionTableSelectValue],
  },
  result: {
    csv: [generalSelectValue, csvTransitionTableSelectValue],
  },
} as ImportFormatMap
