import { useCallback } from 'react'
import useSWR, { useSWRConfig } from 'swr'

import {
  BudgetTag,
  BudgetTagCategory,
  getBaseBudgetTagsUrl,
  getBudgetTagsUrl,
  Org,
} from '@/frontend/api'
import { fetcher } from '@/frontend/utils'

export function useBudgetTags(
  org: Org,
  primaryBudgetTagCategory: BudgetTagCategory | null,
  params?: { [key: string]: string },
) {
  const url = primaryBudgetTagCategory
    ? getBudgetTagsUrl(org.id, primaryBudgetTagCategory.id, params)
    : null
  const swrResponse = useSWR<BudgetTag[], Error>(url, fetcher)

  const { mutate } = useSWRConfig()

  // NOTE: タグを取得している全てのURLに再フェッチを指示するので使用時は注意
  const globalMutate = useCallback(() => {
    const pattern = new RegExp(getBaseBudgetTagsUrl(org.id))
    mutate((key) => typeof key === 'string' && pattern.test(key))
  }, [mutate, org.id])

  return {
    ...swrResponse,
    globalMutate,
  }
}
