import { Org } from '@/frontend/api'
import { useFiscalPeriods } from '@/frontend/features/importers/hooks/useFiscalPeriods'
import ImportSettingForm from './ImportSettingForm'
import { RawCsvTable } from './types'

type Props = {
  org: Org
  formAuthenticityToken: string
  transitionTableImportId: number
  budgetId: number
  rawCsvTable: RawCsvTable
}

export default function ImportSettingFormContainer({
  org,
  formAuthenticityToken,
  transitionTableImportId,
  budgetId,
  rawCsvTable,
}: Props) {
  const { data: fiscalPeriods } = useFiscalPeriods(org)
  return (
    <div className="flex">
      <ImportSettingForm
        org={org}
        transitionTableImportId={transitionTableImportId}
        formAuthenticityToken={formAuthenticityToken}
        budgetId={budgetId}
        fiscalPeriods={fiscalPeriods || []}
        rawCsvTable={rawCsvTable}
      />
    </div>
  )
}
