import { AuthScopes, Org, PendingChecker } from '@/frontend/api'
import { useSearchParams } from '@/frontend/hooks/useSearchParams'
import ImportSettingProvider from '../context/ImportSettingProvider'
import { ScrollControlProvider } from '../context/ScrollControlProvider'
import { getImportFormatMap } from '../getImportFormatMap'
import { useImportSetting } from '../hooks/useImportSetting'
import { AccountingSoftware, Budget } from '../types'
import ContentContainer from './ContentContainer'
import DataSyncSection from './DataSyncSection'
import ImportPageTabs from './ImportPageTabs'
import ImportSettingForm from './ImportSettingForm'

export type ImportPageContentProps = {
  org: Org
  budgets: Budget[]
  accountingSoftware: AccountingSoftware
  openingBalanceUrl: string
  pendingChecker: PendingChecker
  authScopes: AuthScopes
  children: React.ReactNode
}

export default function ImportPageContent({
  org,
  budgets,
  accountingSoftware,
  openingBalanceUrl,
  pendingChecker,
  authScopes,
  children,
}: ImportPageContentProps) {
  const importFormatMap = getImportFormatMap(accountingSoftware.key)
  const { importSetting, onChange } = useImportSetting(
    org,
    accountingSoftware,
    budgets,
    authScopes,
  )
  const importStatus = useSearchParams('import_status')
  const allowAutoScroll = importStatus === 'active'

  return (
    <ImportSettingProvider importSetting={importSetting}>
      <ScrollControlProvider allowAutoScroll={allowAutoScroll}>
        <ImportPageTabs
          org={org}
          accountingSoftware={accountingSoftware}
          openingBalanceUrl={openingBalanceUrl}
          pendingChecker={pendingChecker}
          authScopes={authScopes}
        />

        <ContentContainer>
          <ImportSettingForm
            org={org}
            budgets={budgets}
            importFormatMap={importFormatMap}
            authScopes={authScopes}
            onChange={onChange}
          />

          {children}

          {importSetting.importFormat !== 'transitionTable' && (
            <DataSyncSection
              org={org}
              accountingSoftware={accountingSoftware}
              pendingChecker={pendingChecker}
            />
          )}
        </ContentContainer>
      </ScrollControlProvider>
    </ImportSettingProvider>
  )
}
