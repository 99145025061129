import { createErr, createOk, Result } from 'option-t/PlainResult'
import { FileWithPath } from 'react-dropzone'

import { axios, z } from '@/frontend/utils'
import { bulkImportCsvBudgetTagsUrl, getBudgetTagsUrl } from './routes'
import { ApiError, BudgetTag } from './types'
import { handleAxiosError } from './utils'

export const bulkImportCsvBudgetTagsSchema = z.object({
  csvFile: z.custom<FileWithPath>().nullable(),
})

export type BulkImportCsvBudgetTagsPayload = z.infer<
  typeof bulkImportCsvBudgetTagsSchema
>

export async function bulkImportCsvBudgetTags(
  orgId: number,
  payload: BulkImportCsvBudgetTagsPayload,
): Promise<Result<null, ApiError>> {
  const url = bulkImportCsvBudgetTagsUrl(orgId)
  try {
    const formData = new FormData()
    formData.append('csv_file', payload.csvFile as FileWithPath)

    await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      // transformRequestするとmultipartが正しく送られないため空にする
      transformRequest: [],
    })
    return createOk(null)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}

export async function getBudgetTagsByTagCategory(
  orgId: number,
  budgetTagCategoryId: number,
): Promise<Result<BudgetTag[], ApiError>> {
  const url = getBudgetTagsUrl(orgId, budgetTagCategoryId)
  try {
    const response = await axios.get(url)
    return createOk(response.data)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
