import { useCallback, useEffect, useState } from 'react'

import {
  assignMembers as _assignMembers,
  deleteClient as _deleteClient,
  Client,
  ClientFolder,
  ClientsOrderParam,
  Org,
  updateClientsOrder,
} from '@/frontend/api'
import { ClientFolderItem, Data, Item } from '@/frontend/features/clients/types'
import useLocalStorage from '@/frontend/hooks/useLocalStorage'

export function useClientWithFolders(
  org: Org,
  clients: Client[],
  folders: ClientFolder[],
  isSearching: boolean,
) {
  // NOTE: 初期表示用のデータを生成
  //       - クライアントとフォルダの入れ子関係の生成
  //       - クライアントとフォルダの並び順のソート
  const generateInitialData = (): Data[] => {
    const indexedFolderDatas = folders.reduce(
      (acc: { [id: number]: Data }, folder) => {
        acc[folder.id] = {
          id: String(folder.id),
          name: folder.name,
          item: { ...folder, type: 'ClientFolder' },
          children: [],
        }
        return acc
      },
      {},
    )

    const folderedClients = clients.filter(
      (client) => client.clientPosition.clientFolderId,
    )
    const unFolderedClients = clients.filter(
      (client) => !client.clientPosition.clientFolderId,
    )

    // フォルダにクライアントを追加
    for (const client of folderedClients) {
      if (client.clientPosition.clientFolderId) {
        const folder = indexedFolderDatas[client.clientPosition.clientFolderId]
        if (folder) {
          if (!folder.children) {
            folder.children = []
          }
          folder.children.push({
            id: String(client.id),
            name: client.name,
            item: {
              ...client,
              type: 'Client',
            },
          })
        }
      }
    }

    // NOTE: フォルダを親フォルダに追加
    Object.keys(indexedFolderDatas).forEach((folderId) => {
      const folder = indexedFolderDatas[Number(folderId)]
      if (folder) {
        const folderItem = folder.item as ClientFolderItem
        if (folderItem.parentId) {
          const parentFolder = indexedFolderDatas[folderItem.parentId]
          if (parentFolder) {
            if (!parentFolder.children) {
              parentFolder.children = []
            }
            parentFolder.children.push(folder)
          }
        }
      }
    })

    // 子のtypeを再帰的に取得
    const recursiveChildrenItemType = (data: Data): string[] => {
      if (data.children) {
        return data.children.flatMap((child) => {
          return [child.item.type, ...recursiveChildrenItemType(child)]
        })
      }
      return []
    }

    // NOTE: 親フォルダに追加したフォルダで不要なものを削除
    // 検索条件が担当者で絞り込まれている場合、配下にクライアントが存在しないものは表示しない
    if (isSearching) {
      Object.keys(indexedFolderDatas).forEach((folderId) => {
        const folder = indexedFolderDatas[Number(folderId)]
        if (folder) {
          const folderItem = folder.item as ClientFolderItem
          if (folderItem.parentId) {
            const parentFolder = indexedFolderDatas[folderItem.parentId]
            if (parentFolder) {
              if (!parentFolder.children) {
                parentFolder.children = []
              }
              const childrenItemTypes = recursiveChildrenItemType(folder)
              if (!childrenItemTypes.includes('Client')) {
                const index = parentFolder.children.findIndex(
                  (child) => child.id == folder.id,
                )
                parentFolder.children.splice(index, 1)
              }
            }
          }
        }
      })
    }

    /*
     - 検索条件が担当者で絞り込まれていない場合、すべてのフォルダを表示する
     - 検索条件が担当者で絞り込まれている場合、配下にクライアントが存在するもののみ表示する
    */
    const rootFolders = folders.filter((folder) => {
      const data = indexedFolderDatas[folder.id]
      if (isSearching && data) {
        const childrenItemTypes = recursiveChildrenItemType(data)
        return (
          !folder.parentId &&
          data.children &&
          data.children.length > 0 &&
          childrenItemTypes.includes('Client')
        )
      } else {
        return !folder.parentId
      }
    })
    const rootDatas: Data[] = rootFolders
      .map((folder) => {
        const data = indexedFolderDatas[folder.id]
        if (data) {
          return data
        }

        return {
          id: String(folder.id),
          name: folder.name,
          item: { ...folder, type: 'ClientFolder' } as ClientFolderItem,
        }
      })
      .concat(
        unFolderedClients.map((client) => ({
          id: String(client.id),
          name: client.name,
          item: {
            ...client,
            type: 'Client',
          },
        })),
      )

    const dataSorter = (a: Data, b: Data) => {
      const position_a =
        a.item.type === 'Client'
          ? a.item.clientPosition.position
          : a.item.position
      const position_b =
        b.item.type === 'Client'
          ? b.item.clientPosition.position
          : b.item.position
      if (position_a < position_b) {
        return -1
      }
      if (position_a > position_b) {
        return 1
      }
      return 0
    }

    const recursiveSort = (data: Data) => {
      if (data.children) {
        data.children = data.children.sort(dataSorter).map(recursiveSort)
      }
      return data
    }

    const sortedDatas = rootDatas.sort(dataSorter).map(recursiveSort)
    return sortedDatas
  }
  const [data, setData] = useState<Data[]>([])
  const [isQueingSync, setIsQueingSync] = useState(false)

  useEffect(() => {
    setData(generateInitialData())
  }, [clients, folders])

  // NOTE: フォルダの初期展開状態を保持する
  const INITIAL_OPEN_STATE_STORAGE_KEY =
    `ClientsFolderInitialOpenState_${org.id}` as const
  const [initialOpenState, setInitialOpenState] = useLocalStorage<{
    [id: string]: boolean
  }>(INITIAL_OPEN_STATE_STORAGE_KEY)

  const updateInitialOpenState = useCallback(
    (id: string, isOpen: boolean) => {
      setInitialOpenState((prev) => {
        return prev ? { ...prev, [id]: isOpen } : { [id]: isOpen }
      })
    },
    [setInitialOpenState],
  )

  const _syncOrderToServer = useCallback(async () => {
    const orderParams = convertToUpdateClientsOrderApiParams(data)
    await updateClientsOrder(org.id, { orderParams })
  }, [org, data])

  const syncOrderToServer = useCallback(() => {
    setIsQueingSync(true)
  }, [])

  useEffect(() => {
    if (isQueingSync) {
      _syncOrderToServer()
      setIsQueingSync(false)
    }
  }, [data, isQueingSync, _syncOrderToServer])

  return {
    data,
    setData,
    syncOrderToServer,
    initialOpenState,
    updateInitialOpenState,
  }
}

function convertToUpdateClientsOrderApiParams(
  data: Data[],
  parent?: ClientFolderItem,
): ClientsOrderParam[] {
  const convert = (item: Item, position: number) => {
    const getType = (item: Item): 'client' | 'client_folder' => {
      switch (item.type) {
        case 'ClientFolder':
          return 'client_folder'
        case 'Client':
          return 'client'
      }
    }

    return {
      id: item.id,
      type: getType(item),
      parentFolderId: parent ? parent.id : null,
      position,
    }
  }

  const params: ClientsOrderParam[] = []
  data.forEach((d, index) => {
    params.push(convert(d.item, index))
    if (d.children && d.item.type == 'ClientFolder') {
      params.push(...convertToUpdateClientsOrderApiParams(d.children, d.item))
    }
  })

  return params
}
