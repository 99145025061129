import { useState } from 'react'

import { AuthScopes, Org } from '@/frontend/api'
import {
  getImportFormatMap,
  ImportFormat,
  ImportFormatMap,
} from '../getImportFormatMap'
import {
  AccountingSoftware,
  Budget,
  DataFormat,
  ImportSetting,
  ImportTarget,
} from '../types'
import useStoredImportSettingValue from './useStoredImportSettingValue'

export const useImportSetting = (
  org: Org,
  accountingSoftware: AccountingSoftware,
  budgets: Budget[],
  authScopes: AuthScopes,
) => {
  const { storedImportSettingValue, updateStoredImportSettingValue } =
    useStoredImportSettingValue(org.id, accountingSoftware.key)
  const importFormatMap = getImportFormatMap(accountingSoftware.key)

  const initialImportTarget = determineImportTarget(
    getAvailableImportTargets(authScopes),
    storedImportSettingValue,
  )

  const initialBudgetId = determineBudgetId(
    budgets,
    initialImportTarget as ImportTarget,
    storedImportSettingValue,
  )

  const initialDataFormat = determineDataFormat(
    importFormatMap,
    initialImportTarget as ImportTarget,
    storedImportSettingValue,
  )

  const initialImportFormat = determineImportFormat(
    importFormatMap,
    initialImportTarget as ImportTarget,
    initialDataFormat,
    storedImportSettingValue,
  )

  const initialImportSetting: ImportSetting = {
    importTarget: initialImportTarget,
    budgetId: initialBudgetId,
    dataFormat: initialDataFormat,
    importFormat: initialImportFormat,
  }

  const [importSetting, setImportSetting] =
    useState<ImportSetting>(initialImportSetting)

  const onChange = (changes: Partial<ImportSetting>) => {
    setImportSetting((prev) => ({ ...prev, ...changes }))
    updateStoredImportSettingValue(changes)
  }
  return {
    importSetting,
    onChange,
  }
}

export function getAvailableImportTargets(
  authScopes: AuthScopes,
): ImportTarget[] {
  const targets: ImportTarget[] = []
  if (authScopes.manageBudgetsImporter) {
    targets.push('budget')
  }
  if (authScopes.manageResultImporter) {
    targets.push('result')
  }
  return targets
}

function determineImportTarget(
  availableImportTargets: ImportTarget[],
  storedImportSettingValue: ImportSetting | undefined = undefined,
): ImportTarget {
  const storedImportTarget = storedImportSettingValue?.importTarget
  return storedImportTarget &&
    availableImportTargets.includes(storedImportTarget)
    ? storedImportTarget
    : (availableImportTargets[0] as ImportTarget)
}

export function determineBudgetId(
  budgets: Budget[],
  importTarget: ImportTarget,
  storedImportSettingValue: ImportSetting | undefined = undefined,
): string {
  const availableBudgets = budgets.filter(
    (budget) => budget.result === (importTarget === 'result'),
  )
  const storedBudgetId = storedImportSettingValue?.budgetId
  return storedBudgetId &&
    availableBudgets.some((budget) => budget.id.toString() === storedBudgetId)
    ? storedBudgetId
    : (availableBudgets[0]?.id.toString() as string)
}

export function determineDataFormat(
  importFormatMap: ImportFormatMap,
  importTarget: ImportTarget,
  storedImportSettingValue: ImportSetting | undefined = undefined,
): DataFormat {
  const storedDataFormat = storedImportSettingValue?.dataFormat
  const availableDataFormats = Object.keys(importFormatMap[importTarget])
  return storedDataFormat && availableDataFormats.includes(storedDataFormat)
    ? storedDataFormat
    : (availableDataFormats[0] as DataFormat)
}

export function determineImportFormat(
  importFormatMap: ImportFormatMap,
  importTarget: ImportTarget,
  dataFormat: DataFormat,
  storedImportSettingValue: ImportSetting | undefined = undefined,
): ImportFormat {
  const storedImportFormat = storedImportSettingValue?.importFormat
  const availableImportFormats = importFormatMap[importTarget][dataFormat]
  return storedImportFormat &&
    availableImportFormats.some(
      (importFormat) => importFormat.value === storedImportFormat,
    )
    ? storedImportFormat
    : (availableImportFormats[0]?.value as ImportFormat)
}
