import { useEffect, useState } from 'react'

import { JobLog, Org, PendingChecker } from '@/frontend/api'
import { Alert, ApiAlert, Button } from '@/frontend/components'
import useAlertStatus from '../../hooks/useAlertStatus'
import { useImportSettingContext } from '../../hooks/useImportSettingContext'
import { usePollingJobLog } from '../../hooks/usePollingJobLog'
import { AccountingSoftwareKey } from '../../types'
import { JournalUpdateStatus } from './JournalUpdateStatus'
import { useJournalsUpdateFromSource } from './useJournalsUpdateFromSource'

type Props = {
  org: Org
  accountingSoftwareKey: AccountingSoftwareKey
  pendingChecker: PendingChecker
}

export default function JournalUpdateContainer({
  org,
  accountingSoftwareKey,
  pendingChecker: { isApiJournalPending, isCsvJournalPending },
}: Props) {
  const {
    importSetting: { dataFormat },
  } = useImportSettingContext()
  const { alertStatus, setAlertStatus } = useAlertStatus(null)
  const [isPendingExists, setIsPendingExists] = useState<boolean | null>(null)

  const {
    submit,
    jobLog: returnedJobLog,
    isSubmitting,
    apiError,
  } = useJournalsUpdateFromSource(org, accountingSoftwareKey, dataFormat)

  const onStopPolling = (jobLog: JobLog) => {
    setAlertStatus({
      status: jobLog.status,
      errorDetail: jobLog.errorDetail,
    })
  }

  // NOTE: ちらつき対応
  useEffect(() => {
    setPollingJobLogId(returnedJobLog?.id || null)
  }, [returnedJobLog])

  const { isPolling, setPollingJobLogId } = usePollingJobLog(org, null, {
    onStopPolling,
  })

  useEffect(() => {
    const pendingExists =
      (isApiJournalPending && dataFormat === 'api') ||
      (isCsvJournalPending && dataFormat === 'csv')
    setIsPendingExists(pendingExists)
  }, [dataFormat, isApiJournalPending, isCsvJournalPending])

  const _onClick = () => {
    submit()
    setIsPendingExists(null)
  }

  const isDisplayPendingAlert =
    isPendingExists && alertStatus?.status !== 'succeeded'

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <div className="font-bold">実績の反映</div>
        <div className="flex justify-between items-center">
          <div>
            <p>マスタの割当にて設定した内容を実績に反映します。</p>
          </div>
          <Button
            variant={`${isDisplayPendingAlert ? 'primary' : 'secondary'}`}
            loading={isPolling || isSubmitting}
            onClick={_onClick}
          >
            実績の反映
          </Button>
        </div>
        <div
          className={`${
            apiError || isDisplayPendingAlert || alertStatus
              ? 'py-2 space-y-2'
              : ''
          }`}
        >
          {apiError && <ApiAlert apiError={apiError} />}
          {isDisplayPendingAlert && (
            <Alert
              title="実績に反映されていない項目があります。実績の反映ボタンを押してください。"
              variant="warning"
            />
          )}
          {alertStatus && (
            <JournalUpdateStatus
              status={alertStatus.status}
              errorDetail={alertStatus.errorDetail}
            />
          )}
        </div>
      </div>
    </div>
  )
}
