import { ButtonLink } from '@/frontend/components'

export default function RevokeApiAuthButton({
  revokeApiAuthUrl,
}: {
  revokeApiAuthUrl: string
}) {
  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const result = window.confirm('連携を解除しますか？')
    if (!result) e.preventDefault()
  }
  return (
    <div onClick={onClick}>
      <ButtonLink variant="outlined" href={revokeApiAuthUrl}>
        連携を解除する
      </ButtonLink>
    </div>
  )
}
