import { useCallback, useState } from 'react'

import {
  ApiError,
  bulkImportCsvBudgetTags,
  BulkImportCsvBudgetTagsPayload,
  Org,
} from '@/frontend/api'

export function useBulkImportCsvBudgetTags(org: Org) {
  const [isSucceeded, setIsSucceeded] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined)

  const submit = useCallback(
    async (data: BulkImportCsvBudgetTagsPayload) => {
      setIsSucceeded(false)
      setIsSubmitting(true)
      const result = await bulkImportCsvBudgetTags(org.id, data)
      if (result.ok) {
        setApiError(undefined)
        setIsSucceeded(true)
        setIsSubmitting(false)
        return result.val
      } else {
        setApiError(result.err)
        setIsSucceeded(false)
        setIsSubmitting(false)
        return null
      }
    },
    [org],
  )
  return {
    submit,
    isSubmitting,
    isSucceeded,
    apiError,
  }
}
