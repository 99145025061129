import { SlideOver } from '@/frontend/components'
import { NonFinancialItemSlideOverFormActions } from './NonFinancialItemSlideOverFormActions'

export function NonFinancialItemSlideOverFormSkeleton({
  title,
  description,
  onClose,
}: {
  title: string
  description: string
  onClose: () => void
}) {
  return (
    <SlideOver.Panel
      title={title}
      description={description}
      onClose={onClose}
      actions={
        <NonFinancialItemSlideOverFormActions onClose={onClose} disabled />
      }
    >
      <div className="space-y-6 pt-6 pb-5 px-6">
        <InputGroupSkeleton />
        <InputGroupSkeleton />
        <InputGroupSkeleton />
        <InputGroupSkeleton />
        <InputGroupSkeleton />
        <InputGroupSkeleton />
        <InputGroupSkeleton />
      </div>
    </SlideOver.Panel>
  )
}

function InputGroupSkeleton() {
  return (
    <div className="space-y-2 animate-pulse">
      <div className="py-[3px]">
        <div className="h-[14px] max-w-[140px] rounded-full bg-gray-100" />
      </div>
      <div className="h-[36px] w-full rounded-full bg-gray-100" />
    </div>
  )
}
