import { useEffect } from 'react'

import { JobLog, Org, PendingChecker } from '@/frontend/api'
import { GeneralCsvSourceAccountItemPayload } from '@/frontend/api/importer'
import useLocalStorage from '@/frontend/hooks/useLocalStorage'
import { useNavigate } from '@/frontend/hooks/useNavigate'
import { getInitialImportFileStatus } from '../getInitialImportFileStatus'
import useAlertStatus from '../hooks/useAlertStatus'
import { useApiPendingChecker } from '../hooks/useApiPendingChecker'
import useImportFileStatus from '../hooks/useImportFileStatus'
import { usePollingJobLog } from '../hooks/usePollingJobLog'
import useReturnedPendingCheckerEffect from '../hooks/useReturnedPendingCheckerEffect'
import { JobLogWithAttachedFile } from '../types'
import AccountItemImportForm from './AccountItemImportForm'
import AccountItemsMappingMessageWithLink from './AccountItemsMappingMessageWithLink'
import { useUploadGeneralCsvSourceAccountItems } from './useUploadGeneralCsvSourceAccountItems'

type Props = {
  org: Org
  sourceAccountItemUrl: string
  jobLogWithAttachedFile: JobLogWithAttachedFile | null
  initialInProgressJobLogId: number | null
  setIsJournalImportable: (isJournalImportable: boolean) => void
  pendingChecker: PendingChecker
  setPendingChecker: (pendingChecker: PendingChecker) => void
}

const STORED_VALUES_STORAGE_KEY = 'GeneralCsvAccountItemImportSettings' as const

type storeValues = { headerRowNumber?: number }

type storedValueStorageType = {
  [id: number]: storeValues
}

function getGeneralAccountItemsImportUrl(id: number, uploadId: string): string {
  return `/orgs/${id}/result/general/csv/account_items_import?token=${uploadId}`
}

export default function AccountItemImportFormContainer({
  org,
  sourceAccountItemUrl,
  jobLogWithAttachedFile,
  initialInProgressJobLogId,
  setIsJournalImportable,
  pendingChecker,
  setPendingChecker,
}: Props) {
  const { navigate } = useNavigate()
  const {
    importFileStatus,
    updateImportFileToQueued,
    updateOnlyStatusForImportFile,
  } = useImportFileStatus(getInitialImportFileStatus(jobLogWithAttachedFile))

  const {
    submit: pendingCheckerSubmit,
    apiError: pendingCheckerApiError,
    returnedPendingChecker,
  } = useApiPendingChecker(org)

  const [storedValues, setStoreValues] =
    useLocalStorage<storedValueStorageType>(STORED_VALUES_STORAGE_KEY)

  const { alertStatus, setAlertStatus } = useAlertStatus(jobLogWithAttachedFile)

  const { submit, isSubmitting, apiError } =
    useUploadGeneralCsvSourceAccountItems(org)

  const onStopPolling = (jobLog: JobLog) => {
    updateOnlyStatusForImportFile(jobLog.status)
    setAlertStatus({
      status: jobLog.status,
      errorDetail: jobLog.errorDetail,
    })
    pendingCheckerSubmit()
  }

  const { data: pollingJobLog, isPolling } = usePollingJobLog(
    org,
    initialInProgressJobLogId,
    { onStopPolling },
  )

  const onFileChange = (file: File) => {
    updateImportFileToQueued([{ name: file.name } as File])
    setAlertStatus(null)
  }

  useEffect(() => {
    if (pollingJobLog?.status === 'succeeded') {
      setIsJournalImportable(true)
    }
  }, [pollingJobLog, setIsJournalImportable])

  useReturnedPendingCheckerEffect(returnedPendingChecker, setPendingChecker)

  const onSubmit = async (data: GeneralCsvSourceAccountItemPayload) => {
    const uploadId = await submit(data)
    if (uploadId) {
      setStoreValues((prev) => {
        return {
          ...prev,
          [org.id]: {
            headerRowNumber: data.headerRowNumber,
          },
        }
      })

      const url = getGeneralAccountItemsImportUrl(org.id, uploadId)
      navigate(url)
    }
  }

  const isLoading = isPolling || isSubmitting

  return (
    <div>
      <div className="space-y-4 pb-4">
        <div className="font-bold">勘定科目のインポート</div>
        <div>会計ソフトから出力した勘定科目データを取り込みます。</div>
        <AccountItemImportForm
          importFileStatus={importFileStatus}
          onFileChange={onFileChange}
          onSubmit={onSubmit}
          initialValues={storedValues?.[org.id]}
          isLoading={isLoading}
          apiError={apiError || pendingCheckerApiError}
          alertStatus={alertStatus}
          jobLogWithAttachedFile={jobLogWithAttachedFile}
        />
      </div>
      <AccountItemsMappingMessageWithLink
        sourceAccountItemUrl={sourceAccountItemUrl}
        pendingChecker={pendingChecker}
      />
    </div>
  )
}
