import { useEffect } from 'react'

import { BudgetTagCategory, Org } from '@/frontend/api'
import PrimaryTagCategoryLink from '../components/PrimaryTagCategoryLink'
import TagCategoryLink from '../components/TagCategoryLink'
import { useBudgetTags } from '../hooks/useBudgetTags'
import { useBulkImportCsvBudgetTags } from '../hooks/useBulkImportCsvBudgetTags'
import useImportFileStatus from '../hooks/useImportFileStatus'
import BudgetTagImportForm from './BudgetTagImportForm'

type Props = {
  org: Org
  primaryBudgetTagCategory: BudgetTagCategory
}

export default function BudgetTagImportFormContainer({
  org,
  primaryBudgetTagCategory,
}: Props) {
  const {
    importFileStatus,
    updateImportFileToQueued,
    updateOnlyStatusForImportFile,
  } = useImportFileStatus(null)

  const { globalMutate: refreshBudgetTags } = useBudgetTags(
    org,
    primaryBudgetTagCategory,
  )

  const { submit, isSubmitting, isSucceeded, apiError } =
    useBulkImportCsvBudgetTags(org)

  const onFileChange = (file: File) => {
    updateImportFileToQueued([{ name: file.name } as File])
  }

  useEffect(() => {
    // ファイルのとこのステータス変更処理
    if (isSucceeded) {
      updateOnlyStatusForImportFile('succeeded')
      // NOTE: 同一画面でタグ一覧を使っているところがあるので同期してあげる。
      refreshBudgetTags()
    }
    if (apiError) {
      updateOnlyStatusForImportFile('failed')
    }
  }, [isSucceeded, apiError])

  return (
    <div>
      <div className="space-y-4 pb-4">
        <div className="font-bold">タグの一括登録</div>
        <div>
          <p>Manageboardで使用するタグを取り込みます。</p>
          <p>
            タグが未登録の場合は、タグの一括登録前にタグカテゴリを設定した後、そのタグカテゴリをPrimaryタグカテゴリに指定してください。
          </p>
          <p className="space-x-4">
            <TagCategoryLink org={org}>タグカテゴリの設定</TagCategoryLink>
            <PrimaryTagCategoryLink org={org}>
              Primaryタグカテゴリの設定
            </PrimaryTagCategoryLink>
          </p>
        </div>
        <BudgetTagImportForm
          org={org}
          importFileStatus={importFileStatus}
          onFileChange={onFileChange}
          onSubmit={submit}
          isSucceeded={isSucceeded}
          isLoading={isSubmitting}
          apiError={apiError}
        />
      </div>
    </div>
  )
}
