import { MutableRefObject, useEffect } from 'react'

export default function useResizeObserver(
  elements: Array<
    MutableRefObject<HTMLElement | Element> | HTMLElement | Element | null
  >,
  callback: (entries: ResizeObserverEntry[]) => void,
  rerun?: unknown,
) {
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      callback(entries)
    })

    for (const elm of elements) {
      if (!elm) continue
      resizeObserver.observe(
        elm instanceof HTMLElement || elm instanceof Element
          ? elm
          : elm.current,
      )
    }

    return () => resizeObserver.disconnect()
  }, [rerun, callback, elements])
}
