import { useState } from 'react'

import { BudgetTagCategory, JobLog, Org, PendingChecker } from '@/frontend/api'
import { Card as Section } from '@/frontend/components'
import ApiAuthButton from '../components/ApiAuthButton'
import ApiAuthContainer from '../components/ApiAuthContainer'
import AuthedAccountingSoftwareLabel from '../components/AuthedAccountingSoftwareLabel'
import FiscalPeriodLink from '../components/FiscalPeriodLink'
import TitleWithJobLogLink from '../components/TitleWithJobLogLink'
import TransitionTableImportContainer from '../components/TransitionTableImportContainer'
import { useImportSettingContext } from '../hooks/useImportSettingContext'
import { AccountingSoftware, FreeeCompany } from '../types'
import ApiImportFormContainer from './ApiImportFormContainer'

export type DataSourceSectionProps = {
  org: Org
  accountingSoftware: AccountingSoftware
  primaryBudgetTagCategory: BudgetTagCategory | null
  apiAuthorizeUrl: string
  freeeCompanyId: number | null
  freeeCompanies: FreeeCompany[]
  lastApiImportJobLog: JobLog | null
  lastInProgressApiImportJobId: number | null
  setPendingChecker: (pendingChecker: PendingChecker) => void
}

export default function DataSourceSection({
  org,
  accountingSoftware,
  primaryBudgetTagCategory,
  apiAuthorizeUrl,
  freeeCompanyId,
  freeeCompanies,
  lastApiImportJobLog,
  lastInProgressApiImportJobId,
  setPendingChecker,
}: DataSourceSectionProps) {
  const initialFreeeCompany = freeeCompanies.find(
    (company) => company.id === freeeCompanyId,
  )

  const [freeeCompany, setFreeeCompany] = useState<FreeeCompany | undefined>(
    initialFreeeCompany,
  )
  const {
    importSetting: { dataFormat, importFormat },
  } = useImportSettingContext()
  const isImportable = accountingSoftware.isApiAuthed

  return (
    <Section onGray>
      <Section.Title>
        <TitleWithJobLogLink org={org}>データ取得</TitleWithJobLogLink>
      </Section.Title>
      {dataFormat === 'api' && (
        <div className="p-6 border space-y-4 text-sm rounded-md">
          <ApiAuthContainer description={<Description org={org} />}>
            <ApiAuthButton
              accountingSoftware={accountingSoftware}
              apiAuthorizeUrl={apiAuthorizeUrl}
            />
            <AuthedAccountingSoftwareLabel
              accountingSoftware={accountingSoftware}
            />
          </ApiAuthContainer>

          {isImportable && (
            <ApiImportFormContainer
              org={org}
              freeeCompany={freeeCompany}
              freeeCompanies={freeeCompanies}
              onChangeCompany={setFreeeCompany}
              lastApiImportJobLog={lastApiImportJobLog}
              lastInProgressApiImportJobId={lastInProgressApiImportJobId}
              setPendingChecker={setPendingChecker}
            />
          )}
        </div>
      )}

      {/* CSVインポート、推移表フォーマット */}
      {dataFormat === 'csv' && importFormat === 'transitionTable' && (
        <div className="p-6 border space-y-12 text-sm rounded-md">
          {/* 推移表インポート */}
          <TransitionTableImportContainer
            org={org}
            primaryBudgetTagCategory={primaryBudgetTagCategory}
          />
        </div>
      )}
    </Section>
  )
}

function Description({ org }: { org: Org }) {
  const description =
    '会計ソフトとAPI連携を行います。API連携前にfreee会計と同じ会計期間をManageboardに登録してください。'

  return (
    <div className="space-y-2">
      <div>{description}</div>
      <FiscalPeriodLink org={org} />
    </div>
  )
}
