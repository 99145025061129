import { useCallback } from 'react'

import {
  createNonFinancialItem,
  NonFinancialItemPayload,
  Org,
} from '@/frontend/api'
import { useNonFinancialItemsSuspense } from './useNonFinancialItemsSuspense'

export function useCreateNonFinancialItem(org: Org) {
  const { data: nonFinancialItems, mutate } = useNonFinancialItemsSuspense(org)

  return useCallback(
    async (data: NonFinancialItemPayload) => {
      const result = await createNonFinancialItem(org.id, data)
      if (result.ok) {
        mutate([...nonFinancialItems, result.val])
      }
      return result
    },
    [org, nonFinancialItems, mutate],
  )
}
