import { ErrorMsg } from './ErrorMsg'
import { LoadingOverlay } from './LoadingOverlay'

export function PageContainer({
  isLoading = false,
  hasError = false,
  children,
}: {
  isLoading?: boolean
  hasError?: boolean
  children: React.ReactNode
}) {
  return (
    <>
      {hasError ? <ErrorMsg /> : !isLoading && <>{children}</>}
      <LoadingOverlay isLoading={isLoading && !hasError} />
    </>
  )
}
