import { useContext } from 'react'

import { dropdownContext } from './dropdownContext'

type Props = {
  placeholder: React.ReactNode
  values: string[]
  limit?: number
}

export function MultiValueBadge({ values, limit, placeholder }: Props) {
  const valuesPresent = values.length > 0
  const displayValues = limit ? values.slice(0, limit) : values
  const hiddenValueCount = limit ? values.length - limit : 0
  const { disabled } = useContext(dropdownContext)
  const badgeColorClass = disabled ? 'bg-gray-200' : 'bg-blue-50'

  return (
    <div className="w-full flex items-center justify-between">
      <div className="grow-1 flex items-center">
        {valuesPresent ? (
          displayValues.map((v) => (
            <div
              key={v}
              className={`w-24 px-2 py-0.5 mx-0.5 text-xs text-center rounded-xl truncate ${badgeColorClass}`}
            >
              {v}
            </div>
          ))
        ) : (
          <div className="py-0.5">{placeholder}</div>
        )}
      </div>
      {hiddenValueCount > 0 && (
        <div
          className={`px-2 py-0.5 mr-2 text-xs rounded-xl nowrap ${badgeColorClass}`}
        >
          + {hiddenValueCount > 99 ? '99+' : hiddenValueCount}
        </div>
      )}
    </div>
  )
}
