import React, { createContext, useCallback, useState } from 'react'

type ScrollControlContextValue = {
  isAutoScrollEnabled: boolean
  setIsAutoScrollEnabled: (isAutoScrollEnabled: boolean) => void
  targetRef: React.RefObject<HTMLDivElement>
  scrollToRefWithDelay: () => void
}

export const ScrollControlContext = createContext<
  ScrollControlContextValue | undefined
>(undefined)

type Props = {
  children: React.ReactNode
  allowAutoScroll?: boolean
}

export function ScrollControlProvider({
  children,
  allowAutoScroll = false,
}: Props) {
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] =
    useState(allowAutoScroll)
  const targetRef = React.useRef<HTMLDivElement>(null)

  const scrollToRefWithDelay = useCallback(() => {
    const timerId = setTimeout(() => {
      targetRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, 500)

    return () => clearTimeout(timerId)
  }, [])

  return (
    <ScrollControlContext.Provider
      value={{
        isAutoScrollEnabled,
        setIsAutoScrollEnabled,
        targetRef,
        scrollToRefWithDelay,
      }}
    >
      {children}
    </ScrollControlContext.Provider>
  )
}
