import {
  AllocationDriver,
  List,
  SubProps,
} from '@/frontend/features/allocationDrivers'

type Props = {
  subProps: SubProps
  updateOrderUrl: string
  allocationDrivers: AllocationDriver[]
  isSortable: boolean
}

export default function IndexPage({
  subProps,
  updateOrderUrl,
  allocationDrivers,
  isSortable,
}: Props) {
  return (
    <List
      subProps={subProps}
      updateOrderUrl={updateOrderUrl}
      items={allocationDrivers}
      isSortable={isSortable}
    />
  )
}
