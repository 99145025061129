import { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export const Table = function Table({ children }: Props) {
  return (
    <div className="overflow-hidden ring-1 ring-gray-200 rounded">
      <table className="w-full divide-y divide-gray-200 text-sm leading-5 text-gray-800 text-left">
        {children}
      </table>
    </div>
  )
}
