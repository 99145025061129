import { useMemo } from 'react'
import {
  MoveHandler,
  NodeApi,
  NodeRendererProps,
  SimpleTree,
  Tree,
} from 'react-arborist'

import type { ClientsSearchParamsPayload } from '@/frontend/api/client'
import {
  Client,
  ClientFolder,
  MemberWithAssign,
  Org,
} from '@/frontend/api/types'
import { useClientWithFolders } from '@/frontend/features/clients/hooks'
import { ClientRow } from '@/frontend/features/clients/Table/ClientRow'
import { ClientFolderRow } from '@/frontend/features/clients/Table/FolderRow'
import { Data } from '@/frontend/features/clients/types'

const ROW_HEIGHT = 60
const MEMBER_SELECT_HEIGHT = 350

type Props = {
  org: Org
  member: MemberWithAssign
  clients: Client[]
  clientFolders: ClientFolder[]
  searchParamsPayload: ClientsSearchParamsPayload
  assignable: boolean
  creatable: boolean
  destroyable: boolean
  onDelete: (client: Client) => void
  onFolderEdit: (folder: ClientFolder) => void
  formAuthenticityToken: string
}

export function Table({
  org,
  member,
  clients,
  clientFolders,
  searchParamsPayload,
  assignable,
  creatable,
  destroyable,
  onDelete,
  onFolderEdit,
  formAuthenticityToken,
}: Props) {
  const { searchUserId } = searchParamsPayload
  const isSearching = !!searchUserId
  const {
    data,
    setData,
    syncOrderToServer,
    initialOpenState,
    updateInitialOpenState,
  } = useClientWithFolders(org, clients, clientFolders, isSearching)

  const NodeWithSubProps = (props: NodeRendererProps<Data>) => {
    return (
      <Node
        {...props}
        org={org}
        member={member}
        assignable={assignable}
        creatable={creatable}
        destroyable={destroyable}
        onDelete={onDelete}
        updateInitialOpenState={updateInitialOpenState}
        onFolderEdit={onFolderEdit}
        formAuthenticityToken={formAuthenticityToken}
      />
    )
  }

  const tree = useMemo(() => new SimpleTree<Data>(data), [data])
  const onMove: MoveHandler<Data> = (args: {
    dragIds: string[]
    parentId: null | string
    index: number
    parentNode: NodeApi<Data> | null
  }) => {
    for (const id of args.dragIds) {
      tree.move({ id, parentId: args.parentId, index: args.index })
    }
    setData(tree.data)

    if (args.parentNode && args.parentNode.data.item.type === 'ClientFolder') {
      updateInitialOpenState(args.parentNode.id, true)
    }

    syncOrderToServer()
  }

  return (
    <div className="mx-auto mt-4 max-w-[88rem]">
      {/* NOTE: 既存の_table.scssを使用 */}
      <Container>
        <div className="flex px-6 py-3 border-b border-gray-200 bg-gray-50 leading-4 tracking-wider font-medium text-left text-xs text-gray-500">
          <div className="flex-none w-72">名称</div>
          <div className="flex-none w-96">担当者</div>
          <div className="flex-none w-48 text-right pr-8">
            クライアントユーザー数
          </div>
          <div className="flex-none w-40">実績の最終更新日</div>
          <div className="flex-none w-40">登録日</div>
          <div className="flex-none w-44">{/* ログイン */}</div>
        </div>
        <Tree
          data={data}
          width="100%"
          height={
            ROW_HEIGHT * (clients.length + clientFolders.length) +
            MEMBER_SELECT_HEIGHT
          }
          rowHeight={ROW_HEIGHT}
          onMove={onMove}
          disableDrag={!destroyable}
          initialOpenState={initialOpenState}
        >
          {NodeWithSubProps}
        </Tree>
      </Container>
    </div>
  )
}

function Container({ children }: { children: React.ReactNode }) {
  return (
    <div className="align-middle inline-block min-w-full overflow-hidden">
      {children}
    </div>
  )
}

function Node(
  props: NodeRendererProps<Data> & {
    org: Org
    member: MemberWithAssign
    assignable: boolean
    creatable: boolean
    destroyable: boolean
    onDelete: (client: Client) => void
    updateInitialOpenState: (id: string, isOpen: boolean) => void
    onFolderEdit: (folder: ClientFolder) => void
    formAuthenticityToken: string
  },
) {
  const { style, dragHandle } = props
  const { paddingLeft, ...rowStyle } = style

  return (
    <div style={rowStyle}>
      <div
        ref={dragHandle}
        style={{ height: ROW_HEIGHT }}
        className="flex px-6 items-center whitespace-nowrap border-b border-gray-200 text-sm leading-5 bg-white text-gray-900"
      >
        <div className="w-full">
          <Row {...props} style={{ paddingLeft }} />
        </div>
      </div>
    </div>
  )
}

function Row(props: {
  node: NodeApi<Data>
  org: Org
  member: MemberWithAssign
  assignable: boolean
  creatable: boolean
  destroyable: boolean
  onDelete: (client: Client) => void
  updateInitialOpenState: (id: string, isOpen: boolean) => void
  style: React.CSSProperties
  onFolderEdit: (folder: ClientFolder) => void
  formAuthenticityToken: string
}) {
  const item = props.node.data.item

  switch (item.type) {
    case 'ClientFolder':
      return (
        <ClientFolderRow
          style={props.style}
          node={props.node}
          creatable={props.creatable}
          destroyable={props.destroyable}
          updateInitialOpenState={props.updateInitialOpenState}
          onFolderEdit={props.onFolderEdit}
          formAuthenticityToken={props.formAuthenticityToken}
          org={props.org}
        />
      )
    case 'Client':
      return <ClientRow {...props} client={item} style={props.style} />
  }
}
