import { forwardRef } from 'react'

import { InputRadio } from '../InputRadio'
import { Label } from '../Label'

type Props = Omit<
  React.ComponentProps<typeof InputRadio>,
  'className' | 'type'
> & {
  children: React.ReactNode
}

export const Radio = forwardRef<HTMLInputElement, Props>(function Radio(
  { children, ...props },
  ref,
) {
  return (
    <div className="inline-flex items-center space-x-2">
      <InputRadio ref={ref} {...props} />
      <Label htmlFor={props.id} disabled={props.disabled}>
        {children}
      </Label>
    </div>
  )
})
