import { useState } from 'react'
import dayjs from 'dayjs'

import {
  ApiError,
  Comment,
  deleteComment,
  deleteCommentThread,
  updateComment,
  UpdateCommentPayload,
} from '@/frontend/api'
import { Avatar } from '@/frontend/components'
import { CommentActionDropdown } from './CommentActionDropdown'
import { CommentForm } from './CommentForm'
import { MentionItem } from './lexicalPlugins/MentionsPlugin'
import { useCommentRender } from './useCommentRender'

type Props = {
  href: string | null
  userId: number
  memberId: number
  orgId: number
  comment: Comment
  commentIndex: number
  onUpdate: (comment: Comment) => void
  onDelete: (comment: Comment) => void
  onDeleteThread: () => void
  creatableComment: boolean
  suggestionContainerRef: React.RefObject<HTMLElement>
  commentMentionMembers: MentionItem[]
}

export function CommentItem({
  href,
  userId,
  memberId,
  orgId,
  comment,
  commentIndex,
  onUpdate,
  onDelete,
  onDeleteThread,
  creatableComment,
  suggestionContainerRef,
  commentMentionMembers,
}: Props) {
  const [isEditing, setIsEditing] = useState(false)
  const [apiError, setApiError] = useState<ApiError | null>(null)

  const _onUpdate = async (data: UpdateCommentPayload) => {
    const result = await updateComment(
      orgId,
      comment.commentThreadId,
      comment.id,
      data,
    )
    if (result.ok) {
      setIsEditing(false)
      onUpdate({ ...comment, ...data })
      return true
    } else {
      setApiError(result.err)
      return false
    }
  }

  const deleteCommentThreadConfirm = () => {
    if (!(commentIndex === 0)) return true
    return confirm(
      'このコメントスレッドを削除しますか？\r\n' +
        'コメントスレッドを削除するとスレッド内のすべてのコメントが削除されます。',
    )
  }

  const _onDelete = async () => {
    if (commentIndex === 0) {
      const result = await deleteCommentThread(orgId, comment.commentThreadId)
      if (result.ok) {
        onDeleteThread()
        return true
      } else {
        setApiError(result.err)
        return false
      }
    } else {
      const result = await deleteComment(
        orgId,
        comment.commentThreadId,
        comment.id,
      )
      if (result.ok) {
        onDelete(comment)
        return true
      } else {
        setApiError(result.err)
        return false
      }
    }
  }

  const renderedHtml = useCommentRender(
    comment.content,
    commentMentionMembers,
    [memberId],
  )

  if (isEditing) {
    return (
      <div className="py-3">
        <div className="flex items-start justify-between">
          <div className="flex-auto space-y-2">
            <div className="flex items-center space-x-2">
              <div className="w-8 h-8">
                <Avatar imgSrc={comment.user?.avatarThumbUrl} />
              </div>
              <div className="text-gray-900 text-sm font-bold">
                {comment.user?.name ? comment.user.name : '削除済みのユーザー'}
              </div>
            </div>
            <CommentForm
              initialValue={comment.content}
              apiError={apiError}
              onSubmit={_onUpdate}
              onCancel={() => setIsEditing(false)}
              suggestionContainerRef={suggestionContainerRef}
              commentMentionMembers={commentMentionMembers}
              memberId={memberId}
            />
          </div>
          <div className="flex-none">
            {userId === comment.user?.id && creatableComment && (
              <CommentActionDropdown
                onEdit={() => setIsEditing(true)}
                onDelete={() => {
                  if (deleteCommentThreadConfirm()) {
                    _onDelete()
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
    )
  } else if (href == '' || href == null) {
    return (
      <div className="py-3">
        <div className="flex items-start justify-between">
          <div className="flex-auto space-y-2 min-w-0">
            <div className="flex items-center space-x-2">
              <div className="w-8 h-8">
                <Avatar imgSrc={comment.user?.avatarThumbUrl} />
              </div>
              <div className="text-gray-900 text-sm font-bold">
                {comment.user?.name ? comment.user.name : '削除済みのユーザー'}
              </div>
            </div>
            <div
              className="text-gray-600 text-sm break-words"
              dangerouslySetInnerHTML={{ __html: renderedHtml }}
            />
            <div className="text-gray-500 text-xs space-x-2">
              <span>{dayjs(comment.createdAt).format('YYYY/MM/DD')}</span>
              <span>{dayjs(comment.createdAt).format('HH:mm')}</span>
            </div>
          </div>
          <div className="flex-none">
            {userId === comment.user?.id && creatableComment && (
              <CommentActionDropdown
                onEdit={() => setIsEditing(true)}
                onDelete={() => {
                  if (deleteCommentThreadConfirm()) {
                    _onDelete()
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="py-3">
        <div className="flex items-start justify-between">
          <div className="flex-auto min-w-0">
            <a href={href} className="block hover:bg-gray-100 space-y-2">
              <div className="flex items-center space-x-2">
                <div className="w-8 h-8">
                  <Avatar imgSrc={comment.user?.avatarThumbUrl} />
                </div>
                <div className="text-gray-900 text-sm font-bold">
                  {comment.user?.name
                    ? comment.user.name
                    : '削除済みのユーザー'}
                </div>
              </div>
              <div
                className="text-gray-600 text-sm break-words"
                dangerouslySetInnerHTML={{ __html: renderedHtml }}
              />
              <div className="text-gray-500 text-xs space-x-2">
                <span>{dayjs(comment.createdAt).format('YYYY/MM/DD')}</span>
                <span>{dayjs(comment.createdAt).format('HH:mm')}</span>
              </div>
            </a>
          </div>
          <div className="flex-none">
            {userId === comment.user?.id && creatableComment && (
              <CommentActionDropdown
                onEdit={() => setIsEditing(true)}
                onDelete={() => {
                  if (deleteCommentThreadConfirm()) {
                    _onDelete()
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}
