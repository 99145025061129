import { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useController, useForm } from 'react-hook-form'
import { RiErrorWarningLine } from 'react-icons/ri'

import {
  ApiError,
  CreateReportGeneralCommentPayload,
  createReportGeneralCommentSchema,
} from '@/frontend/api'
import { ApiAlert, Button } from '@/frontend/components'
import { Editor } from './Editor'
import { EditorContext } from './EditorContext'
import { useRemount } from './hooks/useRemount'

type Props = {
  initialValue?: string
  apiError: ApiError | null
  onSubmit: (data: CreateReportGeneralCommentPayload) => Promise<boolean>
  onCancel?: () => void
}

export function GeneralCommentForm({
  initialValue,
  apiError,
  onSubmit,
  onCancel,
}: Props) {
  const { remount, Remount } = useRemount()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, errors },
    reset,
  } = useForm<CreateReportGeneralCommentPayload>({
    resolver: zodResolver(createReportGeneralCommentSchema),
    defaultValues: {
      content: initialValue || '',
      textContent: initialValue || '',
    },
  })

  const submitHandler = handleSubmit(
    async (data: CreateReportGeneralCommentPayload) => {
      const isSuccessed = await onSubmit(data)
      if (isSuccessed) {
        // コンポーネントを再マウントすることでLexicalの値をリセットしている
        // Lexicalの値をリセットする方法が外部から使いにくかったため...
        remount()
      }
    },
  )

  useEffect(() => {
    reset()
  }, [reset, isSubmitSuccessful])

  /**
   * エディタから送信値を取る用
   * */
  const { field: fieldContent } = useController({
    control,
    name: 'content',
  })
  const { field: fieldTextContent } = useController({
    control,
    name: 'textContent',
  })

  return (
    <form onSubmit={submitHandler}>
      {apiError && <ApiAlert apiError={apiError} />}
      <Remount>
        <EditorContext>
          <Editor
            initialValue={initialValue}
            onChangeContent={fieldContent.onChange}
            onChangeTextContent={fieldTextContent.onChange}
          />
        </EditorContext>
      </Remount>
      {errors.content?.message && (
        <div className="flex items-center text-red-500 text-xs">
          <RiErrorWarningLine className="mr-1" />
          <div>{errors.content.message}</div>
        </div>
      )}
      <div className="flex items-center justify-end space-x-2 mt-2">
        <Button
          type="button"
          disabled={isSubmitting}
          variant="outlined"
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button type="submit" disabled={isSubmitting} variant="primary">
          保存
        </Button>
      </div>
    </form>
  )
}
