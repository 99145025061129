import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Listbox, Portal } from '@headlessui/react'
import classNames from 'classnames'
import { groupBy, keyBy, uniqBy } from 'lodash'
import { RiArrowDownSLine } from 'react-icons/ri'
import { usePopper } from 'react-popper'

import { InputCheckBox } from '@/frontend/components'
import { BudgetTag, BudgetTagGroup } from './types'

type Props = {
  budgetTagGroups: BudgetTagGroup[]
  budgetTags: BudgetTag[]
  value: string[]
  onChange: (val: string[]) => void
  invalid?: boolean
}

type SelectedTag =
  | BudgetTag
  | {
      id: 0
      name: '未設定'
    }

const nonTagged = {
  id: 0,
  name: '未設定',
} as const

export function MultiSelect({
  budgetTagGroups,
  budgetTags,
  value,
  onChange,
  invalid,
}: Props) {
  const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(triggerElement, popperElement, {
    placement: 'bottom',
    modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
  })

  const tagById = keyBy(budgetTags, (x) => x.id)
  const defaultSelected = value
    .map((id) => (id === '0' ? nonTagged : tagById[parseInt(id)]))
    .filter((x) => !!x) as BudgetTag[]
  const [selected, _setSelected] = useState<SelectedTag[]>(defaultSelected)

  const setSelected = (tags: SelectedTag[]) => {
    _setSelected(tags)
    onChange(tags.map((tag) => tag.id.toString()))
  }

  const selectedById = keyBy(selected, (x) => x.id)
  // グループ直下のタグ
  const tagsByGroupId = groupBy(budgetTags, (x) => x.budgetTagGroupId)

  const getParentId = (x: BudgetTagGroup) => {
    return x.ancestorIds.length > 0
      ? x.ancestorIds[x.ancestorIds.length - 1]
      : null
  }
  const groupsByParentId = groupBy(budgetTagGroups, getParentId)

  const rootGroups = budgetTagGroups.filter((group) => !getParentId(group))
  const getDescendantGroups = useCallback(
    (group: BudgetTagGroup) => {
      let groups: BudgetTagGroup[] = []
      const childGroups = groupsByParentId[group.id] || []
      childGroups.forEach((child) => {
        groups = [...groups, child, ...getDescendantGroups(child)]
      })
      return groups
    },
    [groupsByParentId],
  )

  const sortedGroups = useMemo(() => {
    let groups: BudgetTagGroup[] = []
    rootGroups.forEach((group) => {
      groups = [...groups, group, ...getDescendantGroups(group)]
    })
    return groups
  }, [rootGroups, getDescendantGroups])

  const getDescendantTags = useCallback(
    (group: BudgetTagGroup) => {
      let descendantTags: BudgetTag[] = []
      const childGroups = groupsByParentId[group.id] || []
      childGroups.forEach((childGroup) => {
        descendantTags = [...descendantTags, ...getDescendantTags(childGroup)]
      })
      const tags = tagsByGroupId[group.id] || []

      return [...descendantTags, ...tags]
    },
    [groupsByParentId, tagsByGroupId],
  )

  // 子孫全部のタグ
  const descendantTagsByGroupId = useMemo(() => {
    let map: Record<number, BudgetTag[]> = {}
    budgetTagGroups.forEach((group) => {
      map = { ...map, [group.id]: getDescendantTags(group) }
    })
    return map
  }, [budgetTagGroups, getDescendantTags])
  const getTagsByGroupId = (groupId: number) => {
    return tagsByGroupId[groupId] || []
  }
  const ungroupedTags = budgetTags.filter((x) => !x.budgetTagGroupId)

  const onGroupChange = (group: BudgetTagGroup, val: boolean) => {
    const tags = descendantTagsByGroupId[group.id] || []
    if (val) {
      const updated = uniqBy([...selected, ...tags], (x) => x.id)
      setSelected(updated)
    } else {
      const tagIds = tags.map((x) => x.id)
      const updated = selected.filter((x) => !tagIds.includes(x.id))
      setSelected(updated)
    }
  }

  const isGroupChecked = (group: BudgetTagGroup) => {
    const tags = descendantTagsByGroupId[group.id] || []

    return tags.every((tag) => !!selectedById[tag.id])
  }

  const isGroupIndeterminate = (group: BudgetTagGroup) => {
    const tags = descendantTagsByGroupId[group.id] || []
    return !isGroupChecked(group) && tags.some((tag) => !!selectedById[tag.id])
  }

  const baseClassName =
    'relative text-left leading-6 w-full py-1 pl-3 pr-10 border border-gray-300 rounded-md block text-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 focus:outline-none transition duration-150 ease-in-out'
  const validClassName =
    'border-gray-300 focus:ring-blue-200 focus:border-blue-300'
  const invalidClassName =
    'border-red-300 focus:ring-red-200 focus:border-red-300'
  const className = classNames({
    [baseClassName]: true,
    [validClassName]: !invalid,
    [invalidClassName]: invalid,
  })

  const indentStyle = (indent: number) => {
    return {
      paddingLeft: `${1.5 * indent}rem`,
    }
  }

  const isAllChecked = selected.length === budgetTags.length + 1
  const isIndeterminate = selected.length > 0 && !isAllChecked

  const onBulkChange = (val: boolean) => {
    if (val) {
      setSelected([...budgetTags, nonTagged])
    } else {
      setSelected([])
    }
  }

  return (
    <Listbox value={selected} onChange={setSelected} multiple>
      <div className="relative">
        <Listbox.Button ref={setTriggerElement} className={className}>
          <span className="block truncate">
            {selected.length > 0
              ? selected.map((x) => x.name).join(', ')
              : '選択してください'}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <RiArrowDownSLine className="h-4 w-4 text-gray-500" />
          </span>
        </Listbox.Button>
        <Portal>
          <Listbox.Options
            ref={setPopperElement}
            className="z-30 absolute mt-1 max-h-96 w-80 overflow-auto rounded-md bg-white p-4 shadow-lg ring-1 ring-black ring-opacity-5 outline-none text-sm"
            style={styles.popper}
            {...attributes.popper}
          >
            <div className="flex items-center mb-2 space-x-2">
              <BulkCheckBox
                indeterminate={isIndeterminate}
                checked={isAllChecked}
                onChange={(val: boolean) => onBulkChange(val)}
              />
              <div className="text-gray-700 font-bold">すべて</div>
            </div>
            {sortedGroups.map((group) => {
              const indent = group.ancestorIds.length
              return (
                <div key={group.id}>
                  <div
                    className="flex items-center py-0.5 space-x-2 border-b border-gray-200"
                    style={indentStyle(indent)}
                  >
                    <BulkCheckBox
                      indeterminate={isGroupIndeterminate(group)}
                      checked={isGroupChecked(group)}
                      onChange={(val: boolean) => onGroupChange(group, val)}
                    />
                    <div className="text-gray-700 font-bold">{group.name}</div>
                  </div>
                  {getTagsByGroupId(group.id).map((tag) => (
                    <div
                      key={tag.id}
                      className="flex items-center justify-between py-0.5 space-x-2 border-b border-gray-200"
                      style={indentStyle(indent + 1)}
                    >
                      <div className="text-gray-900">{tag.name}</div>
                      <Listbox.Option
                        value={tag}
                        as="div"
                        className="flex-none flex items-center"
                      >
                        {({ selected }) => (
                          <InputCheckBox checked={selected} readOnly />
                        )}
                      </Listbox.Option>
                    </div>
                  ))}
                </div>
              )
            })}
            {ungroupedTags.map((tag) => (
              <div
                key={tag.id}
                className="flex items-center justify-between py-0.5 space-x-2 border-b border-gray-200"
                style={indentStyle(0)}
              >
                <div className="text-gray-900">{tag.name}</div>
                <Listbox.Option
                  value={tag}
                  as="div"
                  className="flex-none flex items-center"
                >
                  {({ selected }) => (
                    <InputCheckBox checked={selected} readOnly />
                  )}
                </Listbox.Option>
              </div>
            ))}
            <div
              className="flex items-center justify-between py-0.5 space-x-2 border-b border-gray-200"
              style={indentStyle(0)}
            >
              <div className="text-gray-900">{nonTagged.name}</div>
              <Listbox.Option
                value={nonTagged}
                as="div"
                className="flex-none flex items-center"
              >
                {({ selected }) => (
                  <InputCheckBox checked={selected} readOnly />
                )}
              </Listbox.Option>
            </div>
          </Listbox.Options>
        </Portal>
      </div>
    </Listbox>
  )
}

function BulkCheckBox({
  indeterminate,
  checked,
  onChange,
}: {
  indeterminate: boolean
  checked: boolean
  onChange: (val: boolean) => void
}) {
  const cRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (cRef.current) {
      cRef.current.indeterminate = indeterminate
    }
  }, [cRef, indeterminate])

  return (
    <InputCheckBox
      ref={cRef}
      checked={checked}
      onChange={() => onChange(!checked)}
    />
  )
}
