import { RiQuestionLine } from 'react-icons/ri'

import { BudgetTagCategory, Org, TransitionTableImport } from '@/frontend/api'
import { ApiAlert, Button, Tooltip } from '@/frontend/components'
import { SectionTitle } from '@/frontend/features/importers/components/SectionTitle'
import { useActiveTransitionTableImport } from '../hooks/useActiveTransitionTableImport'
import { useScrollControl } from '../hooks/useScrollControl'
import { useUpdateTransitionTableImport } from '../hooks/useUpdateTransitionTableImport'
import TransitionTableImportFormContainer from './TransitionTableImportFormContainer'

type Props = {
  org: Org
  primaryBudgetTagCategory: BudgetTagCategory | null
}

export default function TransitionTableImportContainer({
  org,
  primaryBudgetTagCategory,
}: Props) {
  const { data: transitionTableImport, refetch } =
    useActiveTransitionTableImport(org)
  const { targetRef, scrollToRefWithDelay } = useScrollControl()

  return (
    <div className="space-y-4 scroll-mt-16" ref={targetRef}>
      <SectionTitle>推移表インポート</SectionTitle>
      <div className="space-y-4">
        <div>
          <p>
            推移表形式のCSVファイルを取り込みます。複数のCSVファイルがある場合は、すべて同じフォーマットでアップロードしてください。
          </p>
          <p>
            ドロップエリアに複数でアップロードしたファイルは、下のエリアでタグ名に対応して自動割当されます。
          </p>
        </div>

        {transitionTableImport && (
          <>
            <CompleteButton
              org={org}
              transitionTableImport={transitionTableImport}
              onClick={refetch}
              scrollToRefWithDelay={scrollToRefWithDelay}
            />
            <TransitionTableImportFormContainer
              org={org}
              transitionTableImport={transitionTableImport}
              primaryBudgetTagCategory={primaryBudgetTagCategory}
            />
          </>
        )}
      </div>
    </div>
  )
}

function CompleteButton({
  org,
  transitionTableImport,
  onClick,
  scrollToRefWithDelay,
}: {
  org: Org
  transitionTableImport: TransitionTableImport
  onClick: () => void
  scrollToRefWithDelay: () => void
}) {
  const { submit, isSubmitting, apiError } = useUpdateTransitionTableImport(
    org,
    transitionTableImport,
  )

  const handleOnClick = async () => {
    await submit({ active: false })
    onClick()
    scrollToRefWithDelay()
  }

  return (
    <>
      <div className="flex justify-end">
        <Button
          type="submit"
          variant="secondary"
          onClick={handleOnClick}
          loading={isSubmitting}
        >
          インポートを完了する
        </Button>
        <span className="font-bold py-1">
          <Tooltip content="ステータスが「インポート完了」「インポート失敗」「未インポート」のファイルをすべて削除することができます。">
            {(ref) => (
              <span ref={ref} className="ml-1 text-lg">
                <RiQuestionLine className="inline" />
              </span>
            )}
          </Tooltip>
        </span>
      </div>
      {apiError && <ApiAlert apiError={apiError} />}
    </>
  )
}
