import { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { ApiError, JobLog, Org } from '@/frontend/api'
import {
  BugyoCloudApiImportPayload,
  bugyoCloudApiImportSchema,
} from '@/frontend/api/importer'
import { ApiAlert, Button } from '@/frontend/components'
import PeriodInputs from '../components/forms/PeriodInputs'
import TagCategoriesSelect from '../components/forms/TagCategoriesSelect'
import { ImportStatus } from '../components/ImportStatus'
import JobLogLink from '../components/JobLogLink'
import LastImportedAt from '../components/LastImportedAt'
import { getTagCategories } from '../getTagCategories'
import { AlertStatus } from '../hooks/useAlertStatus'
import { useImportSettingContext } from '../hooks/useImportSettingContext'

type Props = {
  org: Org
  alertStatus: AlertStatus | null
  onSubmit: (data: BugyoCloudApiImportPayload) => void
  isLoading: boolean
  apiError?: ApiError
  initialValues?: Omit<BugyoCloudApiImportPayload, 'budgetId'>
  lastApiImportJobLog: JobLog | null
}

export default function ApiImportForm({
  org,
  alertStatus,
  onSubmit,
  isLoading,
  apiError,
  initialValues,
  lastApiImportJobLog,
}: Props) {
  const { importSetting } = useImportSettingContext()
  const formMethods = useForm<BugyoCloudApiImportPayload>({
    mode: 'onChange',
    resolver: zodResolver(bugyoCloudApiImportSchema),
    defaultValues: {
      budgetId: Number(importSetting.budgetId),
      firstDate: initialValues?.firstDate || '',
      lastDate: initialValues?.lastDate || '',
      tagCategories: initialValues?.tagCategories || ['department'],
    },
  })

  const { handleSubmit, control, setValue } = formMethods

  const _onSubmit = async (data: BugyoCloudApiImportPayload) => {
    onSubmit(data)
  }

  useEffect(() => {
    setValue('budgetId', Number(importSetting.budgetId))
  }, [importSetting, setValue])

  return (
    <form onSubmit={handleSubmit(_onSubmit)}>
      <div className="space-y-2">
        <div className="w-5/12">
          <PeriodInputs control={control} disabled={isLoading} />
        </div>
        <div className="w-1/5">
          <TagCategoriesSelect
            control={control}
            disabled={isLoading}
            initialValues={initialValues?.tagCategories || ['department']}
            categories={getTagCategories('bugyo_cloud')}
          />
        </div>
      </div>
      {apiError && (
        <div className="py-4">
          <ApiAlert apiError={apiError} />
        </div>
      )}
      <div className="pt-4">
        <div className="pb-4">
          {alertStatus && (
            <ImportStatus
              status={alertStatus.status}
              errorDetail={alertStatus.errorDetail}
            />
          )}
        </div>
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <LastImportedAt lastImportedAt={lastApiImportJobLog?.createdAt} />
            <JobLogLink org={org} />
          </div>
          <div className="mt-auto">
            <Button type="submit" variant="primary" loading={isLoading}>
              インポート
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}
