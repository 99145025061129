import { useCallback, useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { FileRejection, FileWithPath } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { RiQuestionLine } from 'react-icons/ri'

import { ApiError, Org } from '@/frontend/api'
import {
  BugyoICsvJournalImportPayload,
  bugyoICsvJournalImportSchema,
} from '@/frontend/api/importer'
import { ApiAlert, InputGroup, Tooltip } from '@/frontend/components'
import { Alert } from '@/frontend/components/Alert/Alert'
import { Button } from '@/frontend/components/buttons/Button'
import Dropzone from '../components/Dropzone'
import PeriodInputs from '../components/forms/PeriodInputs'
import { ImportStatus } from '../components/ImportStatus'
import JobLogLink from '../components/JobLogLink'
import LastImportedAt from '../components/LastImportedAt'
import { LastImportStatus } from '../components/LastImportStatus'
import { AlertStatus } from '../hooks/useAlertStatus'
import { useImportSettingContext } from '../hooks/useImportSettingContext'
import { ImportFileStatus, JobLogWithAttachedFile } from '../types'

type CsvFileField = 'journalCsvFile' | 'openingBalanceCsvFile'

type Props = {
  initialValues?: Pick<BugyoICsvJournalImportPayload, 'firstDate' | 'lastDate'>
  importFileStatus: ImportFileStatus | null
  onFileChange: (
    journalCsvFile: FileWithPath | null,
    openingBalanceCsvFile: FileWithPath | null,
  ) => void
  onSubmit: (data: BugyoICsvJournalImportPayload) => void
  isLoading: boolean
  isJournalImportable: boolean
  apiError?: ApiError
  alertStatus: AlertStatus | null
  jobLogWithAttachedFile: JobLogWithAttachedFile | null
  org: Org
}

export default function JournalImportForm({
  initialValues,
  importFileStatus,
  onFileChange,
  onSubmit,
  isLoading,
  isJournalImportable,
  apiError,
  alertStatus,
  jobLogWithAttachedFile,
  org,
}: Props) {
  const { importSetting } = useImportSettingContext()
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([])
  const { control, setValue, getValues, watch, handleSubmit } =
    useForm<BugyoICsvJournalImportPayload>({
      resolver: zodResolver(bugyoICsvJournalImportSchema),
      defaultValues: {
        budgetId: Number(importSetting.budgetId),
        firstDate: initialValues?.firstDate || '',
        lastDate: initialValues?.lastDate || '',
        journalCsvFile: null,
        openingBalanceCsvFile: null,
      },
    })
  const journalCsvFile = watch('journalCsvFile')
  const _onSubmit = handleSubmit((data) => {
    onSubmit(data)
    setValue('journalCsvFile', null)
  })

  const generateOnDrop = useCallback(
    (name: CsvFileField) =>
      (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
        if (acceptedFiles.length <= 0) return
        setValue(name, acceptedFiles[0] as FileWithPath)

        const { journalCsvFile, openingBalanceCsvFile } = getValues()
        onFileChange(journalCsvFile, openingBalanceCsvFile)
        setFileRejections(fileRejections)
      },
    [],
  )

  const onDropJournalCsv = useCallback(generateOnDrop('journalCsvFile'), [])

  const onDropOpeningBalanceCsv = useCallback(
    generateOnDrop('openingBalanceCsvFile'),
    [],
  )

  const errorMessage =
    fileRejections.length > 0 ? 'CSVファイルを選択してください' : ''

  useEffect(() => {
    setValue('budgetId', Number(importSetting.budgetId))
  }, [importSetting, setValue])

  return (
    <form onSubmit={_onSubmit}>
      <div className="space-y-4">
        <div className="w-5/12">
          <PeriodInputs control={control} disabled={isLoading} />
        </div>
        {errorMessage && <Alert title={errorMessage} />}
        {apiError && <ApiAlert apiError={apiError} />}

        <InputGroup label={<span className="font-bold">仕訳帳</span>}>
          {() => <Dropzone onDrop={onDropJournalCsv} disabled={isLoading} />}
        </InputGroup>
        <InputGroup
          label={
            <span className="font-bold">
              開始残高
              <Tooltip content="初めて仕訳帳をインポートする場合や、期首月を含む仕訳帳をインポートする場合は、開始残高を同時にインポートしてください。">
                {(ref) => (
                  <span ref={ref} className="ml-1 text-lg">
                    <RiQuestionLine className="inline" />
                  </span>
                )}
              </Tooltip>
            </span>
          }
          optional={true}
        >
          {() => (
            <Dropzone onDrop={onDropOpeningBalanceCsv} disabled={isLoading} />
          )}
        </InputGroup>
        {importFileStatus && (
          <LastImportStatus
            importFileStatus={importFileStatus}
            loading={isLoading}
          />
        )}
        {alertStatus && (
          <ImportStatus
            status={alertStatus.status}
            errorDetail={alertStatus.errorDetail}
          />
        )}
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <LastImportedAt
              lastImportedAt={jobLogWithAttachedFile?.createdAt}
            />
            <JobLogLink org={org} />
          </div>
          <div className="mt-auto">
            <Button
              type="submit"
              variant="primary"
              loading={isLoading}
              disabled={journalCsvFile == null || !isJournalImportable}
            >
              インポート
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}
