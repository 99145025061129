import { Button } from '@/frontend/components'

export function NonFinancialItemSlideOverFormActions({
  disabled,
  loading,
  onClose,
}: {
  disabled?: boolean
  loading?: boolean
  onClose: () => void
}) {
  return (
    <>
      <Button variant="outlined" onClick={onClose}>
        キャンセル
      </Button>
      <Button
        type="submit"
        variant="primary"
        disabled={disabled}
        loading={loading}
      >
        保存
      </Button>
    </>
  )
}
