import { Transition } from '@headlessui/react'

type Props = {
  appear?: boolean
  show?: boolean
  children: React.ReactNode
}

export function FadeInOut({ appear = false, show = true, children }: Props) {
  return (
    <Transition
      appear={appear}
      show={show}
      enter="duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {children}
    </Transition>
  )
}
