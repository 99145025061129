import { useState } from 'react'

import { JobLogErrorDetail, JobLogStatus } from '@/frontend/api'
import { Alert } from '@/frontend/components/Alert'
import JobLogErrorDetailModal from './JobLogErrorDetailModal'

type Props = {
  status: JobLogStatus
  errorDetail?: JobLogErrorDetail
  successMessage: string
  failedMessage: string
}

export const JobLogStatusAlert = ({
  status,
  errorDetail,
  successMessage,
  failedMessage,
}: Props) => {
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false)

  switch (status) {
    case 'succeeded':
      return <Alert variant="success" title={successMessage} />
    case 'failed':
      return (
        errorDetail && (
          <Alert
            variant="error"
            title={
              <>
                {failedMessage}
                <a
                  className="text-blue-600 cursor-pointer"
                  onClick={() => setIsOpenErrorModal(true)}
                >
                  原因を表示
                </a>
                <JobLogErrorDetailModal
                  isOpen={isOpenErrorModal}
                  onClose={() => setIsOpenErrorModal(false)}
                  errorDetail={errorDetail}
                />
              </>
            }
          />
        )
      )
  }
}
