import { Fragment } from 'react'

import { Select } from '@/frontend/components'
import { ReservedItem } from './types'

const Type = {
  RESERVED_ITEM: 'ReservedItem',
} as const

type OptionItem = {
  id?: string
  type?: string
}

type Props = {
  reservedItems: ReservedItem[]
  value: OptionItem | undefined
  onChange: (value: OptionItem) => void
  invalid?: boolean
}

export function ReservedItemSelect({
  reservedItems,
  value,
  onChange,
  invalid,
}: Props) {
  const toOptionValue = (item: OptionItem | undefined) => {
    if (!item) return

    return `${item.type}_${item.id}`
  }

  const toOptionItem = (optionValue: string): OptionItem => {
    const splitOptionValue = optionValue.split('_')
    const id = splitOptionValue.pop()
    const type = splitOptionValue.join('_')
    return {
      id: id || '',
      type: type,
    }
  }

  const selectedItem = toOptionValue(value)

  const setSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(toOptionItem(e.target.value))
  }

  return (
    <div>
      <Select invalid={invalid} onChange={setSelected} value={selectedItem}>
        <option value="">選択してください</option>
        {reservedItems.map((reservedItem) => {
          return (
            <Fragment key={reservedItem.id}>
              <option
                value={toOptionValue({
                  id: reservedItem.id.toString(),
                  type: Type.RESERVED_ITEM,
                })}
              >
                {reservedItem.name}
              </option>
            </Fragment>
          )
        })}
      </Select>
    </div>
  )
}
