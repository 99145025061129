import { Popover, Transition } from '@headlessui/react'
import { RiArrowDownSLine } from 'react-icons/ri'

import {
  DisplaySubject,
  Form,
  LandingEstimationForm,
  NormalForm,
} from '../type'

type Props = {
  label: string
  displaySubjects: DisplaySubject[]
  form: Form
  onChange: (form: Form) => void
}

function formToButtonLabel(
  form: Form,
  displaySubjects: DisplaySubject[],
): string {
  switch (form.type) {
    case 'normal': {
      const selected = findDisplaySubject(displaySubjects, form.normal.id)
      return selected ? selected.name : '選択してください'
    }
    case 'landing_estimation': {
      const selected = findDisplaySubject(
        displaySubjects,
        form.landingEstimation.id,
      )
      const label = selected ? selected.name : '選択してください'
      return `実績 + ${label}`
    }
    default: {
      throw new Error('Unexpected')
    }
  }
}

function findDisplaySubject(
  displaySubjects: DisplaySubject[],
  id: string,
): DisplaySubject | undefined {
  return displaySubjects.find((displaySubject) => displaySubject.id === id)
}

function toLeftBorderLabel(form: Form): string {
  if (form.landingEstimation.borderDate) {
    const borderDate = new Date(form.landingEstimation.borderDate)
    const leftBorderDate = new Date(
      borderDate.getFullYear(),
      borderDate.getMonth() - 1,
    )
    const y = leftBorderDate.getFullYear()
    const m = (leftBorderDate.getMonth() + 1).toString().padStart(2, '0')
    return `${y}年${m}月`
  } else {
    return '----年--月'
  }
}

export default function BudgetDropdown({
  label,
  displaySubjects,
  form,
  onChange,
}: Props) {
  const handleChange = (v: Partial<Form>) => {
    onChange({ ...form, ...v })
  }

  const handleNormalChange = (v: Partial<NormalForm>) => {
    const normal = { ...form.normal, ...v }
    handleChange({ normal })
  }

  const handleLandingEstimationChange = (v: Partial<LandingEstimationForm>) => {
    const landingEstimation = { ...form.landingEstimation, ...v }
    handleChange({ landingEstimation })
  }

  const buttonLabel = formToButtonLabel(form, displaySubjects)
  return (
    <div>
      <label className="btn-select-label">{label}</label>
      <Popover className="relative">
        <div className="btn-wrapper">
          <Popover.Button
            className="btn btn-select rounded"
            style={{ minWidth: '8rem' }}
          >
            <span>
              {buttonLabel}
              <div className="btn-select-caret">
                <RiArrowDownSLine />
              </div>
            </span>
          </Popover.Button>
        </div>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          className="absolute z-50 mt-1"
          style={{ width: '44rem' }}
        >
          <Popover.Panel>
            <div className="simple-dropdown">
              <div className="simple-dropdown-content">
                <div className="relative bg-white rounded-md -space-y-px">
                  <label className="rounded-tl-md rounded-tr-md relative border p-4 flex cursor-pointer md:pl-4 md:pr-6 focus:outline-none">
                    <div className="flex items-center text-sm">
                      <input
                        className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-gray-900"
                        name="budget_type"
                        value="normal"
                        type="radio"
                        onChange={(e) => handleChange({ type: e.target.value })}
                        checked={form.type === 'normal'}
                        data-custom-report-budget-type
                      />
                    </div>
                    <div className="ml-6 text-sm">
                      <span className="font-medium">
                        <select
                          value={form.normal.id}
                          onChange={(e) =>
                            handleNormalChange({ id: e.target.value })
                          }
                          disabled={form.type !== 'normal'}
                          className="block form-select w-40 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          data-custom-report-version-id
                        >
                          {displaySubjects.map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                        </select>
                      </span>
                    </div>
                  </label>
                  <label
                    className={`rounded-bl-md rounded-br-md relative border p-2 flex cursor-pointer md:pl-4 md:pr-6 focus:outline-none ${
                      form.type !== 'landing_estimation'
                        ? 'text-gray-300'
                        : 'text-gray-900'
                    }`}
                  >
                    <div className="flex items-center text-sm">
                      <input
                        className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-gray-900"
                        name="budget_type"
                        value="landing_estimation"
                        type="radio"
                        onChange={(e) => handleChange({ type: e.target.value })}
                        checked={form.type === 'landing_estimation'}
                        data-custom-report-budget-type
                      />
                    </div>
                    <div className="ml-6 flex items-center bg-gray-100 p-3 py-2 font-medium text-sm">
                      <span>実績</span>
                      <span className="ml-4">~</span>
                      <span className="ml-2">{toLeftBorderLabel(form)}</span>
                    </div>
                    <div className="ml-3 flex items-center">+</div>
                    <div className="flex ml-3 pl-1">
                      <div className="flex bg-gray-100 p-3 py-2 font-medium">
                        <select
                          value={form.landingEstimation.id}
                          onChange={(e) =>
                            handleLandingEstimationChange({
                              id: e.target.value,
                            })
                          }
                          className="block form-select w-40 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          disabled={form.type !== 'landing_estimation'}
                          data-custom-report-budget-id
                        >
                          {displaySubjects
                            .filter((displaySubject) => !displaySubject.result)
                            .map((budget) => {
                              return (
                                <option key={budget.id} value={budget.id}>
                                  {budget.name}
                                </option>
                              )
                            })}
                        </select>
                        <input
                          className="ml-3 block form-select w-40 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          type="month"
                          value={form.landingEstimation.borderDate}
                          onChange={(e) =>
                            handleLandingEstimationChange({
                              borderDate: e.target.value,
                            })
                          }
                          disabled={form.type !== 'landing_estimation'}
                          data-custom-report-border-date
                        />
                        <div className="ml-2 flex items-center">~</div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  )
}
