import { forwardRef, useRef, useState } from 'react'
import { tv } from 'tailwind-variants'

import { useMergeRefs } from '@/frontend/hooks'

type Props = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'type' | 'className'
> & {
  invalid?: boolean
}

const button = tv({
  base: [
    'flex-shrink-0',
    'px-4 py-2 ring-inset rounded-md text-sm leading-5 border-0 ring-1 ring-gray-800 text-gray-800 bg-white shadow-sm',
    'transition duration-150 ease-in-out',
    'hover:text-blue-600',
    'active:text-blue-600 active:bg-gray-100',
    'focus:outline focus:outline-blue-100 focus:outline-offset-0 focus:outline-[3px]',
  ].join(' '),
  variants: {
    invalid: {
      true: 'outline outline-red-50 outline-offset-0 outline-[3px]',
    },
    disabled: {
      true: [
        'shadow-none ring-0 text-gray-400 bg-gray-100 pointer-events-none',
      ].join(' '),
    },
  },
})

export const InputFile = forwardRef<HTMLInputElement, Props>(function Input(
  { invalid = false, onChange, ...props },
  ref,
) {
  const inputRef = useRef<HTMLInputElement>(null)
  const mergedRef = useMergeRefs(ref, inputRef)
  const [fileName, setFileName] = useState<string | null>(null)
  const handleClick = () => inputRef.current?.click()

  return (
    <div className="max-w-full inline-flex items-center justify-center space-x-4">
      <button
        className={button({ invalid, disabled: props.disabled })}
        onClick={handleClick}
      >
        ファイルを選択
      </button>
      {!props.disabled && (
        <span className="min-w-0 text-sm leading-5 text-gray-800 truncate">
          {fileName ?? '選択されていません'}
        </span>
      )}
      <input
        ref={mergedRef}
        className="hidden"
        type="file"
        onChange={(e) => {
          onChange?.(e)
          setFileName(e.target.files?.[0]?.name ?? null)
        }}
        {...props}
      />
    </div>
  )
})
