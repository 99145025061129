import { JobLogErrorDetail, JobLogStatus } from '@/frontend/api'
import { JobLogStatusAlert } from '../JobLogStatusAlert'

type Props = {
  status: JobLogStatus
  errorDetail?: JobLogErrorDetail
}

export const JournalUpdateStatus = ({ status, errorDetail }: Props) => {
  return (
    <JobLogStatusAlert
      status={status}
      errorDetail={errorDetail}
      successMessage="実績の反映が完了しました。"
      failedMessage="実績の反映が失敗しました。"
    />
  )
}
