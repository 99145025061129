import { RiExternalLinkLine } from 'react-icons/ri'

import { Org } from '@/frontend/api/types'

type Props = {
  org: Org
  children: React.ReactNode
}

export default function TagCategoryLink({ org, children }: Props) {
  return (
    <a
      href={`/orgs/${org.id}/tag_categories`}
      target="_blank"
      rel="noreferrer"
      className="text-blue-600 inline-flex flex-row items-center"
    >
      {children}
      <RiExternalLinkLine />
    </a>
  )
}
