import { RiFileCopy2Fill } from 'react-icons/ri'

import Dropzone from '../Dropzone'

type Props = {
  onDrop: (files: File[]) => void
  disabled: boolean
}

export default function TransitionTableDropzone({ onDrop, disabled }: Props) {
  return (
    <Dropzone
      onDrop={onDrop}
      disabled={disabled}
      icon={<RiFileCopy2Fill className="text-gray-500 text-4xl" />}
      message="フォルダまたは複数ファイルをドロップ"
    />
  )
}
