import {
  RiAlertFill,
  RiCheckboxCircleFill,
  RiErrorWarningFill,
  RiInformationFill,
} from 'react-icons/ri'
import { tv } from 'tailwind-variants'

type Props = {
  variant: 'info' | 'error' | 'success' | 'warning'
}

const icon = tv({
  base: 'h-5 w-5',
})

export function AlertIcon({ variant }: Props) {
  switch (variant) {
    case 'info':
      return <RiInformationFill className={icon()} />
    case 'error':
      return <RiErrorWarningFill className={icon()} />
    case 'success':
      return <RiCheckboxCircleFill className={icon()} />
    case 'warning':
      return <RiAlertFill className={icon()} />
  }
}
