import { useLayoutEffect, useMemo, useState } from 'react'
import { BeautifulMentionsPlugin } from 'lexical-beautiful-mentions'

import useMutationObserver from './hooks/useMutationObserver'
import useResizeObserver from './hooks/useResizeObserver'
import { MentionsPluginProps } from './MentionsPluginProps'
import { Menu, MenuItem } from './Menu'

/**
 * メンションサジェストの位置を計算して適用する
 */
function calculateAndApplyMenuPosition(typeaheadElm: HTMLElement) {
  const viewportHeight = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0,
  )
  const typeaheadParent = typeaheadElm.offsetParent ?? document.body
  const typeaheadParentRect = typeaheadParent.getBoundingClientRect()
  const diffAdjustY = window.pageYOffset + typeaheadParentRect.top
  const diffAdjustX = window.pageXOffset + typeaheadParentRect.left

  typeaheadElm.style.transform = `translate(${-diffAdjustX}px, ${-diffAdjustY}px)`

  // メンションサジェストが画面外にはみ出している場合は、はみ出さないようにいいかんじにする
  const menuElement = typeaheadElm.firstChild as HTMLElement
  if (menuElement) {
    menuElement.style.top = ''
    const menuRect = menuElement?.getBoundingClientRect()

    if (menuRect && viewportHeight < menuRect.top + menuRect.height) {
      menuElement.style.top = `${-menuRect.height}px`
    }
    if (menuRect && 0 > menuRect.top) {
      menuElement.style.top = ''
    }
  }
}

export function MentionsPlugin<T extends HTMLElement>({
  mentionItems,
  suggestionContainerRef,
}: MentionsPluginProps<T>) {
  const mentionDict = useMemo(() => {
    return { '@': [{ id: 'all', value: 'すべてのメンバー' }, ...mentionItems] }
  }, [mentionItems])
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null)

  /*
   * FIXME:
   *   超むりやりな実装でPopoverが閉じないようにしています!
   *   なんとかしたいけどメンション機能を一からつくる(地獄へようこそ)以外思いつかない X(
   *   ライブラリのVar upに期待
   *
   * サジェストにフォーカスがあったたときにPopoverが閉じないようにするための処理(むりやり)
   * (追加されたTypeaheadMenuをcontainerRefに移動する)
   *   → Popoverの子として認識されるので閉じない!! やったぜ。
   */
  const handleTypeaheadElement = (mutations: MutationRecord[]) => {
    for (const mutate of mutations) {
      if (mutate.type === 'childList') {
        mutate.addedNodes.forEach((node) => {
          if (node instanceof HTMLElement) {
            if (node.id === 'typeahead-menu') {
              suggestionContainerRef.current?.appendChild(node)
              setMenuAnchor(node)
            }
          }
        })
      }
    }
  }

  // メンションサジェストが追加されたら、Popoverの子要素にする
  useMutationObserver([document.body], handleTypeaheadElement, {
    childList: true,
  })

  // メンションサジェストの位置の計算用(初回)
  useLayoutEffect(() => {
    if (menuAnchor) calculateAndApplyMenuPosition(menuAnchor)
  }, [menuAnchor])

  // メンションサジェストの位置の計算用(サジェストが更新されたら再計算)
  useMutationObserver(
    [menuAnchor],
    (mut) => {
      for (const _ of mut) {
        if (menuAnchor) {
          calculateAndApplyMenuPosition(menuAnchor)
          break
        }
      }
    },
    { attributes: true },
    menuAnchor,
  )

  // メンションサジェストの位置の計算用(Popover要素がリサイズまたは移動したら再計算)
  useResizeObserver(
    [menuAnchor?.offsetParent ?? null],
    (_) => {
      if (menuAnchor) {
        calculateAndApplyMenuPosition(menuAnchor)
      }
    },
    menuAnchor,
  )

  return (
    <BeautifulMentionsPlugin
      items={mentionDict}
      // onSearch={handleSearch}
      // searchDelay={asynchronous ? 250 : 0}
      // triggers={triggers}
      // mentionEnclosure={mentionEnclosure}
      // allowSpaces={allowSpaces}
      // creatable={creatable}
      // insertOnBlur={insertOnBlur}
      // showMentionsOnDelete={showMentionsOnDelete}
      menuComponent={Menu}
      menuItemComponent={MenuItem}
    />
  )
}
