import { FileRejection, useDropzone } from 'react-dropzone'
import { RiFileList2Fill, RiUploadLine } from 'react-icons/ri'

type Props = {
  onDrop: (files: File[], fileRejections: FileRejection[]) => void
  fileName?: string
}

export default function TransitionTableAssignedRow({
  onDrop,
  fileName,
}: Props) {
  const { open } = useDropzone({
    onDrop,
    accept: { 'text/csv': ['.csv'] },
    noClick: true,
  })

  return (
    <div className="p-0.5 h-14 flex w-full items-center">
      <div className="basis-5/6">
        <div className="flex flex-row items-center space-x-2 justify-start">
          <RiFileList2Fill className="text-2xl" />
          <span>{fileName}</span>
        </div>
      </div>
      <div className="basis-1/6">
        <button
          type="button"
          className=" p-2 border border-gray-400 rounded text-gray-800 bg-white hover:text-blue-600 ml-2"
          onClick={open}
        >
          <RiUploadLine className="text-sm" />
        </button>
      </div>
    </div>
  )
}
