import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { ApiError, Org } from '@/frontend/api'
import { apiTransitionTableItemsJournalImport } from '@/frontend/api/importers/apiTransitionTableItemsJournalImport'
import {
  ApiAlert,
  Button,
  ButtonLink,
  Card as Section,
} from '@/frontend/components'
import { useNavigate } from '@/frontend/hooks/useNavigate'
import { getTransitionTableImportSettingUrl } from '../getTransitionTableImportSettingUrl'
import ColumnMappingForm from './ColumnMappingForm'
import RowMappingForm from './RowMappingForm'
import {
  AccountItem,
  AccountItemMapping,
  CsvAccountItem,
  CsvHeader,
  MappingFormProps,
  MonthlyMapping,
} from './types'

type Props = {
  org: Org
  formAuthenticityToken: string
  budgetId: number
  transitionTableImportId: number
  initialAccountItemMappings: AccountItemMapping[]
  initialMonthlyMappings: MonthlyMapping[]
  accountItems: AccountItem[]
  csvAccountItems: CsvAccountItem[]
  csvHeaders: CsvHeader[]
  headerRowNumber: number
  accountItemColumnName: string
}

export default function MappingFormContainer(props: Props) {
  const accountItemMappingsWithKey = addKeyToProperty(
    props.initialAccountItemMappings,
    'csvAccountItem',
  )
  const csvAccountItemsWithKey = props.csvAccountItems.map(addKeyForRHF)
  const monthlyMappingsWithKey = addKeyToProperty(
    props.initialMonthlyMappings,
    'csvHeader',
  )
  const csvHeadersWithKey = props.csvHeaders.map(addKeyForRHF)

  const methods = useForm<MappingFormProps>({
    defaultValues: {
      budgetId: props.budgetId,
      transitionTableImportId: props.transitionTableImportId,
      headerRowNumber: props.headerRowNumber,
      accountItemColumnName: props.accountItemColumnName,
      accountItemMappings: accountItemMappingsWithKey as AccountItemMapping[],
      monthlyMappings: monthlyMappingsWithKey as MonthlyMapping[],
    },
  })

  const { navigate } = useNavigate()
  const [apiError, setApiError] = useState<ApiError | null>(null)

  async function onSubmit(data: MappingFormProps) {
    const res = await apiTransitionTableItemsJournalImport(
      props.org.id,
      props.transitionTableImportId,
      props.budgetId,
      data,
    )
    if (res.ok) {
      const redirectUrl = getOrgImporterUrl(props.org.id)
      navigate(redirectUrl)
    } else {
      setApiError(res.err)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <input
          type="hidden"
          readOnly
          name="authenticity_token"
          value={props.formAuthenticityToken}
        />
        <input
          type="hidden"
          name="header_row_number"
          value={props.headerRowNumber}
        />
        <input
          type="hidden"
          name="account_item_column_name"
          value={props.accountItemColumnName}
        />
        <div className="flex pt-6">
          <div className="w-7/12">
            <Section onGray>
              <Section.Title>行（勘定科目）の指定</Section.Title>
              <RowMappingForm
                accountItems={props.accountItems}
                csvAccountItems={csvAccountItemsWithKey as CsvAccountItem[]}
              />
            </Section>
          </div>
          <div className="w-5/12">
            <Section onGray>
              <Section.Title>列（年月）の指定</Section.Title>
              <ColumnMappingForm
                csvHeaders={csvHeadersWithKey as CsvHeader[]}
              />
            </Section>
          </div>
        </div>
        <div className="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
          {apiError && (
            <div className="pb-4">
              <ApiAlert apiError={apiError} />
            </div>
          )}
          <div className="flex justify-end space-x-3">
            <ButtonLink
              href={getTransitionTableImportSettingUrl(
                props.org.id,
                props.transitionTableImportId,
                String(props.budgetId),
              )}
            >
              戻る
            </ButtonLink>
            <Button type="submit" variant="primary">
              インポート
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

function addKeyForRHF(obj: CsvAccountItem | CsvHeader | undefined) {
  return {
    ...obj,
    key: obj ? `${obj.name}-${obj.position}` : null,
  }
}

type MappingType = AccountItemMapping | MonthlyMapping

function addKeyToProperty<T extends MappingType>(arr: T[], propName: keyof T) {
  return arr.map((item) => ({
    ...item,
    [propName]: addKeyForRHF(
      item[propName] as CsvAccountItem | CsvHeader | undefined,
    ),
  }))
}

function getOrgImporterUrl(orgId: number) {
  return `/orgs/${orgId}/importer?import_status=active`
}
