import { useState } from 'react'

import { BudgetTagCategory, Org, PendingChecker } from '@/frontend/api'
import { Card as Section } from '@/frontend/components'
import TitleWithJobLogLink from '../components/TitleWithJobLogLink'
import TransitionTableImportContainer from '../components/TransitionTableImportContainer'
import { getSourceAccountItemUrl } from '../getSourceAccountItemUrl'
import { useImportSettingContext } from '../hooks/useImportSettingContext'
import { AccountingSoftware, JobLogWithAttachedFile } from '../types'
import JournalImportFormContainer from './JournalImportFormContainer'
import MasterImportContainer from './MasterImportContainer'

export type DataSourceSectionProps = {
  org: Org
  accountingSoftware: AccountingSoftware
  primaryBudgetTagCategory: BudgetTagCategory | null
  lastCsvAccountItemsImportJobLog: JobLogWithAttachedFile | null
  lastInProgressCsvAccountItemsImportJobId: number | null
  lastCsvJournalImportJobLog: JobLogWithAttachedFile | null
  lastInProgressCsvJournalImportJobId: number | null
  isFirstImportedAccountItems: boolean
  pendingChecker: PendingChecker
  setPendingChecker: (pendingChecker: PendingChecker) => void
}

export default function DataSourceSection({
  org,
  accountingSoftware,
  primaryBudgetTagCategory,
  lastCsvAccountItemsImportJobLog,
  lastInProgressCsvAccountItemsImportJobId,
  lastCsvJournalImportJobLog,
  lastInProgressCsvJournalImportJobId,
  isFirstImportedAccountItems,
  pendingChecker,
  setPendingChecker,
}: DataSourceSectionProps) {
  const {
    importSetting: { dataFormat, importFormat },
  } = useImportSettingContext()
  const [isJournalImportable, setIsJournalImportable] = useState(
    isFirstImportedAccountItems,
  )

  const sourceAccountItemUrl = getSourceAccountItemUrl(
    org.id,
    accountingSoftware.key,
    dataFormat,
  )

  return (
    <Section onGray>
      <Section.Title>
        <TitleWithJobLogLink org={org}>データ取得</TitleWithJobLogLink>
      </Section.Title>

      {/* CSVインポート */}
      {dataFormat === 'csv' && (
        <div className="p-6 border space-y-4 text-sm rounded-md">
          <div>
            <MasterImportContainer
              org={org}
              primaryBudgetTagCategory={primaryBudgetTagCategory}
              sourceAccountItemUrl={sourceAccountItemUrl}
              jobLogWithAttachedFile={lastCsvAccountItemsImportJobLog}
              initialInProgressJobLogId={
                lastInProgressCsvAccountItemsImportJobId
              }
              setIsJournalImportable={setIsJournalImportable}
              pendingChecker={pendingChecker}
              setPendingChecker={setPendingChecker}
            />
          </div>

          {importFormat === 'general' && (
            <div>
              {/* その他形式 仕訳インポート */}
              <JournalImportFormContainer
                org={org}
                jobLogWithAttachedFile={lastCsvJournalImportJobLog}
                initialInProgressJobLogId={lastInProgressCsvJournalImportJobId}
                isJournalImportable={isJournalImportable}
              />
            </div>
          )}

          {importFormat === 'transitionTable' && (
            <div>
              {/* 推移表形式 インポート */}
              <TransitionTableImportContainer
                org={org}
                primaryBudgetTagCategory={primaryBudgetTagCategory}
              />
            </div>
          )}
        </div>
      )}
    </Section>
  )
}
