import { useCallback } from 'react'
import useSWR from 'swr'

import {
  Comment,
  CommentThread,
  CreateCommentPayload,
  createCustomReportComment,
  createPreparedReportComment,
  getCommentsUrl,
  updateCommentThread,
  UpdateCommentThreadPayload,
} from '@/frontend/api'
import { fetcher } from '@/frontend/utils'
import { ReportMeta } from '.'

type CommentsResponse = {
  comments: Comment[]
}

// スレッドがない場合はスキップする
export function useComments(orgId: number, commentThreadId: number | null) {
  const url = commentThreadId ? getCommentsUrl(orgId, commentThreadId) : null
  return useSWR<CommentsResponse, Error>(url, fetcher)
}

export function usePostComment(
  orgId: number,
  reportMeta: ReportMeta,
  commentThreadId: number | null,
  onCreate?: (comment: Comment) => void,
) {
  return useCallback(
    async (data: CreateCommentPayload) => {
      switch (reportMeta.type) {
        case 'CustomReport': {
          const result = await createCustomReportComment(
            orgId,
            reportMeta.id,
            commentThreadId,
            reportMeta.commentThreadPosition,
            data,
          )
          if (result.ok) {
            onCreate?.(result.val)
          }
          return result
        }
        case 'PreparedReport': {
          const result = await createPreparedReportComment(
            orgId,
            reportMeta.reportType,
            commentThreadId,
            reportMeta.commentThreadPosition,
            data,
          )
          if (result.ok) {
            onCreate?.(result.val)
          }
          return result
        }
      }
    },
    [orgId, commentThreadId, onCreate, reportMeta],
  )
}

export function useUpdateCommentThread(
  orgId: number,
  commentThreadId: number | null,
  onUpdate?: (comment: CommentThread) => void,
) {
  return useCallback(
    async (data: UpdateCommentThreadPayload) => {
      if (commentThreadId) {
        const result = await updateCommentThread(orgId, commentThreadId, data)
        if (result.ok) {
          onUpdate?.(result.val)
        }
        return result
      }
    },
    [orgId, commentThreadId, onUpdate],
  )
}
