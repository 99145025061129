import React from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { RiFileList2Fill, RiUploadLine } from 'react-icons/ri'

type AcceptFileTypes = 'csv' | 'txt'

const acceptFileTypeMap = {
  csv: { 'text/csv': ['.csv'] },
  txt: { 'text/plain': ['.txt'] },
} as const

type Props = {
  onDrop: (files: File[], fileRejections: FileRejection[]) => void
  disabled?: boolean
  acceptFileTypes?: AcceptFileTypes[]
  icon?: React.ReactNode
  message?: string
}

const defaultAccept = acceptFileTypeMap['csv']

export default function TransitionTableIndividuallyDropzone({
  onDrop,
  acceptFileTypes,
  disabled,
  icon = <RiFileList2Fill className="text-gray-500 text-2xl" />,
  message = 'ファイルをドロップ',
}: Props) {
  const accept = acceptFileTypes
    ?.map((type) => acceptFileTypeMap[type])
    .reduce((acc, accept) => ({ ...acc, ...accept }), {})
  const { getRootProps, open, getInputProps } = useDropzone({
    onDrop,
    accept: accept || defaultAccept,
    disabled: disabled,
    noClick: true,
  })

  return (
    <div
      {...getRootProps()}
      className="border-2 border-dotted h-14 flex w-full items-center"
    >
      <input {...getInputProps()} />
      <div className="basis-5/6">
        <div className="pl-8 flex flex-row items-center space-x-2 justify-center">
          {icon}
          <span className="text-gray-500">{message}</span>
        </div>
      </div>
      <div className="basis-1/6">
        <button
          type="button"
          className=" p-2 border border-gray-400 rounded text-gray-800 bg-white hover:text-blue-600 ml-2"
          onClick={open}
        >
          <RiUploadLine className="text-sm" />
        </button>
      </div>
    </div>
  )
}
