import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { groupBy } from 'lodash'
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form'
import { RiAddLine } from 'react-icons/ri'

import { ApiError, ApiResultAllocationVersion, Org } from '@/frontend/api'
import {
  ApiAlert,
  Button,
  InputText,
  ModalWithIcon,
  MonthInput,
  Radio,
  Select,
} from '@/frontend/components'
import { usePageBack } from '@/frontend/hooks/usePageBack'
import { BudgetTypeModal } from '@/frontend/pages/allocation_versions/components/BudgetTypeModal'
import { z } from '@/frontend/utils'
import { CancelDialog } from './CancelDialog'
import { useGetAccountItemsByReservedItem } from './hooks'
import { HorizontalInputGroup } from './HorizontalInputGroup'
import { MultiSelect } from './MultiSelect'
import { ReservedItemSelect } from './ReservedItemSelect'
import { SectionTitle } from './SectionTitle'
import {
  AccountItem,
  AccountItemGroup,
  AllocationDriver,
  BudgetTag,
  BudgetTagGroup,
  FiscalPeriod,
  ReservedItem,
} from './types'
import { VerticalInputGroup } from './VerticalInputGroup'

type Props = {
  org: Org
  allocationVersionId?: number
  fiscalPeriods: FiscalPeriod[]
  reservedItems: ReservedItem[]
  accountItemGroups: AccountItemGroup[]
  accountItems: AccountItem[]
  budgetTagGroups: BudgetTagGroup[]
  budgetTags: BudgetTag[]
  allocationDrivers: AllocationDriver[]
  allocationVersionPayload?: AllocationVersionPayload
  allocationVersionsUrl: string
  accountItemsUrl: string
  reservedItemsUrl: string
  onSubmit: (
    data: AllocationVersionPayload,
  ) => Promise<ApiResultAllocationVersion>
  onChangeForm?: ({
    value,
    name,
    type,
  }: {
    value: unknown
    name: string | undefined
    type: string | undefined
  }) => void
  onCancel?: () => void
}

export const allocationVersionSchema = z.object({
  name: z.string().min(1, { message: '文字を入力してください。' }).max(20),
  memo: z.string().max(30).nullable(),
  allocationPeriods: z
    .array(
      z
        .object({
          allocationTarget: z.enum(['budget', 'result', 'both']),
          allocationMethod: z.enum(['individually', 'in_bulk']),
          periodType: z.enum(['indefinite', 'fiscal_period', 'any']),
          firstYearMonth: z.string().nullable(),
          lastYearMonth: z.string().nullable(),
          fiscalPeriodId: z.string().nullable(),
          allocationDriverBudgetTypes: z.record(
            z.string(), // allocationDriverId
            z.enum(['current', 'budget', 'result']), // type
          ),
          allocationSteps: z
            .array(
              z.object({
                sourceBudgetTagIds: z
                  .array(z.string())
                  .nonempty({ message: 'タグを選択してください。' }),
                destinationBudgetTagIds: z
                  .array(z.string())
                  .nonempty({ message: 'タグを選択してください。' }),
                allocationDriverIndividuallyMappings: z.record(
                  z.string(), // accountItemId
                  z.string(), // allocationDriverId
                ),
                allocationDriverInBulkMapping: z
                  .object({
                    allocationDriverId: z.string(),
                    destinationAccountItemId: z.string(),
                    sourceItem: z.object({
                      id: z.string(),
                      type: z.string(),
                    }),
                  })
                  .deepPartial(),
              }),
            )
            .min(1)
            .max(5),
        })
        .superRefine((val, ctx) => {
          switch (val.allocationMethod) {
            case 'individually':
              break
            case 'in_bulk':
              val.allocationSteps.forEach((step, index) => {
                const inBulkMapping = step.allocationDriverInBulkMapping
                if (!inBulkMapping.allocationDriverId) {
                  ctx.addIssue({
                    path: [
                      `allocationSteps.${index}.allocationDriverInBulkMapping.allocationDriverId`,
                    ],
                    code: z.ZodIssueCode.custom,
                    message: '選択してください',
                  })
                }
                if (!inBulkMapping.destinationAccountItemId) {
                  ctx.addIssue({
                    path: [
                      `allocationSteps.${index}.allocationDriverInBulkMapping.destinationAccountItemId`,
                    ],
                    code: z.ZodIssueCode.custom,
                    message: '選択してください',
                  })
                }
                if (!inBulkMapping.sourceItem?.id) {
                  ctx.addIssue({
                    path: [
                      `allocationSteps.${index}.allocationDriverInBulkMapping.sourceItem`,
                    ],
                    code: z.ZodIssueCode.custom,
                    message: '選択してください',
                  })
                }
              })
          }
          switch (val.periodType) {
            case 'fiscal_period':
              if (!val.fiscalPeriodId) {
                ctx.addIssue({
                  path: ['fiscalPeriodId'],
                  code: z.ZodIssueCode.custom,
                  message: '入力してください。',
                })
              }
              break
            case 'any':
              if (!val.firstYearMonth) {
                ctx.addIssue({
                  path: ['firstYearMonth'],
                  code: z.ZodIssueCode.custom,
                  message: '入力してください。',
                })
              }
              if (!val.lastYearMonth) {
                ctx.addIssue({
                  path: ['lastYearMonth'],
                  code: z.ZodIssueCode.custom,
                  message: '入力してください。',
                })
              }
              break
          }
        }),
    )
    .min(1)
    .max(5),
})

export type AllocationVersionPayload = z.infer<typeof allocationVersionSchema>

const periodTypes = [
  { label: '全期間', value: 'indefinite' },
  { label: '会計期間', value: 'fiscal_period' },
  { label: '任意の期間', value: 'any' },
] as const

const allocationTargets = [
  { label: '計画および実績', value: 'both' },
  { label: '計画のみ', value: 'budget' },
  { label: '実績のみ', value: 'result' },
] as const

const allocationMethods = [
  { label: '各勘定科目に個別配賦', value: 'individually' },
  { label: '共通費科目に一括配賦', value: 'in_bulk' },
] as const

const indentStyle = (indent: number) => {
  return {
    paddingLeft: `${1.5 * indent}rem`,
  }
}

export function Form({
  fiscalPeriods,
  reservedItems,
  accountItemGroups,
  accountItems,
  budgetTagGroups,
  budgetTags,
  allocationDrivers,
  allocationVersionPayload,
  allocationVersionsUrl,
  accountItemsUrl,
  reservedItemsUrl,
  onSubmit,
  onChangeForm = (_) => undefined,
  onCancel = () => undefined,
}: Props) {
  const [openModalPeriodIndex, setOpenModalPeriodIndex] = useState<
    number | null
  >(null)

  const plJointReservedItems = useMemo(() => {
    return reservedItems.filter((reservedItem) => {
      return reservedItem.joint && reservedItem.fiscalStateType === 'pl_state'
    })
  }, [reservedItems])

  const plReservedItems = useMemo(() => {
    return reservedItems.filter((reservedItem) => {
      return reservedItem.fiscalStateType === 'pl_state'
    })
  }, [reservedItems])

  const formMethods = useForm<AllocationVersionPayload>({
    mode: 'onChange',
    resolver: zodResolver(allocationVersionSchema),
    defaultValues: allocationVersionPayload || {
      name: '',
      memo: '',
      allocationPeriods: [
        {
          allocationTarget: 'both',
          allocationMethod: 'individually',
          periodType: 'indefinite',
          firstYearMonth: null,
          lastYearMonth: null,
          fiscalPeriodId: null,
          allocationDriverBudgetTypes: allocationDrivers.reduce(
            (result, driver) => {
              const key = driver.id.toString()
              result[key] = 'current'
              return result
            },
            {} as { [id: string]: 'current' | 'budget' | 'result' },
          ),
          allocationSteps: [
            {
              sourceBudgetTagIds: [],
              destinationBudgetTagIds: [],
              allocationDriverIndividuallyMappings: {},
              allocationDriverInBulkMapping: {},
            },
          ],
        },
      ],
    },
  })

  const [apiError, setApiError] = useState<ApiError | null>(null)

  const _onSubmit = async (data: AllocationVersionPayload) => {
    setApiError(null)
    const result = await onSubmit(data)
    if (result.ok) {
      location.href = allocationVersionsUrl
    } else {
      setApiError(result.err)
    }
  }

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    watch,
  } = formMethods

  useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      onChangeForm({ value, name, type }),
    )
    return () => subscription.unsubscribe()
  }, [watch, onChangeForm])

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'allocationPeriods',
  })

  const addAllocationPeriod = () => {
    if (fields.length === 0) {
      throw new Error('empty fields')
    }

    const lastIndex = fields.length - 1
    const values = getValues(`allocationPeriods.${lastIndex}`)
    append({ ...values })
  }

  const isPeriodAddable = fields.length < 5

  const [isPageBack, cancelPageBack] = usePageBack()
  useEffect(() => {
    if (isPageBack) {
      setCancelDialogOpen(true)
    }
  }, [isPageBack])

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const handleCancelDialogClose = () => {
    if (isPageBack) {
      cancelPageBack()
    }
    setCancelDialogOpen(false)
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(_onSubmit)} className="pb-16">
        {/* 名称 */}
        <div className="p-6">
          <div className="bg-white rounded">
            {apiError && (
              <div className="px-6 pt-4 pb-0">
                <ApiAlert apiError={apiError} autoScroll />
              </div>
            )}
            <div className="px-6 pt-6 pb-4 w-full">
              <SectionTitle>配賦バージョン設定</SectionTitle>
              <div className="pt-4 space-y-4 w-80">
                <div className="w-80">
                  <VerticalInputGroup
                    label="名称"
                    errorMsg={errors.name?.message}
                  >
                    {(props) => <InputText {...props} {...register('name')} />}
                  </VerticalInputGroup>
                </div>
                <div style={{ width: '30rem' }}>
                  <VerticalInputGroup
                    label="メモ"
                    optional
                    errorMsg={errors.memo?.message}
                  >
                    {(props) => <InputText {...props} {...register('memo')} />}
                  </VerticalInputGroup>
                </div>
              </div>
            </div>
          </div>
          {fields.map((field, i) => {
            const allocationPeriodErrors = errors.allocationPeriods?.[i]
            return (
              <div key={field.id} className="bg-white rounded">
                <div className="px-6 py-4 bg-white border-b border-gray-200">
                  <SectionTitle>配賦設定-{i + 1}</SectionTitle>
                  {/* 配賦期間 */}
                  <div className="mt-6 space-y-4">
                    <HorizontalInputGroup
                      label={'配賦期間'}
                      errorMsg={allocationPeriodErrors?.periodType?.message}
                    >
                      {({ id, ...props }) => (
                        <div className="flex items-center space-y-0 space-x-6 whitespace-nowrap">
                          {periodTypes.map(({ label, value }) => (
                            <Fragment key={value}>
                              <Radio
                                id={`${id}_${value}`}
                                {...register(
                                  `allocationPeriods.${i}.periodType`,
                                )}
                                {...props}
                                value={value}
                              >
                                {label}
                              </Radio>
                              {[
                                watch(`allocationPeriods.${i}.periodType`),
                                value,
                              ].every((e) => e == 'fiscal_period') && (
                                <Select
                                  invalid={
                                    !!allocationPeriodErrors?.fiscalPeriodId
                                      ?.message
                                  }
                                  {...register(
                                    `allocationPeriods.${i}.fiscalPeriodId`,
                                  )}
                                >
                                  <option value="" disabled>
                                    選択してください
                                  </option>
                                  {fiscalPeriods.map((fiscalPeriod) => {
                                    return (
                                      <option
                                        key={fiscalPeriod.id}
                                        value={fiscalPeriod.id}
                                      >
                                        {fiscalPeriod.name}
                                      </option>
                                    )
                                  })}
                                </Select>
                              )}
                              {[
                                watch(`allocationPeriods.${i}.periodType`),
                                value,
                              ].every((e) => e == 'any') && (
                                <div className="flex items-center space-x-2">
                                  <MonthInput
                                    {...register(
                                      `allocationPeriods.${i}.firstYearMonth`,
                                    )}
                                    invalid={
                                      !!allocationPeriodErrors?.firstYearMonth
                                        ?.message
                                    }
                                  />
                                  <div>~</div>
                                  <MonthInput
                                    {...register(
                                      `allocationPeriods.${i}.lastYearMonth`,
                                    )}
                                    invalid={
                                      !!allocationPeriodErrors?.lastYearMonth
                                        ?.message
                                    }
                                  />
                                </div>
                              )}
                            </Fragment>
                          ))}
                        </div>
                      )}
                    </HorizontalInputGroup>
                    {/* 配賦対象 */}
                    <HorizontalInputGroup
                      label={'配賦対象'}
                      errorMsg={
                        allocationPeriodErrors?.allocationTarget?.message
                      }
                    >
                      {({ id, ...props }) => (
                        <div className="flex items-center space-y-0 space-x-10">
                          {allocationTargets.map(({ label, value }) => (
                            <Radio
                              key={value}
                              id={`${id}_${value}`}
                              {...register(
                                `allocationPeriods.${i}.allocationTarget`,
                              )}
                              {...props}
                              value={value}
                            >
                              {label}
                            </Radio>
                          ))}
                        </div>
                      )}
                    </HorizontalInputGroup>
                    {/* 配賦方法 */}
                    <HorizontalInputGroup
                      label={'配賦方法'}
                      errorMsg={
                        allocationPeriodErrors?.allocationMethod?.message
                      }
                    >
                      {({ id, ...props }) => (
                        <div className="flex items-center space-y-0 space-x-10">
                          {allocationMethods.map(({ label, value }) => (
                            <Radio
                              key={value}
                              id={`${id}_${value}`}
                              {...register(
                                `allocationPeriods.${i}.allocationMethod`,
                              )}
                              {...props}
                              value={value}
                            >
                              {label}
                            </Radio>
                          ))}
                        </div>
                      )}
                    </HorizontalInputGroup>
                  </div>
                  <div className="mt-5 mb-3">
                    <a
                      className="text-sm text-blue-700 hover:underline cursor-pointer"
                      onClick={() => {
                        setOpenModalPeriodIndex(i)
                      }}
                    >
                      配賦ドライバの基準設定
                    </a>
                    <p className="text-xs text-gray-500 py-2">
                      ※配賦ドライバで使用する基準は、「計画は計画値、実績は実績値で計算」がデフォルトで設定されています。
                      <br />
                      基準を変更したい場合、こちらから設定してください。
                    </p>
                  </div>
                  <BudgetTypeModal
                    budgetTypeModalOpen={i === openModalPeriodIndex}
                    onBudgetTypeModalClose={() => setOpenModalPeriodIndex(null)}
                    periodIndex={i}
                    allocationDrivers={allocationDrivers}
                  />
                </div>
                <div className="px-6 pb-4">
                  <AllocationStepsForm
                    periodIndex={i}
                    isPeriodRemovable={fields.length > 1}
                    plJointReservedItems={plJointReservedItems}
                    plReservedItems={plReservedItems}
                    accountItemGroups={accountItemGroups}
                    accountItems={accountItems}
                    budgetTagGroups={budgetTagGroups}
                    budgetTags={budgetTags}
                    allocationDrivers={allocationDrivers}
                    removeAllocationPeriod={() => remove(i)}
                    accountItemsUrl={accountItemsUrl}
                    reservedItemsUrl={reservedItemsUrl}
                  />
                </div>
              </div>
            )
          })}
          {isPeriodAddable && (
            <div className="pt-4">
              <Button variant="secondary" onClick={addAllocationPeriod}>
                配賦期間の追加
              </Button>
            </div>
          )}
        </div>
        <div className="bg-white border-t border-gray-200 fixed bottom-0 inset-x-0 flex justify-end space-x-4 p-4 z-30">
          <Button variant="outlined" onClick={() => setCancelDialogOpen(true)}>
            戻る
          </Button>
          <Button type="submit" variant="primary" disabled={isSubmitting}>
            保存
          </Button>
        </div>
      </form>
      <CancelDialog
        open={cancelDialogOpen}
        onClose={handleCancelDialogClose}
        onAcceptCancel={onCancel}
        allocationVersionsUrl={allocationVersionsUrl}
      />
    </FormProvider>
  )
}

function Sticky({
  children,
  offsetTop,
  zIndex,
}: {
  children: React.ReactNode
  offsetTop: number
  zIndex: number
}) {
  const stickyStyle: React.CSSProperties = {
    position: 'sticky',
    top: offsetTop,
    zIndex: zIndex,
  }

  return <div style={stickyStyle}>{children}</div>
}

type AllocationStepsFormProps = {
  periodIndex: number
  isPeriodRemovable: boolean
  plJointReservedItems: ReservedItem[]
  plReservedItems: ReservedItem[]
  accountItemGroups: AccountItemGroup[]
  accountItems: AccountItem[]
  budgetTagGroups: BudgetTagGroup[]
  budgetTags: BudgetTag[]
  allocationDrivers: AllocationDriver[]
  removeAllocationPeriod: () => void
  accountItemsUrl: string
  reservedItemsUrl: string
}

function AllocationStepsForm({
  periodIndex,
  isPeriodRemovable,
  plJointReservedItems,
  plReservedItems,
  accountItemGroups,
  accountItems,
  budgetTagGroups,
  budgetTags,
  allocationDrivers,
  removeAllocationPeriod,
  accountItemsUrl,
  reservedItemsUrl,
}: AllocationStepsFormProps) {
  const [allocationDriverSectionIndex, setAllocationDriverSectionIndex] =
    useState<number | null>(null)

  const {
    formState: { errors },
    getValues,
    watch,
    control,
  } = useFormContext<AllocationVersionPayload>()

  const checkedAllocationMethod = watch(
    `allocationPeriods.${periodIndex}.allocationMethod`,
  )

  const { fields, append, remove } = useFieldArray({
    control,
    name: `allocationPeriods.${periodIndex}.allocationSteps`,
  })

  const bulkSelect = useRef<HTMLSelectElement>(null)

  const addAllocationStep = () => {
    if (fields.length === 0) {
      throw new Error('empty fields')
    }

    const lastIndex = fields.length - 1
    const values = getValues(
      `allocationPeriods.${periodIndex}.allocationSteps.${lastIndex}`,
    )
    append({ ...values })
  }

  const removeAllocationStep = (index: number) => {
    remove(index)
    const allocationSteps = getValues(
      `allocationPeriods.${periodIndex}.allocationSteps`,
    )

    // ステップが全部なくなったら期間ごと消す
    if (allocationSteps.length === 0) {
      removeAllocationPeriod()
    }
  }

  const isRemovable = fields.length > 1 || isPeriodRemovable

  const isStepAddable = fields.length < 5

  return (
    <>
      <div className="divide-y divide-gray-200">
        {fields.map((field, i) => (
          <div key={field.id} className="pb-4">
            <Sticky offsetTop={48} zIndex={15}>
              <div className="pt-8 bg-white">
                <div className="pb-4">
                  <SectionTitle noBorderDecoration>{i + 1}次配賦</SectionTitle>
                </div>
                <div className="pb-4 flex justify-between">
                  <div className="flex space-x-10">
                    {/* 配賦元タグ */}
                    <div className="w-80">
                      <VerticalInputGroup
                        label={'配賦元タグ'}
                        errorMsg={
                          errors?.allocationPeriods?.[periodIndex]
                            ?.allocationSteps?.[i]?.sourceBudgetTagIds?.message
                        }
                      >
                        {({ invalid }) => (
                          <Controller
                            render={({ field }) => (
                              <MultiSelect
                                budgetTagGroups={budgetTagGroups}
                                budgetTags={budgetTags}
                                value={field.value}
                                onChange={field.onChange}
                                invalid={invalid}
                              />
                            )}
                            control={control}
                            name={`allocationPeriods.${periodIndex}.allocationSteps.${i}.sourceBudgetTagIds`}
                          />
                        )}
                      </VerticalInputGroup>
                    </div>
                    {/* 配賦先タグ */}
                    <div className="w-80">
                      <VerticalInputGroup
                        label={'配賦先タグ'}
                        errorMsg={
                          errors?.allocationPeriods?.[periodIndex]
                            ?.allocationSteps?.[i]?.destinationBudgetTagIds
                            ?.message
                        }
                      >
                        {({ invalid }) => (
                          <Controller
                            render={({ field }) => (
                              <MultiSelect
                                budgetTagGroups={budgetTagGroups}
                                budgetTags={budgetTags}
                                value={field.value}
                                onChange={field.onChange}
                                invalid={invalid}
                              />
                            )}
                            control={control}
                            name={`allocationPeriods.${periodIndex}.allocationSteps.${i}.destinationBudgetTagIds`}
                          />
                        )}
                      </VerticalInputGroup>
                    </div>
                  </div>
                  <div>
                    {isRemovable && (
                      <Button
                        variant="dangerSecondary"
                        onClick={() => setAllocationDriverSectionIndex(i)}
                      >
                        削除
                      </Button>
                    )}
                    <DeleteDialog
                      open={i === allocationDriverSectionIndex}
                      onClose={() => setAllocationDriverSectionIndex(null)}
                      onDeleteButtonClick={() => {
                        removeAllocationStep(i)
                        setAllocationDriverSectionIndex(null)
                      }}
                    />
                  </div>
                </div>
              </div>
            </Sticky>
            <div className="mt-6">
              {(() => {
                switch (checkedAllocationMethod) {
                  case 'individually':
                    return (
                      <IndividuallyMappingForm
                        periodIndex={periodIndex}
                        stepIndex={i}
                        bulkSelect={bulkSelect}
                        allocationDrivers={allocationDrivers}
                        plJointReservedItems={plJointReservedItems}
                        accountItems={accountItems}
                        accountItemGroups={accountItemGroups}
                      />
                    )
                  case 'in_bulk':
                    return (
                      <InBulkMappingForm
                        periodIndex={periodIndex}
                        stepIndex={i}
                        plReservedItems={plReservedItems}
                        accountItems={accountItems}
                        allocationDrivers={allocationDrivers}
                        accountItemsUrl={accountItemsUrl}
                        reservedItemsUrl={reservedItemsUrl}
                      />
                    )
                }
              })()}
            </div>
          </div>
        ))}
      </div>
      {isStepAddable && (
        <div className="flex space-x-4 items-center">
          <button
            type="button"
            onClick={addAllocationStep}
            className="inline-flex items-center justify-center p-1 border rounded duration-150 focus:outline-none border-gray-300 text-gray-700 bg-white transition ease-in-out hover:text-blue-500 focus:border-blue-300 focus:ring-blue-200 active:text-blue-600 active:bg-gray-50"
          >
            <RiAddLine className="text-xl" />
          </button>
          <div className="text-sm items-center font-medium leading-6 text-gray-900">
            段階を追加する
          </div>
        </div>
      )}
    </>
  )
}

function DeleteDialog({
  open,
  onClose,
  onDeleteButtonClick,
}: {
  open: boolean
  onClose: () => void
  onDeleteButtonClick: () => void
}) {
  const cancelButtonRef = useRef(null)
  return (
    <ModalWithIcon
      variant="danger"
      open={open}
      onClose={() => onClose()}
      initialFocus={cancelButtonRef}
      title="削除の確認"
      actions={
        <>
          <Button onClick={onClose} variant="outlined" ref={cancelButtonRef}>
            キャンセル
          </Button>
          <Button variant="dangerPrimary" onClick={onDeleteButtonClick}>
            削除
          </Button>
        </>
      }
    >
      配賦ドライバの設定を削除しますか？
      <br />
      この操作は巻き戻しできません。
    </ModalWithIcon>
  )
}

function InBulkMappingForm({
  periodIndex,
  stepIndex,
  plReservedItems,
  accountItems,
  allocationDrivers,
  accountItemsUrl,
  reservedItemsUrl,
}: {
  periodIndex: number
  stepIndex: number
  plReservedItems: ReservedItem[]
  accountItems: AccountItem[]
  allocationDrivers: AllocationDriver[]
  accountItemsUrl: string
  reservedItemsUrl: string
}) {
  const {
    formState: { errors },
    control,
    register,
  } = useFormContext<AllocationVersionPayload>()

  const getAccountItems = useGetAccountItemsByReservedItem(accountItems)

  const plAccountItems = plReservedItems.flatMap((reservedItem) => {
    return getAccountItems(reservedItem)
  })

  const plItems = [...plReservedItems, ...plAccountItems].sort((a, b) => {
    const minLength = Math.min(a.positionValues.length, b.positionValues.length)

    for (let i = 0; i < minLength; i++) {
      if (a.positionValues[i] !== b.positionValues[i]) {
        return (a.positionValues[i] as number) - (b.positionValues[i] as number)
      }
    }

    return a.positionValues.length - b.positionValues.length
  })

  const groupedPlItems = () => {
    const result: { [key: string]: AccountItem[] } = {}
    let currentReservedItem: ReservedItem
    plItems.forEach((item) => {
      if ('fiscalStateType' in item) {
        currentReservedItem = item
        result[currentReservedItem.id] = []
      } else {
        result[currentReservedItem?.id]?.push(item)
      }
    })
    return result
  }

  const indentText = (size: number) => {
    return '\u2003'.repeat(size)
  }

  return (
    <>
      <div className="mt-6">
        <div className="flex justify-between">
          <div className="flex space-x-10 max-w-2xl">
            {/* 配賦元項目 */}
            <div className="w-60">
              <VerticalInputGroup
                label={'配賦元項目'}
                errorMsg={
                  errors?.allocationPeriods?.[periodIndex]?.allocationSteps?.[
                    stepIndex
                  ]?.allocationDriverInBulkMapping?.sourceItem?.message
                }
              >
                {({ invalid }) => (
                  <Controller
                    render={({ field }) => (
                      <ReservedItemSelect
                        reservedItems={plReservedItems}
                        value={field.value}
                        onChange={field.onChange}
                        invalid={invalid}
                      />
                    )}
                    control={control}
                    name={`allocationPeriods.${periodIndex}.allocationSteps.${stepIndex}.allocationDriverInBulkMapping.sourceItem`}
                  />
                )}
              </VerticalInputGroup>
            </div>
            <div className="w-60">
              <VerticalInputGroup
                label={'配賦先項目'}
                errorMsg={
                  errors?.allocationPeriods?.[periodIndex]?.allocationSteps?.[
                    stepIndex
                  ]?.allocationDriverInBulkMapping?.destinationAccountItemId
                    ?.message
                }
              >
                {({ invalid }) => (
                  <Select
                    {...register(
                      `allocationPeriods.${periodIndex}.allocationSteps.${stepIndex}.allocationDriverInBulkMapping.destinationAccountItemId`,
                    )}
                    invalid={invalid}
                  >
                    <option value="">選択してください</option>
                    {plReservedItems.map((reservedItem) => {
                      return (
                        <Fragment key={reservedItem.id}>
                          <option disabled>
                            {`${indentText(
                              reservedItem.positionValues.length - 1,
                            )}${reservedItem.name}`}
                          </option>
                          {groupedPlItems()[reservedItem.id]?.flatMap(
                            (accountItem) => {
                              return (
                                <option
                                  key={accountItem.id}
                                  value={accountItem.id}
                                >
                                  {`${indentText(
                                    reservedItem.positionValues.length,
                                  )}${accountItem.name}`}
                                </option>
                              )
                            },
                          )}
                        </Fragment>
                      )
                    })}
                  </Select>
                )}
              </VerticalInputGroup>
            </div>
            <div className="w-60 flex flex-col justify-around">
              <div>
                <a
                  href={reservedItemsUrl}
                  target="_blank"
                  className="text-sm text-blue-500"
                  data-tooltip="設定する配賦元項目（段階利益）が存在しない場合は、その段階利益を登録した後、画面を更新してください"
                  rel="noreferrer"
                >
                  段階利益の追加
                  <i className="ri-external-link-line text-gray-400 ml-3"></i>
                </a>
              </div>
              <div>
                <a
                  href={accountItemsUrl}
                  target="_blank"
                  className="text-sm text-blue-500"
                  data-tooltip="設定する配賦先項目（勘定科目）が存在しない場合は、その勘定科目を登録した後、画面を更新してください"
                  rel="noreferrer"
                >
                  勘定科目の追加
                  <i className="ri-external-link-line text-gray-400 ml-3"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <div className="w-60">
          <VerticalInputGroup
            label={'配賦ドライバの割当'}
            errorMsg={
              errors?.allocationPeriods?.[periodIndex]?.allocationSteps?.[
                stepIndex
              ]?.allocationDriverInBulkMapping?.allocationDriverId?.message
            }
          >
            {({ invalid }) => (
              <Select
                {...register(
                  `allocationPeriods.${periodIndex}.allocationSteps.${stepIndex}.allocationDriverInBulkMapping.allocationDriverId`,
                )}
                invalid={invalid}
              >
                <option value="">選択してください</option>
                {allocationDrivers.map((allocationDriver) => {
                  return (
                    <option
                      key={allocationDriver.id}
                      value={allocationDriver.id}
                    >
                      {allocationDriver.name}
                    </option>
                  )
                })}
              </Select>
            )}
          </VerticalInputGroup>
        </div>
      </div>
    </>
  )
}

function IndividuallyMappingForm({
  periodIndex,
  stepIndex,
  bulkSelect,
  plJointReservedItems,
  allocationDrivers,
  accountItems,
  accountItemGroups,
}: {
  periodIndex: number
  stepIndex: number
  bulkSelect: React.RefObject<HTMLSelectElement>
  plJointReservedItems: ReservedItem[]
  allocationDrivers: AllocationDriver[]
  accountItems: AccountItem[]
  accountItemGroups: AccountItemGroup[]
}) {
  const { setValue } = useFormContext<AllocationVersionPayload>()

  const getAccountItems = useGetAccountItemsByReservedItem(accountItems)

  const accountItemsByGroupId = groupBy(
    accountItems,
    (x) => x.accountItemGroupId,
  )

  const groupsByReservedItemId = groupBy(
    accountItemGroups,
    (x) => x.reservedItemId,
  )

  const getGroupsByReservedItem = (reservedItem: ReservedItem) => {
    return groupsByReservedItemId[reservedItem.id] || []
  }

  const getAccountItemsByGroup = (accountItemGroup: AccountItemGroup) => {
    return accountItemsByGroupId[accountItemGroup.id] || []
  }

  const getUngroupedAccountItems = (reservedItem: ReservedItem) => {
    return getAccountItems(reservedItem).filter((x) => !x.accountItemGroupId)
  }

  const onBulkSelectChange = (stepIndex: number, val: string) => {
    accountItems.forEach((accountItem) => {
      setValue(
        `allocationPeriods.${periodIndex}.allocationSteps.${stepIndex}.allocationDriverIndividuallyMappings.${accountItem.id.toString()}`,
        val,
      )
    })
  }

  return (
    <>
      <label className="block text-sm font-bold text-gray-700 leading-5">
        配賦ドライバの割当
      </label>
      <div
        className="mt-2 p-4 max-w-2xl rounded border border-gray-200 overflow-y-auto"
        style={{ height: '740px' }}
      >
        <div>
          <div className="flex items-center">
            <div className="w-1/2 text-gray-700 text-sm">一括操作</div>
            <div className="w-1/2">
              <Select
                ref={bulkSelect}
                onChange={(e) => onBulkSelectChange(stepIndex, e.target.value)}
              >
                <option value=""></option>
                {allocationDrivers.map((allocationDriver) => {
                  return (
                    <option
                      key={allocationDriver.id}
                      value={allocationDriver.id}
                    >
                      {allocationDriver.name}
                    </option>
                  )
                })}
              </Select>
            </div>
          </div>

          {plJointReservedItems.map((reservedItem) => {
            const accountItems = getAccountItems(reservedItem)
            if (accountItems.length === 0) return null

            return (
              <div key={reservedItem.id} className="mt-4">
                <div className="font-bold text-sm text-gray-700 border-b border-gray-200 pb-1">
                  {reservedItem.name}
                </div>
                <div className="pt-2 pb-4 space-y-2">
                  {getGroupsByReservedItem(reservedItem).map((group) => {
                    const groupedAccountItems = getAccountItemsByGroup(group)
                    if (groupedAccountItems.length === 0) return null

                    return (
                      <div key={group.id}>
                        <div
                          className="font-bold text-sm text-gray-700 pb-1"
                          style={indentStyle(1)}
                        >
                          {group.name}
                        </div>
                        <div className="space-y-2">
                          {groupedAccountItems.map((accountItem) => (
                            <AccountItemRow
                              key={accountItem.id}
                              indent={2}
                              accountItem={accountItem}
                              periodIndex={periodIndex}
                              stepIndex={stepIndex}
                              bulkSelect={bulkSelect}
                              allocationDrivers={allocationDrivers}
                            />
                          ))}
                        </div>
                      </div>
                    )
                  })}
                  {getUngroupedAccountItems(reservedItem).map((accountItem) => (
                    <AccountItemRow
                      indent={1}
                      key={accountItem.id}
                      accountItem={accountItem}
                      periodIndex={periodIndex}
                      stepIndex={stepIndex}
                      bulkSelect={bulkSelect}
                      allocationDrivers={allocationDrivers}
                    />
                  ))}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

function AccountItemRow({
  indent,
  accountItem,
  periodIndex,
  stepIndex,
  bulkSelect,
  allocationDrivers,
}: {
  indent: number
  accountItem: AccountItem
  periodIndex: number
  stepIndex: number
  bulkSelect: React.RefObject<HTMLSelectElement>
  allocationDrivers: AllocationDriver[]
}) {
  const { register } = useFormContext<AllocationVersionPayload>()

  return (
    <div className="flex items-center">
      <div
        className="w-1/2 text-sm text-gray-700 pl-4"
        style={indentStyle(indent)}
      >
        {accountItem.name}
      </div>
      <div className="w-1/2">
        <Select
          {...register(
            `allocationPeriods.${periodIndex}.allocationSteps.${stepIndex}.allocationDriverIndividuallyMappings.${accountItem.id.toString()}`,
            {
              onChange: () => {
                if (bulkSelect.current) {
                  bulkSelect.current.selectedIndex = -1
                }
              },
            },
          )}
        >
          <option value=""></option>
          {allocationDrivers.map((allocationDriver) => {
            return (
              <option key={allocationDriver.id} value={allocationDriver.id}>
                {allocationDriver.name}
              </option>
            )
          })}
        </Select>
      </div>
    </div>
  )
}
