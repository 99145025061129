import { JobLog, Org, PendingChecker } from '@/frontend/api'
import { ApiImportFormHeader } from '../components/ApiImportFormHeader'
import { JobLogPollingContainer } from '../components/JobLogPollingContainer'
import useAlertStatus from '../hooks/useAlertStatus'
import { useApiPendingChecker } from '../hooks/useApiPendingChecker'
import useReturnedPendingCheckerEffect from '../hooks/useReturnedPendingCheckerEffect'
import useStoredFormValues, {
  BaseApiFormValuesType,
} from '../hooks/useStoredFormValues'
import { BugyoCloudCompany } from '../types'
import ApiCompanySelector from './ApiCompanySelector'
import ApiImportForm from './ApiImportForm'
import { useImportBugyoCloudApi } from './useImportBugyoCloudApi'

type Props = {
  org: Org
  bugyoCloudCompany: BugyoCloudCompany | undefined
  bugyoCloudCompanies: BugyoCloudCompany[]
  onChangeCompany: (company: BugyoCloudCompany | undefined) => void
  lastApiImportJobLog: JobLog | null
  lastInProgressApiImportJobId: number | null
  setPendingChecker: (pendingChecker: PendingChecker) => void
}

export default function ApiImportFormContainer({
  org,
  bugyoCloudCompany,
  bugyoCloudCompanies,
  onChangeCompany,
  lastApiImportJobLog,
  lastInProgressApiImportJobId,
  setPendingChecker,
}: Props) {
  const { alertStatus, updateAlertStatusFromJobLog, resetAlertStatus } =
    useAlertStatus(lastApiImportJobLog)

  const { storedFormValues, storeImportFormValues } =
    useStoredFormValues<BaseApiFormValuesType>(org, 'BugyoCloudApi')

  const {
    submit,
    jobLog: returnedJobLog,
    isSubmitting,
    apiError,
  } = useImportBugyoCloudApi(org)

  const {
    submit: pendingCheckerSubmit,
    apiError: pendingCheckerApiError,
    returnedPendingChecker,
  } = useApiPendingChecker(org)

  useReturnedPendingCheckerEffect(returnedPendingChecker, setPendingChecker)

  return (
    <JobLogPollingContainer
      org={org}
      pollingJobLog={returnedJobLog}
      initJobLogId={lastInProgressApiImportJobId}
      onStopPolling={(jobLog: JobLog) => {
        updateAlertStatusFromJobLog(jobLog)
        pendingCheckerSubmit()
      }}
    >
      {({ isPolling }) => (
        <div className="space-y-2">
          <ApiImportFormHeader />
          <ApiCompanySelector
            org={org}
            bugyoCloudCompany={bugyoCloudCompany}
            bugyoCloudCompanies={bugyoCloudCompanies}
            onChangeCompany={onChangeCompany}
          />
          {bugyoCloudCompany && (
            <ApiImportForm
              org={org}
              alertStatus={alertStatus}
              onSubmit={(data) => {
                const { budgetId: _, ...values } = data
                storeImportFormValues(values)
                resetAlertStatus()
                submit(data)
              }}
              apiError={apiError || pendingCheckerApiError}
              isLoading={isPolling || isSubmitting}
              initialValues={storedFormValues}
              lastApiImportJobLog={lastApiImportJobLog}
            />
          )}
        </div>
      )}
    </JobLogPollingContainer>
  )
}
