import { ButtonLink } from '@/frontend/components'
import { AccountingSoftware } from '../types'

export default function ApiAuthButton({
  accountingSoftware,
  apiAuthorizeUrl,
}: {
  accountingSoftware: AccountingSoftware
  apiAuthorizeUrl: string
}) {
  return accountingSoftware.isApiAuthed ? (
    <ButtonLink variant="secondary" href={apiAuthorizeUrl}>
      連携先を変更する
    </ButtonLink>
  ) : (
    <ButtonLink variant="primary" href={apiAuthorizeUrl}>
      連携の設定をする
    </ButtonLink>
  )
}
