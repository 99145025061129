import { Breadcrumbs } from '@/frontend/components'

const breadCrumbItems = [{ label: '管理' }, { label: '組織' }]

export default function PageHeader() {
  return (
    <div className="py-2 px-8 bg-white">
      <Breadcrumbs items={breadCrumbItems} />
    </div>
  )
}
