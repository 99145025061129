export function getJobStatusMap(job_log_status: string | null) {
  switch (job_log_status) {
    case 'pending':
    case 'queued':
      return { variant: 'notYet', labelText: '未インポート' }
    case 'in_progress':
      return { variant: 'loading', labelText: 'インポート中' }
    case 'succeeded':
      return { variant: 'completed', labelText: 'インポート完了' }
    case 'failed':
      return { variant: 'error', labelText: 'インポート失敗' }
    case 'none':
    default:
      return { variant: 'empty', labelText: '未アップロード' }
  }
}
