import type { ReportGeneralComment } from '@/frontend/api'
import { GeneralComment } from '@/frontend/features/reportGeneralComments'
import { ReportMeta } from '@/frontend/features/reportGeneralComments/types'

declare global {
  interface DocumentEventMap {
    'report-general-comments-focus': CustomEvent<string>
  }
}
type Props = {
  orgId: number
  reportMeta: ReportMeta
  reportGeneralComment?: ReportGeneralComment
}

export default function ReportGeneralComment({
  orgId,
  reportMeta,
  reportGeneralComment,
}: Props) {
  return (
    <GeneralComment
      orgId={orgId}
      reportMeta={reportMeta}
      reportGeneralComment={reportGeneralComment}
    />
  )
}
