import BudgetDropdowns from '../../../components/BudgetDropdowns'

type Version = {
  id: number
  name: string
  result: boolean
  current: boolean
  archived: boolean
}

type PastResult = {
  key: 'previous_result' | 'before_previous_result'
}

type OptionBudgetContainer = {
  budget: Version | PastResult
  type: string
  border_date?: string
}

type Props = {
  option_budget_containers: OptionBudgetContainer[]
  org_versions: Version[]
}

function toId(x: Version | PastResult): string {
  if (isVersion(x)) {
    return x.id.toString()
  } else {
    return x.key
  }
}

function isVersion(x: Version | PastResult): x is Version {
  return !('key' in x)
}

// '2010-01-01' => '2010-01'
function toBorderDate(str: string): string {
  const x = new Date(str)
  const y = x.getFullYear()
  const m = (x.getMonth() + 1).toString().padStart(2, '0')
  return `${y}-${m}`
}

function defaultBorderDate(): string {
  const x = new Date()
  const y = x.getFullYear()
  const m = (x.getMonth() + 1).toString().padStart(2, '0')
  return `${y}-${m}`
}

export default function Show({
  option_budget_containers,
  org_versions,
}: Props) {
  const result = org_versions.find((v) => v.result)
  const budgets = org_versions.filter((v) => !v.result)

  const defaultBudget = budgets.find((e) => e.current) || budgets[0]
  const defaultBudgetId = defaultBudget ? toId(defaultBudget) : ''
  const defaultValues = option_budget_containers.map((bc) => {
    return {
      type: bc.type,
      normal: {
        id: toId(bc.budget),
      },
      landingEstimation: {
        id: isVersion(bc.budget)
          ? bc.budget.result
            ? defaultBudgetId
            : toId(bc.budget)
          : defaultBudgetId,
        borderDate: bc.border_date
          ? toBorderDate(bc.border_date)
          : defaultBorderDate(),
      },
    }
  })

  const selectBudgetIds = option_budget_containers.flatMap((bc) => {
    return [
      toId(bc.budget),
      isVersion(bc.budget)
        ? bc.budget.result
          ? defaultBudgetId
          : toId(bc.budget)
        : defaultBudgetId,
    ]
  })

  if (!result) {
    throw new Error('実績が見つかりませんでした')
  }
  const displaySubjects = [
    {
      id: result.id.toString(),
      name: result.name,
      result: true,
    },
    {
      id: 'previous_result',
      name: '前年実績',
      result: true,
    },
    {
      id: 'before_previous_result',
      name: '前々年実績',
      result: true,
    },
    ...budgets
      .filter((v) => !v.archived || selectBudgetIds.includes(v.id.toString()))
      .map((v) => ({
        id: v.id.toString(),
        name: v.name,
        result: false,
      })),
  ]

  return (
    <BudgetDropdowns
      displaySubjects={displaySubjects}
      defaultValues={defaultValues}
    />
  )
}
