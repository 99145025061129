import { useMemo, useState } from 'react'

import { Dropdown } from '@/frontend/features/clients/AssignMembersSelect/Dropdown'
import { DropdownIndicatorContainer } from '@/frontend/features/clients/AssignMembersSelect/DropdownIndicatorContainer'
import { TriggerContainer } from '@/frontend/features/clients/AssignMembersSelect/TriggerContainer'
import { OptionSingleSelect } from './OptionSingleSelect'
import { SingleValueBadge } from './SingleValueBadge'

export function SingleSelect<TValue>({
  value,
  name,
  items,
  onChange,
  onMenuOpen,
  onMenuClose,
  disabled,
  truncate,
  blankOption,
}: {
  name?: string // Form input name
  value: TValue | undefined // initial value
  items: { id: TValue; name: string }[] // options
  onChange: (value: TValue | undefined) => void // value change callback
  onMenuClose?: () => void // callback when menuclose
  onMenuOpen?: () => void // callback when menuopen
  disabled?: boolean
  truncate?: boolean
  blankOption?: { id: TValue; name: string }
}) {
  // NOTE: ドロップダウンを開いたときに選択前の値を保持するよ
  //       2回目以降のときに選択されたものを並べかえるためだよ
  const [beforeValue, setBeforeValue] = useState(value)
  const [isOpen, setIsOpen] = useState(false)
  const options = useMemo(() => {
    const mergedItems = blankOption ? [blankOption, ...items] : items
    const sortedItems = mergedItems
      .map((item) => ({
        label: item.name,
        value: item.id,
      }))
      .sort((a, b) => {
        if (!beforeValue) {
          return 0
        }
        // NOTE: 選択されたもの順番を上に持ってくるよ
        return Number(beforeValue == b.value) - Number(beforeValue == a.value)
      })

    return sortedItems
  }, [items, beforeValue, blankOption])

  const handleOnOpen = () => {
    setIsOpen(true)
    setBeforeValue(value)
    onMenuOpen?.()
  }

  const handleOnClose = () => {
    setIsOpen(false)
    onMenuClose?.()
  }

  const handleOnChange = (value: TValue | undefined) => {
    onChange(value)
    handleOnClose()
  }

  return (
    <div>
      <Dropdown
        isOpen={isOpen}
        onOpen={handleOnOpen}
        onClose={handleOnClose}
        disabled={disabled}
        trigger={
          <TriggerContainer>
            <DropdownIndicatorContainer truncate={truncate}>
              <SingleValueBadge
                placeholder=""
                value={items.find((item) => value === item.id)?.name}
                truncate={truncate}
              />
            </DropdownIndicatorContainer>
          </TriggerContainer>
        }
        menuZIndex={20}
      >
        <OptionSingleSelect<TValue>
          name={name}
          options={options}
          value={value}
          placeholder="検索"
          onChange={handleOnChange}
          noOptionsMessage={NoOptionsMessage}
        />
      </Dropdown>
    </div>
  )
}

function NoOptionsMessage() {
  return <span className="text-sm">項目がありません</span>
}
