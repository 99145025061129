import { TableHead } from './TableHead'

export function TableSkeleton() {
  return (
    <div className="overflow-hidden ring-1 ring-gray-200 rounded">
      <table className="w-full divide-y divide-gray-200 text-sm text-gray-800 text-left">
        <TableHead />
        <tbody className="divide-y divide-gray-200">
          {[...Array(4)].map((_, index) => (
            <tr key={index}>
              <td className="py-4 px-4">
                <div className="py-[3px]">
                  <div className="h-[14px] max-w-[100px] bg-gray-100 rounded-full" />
                </div>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
