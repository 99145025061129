import useSWR, { useSWRConfig } from 'swr'

import {
  getActiveTransitionTableImportUrl,
  Org,
  TransitionTableImport,
} from '@/frontend/api'
import { fetcher } from '@/frontend/utils'

export function useActiveTransitionTableImport(org: Org) {
  const url = getActiveTransitionTableImportUrl(org.id)
  const { data, isLoading } = useSWR<TransitionTableImport, Error>(url, fetcher)
  const { mutate } = useSWRConfig()
  const refetch = () => {
    mutate(url)
  }
  return { data, isLoading, refetch }
}
