import { Org } from '@/frontend/api'
import {
  AllocationVersionPayload,
  Form,
  useCreateAllocationVersion,
} from '@/frontend/features/allocationVersions'
import {
  AccountItem,
  AccountItemGroup,
  AllocationDriver,
  BudgetTag,
  BudgetTagGroup,
  FiscalPeriod,
  ReservedItem,
} from '@/frontend/features/allocationVersions/types'
import useLocalStorage from '@/frontend/hooks/useLocalStorage'

type storedValueStorageType = { [id: number]: unknown }

type Props = {
  org: Org
  fiscalPeriods: FiscalPeriod[]
  reservedItems: ReservedItem[]
  accountItemGroups: AccountItemGroup[]
  accountItems: AccountItem[]
  budgetTagGroups: BudgetTagGroup[]
  budgetTags: BudgetTag[]
  allocationDrivers: AllocationDriver[]
  allocationVersionPayload?: AllocationVersionPayload
  allocationVersionsUrl: string
  accountItemsUrl: string
  reservedItemsUrl: string
}

export default function NewPage({
  org,
  fiscalPeriods,
  reservedItems,
  accountItemGroups,
  accountItems,
  budgetTagGroups,
  budgetTags,
  allocationDrivers,
  allocationVersionPayload,
  allocationVersionsUrl,
  accountItemsUrl,
  reservedItemsUrl,
}: Props) {
  const STORED_VALUES_STORAGE_KEY = `AllocationVersionStoredValueOnNew` as const
  const { createItem } = useCreateAllocationVersion(org)

  const [storedValues, setStoreValues] =
    useLocalStorage<storedValueStorageType>(STORED_VALUES_STORAGE_KEY)
  const orgScopedStoredValues = storedValues
    ? (storedValues[org.id] as AllocationVersionPayload)
    : undefined

  const saveOrgScopedValues = (value: unknown) => {
    setStoreValues((prev) =>
      prev ? { ...prev, [org.id]: value } : { [org.id]: value },
    )
  }

  const clearStoredValues = () => {
    saveOrgScopedValues(undefined)
  }

  const onChangeForm = ({
    value,
    type,
  }: {
    value: unknown
    name: string | undefined
    type: string | undefined
  }) => {
    if (type) {
      saveOrgScopedValues(value)
    }
  }

  const onSubmit = async (data: AllocationVersionPayload) => {
    clearStoredValues()
    return await createItem(data)
  }
  const onCancel = () => {
    clearStoredValues()
  }

  return (
    <>
      <Form
        org={org}
        fiscalPeriods={fiscalPeriods}
        reservedItems={reservedItems}
        accountItemGroups={accountItemGroups}
        accountItems={accountItems}
        budgetTagGroups={budgetTagGroups}
        budgetTags={budgetTags}
        allocationDrivers={allocationDrivers}
        allocationVersionPayload={
          orgScopedStoredValues || allocationVersionPayload
        }
        allocationVersionsUrl={allocationVersionsUrl}
        accountItemsUrl={accountItemsUrl}
        reservedItemsUrl={reservedItemsUrl}
        onSubmit={onSubmit}
        onChangeForm={onChangeForm}
        onCancel={onCancel}
      />
    </>
  )
}
