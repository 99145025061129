// import { createErr, createOk, Result } from 'option-t/PlainResult'
import { createErr, createOk, Result } from 'option-t/esm/PlainResult'
import { FileWithPath } from 'react-dropzone'

import { axios, z } from '@/frontend/utils'
import { bulkUploadTransitionTableImportItemsUrl } from '../../routes'
import { ApiError } from '../../types'
import { handleAxiosError } from '../../utils'

export const transitionTableImportItemBulkUpsertSchema = z.object({
  transitionTableImportItems: z.array(
    z.object({
      budgetTagId: z.number().nullable(),
      csvFile: z.custom<FileWithPath>().nullable(),
    }),
  ),
})

export type TransitionTableImportItemBulkUpsertPayload = z.infer<
  typeof transitionTableImportItemBulkUpsertSchema
>

export async function bulkUploadTransitionTableImportItems(
  orgId: number,
  transitionTableImportId: number,
  payload: TransitionTableImportItemBulkUpsertPayload,
): Promise<Result<null, ApiError>> {
  const url = bulkUploadTransitionTableImportItemsUrl(
    orgId,
    transitionTableImportId,
  )
  try {
    const prefixKey = 'transition_table_import_items[]'
    const formData = new FormData()
    payload.transitionTableImportItems.forEach((item) => {
      formData.append(
        `${prefixKey}[budget_tag_id]`,
        item.budgetTagId?.toString() ?? '',
      )
      formData.append(`${prefixKey}[csv_file]`, item.csvFile as FileWithPath)
    })

    await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      // transformRequestするとmultipartが正しく送られないため空にする
      transformRequest: [],
    })
    return createOk(null)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
