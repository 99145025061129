import { useCallback, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { FileRejection, FileWithPath } from 'react-dropzone'
import { useForm } from 'react-hook-form'

import { ApiError } from '@/frontend/api'
import {
  MfcCsvSourceAccountItemPayload,
  mfcCsvSourceAccountItemSchema,
} from '@/frontend/api/importer'
import { ApiAlert } from '@/frontend/components'
import { Alert } from '@/frontend/components/Alert/Alert'
import { Button } from '@/frontend/components/buttons/Button'
import { ImportStatus } from '@/frontend/features/importers/components/ImportStatus'
import Dropzone from '../components/Dropzone'
import LastImportedAt from '../components/LastImportedAt'
import { LastImportStatus } from '../components/LastImportStatus'
import { AlertStatus } from '../hooks/useAlertStatus'
import { ImportFileStatus, JobLogWithAttachedFile } from '../types'

type Props = {
  importFileStatus: ImportFileStatus | null
  onFileChange: (file: FileWithPath) => void
  onSubmit: (data: MfcCsvSourceAccountItemPayload) => void
  isLoading: boolean
  apiError?: ApiError
  alertStatus: AlertStatus | null
  jobLogWithAttachedFile: JobLogWithAttachedFile | null
}

export default function MasterImportForm({
  importFileStatus,
  onFileChange,
  onSubmit,
  isLoading,
  apiError,
  alertStatus,
  jobLogWithAttachedFile,
}: Props) {
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([])
  const { setValue, watch, handleSubmit } =
    useForm<MfcCsvSourceAccountItemPayload>({
      resolver: zodResolver(mfcCsvSourceAccountItemSchema),
      defaultValues: {
        file: null,
      },
    })

  const file = watch('file')

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
      if (acceptedFiles.length <= 0) return
      const acceptedFile = acceptedFiles[0]
      onFileChange(acceptedFile as FileWithPath)
      setValue('file', acceptedFile as FileWithPath)
      setFileRejections(fileRejections)
    },
    [setValue],
  )

  const _onSubmit = handleSubmit((data) => {
    onSubmit(data)
    setValue('file', null)
  })

  const errorMessage =
    fileRejections.length > 0 ? 'CSVファイルを選択してください' : ''

  return (
    <form onSubmit={_onSubmit}>
      <div className="space-y-4">
        {errorMessage && <Alert title={errorMessage} />}
        {apiError && (
          <div className="py-4">
            <ApiAlert apiError={apiError} />
          </div>
        )}
        <Dropzone onDrop={onDrop} disabled={isLoading} />
        {importFileStatus && (
          <LastImportStatus
            importFileStatus={importFileStatus}
            loading={isLoading}
          />
        )}

        {alertStatus && (
          <ImportStatus
            status={alertStatus.status}
            errorDetail={alertStatus.errorDetail}
          />
        )}
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <LastImportedAt
              lastImportedAt={jobLogWithAttachedFile?.createdAt}
            />
          </div>
          <div className="mt-auto">
            <Button
              type="submit"
              variant="primary"
              loading={isLoading}
              disabled={file == null}
            >
              インポート
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}
