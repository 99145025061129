export default function SelectedCompanyLabel({
  companySelected,
  companyName,
}: {
  companySelected: boolean
  companyName: string
}) {
  return (
    companySelected && (
      <span className="text-sm">{`連携中の事業所： ${companyName}`}</span>
    )
  )
}
