import { useRef } from 'react'

import { NonFinancialItem, Org } from '@/frontend/api'
import { ApiAlert, Button, ModalWithIcon } from '@/frontend/components'
import { useDeleteNonFinancialItem } from './useDeleteNonFinancialItem'

type Props = {
  org: Org
  nonFinancialItem: NonFinancialItem
  open: boolean
  onClose: () => void
}

export function DeleteModal({ org, nonFinancialItem, open, onClose }: Props) {
  const cancelButtonRef = useRef(null)
  const { submit, isSubmitting, apiError } = useDeleteNonFinancialItem(
    org,
    nonFinancialItem,
  )

  return (
    <ModalWithIcon
      variant="danger"
      open={open}
      onClose={() => onClose()}
      initialFocus={cancelButtonRef}
      title="削除の確認"
      actions={
        nonFinancialItem && (
          <>
            <Button variant="outlined" onClick={onClose} ref={cancelButtonRef}>
              キャンセル
            </Button>
            <Button
              type="submit"
              variant="dangerPrimary"
              disabled={!open}
              loading={isSubmitting}
              onClick={() => submit()}
            >
              削除
            </Button>
          </>
        )
      }
      errorAlert={apiError && <ApiAlert apiError={apiError} autoScroll />}
    >
      {nonFinancialItem && (
        <>
          非財務項目「{nonFinancialItem.name}
          」を削除しますか？
          <br />
          詳細計画で使用されている場合はそれらも削除されます。
          <br />
          この操作は巻き戻しできません。
        </>
      )}
    </ModalWithIcon>
  )
}
