import useSWR from 'swr'

import { FiscalPeriod, getFiscalPeriodsUrl, Org } from '@/frontend/api'
import { fetcher } from '@/frontend/utils'

export function useFiscalPeriods(org: Org) {
  const url = getFiscalPeriodsUrl(org.id)
  return useSWR<Pick<FiscalPeriod, 'id' | 'name' | 'current'>[], Error>(
    url,
    fetcher,
  )
}
