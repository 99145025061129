import { forwardRef } from 'react'
import { tv } from 'tailwind-variants'

type Props = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'type' | 'className'
>

const input = tv({
  base: [
    'ReactComponentInput', // 全体の disabled スタイルを回避するため app/javascript/src/scss/_input.scss
    'h-4 w-4 text-blue-600',
    'rounded border-gray-300',
    'transition duration-150 ease-in-out',
    'focus:text-blue-700 focus:outline focus:outline-blue-100 focus:outline-offset-0 focus:outline-[3px] focus:ring-gray-300 focus:ring-inset focus:shadow-none focus:ring-0 focus:ring-offset-0',
  ].join(' '),
  variants: {
    disabled: {
      true: 'border-none bg-gray-100 text-gray-300 ring-0 pointer-events-none',
    },
  },
})

export const InputCheckBox = forwardRef<HTMLInputElement, Props>(function Input(
  { ...props },
  ref,
) {
  // const baseClassName =
  //   'rounded border-gray-300 h-4 w-4 transition duration-150 ease-in-out cursor-pointer'
  // const validClassName =
  //   'text-blue-600 focus:ring-blue-200 focus:border-blue-300'
  // const invalidClassName =
  //   'text-red-600 focus:ring-red-200 focus:border-red-300'
  // const className = classNames({
  //   [baseClassName]: true,
  //   [validClassName]: !invalid,
  //   [invalidClassName]: invalid,
  // })

  return (
    <input
      className={input({ disabled: props.disabled })}
      ref={ref}
      type="checkbox"
      {...props}
    />
  )
})
