import { useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { Org } from '@/frontend/api'
import {
  BugyoCloudApiUpdateCompanyPayload,
  bugyoCloudApiUpdateCompanySchema,
} from '@/frontend/api/importer'
import ApiCompanyButton from '../components/ApiCompanyButton'
import { SelectCompanyModal } from '../components/SelectCompanyModal'
import SelectedCompanyLabel from '../components/SelectedCompanyLabel'
import { BugyoCloudCompany } from '../types'
import { useUpdateBugyoCloudCompanyApi } from './useUpdateBugyoCloudCompanyApi'

type Props = {
  org: Org
  bugyoCloudCompany: BugyoCloudCompany | undefined
  bugyoCloudCompanies: BugyoCloudCompany[]
  onChangeCompany: (company: BugyoCloudCompany | undefined) => void
}

export default function ApiCompanySelector({
  org,
  bugyoCloudCompany,
  bugyoCloudCompanies,
  onChangeCompany,
}: Props) {
  const [sendingId, setSendingId] = useState<string | null>(
    bugyoCloudCompany?.id || null,
  )
  const [isSelectCompanyModalOpen, setIsSelectCompanyModalOpen] =
    useState(false)
  const {
    submit: submitUpdateCompany,
    isSubmitting,
    apiError,
  } = useUpdateBugyoCloudCompanyApi(org)

  const { control, handleSubmit } = useForm<BugyoCloudApiUpdateCompanyPayload>({
    resolver: zodResolver(bugyoCloudApiUpdateCompanySchema),
    defaultValues: {
      companyId: bugyoCloudCompany?.id,
    },
  })

  useEffect(() => {
    // NOTE: APIが成功したら事業所の変更を反映&モーダルを閉じる
    if (!isSubmitting && !apiError) {
      onChangeCompany(
        bugyoCloudCompanies.find((company) => company.id === sendingId),
      )
      setIsSelectCompanyModalOpen(false)
    }
  }, [apiError, isSubmitting])

  const handleOnSubmit = (data: BugyoCloudApiUpdateCompanyPayload) => {
    setSendingId(data.companyId)
    submitUpdateCompany(data)
  }

  const formId = 'update-bugyo-cloud-api-company-form'

  return (
    <div>
      <div className="flex items-center space-x-4">
        <ApiCompanyButton
          companySelected={!!bugyoCloudCompany}
          onClick={() => {
            setIsSelectCompanyModalOpen(true)
          }}
        />
        <SelectedCompanyLabel
          companySelected={!!bugyoCloudCompany}
          companyName={bugyoCloudCompany?.name || ''}
        />
      </div>

      <form id={formId} onSubmit={handleSubmit(handleOnSubmit)}>
        <SelectCompanyModal
          formId={formId}
          control={control}
          isOpen={isSelectCompanyModalOpen}
          isLoading={isSubmitting}
          apiError={apiError}
          onClose={() => {
            setIsSelectCompanyModalOpen(false)
          }}
          companies={bugyoCloudCompanies}
        />
      </form>
    </div>
  )
}
