import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { RiCloseFill } from 'react-icons/ri'

type Props = {
  open: boolean
  onClose: () => void
  initialFocus?: React.MutableRefObject<null>
  children: React.ReactNode
  title: string
  description?: string
  actions: React.ReactNode
  scrollable?: boolean
}

export function Modal({
  open,
  onClose,
  initialFocus,
  children,
  title,
  description,
  actions,
  scrollable = true,
}: Props) {
  const scrollableClass = scrollable ? 'overflow-y-auto' : ''

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={initialFocus}
        onClose={() => onClose()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </Transition.Child>

        <div className={`fixed inset-0 z-10 ${scrollableClass}`}>
          <div className="flex min-h-full h-full justify-center items-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform ${scrollableClass} rounded-md bg-white text-left shadow-xl transition-all w-full max-w-lg max-h-full flex flex-col text-gray-800`}
              >
                <div className="flex-shrink-0 min-h-0 py-6 px-8 flex items-start justify-between border-b border-gray-200">
                  <div className="space-y-1">
                    <Dialog.Title as="h3" className="text-lg font-bold">
                      {title}
                    </Dialog.Title>
                    <div className="text-sm leading-5">{description}</div>
                  </div>
                  <button type="button" onClick={onClose} className="block">
                    <RiCloseFill
                      className="w-6 h-6 cursor-pointer"
                      onClick={onClose}
                    />
                  </button>
                </div>
                <div className={`flex-auto min-h-0 ${scrollableClass}`}>
                  {children}
                </div>
                <div className="flex-shrink-0 min-h-0 py-4 px-8 border-t border-gray-200 flex justify-end space-x-4">
                  {actions}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
