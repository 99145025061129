import { createErr, createOk, Result } from 'option-t/PlainResult'

import { updateTransitionTableImportUrl } from '@/frontend/api/routes'
import { axios } from '@/frontend/utils'
import { ApiError, TransitionTableImport } from './types'
import { handleAxiosError } from './utils'

export type TransitionTableImportPayload = {
  active: boolean
}

export async function updateTransitionTableImport(
  orgId: number,
  transitionTableImportId: number,
  data: TransitionTableImportPayload,
): Promise<Result<TransitionTableImport, ApiError>> {
  const url = updateTransitionTableImportUrl(orgId, transitionTableImportId)
  try {
    const res = await axios.put(url, data)
    const transitionTableImport = res.data as TransitionTableImport
    return createOk(transitionTableImport)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
