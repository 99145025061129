import { useContext } from 'react'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'

import { dropdownContext } from './dropdownContext'

type Props = {
  children: React.ReactNode
  truncate?: boolean
}

export function DropdownIndicatorContainer({ children, truncate }: Props) {
  const { isOpen } = useContext(dropdownContext)

  return (
    <div className="inline-flex justify-between items-center w-full">
      <div className={truncate ? 'w-9/12' : 'w-full'}>{children}</div>
      <div className="flex-none text-lg text-gray-800">
        {isOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
      </div>
    </div>
  )
}
