import { useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { RiSettings2Fill } from 'react-icons/ri'
import { usePopper } from 'react-popper'

import { Slider } from './types'

type Props = {
  instantSimulatorSlideOverId: string
  settingSlideOverId: string
  costTypesSlideOverId: string
  borrowingsPath: string
  plState: boolean
  accountingFirmPlan: boolean
  slider: Slider
  openPlItemDefinitionsSlideOver: () => void
}

export function Dropdown({
  instantSimulatorSlideOverId,
  settingSlideOverId,
  costTypesSlideOverId,
  borrowingsPath,
  plState,
  accountingFirmPlan,
  slider,
  openPlItemDefinitionsSlideOver,
}: Props) {
  const {
    contraItemsDisabled,
    contraItemsHidden,
    simpleContraItemsDisabled,
    simpleContraItemsHidden,
    instantSimulatorDisabled,
    instantSimulatorHidden,
    costTypesDisabled,
    costTypesHidden,
    borrowingsHidden,
    borrowingsDisabled,
    settingDisabled,
    settingHidden,
  } = slider
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  )
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
  })

  return (
    <>
      <Popover>
        <div className="btn-wrapper">
          <Popover.Button
            ref={setReferenceElement}
            className="btn btn-sm btn-white"
          >
            <RiSettings2Fill />
          </Popover.Button>
        </div>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          className="absolute z-50 mt-1"
        >
          <Popover.Panel
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <div className="simple-dropdown">
              <div className="simple-dropdown-content whitespace-nowrap">
                <div className="py-1">
                  {!contraItemsHidden && (
                    <button
                      className="simple-dropdown-link-item"
                      data-action="dropdown#hide"
                      onClick={openPlItemDefinitionsSlideOver}
                      type="button"
                      disabled={contraItemsDisabled}
                    >
                      <span
                        className="inline-block w-full text-left"
                        data-tooltip={(() => {
                          if (contraItemsDisabled) {
                            return '権限がないため操作いただけません。'
                          }
                        })()}
                      >
                        BS・CF項目設定
                      </span>
                    </button>
                  )}
                  {!simpleContraItemsHidden && (
                    <button
                      className="simple-dropdown-link-item"
                      data-action="dropdown#hide"
                      onClick={openPlItemDefinitionsSlideOver}
                      type="button"
                      disabled={simpleContraItemsDisabled}
                    >
                      <span className="inline-block w-full text-left">
                        CF項目設定
                      </span>
                    </button>
                  )}
                  {!instantSimulatorHidden && (
                    <button
                      className="simple-dropdown-link-item"
                      data-action="dropdown#hide"
                      data-slideover-open={`#${instantSimulatorSlideOverId}`}
                      type="button"
                      disabled={instantSimulatorDisabled}
                    >
                      <span
                        className="inline-block w-full text-left"
                        data-tooltip={(() => {
                          if (!plState) {
                            return '計画PLの画面から操作できます。'
                          } else if (instantSimulatorDisabled) {
                            return '「すべて」以外のタグが選択されている場合に操作できます。'
                          }
                        })()}
                      >
                        数値の一括操作
                      </span>
                    </button>
                  )}
                  {!costTypesHidden && (
                    <button
                      className="simple-dropdown-link-item"
                      data-action="dropdown#hide"
                      data-slideover-open={`#${costTypesSlideOverId}`}
                      type="button"
                      disabled={costTypesDisabled}
                    >
                      <span
                        className="inline-block w-full text-left"
                        data-tooltip={(() => {
                          if (!plState) {
                            return '計画PLの画面から操作できます。'
                          } else if (costTypesDisabled) {
                            return '[未設定]以外のタグが選択されている場合に操作できます。'
                          }
                        })()}
                      >
                        変動費設定
                      </span>
                    </button>
                  )}
                  {!borrowingsHidden && (
                    <a
                      href={borrowingsDisabled ? undefined : borrowingsPath}
                      data-action="dropdown#hide"
                      className={`simple-dropdown-link-item inline-block w-full text-left ${
                        borrowingsDisabled ? 'disabled' : ''
                      }`}
                      data-tooltip={(() => {
                        if (borrowingsDisabled) {
                          if (accountingFirmPlan) {
                            return '簡易CFの画面から操作できます。'
                          } else {
                            return '計画CFの画面から操作できます。'
                          }
                        }
                      })()}
                    >
                      借入金設定
                    </a>
                  )}
                  {!settingHidden && (
                    <button
                      className="simple-dropdown-link-item"
                      data-action="dropdown#hide"
                      data-slideover-open={`#${settingSlideOverId}`}
                      type="button"
                      disabled={settingDisabled}
                    >
                      <span
                        className="inline-block w-full text-left"
                        data-tooltip={(() => {
                          if (settingDisabled) {
                            return '権限がないため操作いただけません。'
                          }
                        })()}
                      >
                        計画ロック
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  )
}
