import { Org } from '@/frontend/api'
import MappingFormContainer from '@/frontend/features/importers/transition_table_import/MappingFormContainer'
import SectionLayout from '@/frontend/features/importers/transition_table_import/SectionLayout'
import {
  AccountItem,
  AccountItemMapping,
  CsvAccountItem,
  CsvHeader,
  MonthlyMapping,
} from '@/frontend/features/importers/transition_table_import/types'

type Props = {
  org: Org
  formAuthenticityToken: string
  budgetId: number
  transitionTableImportId: number
  initialAccountItemMappings: AccountItemMapping[]
  initialMonthlyMappings: MonthlyMapping[]
  accountItems: AccountItem[]
  csvAccountItems: CsvAccountItem[]
  csvHeaders: CsvHeader[]
  headerRowNumber: number
  accountItemColumnName: string
}

export default function MappingPage({
  org,
  formAuthenticityToken,
  budgetId,
  transitionTableImportId,
  initialAccountItemMappings,
  initialMonthlyMappings,
  accountItems,
  csvAccountItems,
  csvHeaders,
  headerRowNumber,
  accountItemColumnName,
}: Props) {
  return (
    <SectionLayout
      sectionWidthClass="max-w-7xl"
      title={
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          推移表インポート
        </h3>
      }
    >
      <MappingFormContainer
        org={org}
        formAuthenticityToken={formAuthenticityToken}
        budgetId={budgetId}
        transitionTableImportId={transitionTableImportId}
        initialAccountItemMappings={initialAccountItemMappings}
        initialMonthlyMappings={initialMonthlyMappings}
        accountItems={accountItems}
        csvAccountItems={csvAccountItems}
        csvHeaders={csvHeaders}
        headerRowNumber={headerRowNumber}
        accountItemColumnName={accountItemColumnName}
      />
    </SectionLayout>
  )
}
