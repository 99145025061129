import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { NodeApi } from 'react-arborist'

import { Budget, BudgetFolder, Data } from './type'

type Props = {
  node: NodeApi<Data>
  editModalOpen: boolean
  onEditModalClose: () => void
}

export function EditModal({ node, editModalOpen, onEditModalClose }: Props) {
  const item = node.data.item
  const data = node.data
  switch (item.type) {
    case 'Budget':
      return (
        <BudgetEditModal
          item={item}
          data={data}
          editModalOpen={editModalOpen}
          onEditModalClose={onEditModalClose}
        />
      )
    case 'BudgetFolder':
      return (
        <BudgetFolderEditModal
          item={item}
          data={data}
          editModalOpen={editModalOpen}
          onEditModalClose={onEditModalClose}
        />
      )
  }
}

function BudgetEditModal({
  item,
  data,
  editModalOpen,
  onEditModalClose,
}: {
  item: Budget
  data: Data
  editModalOpen: boolean
  onEditModalClose: () => void
}) {
  const subProps = data.subProps
  const [name, setName] = useState(item.name)
  const [memo, setMemo] = useState(item.memo)
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(() => e.target.value)
  }
  const handleMemoChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMemo(() => e.target.value)
  }
  const handleKeyDownEventPropagationCancel = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation()
  }

  return (
    <Dialog
      open={editModalOpen}
      onClose={onEditModalClose}
      className="relative z-50"
      onKeyDown={handleKeyDownEventPropagationCancel}
    >
      <div
        className="fixed inset-0 bg-gray-600 opacity-75"
        aria-hidden="true"
      />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
          <form action={item.updateUrl} acceptCharset="UTF-8" method="post">
            <input type="hidden" readOnly name="_method" value="patch" />
            <input
              type="hidden"
              readOnly
              name="authenticity_token"
              value={subProps.formAuthenticityToken}
            />
            <div>
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  計画の編集
                </h3>
                <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                  更新する項目に新しい値を入力してください。
                </p>
              </div>
              <div>
                <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <div className="flex justify-between">
                    <label
                      className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                      htmlFor="budget_name"
                    >
                      名称
                    </label>
                  </div>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="w-64 text-black">
                      <div className="rounded-md shadow-sm">
                        <input
                          className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 "
                          onChange={handleNameChange}
                          required={true}
                          type="text"
                          value={name}
                          name="budget[name]"
                          id="budget_name"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <div className="flex justify-between">
                    <label
                      className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                      htmlFor="budget_memo"
                    >
                      メモ
                    </label>
                  </div>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="w-64 text-black">
                      <div className="rounded-md shadow-sm">
                        <textarea
                          className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 "
                          onChange={handleMemoChange}
                          value={memo}
                          name="budget[memo]"
                          id="budget_memo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-5">
              <div className="flex justify-end">
                <div className="btn-wrapper">
                  <button
                    className="btn btn-white"
                    data-modal-close=""
                    type="button"
                    onClick={onEditModalClose}
                  >
                    キャンセル
                  </button>
                </div>
                <div className="ml-3">
                  <div className="btn-wrapper">
                    <input
                      type="submit"
                      name="commit"
                      value="保存"
                      className="btn btn-primary"
                      data-disable-with="保存"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

function BudgetFolderEditModal({
  item,
  data,
  editModalOpen,
  onEditModalClose,
}: {
  item: BudgetFolder
  data: Data
  editModalOpen: boolean
  onEditModalClose: () => void
}) {
  const subProps = data.subProps
  const [name, setName] = useState(item.name)
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(() => e.target.value)
  }
  const handleKeyDownEventPropagationCancel = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation()
  }

  return (
    <Dialog
      open={editModalOpen}
      onClose={onEditModalClose}
      className="relative z-50"
      onKeyDown={handleKeyDownEventPropagationCancel}
    >
      <div
        className="fixed inset-0 bg-gray-600 opacity-75"
        aria-hidden="true"
      />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
          <form action={data.item.showUrl} acceptCharset="UTF-8" method="post">
            <input type="hidden" readOnly name="_method" value="patch" />
            <input
              type="hidden"
              readOnly
              name="authenticity_token"
              value={subProps.formAuthenticityToken}
            />
            <div>
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  フォルダの編集
                </h3>
                <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                  更新する項目に新しい値を入力してください。
                </p>
              </div>
              <div>
                <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <div className="flex justify-between">
                    <label
                      className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                      htmlFor="budget_folder_name"
                    >
                      名称
                    </label>
                  </div>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="w-64 text-black">
                      <div className="rounded-md shadow-sm">
                        <input
                          className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 "
                          onChange={handleNameChange}
                          required={true}
                          type="text"
                          value={name}
                          name="budget_folder[name]"
                          id="budget_folder_name"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-5">
              <div className="flex justify-end">
                <div className="btn-wrapper">
                  <button
                    className="btn btn-white"
                    data-modal-close=""
                    type="button"
                    onClick={onEditModalClose}
                  >
                    キャンセル
                  </button>
                </div>
                <div className="ml-3">
                  <div className="btn-wrapper">
                    <input
                      type="submit"
                      name="commit"
                      value="保存"
                      className="btn btn-primary"
                      data-disable-with="保存"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
