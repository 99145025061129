import { useCallback, useState } from 'react'
import { FileWithPath } from 'react-dropzone'
import { RiArrowRightSLine } from 'react-icons/ri'

import { BudgetTagCategory } from '@/frontend/api'
import { BudgetTag, JobLogMessage, JobLogStatus } from '@/frontend/api/types'
import { Status, StatusLabel } from '@/frontend/components'
import { getJobStatusMap } from '../../getJobStatusMap'
import JobLogErrorDetailModal from '../JobLogErrorDetailModal'
import TransitionTableAssignedRow from './TransitionTableAssignedRow'
import TransitionTableIndividuallyDropzone from './TransitionTableIndividuallyDropzone'

export type TableRowInfo = {
  budgetTag: BudgetTag
  file: FileWithPath | null
  status: JobLogStatus | null
  jobLogMessage?: JobLogMessage | null
}

type TableRowProps = TableRowInfo & {
  onFileDrop: (budgetTagId: number, file: FileWithPath) => void
}

const TableRow = ({
  budgetTag,
  file,
  status,
  jobLogMessage,
  onFileDrop,
}: TableRowProps) => {
  const { variant, labelText } = getJobStatusMap(status)

  const onDrop = (files: FileWithPath[]) => {
    if (files[0]) {
      onFileDrop(budgetTag.id, files[0])
    }
  }

  return (
    <tr>
      <td className="px-4 py-4 w-1/4">{budgetTag.name}</td>
      <td className="px-4 py-4 flex justify-between items-center">
        {file ? (
          <TransitionTableAssignedRow onDrop={onDrop} fileName={file.name} />
        ) : (
          <TransitionTableIndividuallyDropzone onDrop={onDrop} />
        )}
      </td>
      <td className="px-4 py-4 w-1/4">
        <StatusLabelWithModal
          jobLogMessage={jobLogMessage}
          variant={variant as Status}
          loading={variant === 'loading'}
          labelText={labelText}
        />
      </td>
    </tr>
  )
}

const StatusLabelWithModal = ({
  jobLogMessage,
  variant,
  loading,
  labelText,
}: {
  jobLogMessage?: JobLogMessage | null
  variant: Status
  loading: boolean
  labelText: string
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const handleOnClick = useCallback(() => setIsOpenModal(true), [])
  const handleOnClose = useCallback(() => setIsOpenModal(false), [])

  if (!jobLogMessage) {
    return (
      <StatusLabel variant={variant as Status} loading={loading}>
        {labelText}
      </StatusLabel>
    )
  }

  return (
    <>
      <div className="cursor-pointer" onClick={handleOnClick}>
        <StatusLabel variant={variant as Status}>
          <div className="flex">
            {labelText}
            <RiArrowRightSLine className="ml-1 w-4 h-4" />
          </div>
        </StatusLabel>
      </div>
      <JobLogErrorDetailModal
        isOpen={isOpenModal}
        onClose={handleOnClose}
        errorDetail={jobLogMessage}
      />
    </>
  )
}

type TableProps = {
  rows: TableRowInfo[]
  primaryBudgetTagCategory: BudgetTagCategory
  onFileDrop: (budgetTagId: number, file: FileWithPath) => void
}

export default function TransitionTableTagMappingImportTable({
  rows,
  primaryBudgetTagCategory,
  onFileDrop,
}: TableProps) {
  return (
    <div className="border border-gray-300 rounded-lg py-2">
      <div>
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="px-4 py-4 font-bold text-left w-1/4">
                {primaryBudgetTagCategory.name}
              </th>
              <th className="px-4 py-4 font-bold text-left w-1/2">ファイル</th>
              <th className="px-4 py-4 font-bold text-left w-1/4">
                ステータス
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="max-h-[800px] overflow-y-auto">
        <table className="min-w-full table-fixed">
          <tbody>
            {rows.map((row, index) => (
              <TableRow {...row} onFileDrop={onFileDrop} key={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
