import React, { useEffect, useMemo } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { FiscalPeriod, Org } from '@/frontend/api'
import { Button, ButtonLink, InputGroup, Select } from '@/frontend/components'
import FiscalPeriodLink from '@/frontend/features/importers/components/FiscalPeriodLink'
import { indexToAlpha } from '@/frontend/utils/indexToAlpha'
import { ImportSettingPayload, importSettingSchema } from './formSchema'
import { RawCsvTable } from './types'

type Props = {
  org: Org
  formAuthenticityToken: string
  transitionTableImportId: number
  budgetId: number
  fiscalPeriods: Pick<FiscalPeriod, 'id' | 'name' | 'current'>[]
  rawCsvTable: RawCsvTable
}

function getTransitionTableImportMappingUrl(
  orgId: number,
  transitionTableImportId: number,
) {
  return `/orgs/${orgId}/importer/transition_table_imports/${transitionTableImportId}/mapping`
}

function getImporterPageUrl(orgId: number) {
  return `/orgs/${orgId}/importer`
}

export default function ImportSettingForm({
  org,
  formAuthenticityToken,
  transitionTableImportId,
  budgetId,
  fiscalPeriods,
  rawCsvTable,
}: Props) {
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ImportSettingPayload>({
    mode: 'onChange',
    resolver: zodResolver(importSettingSchema),
  })

  const selectedHeaderRowNumber = watch('header_row_number')

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    const form = e.target as HTMLFormElement
    handleSubmit(() => {
      form.submit()
    })(e)
  }

  const headerRowOptions = useMemo(() => {
    return rawCsvTable.map((rows, index) => {
      return {
        rowNumber: index,
        // NOTE: 行番号は表示上では1始まりにしたいので+1
        displayRowNumber: index + 1,
        label: rows[0],
      }
    })
  }, [rawCsvTable])

  const accountItemColumnOptions = useMemo(() => {
    return (
      rawCsvTable[selectedHeaderRowNumber || 0]?.flatMap((column) => {
        return { label: column }
      }) ?? []
    )
  }, [rawCsvTable, selectedHeaderRowNumber])

  // NOTE: 初期値として現在の会計期間に指定されているものを選択するよ
  const initialFiscalPeriodId = useMemo(() => {
    return fiscalPeriods.find((fiscalPeriod) => fiscalPeriod.current)?.id
  }, [fiscalPeriods])

  useEffect(() => {
    if (initialFiscalPeriodId) {
      setValue('fiscal_period_id', initialFiscalPeriodId)
    }
  }, [initialFiscalPeriodId, setValue])

  return (
    <form
      onSubmit={onSubmit}
      className="w-full"
      method="post"
      action={getTransitionTableImportMappingUrl(
        org.id,
        transitionTableImportId,
      )}
    >
      <div className="space-y-6 pb-6 px-6 text-sm">
        <input
          type="hidden"
          readOnly
          name="authenticity_token"
          value={formAuthenticityToken}
        />
        <input type="hidden" readOnly name="budget_id" value={budgetId} />
        <InputGroup
          label="会計期間の選択"
          errorMsg={errors.fiscal_period_id?.message}
        >
          {() => (
            <>
              <Select {...register('fiscal_period_id')}>
                {fiscalPeriods.map((fiscalPeriod) => (
                  <option key={fiscalPeriod.id} value={fiscalPeriod.id}>
                    {fiscalPeriod.name}
                  </option>
                ))}
              </Select>

              <FiscalPeriodLink org={org} />
            </>
          )}
        </InputGroup>
        <InputGroup
          label="ヘッダー（年月）行の指定"
          errorMsg={errors.header_row_number?.message}
        >
          {() => (
            <Select {...register('header_row_number')}>
              {headerRowOptions.map((option) => (
                <option key={option.rowNumber} value={option.rowNumber}>
                  行番号{option.displayRowNumber}: {option.label}
                </option>
              ))}
            </Select>
          )}
        </InputGroup>
        <InputGroup
          label="勘定科目列の指定"
          errorMsg={errors.account_item_column_name?.message}
        >
          {() => (
            <Select {...register('account_item_column_name')}>
              {accountItemColumnOptions.map((option, index) => (
                <option key={index} value={option.label}>
                  列番号{indexToAlpha(index)}: {option.label}
                </option>
              ))}
            </Select>
          )}
        </InputGroup>
      </div>
      <div className="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
        <div className="flex justify-end space-x-3">
          <ButtonLink href={getImporterPageUrl(org.id)}>キャンセル</ButtonLink>
          <Button
            type="submit"
            variant="primary"
            disabled={fiscalPeriods.length === 0}
          >
            次へ
          </Button>
        </div>
      </div>
    </form>
  )
}
