import { useCallback, useState } from 'react'

import { ApiError, Org, TransitionTableImport } from '@/frontend/api'
import {
  TransitionTableImportPayload,
  updateTransitionTableImport,
} from '@/frontend/api/transitionTableImport'

export function useUpdateTransitionTableImport(
  org: Org,
  transitionTableImport: TransitionTableImport,
) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined)

  const submit = useCallback(
    async (data: TransitionTableImportPayload) => {
      setApiError(undefined)
      setIsSubmitting(true)
      const result = await updateTransitionTableImport(
        org.id,
        transitionTableImport.id,
        data,
      )
      if (result.ok) {
        setApiError(undefined)
      } else {
        setApiError(result.err)
      }
      setIsSubmitting(false)
    },
    [org.id, transitionTableImport.id],
  )
  return {
    submit,
    isSubmitting,
    apiError,
  }
}
