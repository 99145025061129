import { RiExternalLinkLine } from 'react-icons/ri'

import { AuthScopes, Org } from '@/frontend/api'
import {
  InputGroup,
  Radio,
  Card as Section,
  Select,
  SelectionGroup,
} from '@/frontend/components'
import { ImportFormat, ImportFormatMap } from '../getImportFormatMap'
import {
  determineBudgetId,
  determineDataFormat,
  determineImportFormat,
  getAvailableImportTargets,
} from '../hooks/useImportSetting'
import { useImportSettingContext } from '../hooks/useImportSettingContext'
import { Budget, DataFormat, ImportSetting, ImportTarget } from '../types'

const DEFAULT_IMPORT_TARGETS: ImportSetting['importTarget'][] = [
  'budget',
  'result',
]
const DEFAULT_DATA_FORMATS: DataFormat[] = ['api', 'csv']

type Props = {
  org: Org
  budgets: Budget[]
  importFormatMap: ImportFormatMap
  authScopes: AuthScopes
  onChange: (changes: Partial<ImportSetting>) => void
}

export default function ImportSettingForm({
  org,
  budgets,
  importFormatMap,
  authScopes,
  onChange,
}: Props) {
  const { importSetting } = useImportSettingContext()

  const handleImportTargetChange = (importTarget: ImportTarget) => {
    const newBudgetId = determineBudgetId(budgets, importTarget)
    const newDataFormat = determineDataFormat(
      importFormatMap,
      importTarget,
      importSetting,
    )
    const newImportFormat = determineImportFormat(
      importFormatMap,
      importTarget,
      newDataFormat,
    )

    onChange({
      importTarget,
      budgetId: newBudgetId,
      dataFormat: newDataFormat,
      importFormat: newImportFormat,
    })
  }

  const handleBudgetChange = (value: string) => {
    onChange({ budgetId: value })
  }

  const handleDataFormatChange = (dataFormat: DataFormat) => {
    const newImportFormat = determineImportFormat(
      importFormatMap,
      importSetting.importTarget,
      dataFormat,
    )
    onChange({ dataFormat, importFormat: newImportFormat })
  }

  const handleImportFormatChange = (value: ImportFormat) => {
    onChange({ importFormat: value })
  }

  return (
    <Section onGray>
      <Section.Title>データ選択</Section.Title>
      <div className="p-6 border rounded-md">
        <>
          {/* インポート対象 */}
          <div className="w-1/3">
            <ImportTargetSelect
              importSetting={importSetting}
              onChange={handleImportTargetChange}
              authScopes={authScopes}
            />
          </div>
          {/* 計画/実績 */}
          <div className="w-1/3 pt-4">
            <BudgetSelect
              org={org}
              budgets={budgets}
              importSetting={importSetting}
              onChange={handleBudgetChange}
            />
          </div>
          {/* データ形式 */}
          <div className="w-1/3 pt-4">
            <DataFormatSelect
              importFormatMap={importFormatMap}
              importSetting={importSetting}
              onChange={handleDataFormatChange}
            />
          </div>
          {/* インポート形式 */}
          <div className="w-1/3 pt-4">
            <ImportFormatSelect
              importFormatMap={importFormatMap}
              importSetting={importSetting}
              onChange={handleImportFormatChange}
            />
          </div>
        </>
      </div>
    </Section>
  )
}

function ImportTargetSelect({
  importSetting,
  onChange,
  authScopes,
}: {
  importSetting: ImportSetting
  onChange: (value: ImportTarget) => void
  authScopes: AuthScopes
}) {
  const availableImportTargets = getAvailableImportTargets(authScopes)
  return (
    <InputGroup label={'インポート対象'}>
      {() => (
        <SelectionGroup>
          {DEFAULT_IMPORT_TARGETS.map((target) => (
            <Radio
              key={target}
              id={`import-target-${target}`}
              name="importTarget"
              value={target}
              checked={importSetting.importTarget === target}
              disabled={!availableImportTargets.includes(target)}
              onChange={(e) => {
                onChange(e.target.value as ImportTarget)
              }}
            >
              {target === 'result' ? '実績' : '計画'}
            </Radio>
          ))}
        </SelectionGroup>
      )}
    </InputGroup>
  )
}

function BudgetSelect({
  org,
  budgets,
  importSetting,
  onChange,
}: {
  org: Org
  budgets: Budget[]
  importSetting: ImportSetting
  onChange: (value: string) => void
}) {
  const isTargetResult = importSetting.importTarget === 'result'
  const filteredBudgets = budgets.filter(
    (budget) => budget.result === isTargetResult,
  )

  return (
    <InputGroup
      label={'インポート先の計画/実績'}
      helpText={
        <>
          全社計画をインポートする場合は
          <a
            href={getBudgetImporterUrl(org.id)}
            className="inline-flex items-center text-blue-600"
          >
            こちら
            <RiExternalLinkLine />
          </a>
        </>
      }
    >
      {(props) => (
        <Select
          {...props}
          value={importSetting.budgetId}
          onChange={(e) => {
            onChange(e.target.value)
          }}
        >
          {filteredBudgets.map((budget) => (
            <option key={budget.id} value={budget.id}>
              {budget.name}
            </option>
          ))}
        </Select>
      )}
    </InputGroup>
  )
}

function DataFormatSelect({
  importFormatMap,
  importSetting,
  onChange,
}: {
  importFormatMap: ImportFormatMap
  importSetting: ImportSetting
  onChange: (value: DataFormat) => void
}) {
  const availableDataFormats = Object.keys(
    importFormatMap[importSetting.importTarget],
  ) as DataFormat[]

  return (
    <InputGroup label={'データ形式'} name="dataFormat">
      {({ id, name }) => (
        <SelectionGroup>
          {DEFAULT_DATA_FORMATS.map((format) => (
            <Radio
              key={format}
              id={`${id}-${format}`}
              name={name}
              disabled={!availableDataFormats.includes(format)}
              value={format}
              checked={importSetting.dataFormat === format}
              onChange={(e) => {
                onChange(e.target.value as DataFormat)
              }}
            >
              {format.toUpperCase()}
            </Radio>
          ))}
        </SelectionGroup>
      )}
    </InputGroup>
  )
}

function ImportFormatSelect({
  importFormatMap,
  importSetting,
  onChange,
}: {
  importFormatMap: ImportFormatMap
  importSetting: ImportSetting
  onChange: (value: ImportFormat) => void
}) {
  const availableImportFormats =
    importFormatMap[importSetting.importTarget][importSetting.dataFormat]
  return (
    <InputGroup label={'インポート形式'}>
      {(props) => (
        <Select
          {...props}
          value={importSetting.importFormat}
          onChange={(e) => onChange(e.target.value as ImportFormat)}
        >
          {availableImportFormats.map((format) => (
            <option key={format.value} value={format.value}>
              {format.label}
            </option>
          ))}
        </Select>
      )}
    </InputGroup>
  )
}

function getBudgetImporterUrl(orgId: number) {
  return `/orgs/${orgId}/budgets/importer`
}
