import { useState } from 'react'

import { MemberWithAssign } from '@/frontend/api/types'
import { AssignMembersSelect } from '@/frontend/features/clients/AssignMembersSelect'

export default function MultiSelect({
  name,
  value,
  members,
}: {
  name: string
  value: Array<number> | undefined
  members: MemberWithAssign[]
}) {
  const [selectedValue, setSelectedValue] = useState(value)

  return (
    <AssignMembersSelect
      name={name}
      value={selectedValue}
      members={members}
      onChange={(value) => {
        setSelectedValue(value)
      }}
    />
  )
}
