import Axios from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

const tokenElement = document.querySelector<HTMLMetaElement>(
  'meta[name=csrf-token]',
)

const defaultTransformRequest = Axios.defaults.transformRequest
if (!Array.isArray(defaultTransformRequest)) {
  throw new Error('Unexpected')
}

const defaultTransformResponse = Axios.defaults.transformResponse
if (!Array.isArray(defaultTransformResponse)) {
  throw new Error('Unexpected')
}
export const axios = Axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': tokenElement ? tokenElement.content : null,
  },
  transformRequest: [
    (data) => data && snakecaseKeys(data, { deep: true }),
    ...defaultTransformRequest,
  ],
  transformResponse: [
    ...defaultTransformResponse,
    (data) => data && camelcaseKeys(data, { deep: true }),
  ],
})
