export function ApiImportFormHeader() {
  return (
    <>
      <div className="font-bold">インポート設定</div>
      <div>
        会計データを取得する期間を指定し、Manageboardで扱うタグカテゴリを選択してください。
      </div>
    </>
  )
}
