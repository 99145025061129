import ApiLinkageAlert from './ApiLinkageAlert'

export default function ApiAuthContainer({
  description,
  children,
}: {
  description: React.ReactNode
  children: React.ReactNode
}) {
  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <div className="font-bold">API連携</div>
        <div>{description}</div>
      </div>
      <div className="flex items-center space-x-4">{children}</div>

      {/* API連携 成功/失敗 の帯表示 */}
      <ApiLinkageAlert />
    </div>
  )
}
