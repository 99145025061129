import { useContext } from 'react'

import { ScrollControlContext } from '../context/ScrollControlProvider'

export function useScrollControl() {
  const context = useContext(ScrollControlContext)
  if (context) return context

  throw new Error(
    'useScrollControl must be used within an ScrollControlProvider',
  )
}
