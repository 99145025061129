import groupBy from 'lodash/groupBy'
import { RiAlertLine } from 'react-icons/ri'

import { BudgetTagCategory, Org } from '@/frontend/api'
import { SimpleTable } from '@/frontend/components'
import { TableHead } from './TableHead'
import { TableRowsOfTag } from './TableRowsOfTag'
import { useBudgetTagsSuspense } from './useBudgetTagsSuspense'
import { useNonFinancialItemsSuspense } from './useNonFinancialItemsSuspense'

type Props = {
  org: Org
  primaryBudgetTagCategory: BudgetTagCategory | null
  editableById: { [id: number]: boolean }
  destroyableById: { [id: number]: boolean }
}

export function Table({
  org,
  primaryBudgetTagCategory,
  editableById,
  destroyableById,
}: Props) {
  const budgetTags = useBudgetTagsSuspense(org, primaryBudgetTagCategory)
  const { data: nonFinancialItems } = useNonFinancialItemsSuspense(org)

  const groupedNonFinancialItems = groupBy(
    nonFinancialItems,
    (nonFinancialItem) => nonFinancialItem.budgetTagId || '',
  )

  return nonFinancialItems.length > 0 ? (
    <SimpleTable>
      <TableHead />
      <SimpleTable.TBody>
        {[null, ...budgetTags].map((nullableBudgetTag, index) => {
          const nonFinancialItemsByTag =
            groupedNonFinancialItems[
              nullableBudgetTag ? nullableBudgetTag.id : ''
            ]
          return nonFinancialItemsByTag ? (
            <TableRowsOfTag
              key={index}
              org={org}
              primaryBudgetTagCategory={primaryBudgetTagCategory}
              budgetTag={nullableBudgetTag}
              nonFinancialItems={nonFinancialItemsByTag}
              editableById={editableById}
              destroyableById={destroyableById}
            />
          ) : null
        })}
      </SimpleTable.TBody>
    </SimpleTable>
  ) : (
    <div className="flex flex-col justify-center items-center py-24">
      <div className="text-gray-400">
        <RiAlertLine className="text-6xl" />
      </div>
      <div className="mt-4">
        <div className="text-gray-500 text-center">
          非財務項目が登録されていません
        </div>
      </div>
    </div>
  )
}
