import { Fragment, useState } from 'react'
import { Dialog, Popover, Transition } from '@headlessui/react'
import {
  RiArrowDownSLine,
  RiCheckLine,
  RiCloseLine,
  RiQuestionFill,
} from 'react-icons/ri'
import { z } from 'zod'

import { Button, Tooltip } from '@/frontend/components'
import { stringToDecimal } from '@/src/js/utils.js'
import { AccountItem, BudgetTag, FormValue } from './types'

type Props = {
  formAuthenticityToken: string
  formAction: string
  formMethod: string
  accountItems: AccountItem[]
  budgetTags: BudgetTag[]
  incomeBeforeTaxesTotalBalance: number
  defaultFormValue: FormValue
  accountItemsPath: string
  open: boolean
  setOpen: (value: boolean) => void
  fiscalPeriodId: number
  isWholeCompany: boolean
}

type ValidationErrors = {
  accountItemId?: string[]
  budgetTagId?: string[]
  taxAdjustments?: string[]
  lossCarriedForward?: string[]
  taxRate?: string[]
  taxCreditControl?: string[]
  interimTaxPayments?: string[]
  calculationType?: string[]
}

const msgNonnegative = '0以上の数値を入力してください'

const FormData = z.object({
  accountItemId: z.number(),
  budgetTagId: z.number().nullable(),
  taxAdjustments: z.number().int(),
  lossCarriedForward: z.number().int().nonnegative({ message: msgNonnegative }),
  taxRate: z
    .number()
    .nonnegative({ message: msgNonnegative })
    .refine(
      (n) => {
        return getDecimalPointLength(n) <= 2
      },
      { message: '小数点以下第二位まで入力できます' },
    ),
  taxCreditControl: z.number().int().nonnegative({ message: msgNonnegative }),
  interimTaxPayments: z.number().int().nonnegative({ message: msgNonnegative }),
  calculationType: z.enum(['income_taxes', 'final_tax_payment']),
})

export function IncomeTaxesSimpleCalculationSlideOver({
  formAuthenticityToken,
  formAction,
  formMethod,
  accountItems,
  budgetTags,
  incomeBeforeTaxesTotalBalance,
  defaultFormValue,
  accountItemsPath,
  open,
  setOpen,
  fiscalPeriodId,
  isWholeCompany,
}: Props) {
  const [formValue, setFormValue] = useState<FormValue>(defaultFormValue)
  const [errors, setErrors] = useState<ValidationErrors | null>(null)
  const [filter, setFilter] = useState('')
  const [submitted, setSubmitted] = useState(false)
  // 課税所得
  const taxableIncome = getTaxableIncome(
    incomeBeforeTaxesTotalBalance,
    formValue,
  )
  // 算出税額
  const calculatedTax = getCalculatedTax(taxableIncome, formValue)
  // 法人税等
  const incomeTaxes = getIncomeTax(calculatedTax, formValue.taxCreditControl)
  // 確定納付税額
  const finalTaxPayment = incomeTaxes - formValue.interimTaxPayments

  const handleChange = (v: Partial<FormValue>) => {
    setFormValue({ ...formValue, ...v })
  }

  // フォーカス外れた時にカンマ区切り
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const n = delimitedToNumber(e.target.value)
    e.target.value = numberWithDelimiter(n)
  }

  const getAmount = () => {
    switch (formValue.calculationType) {
      case 'income_taxes':
        return incomeTaxes
      case 'final_tax_payment':
        return finalTaxPayment
      default:
        return 0
    }
  }
  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    const result = FormData.safeParse(formValue)
    if (!result.success) {
      e.preventDefault()
      setErrors(result.error.flatten().fieldErrors)
    } else {
      setSubmitted(true)
    }
  }
  const budgetTagOptions: { id: number | null; name: string }[] = [
    ...budgetTags,
  ]
  const selectedBudgetTag = budgetTagOptions.find(
    (el) => el.id === formValue.budgetTagId,
  )
  const filteredBudgetTags = budgetTagOptions.filter((budgetTag) => {
    const regex = new RegExp(filter, 'i')
    return regex.test(budgetTag.name)
  })
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-200"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="ease-in-out duration-200"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="transform transition pointer-events-auto w-screen max-w-md">
                  <form
                    action={formAction}
                    method={formMethod}
                    className="slide-over-content flex flex-col"
                    onSubmit={onSubmit}
                  >
                    <input
                      type="hidden"
                      readOnly
                      name="authenticity_token"
                      value={formAuthenticityToken}
                    />
                    <input
                      type="hidden"
                      readOnly
                      name="fiscal_period_id"
                      value={fiscalPeriodId}
                    />
                    <input
                      type="hidden"
                      readOnly
                      name="amount"
                      value={getAmount()}
                    />
                    <div className="shrink-0">
                      <header className="px-4 z-50 bg-white w-full">
                        <div className="py-3 flex items-start justify-between space-x-3 border-b border-gray-200">
                          <h6 className="text-gray-900 text-lg">
                            法人税等の計算
                          </h6>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <RiCloseLine className="text-xl" />
                            </button>
                          </div>
                        </div>
                      </header>
                    </div>
                    <div className="flex-1 relative min-h-0 overflow-y-scroll px-4">
                      <div className="py-4">
                        <table className="w-full text-xs text-left">
                          <thead className="font-bold z-30">
                            {/* <tr>
                              <th className="sticky top-0 bg-blue-50 p-2">
                                勘定科目
                              </th>
                              <th className="sticky top-0 bg-blue-50 p-2 text-center">
                                相手勘定科目
                              </th>
                            </tr> */}
                          </thead>
                          <tbody>
                            <tr>
                              <td className="py-2 text-gray-700 text-sm">
                                勘定科目
                              </td>
                              <td className="py-2 text-gray-700 text-sm">
                                <select
                                  defaultValue={formValue.accountItemId}
                                  name="account_item_id"
                                  required={true}
                                  onChange={(e) =>
                                    handleChange({
                                      accountItemId: Number(e.target.value),
                                    })
                                  }
                                  className="w-48 form-select py-1 text-sm border-gray-300 focus:outline-none focus:ring-blue-200 focus:border-blue-300"
                                >
                                  <option hidden value="">
                                    選択してください
                                  </option>
                                  {accountItems.map(({ id, name }) => (
                                    <option key={id} value={id}>
                                      {name}
                                    </option>
                                  ))}
                                </select>
                                <ErrorMsg msgs={errors?.accountItemId} />
                              </td>
                            </tr>
                            {accountItems.length === 0 && (
                              <tr className="border-b border-gray-200">
                                <td
                                  colSpan={2}
                                  className="py-2 text-gray-700 text-sm"
                                >
                                  <div className="text-xs text-red-400 mt-1 mb-6">
                                    ※ 勘定科目がない場合は、
                                    <a
                                      href={accountItemsPath}
                                      className="link underline"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      法人税等の勘定科目を登録
                                    </a>
                                    し、画面をリロードして下さい。
                                  </div>
                                </td>
                              </tr>
                            )}
                            <tr className="border-b border-gray-200">
                              <td className="pt-2 pb-6 text-gray-700 text-sm">
                                法人税等を計上するタグ
                                <Tooltip content="計算した法人税等の数値を計上するタグを選択します">
                                  {(ref) => (
                                    <span
                                      ref={ref}
                                      className="ml-1 text-gray-500"
                                    >
                                      <RiQuestionFill className="inline" />
                                    </span>
                                  )}
                                </Tooltip>
                              </td>
                              <td className="pt-2 pb-6 text-gray-700 text-sm">
                                <input
                                  type="hidden"
                                  name="budget_tag_id"
                                  value={formValue.budgetTagId || ''}
                                />
                                <Popover>
                                  <div className="btn-wrapper">
                                    <Popover.Button
                                      className="btn btn-select-sm w-48"
                                      disabled={isWholeCompany}
                                    >
                                      <span className="font-normal">
                                        {selectedBudgetTag
                                          ? selectedBudgetTag.name
                                          : '選択してください'}
                                      </span>
                                      <div className="btn-select-caret">
                                        <RiArrowDownSLine />
                                      </div>
                                    </Popover.Button>
                                  </div>
                                  <Transition
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                    className="absolute z-50 mt-1 w-48"
                                  >
                                    <Popover.Panel>
                                      {({ close }) => (
                                        <div className="simple-dropdown">
                                          <div className="simple-dropdown-content whitespace-nowrap">
                                            <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                                              <input
                                                autoComplete="off"
                                                className="p-2 block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                                                placeholder="キーワードを入力"
                                                type="search"
                                                value={filter}
                                                onChange={(e) =>
                                                  setFilter(e.target.value)
                                                }
                                              />
                                            </div>
                                            <div className="py-1">
                                              {filteredBudgetTags.map(
                                                ({ id, name }) => (
                                                  <button
                                                    key={id}
                                                    className={`simple-dropdown-link-item ${
                                                      id ===
                                                      formValue.budgetTagId
                                                        ? 'active'
                                                        : ''
                                                    }`}
                                                    type="button"
                                                    onClick={() => {
                                                      handleChange({
                                                        budgetTagId: id
                                                          ? Number(id)
                                                          : null,
                                                      })
                                                      close()
                                                    }}
                                                  >
                                                    <span className="flex-auto text-left">
                                                      {name}
                                                    </span>
                                                    {id ===
                                                      formValue.budgetTagId && (
                                                      <span className="flex-none text-blue-600">
                                                        <RiCheckLine />
                                                      </span>
                                                    )}
                                                  </button>
                                                ),
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Popover.Panel>
                                  </Transition>
                                </Popover>
                                <ErrorMsg msgs={errors?.budgetTagId} />
                              </td>
                            </tr>
                            <tr>
                              <td className="pt-6 pb-2 text-gray-700 text-sm">
                                税引前当期純利益
                                <Tooltip content="全社の税引前当期純利益の合計額を参照します">
                                  {(ref) => (
                                    <span
                                      ref={ref}
                                      className="ml-1 text-gray-500"
                                    >
                                      <RiQuestionFill className="inline" />
                                    </span>
                                  )}
                                </Tooltip>
                              </td>
                              <td className="pt-6 pb-2 text-gray-700 text-sm">
                                <span className="w-40 text-right inline-block pr-2">
                                  {numberWithDelimiter(
                                    incomeBeforeTaxesTotalBalance,
                                  )}
                                </span>
                                <span className="pl-1">円</span>
                              </td>
                            </tr>
                            <tr>
                              <td className="py-2 text-gray-700 text-sm">
                                税務調整
                              </td>
                              <td className="py-2 text-gray-700 text-sm">
                                <input
                                  type="text"
                                  name="tax_adjustments"
                                  defaultValue={numberWithDelimiter(
                                    formValue.taxAdjustments,
                                  )}
                                  onChange={(e) =>
                                    handleChange({
                                      taxAdjustments: delimitedToNumber(
                                        e.target.value,
                                      ),
                                    })
                                  }
                                  onBlur={handleBlur}
                                  className="form-input py-1 px-2 inline-block transition duration-150 ease-in-out text-sm border-gray-200 text-gray-700 mr-1 text-right w-40"
                                />
                                円
                                <ErrorMsg msgs={errors?.taxAdjustments} />
                              </td>
                            </tr>
                            <tr className="border-b border-gray-200">
                              <td className="pt-2 pb-6 text-gray-700 text-sm">
                                繰越欠損金
                                <Tooltip content="0以上の数値を入力してください">
                                  {(ref) => (
                                    <span
                                      ref={ref}
                                      className="ml-1 text-gray-500"
                                    >
                                      <RiQuestionFill className="inline" />
                                    </span>
                                  )}
                                </Tooltip>
                              </td>
                              <td className="pt-2 pb-6 text-gray-700 text-sm">
                                <input
                                  type="text"
                                  name="loss_carried_forward"
                                  defaultValue={numberWithDelimiter(
                                    formValue.lossCarriedForward,
                                  )}
                                  onChange={(e) => {
                                    handleChange({
                                      lossCarriedForward: delimitedToNumber(
                                        e.target.value,
                                      ),
                                    })
                                  }}
                                  onBlur={(e) => {
                                    const n = delimitedToNumber(e.target.value)
                                    const lossCarriedForward = n < 0 ? 0 : n
                                    e.target.value =
                                      numberWithDelimiter(lossCarriedForward)
                                    handleChange({ lossCarriedForward })
                                  }}
                                  className="form-input py-1 px-2 inline-block transition duration-150 ease-in-out text-sm border-gray-200 text-gray-700 mr-1 text-right w-40"
                                />
                                円
                                <ErrorMsg msgs={errors?.lossCarriedForward} />
                              </td>
                            </tr>
                            <tr>
                              <td className="pt-6 pb-2 text-gray-700 text-sm">
                                課税所得
                                <Tooltip content="千円未満の数値は切り捨てられます">
                                  {(ref) => (
                                    <span
                                      ref={ref}
                                      className="ml-1 text-gray-500"
                                    >
                                      <RiQuestionFill className="inline" />
                                    </span>
                                  )}
                                </Tooltip>
                              </td>
                              <td className="pt-6 pb-2 text-gray-700 text-sm">
                                <span className="w-40 text-right inline-block pr-2">
                                  {numberWithDelimiter(taxableIncome)}
                                </span>
                                <span className="pl-1">円</span>
                              </td>
                            </tr>
                            <tr>
                              <td className="py-2 text-gray-700 text-sm">
                                税率
                              </td>
                              <td className="py-2 text-gray-700 text-sm">
                                <input
                                  type="text"
                                  name="tax_rate"
                                  defaultValue={stringToDecimal(
                                    String(defaultFormValue.taxRate),
                                  )}
                                  onChange={(e) =>
                                    handleChange({
                                      taxRate: delimitedToFloat(e.target.value),
                                    })
                                  }
                                  className="form-input py-1 px-2 inline-block transition duration-150 ease-in-out text-sm border-gray-200 text-gray-700 mr-1 text-right w-40"
                                />
                                %
                                <ErrorMsg msgs={errors?.taxRate} />
                              </td>
                            </tr>
                            <tr>
                              <td className="py-2 text-gray-700 text-sm">
                                算出税額
                                <Tooltip content="百円未満の数値は切り捨てられます">
                                  {(ref) => (
                                    <span
                                      ref={ref}
                                      className="ml-1 text-gray-500"
                                    >
                                      <RiQuestionFill className="inline" />
                                    </span>
                                  )}
                                </Tooltip>
                              </td>
                              <td className="py-2 text-gray-700 text-sm">
                                <span className="w-40 text-right inline-block pr-2">
                                  {numberWithDelimiter(calculatedTax)}
                                </span>
                                <span className="pl-1">円</span>
                              </td>
                            </tr>
                            <tr className="border-b border-gray-200">
                              <td className="pt-2 pb-6 text-gray-700 text-sm">
                                税額控除
                              </td>
                              <td className="pt-2 pb-6 text-gray-700 text-sm">
                                <input
                                  type="text"
                                  name="tax_credit_control"
                                  defaultValue={numberWithDelimiter(
                                    formValue.taxCreditControl,
                                  )}
                                  onChange={(e) =>
                                    handleChange({
                                      taxCreditControl: delimitedToNumber(
                                        e.target.value,
                                      ),
                                    })
                                  }
                                  onBlur={handleBlur}
                                  className="form-input py-1 px-2 inline-block transition duration-150 ease-in-out text-sm border-gray-200 text-gray-700 mr-1 text-right w-40"
                                />
                                円
                                <ErrorMsg msgs={errors?.taxCreditControl} />
                              </td>
                            </tr>
                            <tr className="border-b border-gray-200">
                              <td className="py-6 text-gray-700 text-sm">
                                法人税等の額
                              </td>
                              <td className="py-6 text-gray-700 text-sm">
                                <span className="w-40 text-right inline-block pr-2">
                                  {numberWithDelimiter(incomeTaxes)}
                                </span>
                                <span className="pl-1">円</span>
                              </td>
                            </tr>
                            <tr className="border-b border-gray-200">
                              <td className="py-6 text-gray-700 text-sm">
                                中間納付税額
                              </td>
                              <td className="py-6 text-gray-700 text-sm">
                                <input
                                  type="text"
                                  name="interim_tax_payments"
                                  defaultValue={numberWithDelimiter(
                                    formValue.interimTaxPayments,
                                  )}
                                  onChange={(e) =>
                                    handleChange({
                                      interimTaxPayments: delimitedToNumber(
                                        e.target.value,
                                      ),
                                    })
                                  }
                                  onBlur={handleBlur}
                                  className="form-input py-1 px-2 inline-block transition duration-150 ease-in-out text-sm border-gray-200 text-gray-700 mr-1 text-right w-40"
                                />
                                円
                                <ErrorMsg msgs={errors?.interimTaxPayments} />
                              </td>
                            </tr>
                            <tr className="border-b border-gray-200">
                              <td className="py-6 text-gray-700 text-sm">
                                確定納付税額
                              </td>
                              <td className="py-6 text-gray-700 text-sm">
                                <span className="w-40 text-right inline-block pr-2">
                                  {numberWithDelimiter(finalTaxPayment)}
                                </span>
                                <span className="pl-1">円</span>
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="pt-6 pb-2 text-gray-700 text-sm"
                                colSpan={2}
                              >
                                <input
                                  required={true}
                                  className="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out mr-1"
                                  type="radio"
                                  value="income_taxes"
                                  name="calculation_type"
                                  id="income_taxes"
                                  onChange={(e) =>
                                    handleChange({
                                      calculationType: e.target.value,
                                    })
                                  }
                                  defaultChecked={
                                    formValue.calculationType === 'income_taxes'
                                  }
                                />
                                <label
                                  htmlFor="income_taxes"
                                  className="form-check-label"
                                >
                                  法人税等の額を計画PLに反映する
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="py-2 text-gray-700 text-sm"
                                colSpan={2}
                              >
                                <input
                                  required={true}
                                  className="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out mr-1"
                                  type="radio"
                                  value="final_tax_payment"
                                  name="calculation_type"
                                  id="final_tax_payment"
                                  onChange={(e) =>
                                    handleChange({
                                      calculationType: e.target.value,
                                    })
                                  }
                                  defaultChecked={
                                    formValue.calculationType ===
                                    'final_tax_payment'
                                  }
                                />
                                <label
                                  htmlFor="final_tax_payment"
                                  className="form-check-label"
                                >
                                  確定納付税額を計画PLに反映する
                                </label>
                                <ErrorMsg msgs={errors?.calculationType} />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-4">
                      <span className="text-red-400 text-sm">
                        「計画PLに反映する」ボタンを押下すると、計算結果が計画PLに反映されます。
                      </span>
                      <div className="shrink-0 space-x-4 flex justify-end">
                        <div className="btn-wrapper">
                          <button
                            className="btn btn-white"
                            type="button"
                            onClick={() => setOpen(false)}
                          >
                            キャンセル
                          </button>
                        </div>
                        <div className="btn-wrapper">
                          <Button
                            variant="primary"
                            type="submit"
                            disabled={submitted}
                          >
                            計画PLに反映する
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function ErrorMsg({ msgs }: { msgs?: string[] }) {
  if (!msgs || msgs.length === 0) return null

  return (
    <div className="mt-1 text-sm text-red-500">
      {msgs.map((msg, i) => {
        return <div key={i}>{msg}</div>
      })}
    </div>
  )
}

function numberWithDelimiter(n: number): string {
  return n.toLocaleString('ja')
}

function delimitedToNumber(s: string): number {
  const ns = s.replace(/,/g, '')
  const n = parseInt(ns)
  return isNaN(n) ? 0 : n
}

function delimitedToFloat(s: string): number {
  const ns = s.replace(/,/g, '')
  const n = parseFloat(ns)
  return isNaN(n) ? 0 : n
}

function getTaxableIncome(
  incomeBeforeTaxesTotalBalance: number,
  formValue: FormValue,
): number {
  const taxableIncome =
    incomeBeforeTaxesTotalBalance +
    formValue.taxAdjustments -
    formValue.lossCarriedForward
  return Math.floor(taxableIncome / 1000) * 1000
}

function getCalculatedTax(taxableIncome: number, formValue: FormValue): number {
  const calculatedTax = (taxableIncome * formValue.taxRate) / 100
  if (calculatedTax < 0) return 0

  return Math.floor(calculatedTax / 100) * 100
}

function getIncomeTax(calculatedTax: number, taxCreditControl: number): number {
  const n = calculatedTax - taxCreditControl
  if (n < 0) return 0

  return n
}

function getDecimalPointLength(number: number): number {
  const numbers = String(number).split('.')

  return numbers[1] ? numbers[1].length : 0
}
