import useSWR from 'swr'

import {
  getTransitionTableImportItemsUrl,
  JobLogStatus,
  Org,
  TransitionTableImport,
  TransitionTableImportItem,
} from '@/frontend/api'
import { fetcher } from '@/frontend/utils'

const REFRESH_INTERVAL = 5000

const isInProgress = (status: JobLogStatus) => {
  return ['queued', 'in_progress'].includes(status)
}

export function useTransitionTableImportItems(
  org: Org,
  transitionTableImport: TransitionTableImport,
) {
  const url = getTransitionTableImportItemsUrl(org.id, transitionTableImport.id)
  return useSWR<TransitionTableImportItem[], Error>(url, fetcher, {
    refreshInterval: (latestData) =>
      latestData && latestData.some((item) => isInProgress(item.status))
        ? REFRESH_INTERVAL
        : 0,
  })
}
