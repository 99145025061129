import { tv } from 'tailwind-variants'

export type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
  disabled?: boolean
  optional?: boolean
}

export const label = tv({
  base: 'text-sm text-gray-800 leading-5',
  variants: {
    disabled: {
      true: 'text-gray-400',
    },
  },
})

export function Label({
  optional = false,
  disabled = false,
  ...props
}: LabelProps) {
  return (
    <div className="inline-flex items-center space-x-2">
      <label className={label({ disabled })} {...props} />
      {optional && (
        <span className="text-xs text-gray-600 leading-5">任意</span>
      )}
    </div>
  )
}
