import { Button } from '@/frontend/components'

export default function ApiCompanyButton({
  companySelected,
  onClick,
}: {
  companySelected: boolean
  onClick: () => void
}) {
  return companySelected ? (
    <Button variant="secondary" onClick={onClick}>
      連携する事業所を変更する
    </Button>
  ) : (
    <Button variant="primary" onClick={onClick}>
      連携する事業所を選択する
    </Button>
  )
}
