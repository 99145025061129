import { LegacyTab } from './LegacyTab'

type Props = {
  items: {
    label: React.ReactNode
    href: string
    active?: boolean
  }[]
}

export function LegacyTabs({ items }: Props) {
  return (
    <div className="-mb-px flex space-x-8">
      {items.map((item, i) => {
        return (
          <LegacyTab key={i} href={item.href} active={item.active}>
            {item.label}
          </LegacyTab>
        )
      })}
    </div>
  )
}
