import { ReactNode, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import {
  MoveHandler,
  NodeApi,
  NodeRendererProps,
  SimpleTree,
  Tree,
} from 'react-arborist'
import { RiDeleteBinLine, RiEditBoxLine } from 'react-icons/ri'

import {
  OrderParams,
  updateOrder,
} from '@/frontend/pages/allocation_versions/api'
import { DeleteModal } from './DeleteModal'
import { AllocationDriver, Data, Item, SubProps } from './types'

type Props = {
  updateOrderUrl: string
  items: Item[]
  subProps: SubProps
  isSortable: boolean
}

const ROW_HEIGHT = 60

export function List({ items, subProps, updateOrderUrl, isSortable }: Props) {
  const initialData = itemsToData(items, subProps)
  const [data, setData] = useState(initialData)
  const tree = useMemo(() => new SimpleTree(data), [data])
  const onMove: MoveHandler<Data> = (args: {
    dragIds: string[]
    parentId: null | string
    index: number
  }) => {
    for (const id of args.dragIds) {
      tree.move({ id, parentId: args.parentId, index: args.index })
    }
    setData(tree.data)

    // APIしばき
    const updateParams = dataToApiParams(tree.data)
    updateOrder(updateOrderUrl, {
      orderParams: updateParams,
    })
  }
  return (
    <Container>
      <Header />
      <Tree
        data={data}
        width={'100%'}
        rowHeight={ROW_HEIGHT}
        onMove={onMove}
        height={ROW_HEIGHT * items.length}
        disableDrag={!isSortable}
      >
        {Node}
      </Tree>
    </Container>
  )
}

function Container({ children }: { children: ReactNode }) {
  return (
    <div className="align-middle inline-block min-w-full overflow-hidden">
      {children}
    </div>
  )
}

function Header() {
  return (
    <div className="flex px-6 py-3 border-b border-gray-200 bg-gray-50 leading-4 tracking-wider font-medium text-left text-sm text-gray-500">
      <div className="flex-none w-48">名称</div>
      <div className="flex-none w-24">分類</div>
      <div className="flex-none w-48">項目</div>
      <div className="flex-none w-24">作成日</div>
      <div className="flex-auto">メモ</div>
    </div>
  )
}

function Node(props: NodeRendererProps<Data>) {
  const { style, node, dragHandle } = props
  const { paddingLeft, ...rowStyle } = style
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  return (
    <>
      <div style={rowStyle}>
        <div
          style={{ height: ROW_HEIGHT }}
          className={
            'flex px-6 items-center whitespace-nowrap border-b border-gray-200 text-sm leading-5 bg-white text-gray-900 ' +
            (node.willReceiveDrop ? 'bg-gray-100' : '')
          }
          ref={dragHandle}
        >
          <div className="w-full">
            <Row
              node={node}
              onDeleteModalOpen={() => setDeleteModalOpen(true)}
              style={{ paddingLeft }}
            />
          </div>
        </div>
      </div>
      <DeleteModal
        node={node}
        deleteModalOpen={deleteModalOpen}
        onDeleteModalClose={() => setDeleteModalOpen(false)}
      />
    </>
  )
}

function Row({
  node,
  onDeleteModalOpen,
  style,
}: {
  node: NodeApi<Data>
  onDeleteModalOpen: () => void
  style: React.CSSProperties
}) {
  const allocationDriver = node.data.item
  const criteriaText = (allocationDriver: AllocationDriver) => {
    return allocationDriver.driverItem.type === 'financial_item'
      ? '勘定科目'
      : '非財務項目'
  }

  const isReadonly = node.data.subProps.isReadonly
  return (
    <div className="flex text-gray-700">
      <div style={style} className="flex-none w-48 leading-7 truncate">
        {allocationDriver.name}
      </div>
      <div className="flex-none w-24 leading-7">
        {criteriaText(allocationDriver)}
      </div>
      <div className="flex-none w-48 leading-7 truncate">
        {allocationDriver.driverItem.name}
      </div>
      <div className="flex-none w-24 leading-7">
        {dayjs(allocationDriver.createdAt).format('YYYY/MM/DD')}
      </div>
      <div className="flex-auto leading-7 truncate">
        {allocationDriver.memo}
      </div>
      <div className="flex-none">
        {!isReadonly && (
          <div className="flex justify-end space-x-4">
            {allocationDriver.enableNonFinancialTable && (
              <div className="btn-wrapper">
                <a
                  href={ShowAllocationDriverTableUrl(allocationDriver)}
                  className="btn btn-sm btn-secondary"
                >
                  <RiEditBoxLine className="mr-2" />
                  テーブル設定
                </a>
              </div>
            )}

            <div className="btn-wrapper">
              <a
                href={EditAllocationDriverUrl(allocationDriver)}
                className="btn btn-xs btn-white"
              >
                <RiEditBoxLine className="mr-2" />
                編集
              </a>
            </div>
            <button
              className="btn btn-sm btn-text"
              disabled={!allocationDriver.isDestroyable}
              onClick={onDeleteModalOpen}
            >
              <RiDeleteBinLine className="mr-2 text-gray-500" />
              削除
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

function EditAllocationDriverUrl(allocationDriver: AllocationDriver) {
  return `${AllocationDriverUrl(allocationDriver)}/edit`
}

function ShowAllocationDriverTableUrl(allocationDriver: AllocationDriver) {
  return `${AllocationDriverUrl(allocationDriver)}/table`
}

function AllocationDriverUrl(allocationDriver: AllocationDriver) {
  return `/orgs/${allocationDriver.orgId}/allocation_drivers/${allocationDriver.id}`
}

function itemsToData(items: Item[], subProps: SubProps) {
  return items.map((item) => itemToData(item, subProps))
}

function itemToData(item: Item, subProps: SubProps): Data {
  return {
    id: `${item.id}`,
    name: item.name,
    subProps,
    item,
  }
}

function dataToApiParams(data: readonly Data[]): OrderParams {
  const convert = (item: Item, position: number) => {
    return {
      id: item.id,
      position,
    }
  }
  const params: OrderParams = []
  data.forEach((d, index) => {
    params.push(convert(d.item, index))
  })
  return params
}
