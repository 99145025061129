import { JobLog, Org } from '@/frontend/api'
import { SectionTitle } from '@/frontend/features/importers/components/SectionTitle'
import { JobLogPollingContainer } from '../components/JobLogPollingContainer'
import { getInitialImportFileStatus } from '../getInitialImportFileStatus'
import useAlertStatus from '../hooks/useAlertStatus'
import useImportFileStatus from '../hooks/useImportFileStatus'
import useStoredFormValues, {
  BaseCsvFormValuesType,
} from '../hooks/useStoredFormValues'
import { JobLogWithAttachedFile } from '../types'
import JournalImportForm from './JournalImportForm'
import { useImportMfcCsvJournal } from './useImportMfcCsvJournal'

type Props = {
  org: Org
  jobLogWithAttachedFile: JobLogWithAttachedFile | null
  initialInProgressJobLogId: number | null
  isJournalImportable: boolean
}

export default function JournalImportFormContainer({
  org,
  jobLogWithAttachedFile,
  initialInProgressJobLogId,
  isJournalImportable,
}: Props) {
  const {
    importFileStatus,
    updateImportFileToQueued,
    updateOnlyStatusForImportFile,
  } = useImportFileStatus(getInitialImportFileStatus(jobLogWithAttachedFile))

  const { storedFormValues, storeImportFormValues } =
    useStoredFormValues<BaseCsvFormValuesType>(org, 'MfcCsvJournal')

  const { alertStatus, resetAlertStatus, updateAlertStatusFromJobLog } =
    useAlertStatus(jobLogWithAttachedFile)

  const { submit, isSubmitting, jobLog, apiError } = useImportMfcCsvJournal(org)

  return (
    <JobLogPollingContainer
      org={org}
      pollingJobLog={jobLog}
      initJobLogId={initialInProgressJobLogId}
      onStopPolling={(jobLog: JobLog) => {
        updateOnlyStatusForImportFile(jobLog.status)
        updateAlertStatusFromJobLog(jobLog)
      }}
    >
      {({ isPolling }) => (
        <div className="space-y-4">
          <SectionTitle>仕訳インポート</SectionTitle>
          <div>
            会計ソフトから出力した仕訳データをインポートし、会計実績データを取り込みます。
          </div>
          <JournalImportForm
            org={org}
            importFileStatus={importFileStatus}
            onFileChange={(file: File) => {
              updateImportFileToQueued([file])
              resetAlertStatus()
            }}
            onSubmit={(data) => {
              submit(data)
              const { firstDate, lastDate } = data
              storeImportFormValues({ firstDate, lastDate })
            }}
            isLoading={isSubmitting || isPolling}
            apiError={apiError}
            isJournalImportable={isJournalImportable}
            initialValues={storedFormValues}
            alertStatus={alertStatus}
            jobLogWithAttachedFile={jobLogWithAttachedFile}
          />
        </div>
      )}
    </JobLogPollingContainer>
  )
}
