import { forwardRef } from 'react'
import { tv } from 'tailwind-variants'

type Props = Omit<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  'className'
> & {
  invalid?: boolean
}

const select = tv({
  base: [
    'ReactComponentInput', // 全体の disabled スタイルを回避するため app/javascript/src/scss/_input.scss
    'block w-full p-2 text-sm leading-5 rounded-md',
    'border-0 ring-inset ring-1 ring-gray-300',
    'transition duration-150 ease-in-out',
    'focus:outline focus:outline-blue-100 focus:outline-offset-0 focus:outline-[3px] focus:ring-gray-300 focus:ring-inset',
  ].join(' '),
  variants: {
    invalid: {
      true: 'outline outline-red-50 outline-offset-0 outline-[3px]',
    },
    disabled: {
      true: 'bg-gray-100 text-gray-400 ring-0 pointer-events-none',
    },
  },
})

export const Select = forwardRef<HTMLSelectElement, Props>(function Select(
  { invalid = false, ...props },
  ref,
) {
  return (
    <select
      className={select({ invalid, disabled: props.disabled })}
      ref={ref}
      {...props}
    />
  )
})
