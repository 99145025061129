import { useEffect } from 'react'

import { BudgetTagCategory, Org, TransitionTableImport } from '@/frontend/api'
import { useBudgetTags } from '../hooks/useBudgetTags'
import { useBulkUploadTransitionTableImportItems } from '../hooks/useBulkUploadTransitionTableImportItems'
import { useScrollControl } from '../hooks/useScrollControl'
import { useTransitionTableImportItems } from '../hooks/useTransitionTableImportItems'
import TransitionTableImportForm from './TransitionTable/TransitionTableImportForm'

type Props = {
  org: Org
  transitionTableImport: TransitionTableImport
  primaryBudgetTagCategory: BudgetTagCategory | null
}

export default function TransitionTableImportFormContainer({
  org,
  transitionTableImport,
  primaryBudgetTagCategory,
}: Props) {
  const { data: budgetTags, isLoading: isBudgetTagsLoading } = useBudgetTags(
    org,
    primaryBudgetTagCategory,
    {
      filter: 'authed',
      include: 'non_tagged',
    },
  )

  const { data: importItems, isLoading: isImportItemLoading } =
    useTransitionTableImportItems(org, transitionTableImport)

  const { isAutoScrollEnabled, setIsAutoScrollEnabled, scrollToRefWithDelay } =
    useScrollControl()

  const {
    submit: bulkUpload,
    isSubmitting: bulkUploadSubmitting,
    apiError: bulkUploadApiError,
  } = useBulkUploadTransitionTableImportItems(org, transitionTableImport)

  useEffect(() => {
    if (importItems && importItems.length > 0 && isAutoScrollEnabled) {
      scrollToRefWithDelay()
      setIsAutoScrollEnabled(false)
    }
  }, [importItems])

  if (isBudgetTagsLoading || isImportItemLoading) {
    return null
  }

  return (
    <TransitionTableImportForm
      org={org}
      transitionTableImport={transitionTableImport}
      primaryBudgetTagCategory={primaryBudgetTagCategory}
      budgetTags={budgetTags || []}
      transitionTableImportItems={importItems || []}
      onFileDrop={bulkUpload}
      isLoading={bulkUploadSubmitting}
      apiError={bulkUploadApiError}
    />
  )
}
