import { useState } from 'react'

import { BudgetTagCategory, NonFinancialItem, Org } from '@/frontend/api'
import { Button } from '@/frontend/components'
import { NonFinancialItemSlideOver } from './NonFinancialItemSlideOver'
import { useUpdateNonFinancialItem } from './useUpdateNonFinancialItem'

// NOTE:
// ボタンクリック時に編集する非財務項目をSWRのキャッシュに設定することができれば
// スケルトンスクリーンなしで非財務項目の編集画面を表示できるようになるはず...
export function TableRowActionEdit({
  org,
  primaryBudgetTagCategory,
  nonFinancialItem,
}: {
  org: Org
  primaryBudgetTagCategory: BudgetTagCategory | null
  nonFinancialItem: NonFinancialItem
}) {
  const [open, setOpen] = useState(false)
  const updateNonFinancialItem = useUpdateNonFinancialItem(
    org,
    nonFinancialItem,
  )

  return (
    <>
      <Button variant="outlined" type="button" onClick={() => setOpen(true)}>
        編集
      </Button>
      <NonFinancialItemSlideOver
        open={open}
        org={org}
        primaryBudgetTagCategory={primaryBudgetTagCategory}
        nonFinancialItemId={nonFinancialItem.id}
        onClose={() => setOpen(false)}
        onSubmit={updateNonFinancialItem}
      />
    </>
  )
}
