import { Org } from '@/frontend/api'
import { Breadcrumbs } from '@/frontend/components'
import { Tabs } from './Tabs'

type Props = {
  org: Org
  settingAccessible?: boolean
  memberInvitationAccessible?: boolean
  contractAccessible?: boolean
  chargeAccessible?: boolean
  receiptAccessible?: boolean
}

const breadcrumbItems = {
  items: [{ label: '管理' }, { label: '組織' }],
}

export function PageHeader({
  org,
  settingAccessible,
  memberInvitationAccessible,
  contractAccessible,
  chargeAccessible,
  receiptAccessible,
}: Props) {
  return (
    <div className="bg-white px-8 border-b border-gray-200">
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="flex-1 min-w-0">
          <div className="py-2">
            <Breadcrumbs {...breadcrumbItems} />
          </div>
        </div>
      </div>
      <Tabs
        org={org}
        settingAccessible={settingAccessible}
        memberInvitationAccessible={memberInvitationAccessible}
        contractAccessible={contractAccessible}
        chargeAccessible={chargeAccessible}
        receiptAccessible={receiptAccessible}
      />
    </div>
  )
}
