import { z } from '@/frontend/utils'

// NOTE 同期的なフォーム用なのでcamelCaseにしている
export const importSettingSchema = z.object({
  fiscal_period_id: z.coerce.number(),
  header_row_number: z.coerce.number(),
  account_item_column_name: z.string(),
})

export type ImportSettingPayload = z.infer<typeof importSettingSchema>
