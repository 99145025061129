import { RiErrorWarningLine } from 'react-icons/ri'

export function ErrorMsg() {
  return (
    <div className="flex h-64 items-center justify-center text-red-600">
      <RiErrorWarningLine className="text-red-400 mr-1" />
      データの取得に失敗しました
    </div>
  )
}
