import { FadeInOut, Spinner } from '@/frontend/components'

export function LoadingOverlay({ isLoading }: { isLoading: boolean }) {
  return (
    <FadeInOut show={isLoading}>
      <div className="fixed inset-0 flex items-center justify-center">
        <Spinner />
        <span className="sr-only">Loading...</span>
      </div>
    </FadeInOut>
  )
}
