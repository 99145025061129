import React, { useCallback, useEffect, useState } from 'react'
import { difference } from 'lodash'
import { FileWithPath } from 'react-dropzone'

import {
  ApiError,
  BudgetTag,
  BudgetTagCategory,
  JobLogStatus,
  Org,
  TransitionTableImport,
  TransitionTableImportItem,
} from '@/frontend/api'
import { TransitionTableImportItemBulkUpsertPayload } from '@/frontend/api/importer'
import {
  ApiAlert,
  Button,
  ButtonLink,
  ModalWithIcon,
} from '@/frontend/components'
import { Alert } from '@/frontend/components/Alert/Alert'
import PrimaryTagCategoryLink from '@/frontend/features/importers/components/PrimaryTagCategoryLink'
import { getTransitionTableImportSettingUrl } from '../../getTransitionTableImportSettingUrl'
import { useImportSettingContext } from '../../hooks/useImportSettingContext'
import { useTransitionTableItemOverallStatus } from '../../hooks/useTransitionTableItemOverallStatus'
import TransitionTableDropzone from './TransitionTableDropzone'
import TransitionTableTagMappingImportTable, {
  TableRowInfo,
} from './TransitionTableTagMappingImportTable'
import TransitionTableTagUnmappedTable from './TransitionTableUnmappedTable'

type Props = {
  org: Org
  transitionTableImport: TransitionTableImport
  budgetTags: BudgetTag[]
  primaryBudgetTagCategory: BudgetTagCategory | null
  transitionTableImportItems: TransitionTableImportItem[]
  onFileDrop: (data: TransitionTableImportItemBulkUpsertPayload) => void
  isLoading: boolean
  apiError?: ApiError
}

// NOTE: RHFを使った場合に通常のstateとRHFのvalueとの同期が面倒そうだったので、ControlledComponentにしています。
export default function TransitionTableImportForm({
  org,
  transitionTableImport,
  budgetTags,
  primaryBudgetTagCategory,
  transitionTableImportItems,
  onFileDrop,
  isLoading,
  apiError,
}: Props) {
  const [unmappedFileNames, setUnmappedFileNames] = useState<string[]>([])
  const [mappedRows, setMappedRows] = useState<TableRowInfo[]>([])

  const overallStatus = useTransitionTableItemOverallStatus(mappedRows)

  useEffect(() => {
    setMappedRows(
      mergeTableRows(
        generateInitialTableRows(budgetTags, transitionTableImportItems),
        mappedRows,
      ),
    )
  }, [budgetTags, transitionTableImportItems])

  const mappedRowsMap = createTagMap(mappedRows)

  const upsertImportItems = (
    matchedTagFileMap: Map<number | null, FileWithPath>,
  ) => {
    const items = Array.from(matchedTagFileMap.entries()).reduce(
      (acc, [budgetTagId, file]) => {
        acc.push({ budgetTagId: budgetTagId, csvFile: file })
        return acc
      },
      [] as TransitionTableImportItemBulkUpsertPayload['transitionTableImportItems'],
    )
    if (items.length === 0) return
    onFileDrop({ transitionTableImportItems: items })
  }

  const onDrop = useCallback(
    (files: FileWithPath[]) => {
      const matchedTagFileMap = getMatchedTagFileMap(
        budgetTags,
        // NOTE: ドロップ時の選択順よって未割り当てのファイルの並びが変わるためソート
        files.sort((a, b) => a.name.localeCompare(b.name)),
      )

      upsertImportItems(matchedTagFileMap)

      const newMappedRows = budgetTags.map((budgetTag) => {
        const mappedRow = mappedRowsMap.get(budgetTag.id)
        const matchedFile = matchedTagFileMap.get(budgetTag.id)

        const transitionItemRow = mappedRow?.file ? mappedRow : undefined
        const matchedRow =
          matchedFile && buildMatchedRow(budgetTag, matchedFile)
        const unmatchedRow = buildUnmappedRow(budgetTag)

        return matchedRow || transitionItemRow || unmatchedRow
      })

      setMappedRows(newMappedRows)
      setUnmappedFileNames(
        difference(files, Array.from(matchedTagFileMap.values())).map(
          (file) => file.name,
        ),
      )
    },
    [budgetTags, mappedRows],
  )

  const updateTableRow = (
    budgetTagId: number | null,
    file: File | null,
    status: JobLogStatus | null,
  ) => {
    setMappedRows((prev) =>
      prev.map((row) => {
        if (row.budgetTag.id !== budgetTagId) return row
        return { ...row, file: file, status }
      }),
    )
  }

  const { importSetting } = useImportSettingContext()

  const handleFileDrop = useCallback(
    (budgetTagId: number | null, file: File) => {
      onFileDrop({
        transitionTableImportItems: [{ budgetTagId, csvFile: file }],
      })
      updateTableRow(budgetTagId, file, 'pending')
    },
    [onFileDrop],
  )

  return (
    <form>
      <div className="space-y-4">
        <TransitionTableDropzone onDrop={onDrop} disabled={isLoading} />
        {apiError && <ApiAlert apiError={apiError} />}
        {primaryBudgetTagCategory ? (
          <>
            <div className="flex justify-between space-x-4">
              <div className="flex-1">
                {budgetTags.length > 0 && (
                  <TransitionTableTagMappingImportTable
                    primaryBudgetTagCategory={primaryBudgetTagCategory}
                    rows={mappedRows}
                    onFileDrop={handleFileDrop}
                  />
                )}
              </div>
              {unmappedFileNames.length > 0 && (
                <div className="w-2/5">
                  <TransitionTableTagUnmappedTable
                    unmappedFileNames={unmappedFileNames}
                  />
                </div>
              )}
            </div>

            <ImportAlertWithModal
              status={overallStatus}
              modalBody={mappedRows
                .filter((row) => row.status === 'failed')
                .map((row, idx) => (
                  <div key={idx}>
                    <p>
                      <span>{row.budgetTag.name}：</span>
                      <span>{row.jobLogMessage?.body}</span>
                    </p>
                  </div>
                ))}
            />

            <div className="flex justify-end">
              <ButtonLink
                variant="primary"
                disabled={isLoading || !!apiError}
                href={getTransitionTableImportSettingUrl(
                  org.id,
                  transitionTableImport.id,
                  importSetting.budgetId,
                )}
              >
                アップロード
              </ButtonLink>
            </div>
          </>
        ) : (
          <div>
            <p className="text-gray-500">
              Primaryタグカテゴリが設定されていないため、推移表インポートを行うことができません。
              <PrimaryTagCategoryLink org={org}>
                Primaryタグカテゴリの設定
              </PrimaryTagCategoryLink>
              を行ってください。
            </p>
          </div>
        )}
      </div>
    </form>
  )
}

function getMatchedTagFileMap(budgetTags: BudgetTag[], files: FileWithPath[]) {
  return files.reduce<Map<BudgetTag['id'], FileWithPath>>((acc, file) => {
    const matchedTag = budgetTags.find((tag) => file.name.includes(tag.name))
    if (matchedTag) {
      acc.set(matchedTag?.id ?? null, file)
      return acc
    }
    return acc
  }, new Map())
}

function generateInitialTableRows(
  budgetTags: BudgetTag[],
  transitionTableImportItems: TransitionTableImportItem[],
): TableRowInfo[] {
  const tagImportItemMap = createTagMap(transitionTableImportItems)

  return budgetTags.map((budgetTag) => {
    const importItem = tagImportItemMap.get(budgetTag.id)

    if (!importItem?.fileName) return buildUnmappedRow(budgetTag)

    const { fileName, status, jobLogMessage } = importItem
    return {
      budgetTag,
      file: { name: fileName } as FileWithPath,
      status,
      jobLogMessage,
    }
  })
}

function mergeTableRows(
  initialRows: TableRowInfo[],
  mappedRows: TableRowInfo[],
): TableRowInfo[] {
  const tagInitialRowMap = createTagMap(initialRows)
  const tagMappedRowMap = createTagMap(mappedRows)
  const budgetTagIds = initialRows.map((row) => row.budgetTag.id)

  // NOTE: 処理終了している行そのまま使う
  const isUseMappedRow = (mappedRow: TableRowInfo) => {
    return (
      mappedRow.file &&
      mappedRow.status &&
      !['in_progress', 'pending'].includes(mappedRow.status)
    )
  }

  return budgetTagIds.map((budgetTagId) => {
    const mappedRow = tagMappedRowMap.get(budgetTagId)
    if (mappedRow && isUseMappedRow(mappedRow)) return mappedRow
    return tagInitialRowMap.get(budgetTagId)
  }) as TableRowInfo[]
}

function createTagMap<T extends { budgetTag: { id: number } | null }>(
  items: T[],
): Map<number | null, T> {
  return items.reduce<Map<number | null, T>>((acc, item) => {
    acc.set(item.budgetTag?.id ?? null, item)
    return acc
  }, new Map())
}

function buildMatchedRow(
  budgetTag: BudgetTag,
  file: FileWithPath,
): TableRowInfo {
  return { budgetTag, file, status: 'pending' }
}

function buildUnmappedRow(budgetTag: BudgetTag): TableRowInfo {
  return { budgetTag, file: null, status: null }
}

const ImportAlertWithModal = ({
  status,
  modalBody,
}: {
  status: JobLogStatus
  modalBody: React.ReactNode
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const handleOnClick = useCallback(() => setIsOpenModal(true), [])
  const handleOnClose = useCallback(() => setIsOpenModal(false), [])

  if (status === 'succeeded') {
    return (
      <Alert variant="success" title="データのインポートが完了しました。" />
    )
  }

  if (status !== 'failed') {
    return null
  }

  return (
    <>
      <div onClick={handleOnClick}>
        <Alert
          variant="error"
          title={
            <span>
              インポートが失敗したデータがあります。原因を確認の上、再度インポートしてください。
              <a
                className="cursor-pointer text-blue-600 hover:text-blue-800"
                onClick={() => {
                  setIsOpenModal(true)
                }}
              >
                失敗したデータと原因を表示
              </a>
            </span>
          }
        />
      </div>
      <ModalWithIcon
        open={isOpenModal}
        onClose={handleOnClose}
        variant="danger"
        title="データのインポートが失敗しました。"
        actions={
          <Button onClick={handleOnClose} variant="outlined">
            閉じる
          </Button>
        }
        scrollable
      >
        {modalBody}
      </ModalWithIcon>
    </>
  )
}
