import { useState } from 'react'

import { BudgetTagCategory, Org, PendingChecker } from '@/frontend/api'
import { Card as Section } from '@/frontend/components'
import TitleWithJobLogLink from '../components/TitleWithJobLogLink'
import TransitionTableImportContainer from '../components/TransitionTableImportContainer'
import { useImportSettingContext } from '../hooks/useImportSettingContext'
import { JobLogWithAttachedFile } from '../types'
import JournalImportFormContainer from './JournalImportFormContainer'
import MasterImportFormContainer from './MasterImportFormContainer'

export type DataSourceSectionProps = {
  org: Org
  primaryBudgetTagCategory: BudgetTagCategory | null
  lastCsvAccountItemsImportJobLog: JobLogWithAttachedFile | null
  lastInProgressCsvAccountItemsImportJobId: number | null
  lastCsvJournalImportJobLog: JobLogWithAttachedFile | null
  lastInProgressCsvJournalImportJobId: number | null
  isFirstImportedAccountItems: boolean
  setPendingChecker: (pendingChecker: PendingChecker) => void
}

export default function DataSourceSection({
  org,
  primaryBudgetTagCategory,
  lastCsvAccountItemsImportJobLog,
  lastInProgressCsvAccountItemsImportJobId,
  lastCsvJournalImportJobLog,
  lastInProgressCsvJournalImportJobId,
  isFirstImportedAccountItems,

  setPendingChecker,
}: DataSourceSectionProps) {
  const [isJournalImportable, setIsJournalImportable] = useState(
    isFirstImportedAccountItems,
  )

  const {
    importSetting: { dataFormat, importFormat },
  } = useImportSettingContext()

  return (
    <Section onGray>
      <Section.Title>
        <TitleWithJobLogLink org={org}>データ取得</TitleWithJobLogLink>
      </Section.Title>

      {/* CSVインポート、会計ソフトフォーマット */}
      {dataFormat === 'csv' && importFormat === 'bugyo_i' && (
        <div className="p-6 border space-y-12 text-sm rounded-md">
          {/* マスタインポート */}
          <MasterImportFormContainer
            org={org}
            jobLogWithAttachedFile={lastCsvAccountItemsImportJobLog}
            initialInProgressJobLogId={lastInProgressCsvAccountItemsImportJobId}
            setIsJournalImportable={setIsJournalImportable}
            setPendingChecker={setPendingChecker}
          />

          {/* 仕訳インポート */}
          <JournalImportFormContainer
            org={org}
            jobLogWithAttachedFile={lastCsvJournalImportJobLog}
            initialInProgressJobLogId={lastInProgressCsvJournalImportJobId}
            isJournalImportable={isJournalImportable}
          />
        </div>
      )}

      {/* CSVインポート、推移表フォーマット */}
      {dataFormat === 'csv' && importFormat === 'transitionTable' && (
        <div className="p-6 border space-y-12 text-sm rounded-md">
          {/* 推移表インポート */}
          <TransitionTableImportContainer
            org={org}
            primaryBudgetTagCategory={primaryBudgetTagCategory}
          />
        </div>
      )}
    </Section>
  )
}
