import { RiExternalLinkLine } from 'react-icons/ri'

import { Org } from '@/frontend/api'
import Form from '@/frontend/features/memberInvitations/bulkImport/Form'
import PageHeader from '@/frontend/features/memberInvitations/bulkImport/PageHeader'

type Props = {
  org: Org
  membersUrl: string
  memberInvitationsUrl: string
}
export default function NewPage({
  org,
  membersUrl,
  memberInvitationsUrl,
}: Props) {
  return (
    <>
      <PageHeader />
      <ContentLayout>
        <ContentHeader>
          <h1 className="leading-9 text-xl font-bold text-gray-800">
            メンバーの招待
          </h1>
        </ContentHeader>
        <Description />
        <Form
          org={org}
          membersUrl={membersUrl}
          memberInvitationsUrl={memberInvitationsUrl}
        />
      </ContentLayout>
    </>
  )
}

function ContentLayout({ children }: { children: React.ReactNode }) {
  return <div className="mx-auto max-w-[70rem]">{children}</div>
}

function ContentHeader({ children }: { children: React.ReactNode }) {
  return (
    <div className="py-4 flex items-center justify-between">{children}</div>
  )
}

function Description() {
  return (
    <ul className="text-sm mb-2">
      <li>アドバイザー組織にメンバーを招待します。招待は7日間有効です。</li>
      <li>
        一度に100名までのメンバーを招待できます。権限ごとの操作範囲や複数のメンバーを招待する方法は
        <a
          href="https://intercom.help/manageboard/ja/articles/5369867#h_38cc828d4b"
          target="_blank"
          rel="noreferrer"
          className="text-blue-600 inline-flex items-center"
        >
          <span>マニュアル</span>
          <RiExternalLinkLine />
        </a>
        を参照してください。
      </li>
    </ul>
  )
}
