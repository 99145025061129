import { useCallback, useState } from 'react'

import {
  createCustomReportGeneralComment,
  createPreparedReportGeneralComment,
  CreateReportGeneralCommentPayload,
  deleteCustomReportGeneralComment,
  deletePreparedReportGeneralComment,
  ReportGeneralComment,
  updateCustomReportGeneralComment,
  updatePreparedReportGeneralComment,
  UpdateReportGeneralCommentPayload,
} from '@/frontend/api'
import { ReportMeta } from '../types'

export function useReportGeneralComment(
  orgId: number,
  reportMeta: ReportMeta,
  reportGeneralComment?: ReportGeneralComment,
) {
  const [generalComment, setGeneralComment] =
    useState<ReportGeneralComment | null>(reportGeneralComment || null)

  return {
    generalComment,
    create: useCallback(
      async (data: CreateReportGeneralCommentPayload) => {
        switch (reportMeta.type) {
          case 'CustomReport': {
            const result = await createCustomReportGeneralComment(
              orgId,
              reportMeta.id,
              data,
            )
            if (result.ok) {
              setGeneralComment(result.val)
            }
            return result
          }
          case 'PreparedReport': {
            const result = await createPreparedReportGeneralComment(
              orgId,
              reportMeta.reportType,
              data,
            )
            if (result.ok) {
              setGeneralComment(result.val)
            }
            return result
          }
        }
      },
      [orgId, reportMeta],
    ),
    update: useCallback(
      async (
        reportGeneralComment: ReportGeneralComment,
        data: UpdateReportGeneralCommentPayload,
      ) => {
        if (!reportGeneralComment) {
          return null
        }
        switch (reportMeta.type) {
          case 'CustomReport': {
            const result = await updateCustomReportGeneralComment(
              orgId,
              reportMeta.id,
              reportGeneralComment.id,
              data,
            )
            if (result.ok) {
              setGeneralComment(result.val)
            }
            return result
          }
          case 'PreparedReport': {
            const result = await updatePreparedReportGeneralComment(
              orgId,
              reportGeneralComment.id,
              data,
            )
            if (result.ok) {
              setGeneralComment(result.val)
            }
            return result
          }
        }
      },
      [orgId, reportMeta],
    ),
    destroy: useCallback(
      async (reportGeneralComment: ReportGeneralComment) => {
        if (!reportGeneralComment) {
          return null
        }
        switch (reportMeta.type) {
          case 'CustomReport': {
            const result = await deleteCustomReportGeneralComment(
              orgId,
              reportMeta.id,
              reportGeneralComment.id,
            )
            if (result.ok) {
              setGeneralComment(null)
            }
            return result
          }

          case 'PreparedReport': {
            const result = await deletePreparedReportGeneralComment(
              orgId,
              reportGeneralComment.id,
            )
            if (result.ok) {
              setGeneralComment(null)
            }
            return result
          }
        }
      },
      [orgId, reportMeta],
    ),
    isNew: () => {
      return !generalComment?.id
    },
  }
}
