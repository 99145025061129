import { useState } from 'react'

import BudgetDropdown from './BudgetDropdown' // もっとスマートに指定できるはず...
import { DisplaySubject, Form } from './type'

type Props = {
  displaySubjects: DisplaySubject[]
  defaultValues: Form[] // Max3件まで
}

const labels = ['表示データ①', '表示データ②', '表示データ③']
export default function BudgetDropdowns({
  displaySubjects,
  defaultValues,
}: Props) {
  const [forms, setForms] = useState<Form[]>(defaultValues)
  const hiddenValue = toHiddenValue(forms)

  return (
    <div className="flex space-x-3">
      <input hidden name="budgets" value={hiddenValue} id="budgets" readOnly />
      {forms.map((form, index) => {
        const onChange = (form: Form) => {
          setForms(forms.map((f, i) => (i === index ? form : f)))
        }
        const label = labels[index]
        if (!label) {
          throw new Error('表示データは3件までしか対応していません')
        }
        return (
          <BudgetDropdown
            key={index}
            label={label}
            displaySubjects={displaySubjects}
            form={form}
            onChange={onChange}
          />
        )
      })}
    </div>
  )
}

function toHiddenValue(forms: Form[]) {
  const obj = forms.reduce<
    Record<number, { type: string; id: string; border_date?: string }>
  >((prev, form, index) => {
    const id =
      form.type === 'normal' ? form.normal.id : form.landingEstimation.id
    if (form.type === 'normal') {
      prev[index] = {
        type: form.type,
        id,
      }
    } else {
      prev[index] = {
        type: form.type,
        id,
        border_date: formatBorderDate(form),
      }
    }
    return prev
  }, {})

  return JSON.stringify(obj)
}

function formatBorderDate(form: Form) {
  if (form.type === 'normal') return ''
  if (!form.landingEstimation.borderDate) return ''

  const x = new Date(form.landingEstimation.borderDate)
  const y = x.getFullYear()
  const m = (x.getMonth() + 1).toString().padStart(2, '0')
  const d = x.getDate().toString().padStart(2, '0')
  return `${y}-${m}-${d}`
}
