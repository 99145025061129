import { Org } from '@/frontend/api'
import {
  AllocationVersionPayload,
  Form,
  useUpdateAllocationVersion,
} from '@/frontend/features/allocationVersions'
import {
  AccountItem,
  AccountItemGroup,
  AllocationDriver,
  BudgetTag,
  BudgetTagGroup,
  FiscalPeriod,
  ReservedItem,
} from '@/frontend/features/allocationVersions/types'

type Props = {
  org: Org
  allocationVersionId: number
  fiscalPeriods: FiscalPeriod[]
  reservedItems: ReservedItem[]
  accountItemGroups: AccountItemGroup[]
  accountItems: AccountItem[]
  budgetTagGroups: BudgetTagGroup[]
  budgetTags: BudgetTag[]
  allocationDrivers: AllocationDriver[]
  allocationVersionPayload?: AllocationVersionPayload
  allocationVersionsUrl: string
  accountItemsUrl: string
  reservedItemsUrl: string
}

export default function NewPage({
  org,
  allocationVersionId,
  fiscalPeriods,
  reservedItems,
  accountItemGroups,
  accountItems,
  budgetTagGroups,
  budgetTags,
  allocationDrivers,
  allocationVersionPayload,
  allocationVersionsUrl,
  accountItemsUrl,
  reservedItemsUrl,
}: Props) {
  const { updateItem } = useUpdateAllocationVersion(org)

  const onSubmit = async (data: AllocationVersionPayload) => {
    return updateItem(allocationVersionId, data)
  }

  return (
    <Form
      org={org}
      allocationVersionId={allocationVersionId}
      fiscalPeriods={fiscalPeriods}
      reservedItems={reservedItems}
      accountItemGroups={accountItemGroups}
      accountItems={accountItems}
      budgetTagGroups={budgetTagGroups}
      budgetTags={budgetTags}
      allocationDrivers={allocationDrivers}
      allocationVersionPayload={allocationVersionPayload}
      allocationVersionsUrl={allocationVersionsUrl}
      accountItemsUrl={accountItemsUrl}
      reservedItemsUrl={reservedItemsUrl}
      onSubmit={onSubmit}
    />
  )
}
